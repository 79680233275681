import { AppInsightsService } from '../Logs/AppInsights';

export interface ICsrfToken {
  csrf_token: string;
  expires_at: number;
  expires_in: number;
  token: string;
  token_type: string;
}

class Authentication {
  public static async login() {
    // user's origin url
    const returnUrl = window.location.href;

    // return url after the first login step
    const endLoginUrl = `/end_login?returnUrl=${returnUrl}`;

    // first login step
    window.location.href = `${window.AppSettings.API_ENDPOINT_URI}pmsession/login?returnUrl=${endLoginUrl}`;
  }

  public static async logout() {
    AppInsightsService.clearAuthenticatedUserContext();
    const url = `${window.AppSettings.API_ENDPOINT_URI}pmsession/logout?returnUrl=/`;
    window.location.href = `${url}`;
  }
}

export default Authentication;
