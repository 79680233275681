import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1)
  }
}))