import { gql } from '@apollo/client';

export const CreatePersonGroup = gql`
  mutation CreatePersonGroup(
    $accountCode: String!
    $name: String!
    $description: String
  ) {
    createPersonGroup(
      accountCode: $accountCode
      name: $name
      description: $description
    ) {
      name
    }
  }
`;
