import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import { DataRow } from 'Theme/components';
import useTranslate from 'Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import { TServiceSubscriptionInput } from './ServiceProfileGeneral.container';
import useModifiers from 'Hooks/useModifiers';
import Owners from './Owners';
import TopProfile from 'Components/Shared/ProfileTemplate/TopProfile';

interface Props {
  serviceSubscription: IServiceSubscription;
  onUpdate(serviceSubscriptionInput: TServiceSubscriptionInput): void;
}

const General = ({ serviceSubscription: subscription, onUpdate }: Props) => {
  const { boolToText } = useModifiers();
  const {
    accessByDefault,
    administratedValidation,
    service,
    account
  } = subscription;

  const ts = useTranslate();

  const handleUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => onUpdate({ [e.target.name]: checked });

  const showOwners =
    subscription.service.groupCode !== 'ADM-1' &&
    subscription.service.groupCode !== 'ADM-2' &&
    subscription.service.groupCode !== 'ADM-3' &&
    subscription.service.groupCode !== 'ADM-4';

  return (
    <>
      <TopProfile
        name={service.name ?? ''}
        youConsultSentence={ts('page.serviceProfile.youConsultService')}
        colorBlockSubTitle={ts('common.accountLabel', {
          ...account
        })}
        colorBlockTitle={ts('page.serviceProfile.info.title', {
          value: service.name
        })}
        titleId={TestIds.pages.serviceProfile.infoTitle}
        subtitleId={TestIds.pages.serviceProfile.infoSubtitle}
      />
      <Card>
        <CardContent>
          <Box p={2}>
            <Typography
              variant="h5"
              color="inherit"
              data-testid={TestIds.pages.serviceProfile.aboutTitle}
            >
              {ts('page.serviceProfile.aboutService')}
            </Typography>
          </Box>
          <>
            <DataRow label={ts('common.terminology.description')} divider>
              <Typography
                variant="body2"
                data-testid={TestIds.pages.serviceProfile.serviceDescription}
              >
                {service.description}
              </Typography>
            </DataRow>
            <DataRow label={ts('profile.about.serviceFamily')} divider>
              <Typography
                variant="body2"
                data-testid={TestIds.pages.serviceProfile.serviceFamily}
              >
                {service.family}
              </Typography>
            </DataRow>
            <DataRow label={ts('page.serviceProfile.label.serviceCode')} divider>
              <Typography
                variant="body2"
                data-testid={TestIds.pages.serviceProfile.serviceGroup}
              >
                {service.groupCode}
              </Typography>
            </DataRow>
            <DataRow label={ts('profile.about.outsourceableService.title')} divider={subscription.maxSubscriptions !== null}>
              <Typography
                variant="body2"
                data-testid={TestIds.pages.serviceProfile.serviceExternalisable}
              >
                {boolToText(service.isExternalisable)}
              </Typography>
            </DataRow>
            {subscription.maxSubscriptions !== null && (
              <DataRow label={ts('page.serviceProfile.serviceMembers.alert.title')}>
              <Typography
                variant="body2"
              >
                {subscription.maxSubscriptions}
              </Typography>
            </DataRow>
            )}
          </>
        </CardContent>
      </Card>
      <Box p={1} />
      <Card>
        <CardContent>
          <Box p={2}>
            <Typography variant="h5" color="inherit">
              Paramétrage du service
            </Typography>
          </Box>
          <>
            <DataRow
              label={ts('page.serviceProfile.label.defaultService')}
              divider
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="body2"
                    data-testid={TestIds.pages.serviceProfile.serviceGroup}
                  >
                    {ts('page.serviceProfile.info.defaultService')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={ts(
                      accessByDefault
                        ? 'common.action.deactivate'
                        : 'common.action.activate'
                    )}
                  >
                    <Switch
                      aria-checked={accessByDefault}
                      aria-label="accessByDefault"
                      checked={accessByDefault}
                      onChange={handleUpdate}
                      name="accessByDefault"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </DataRow>
            <DataRow
              label={ts('page.serviceProfile.label.adminValidation')}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="body2"
                    data-testid={TestIds.pages.serviceProfile.serviceGroup}
                  >
                    {ts('page.serviceProfile.info.adminValidation')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={ts(
                      administratedValidation
                        ? 'common.action.deactivate'
                        : 'common.action.activate'
                    )}
                  >
                    <Switch
                      aria-label="administratedValidation"
                      aria-checked={administratedValidation}
                      checked={administratedValidation}
                      onChange={handleUpdate}
                      name="administratedValidation"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </DataRow>
          </>
        </CardContent>
      </Card>
      {showOwners && (
        <>
          <Box p={1} />
          <Owners />
        </>
      )}
    </>
  );
};

export default General;
