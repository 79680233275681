import { Button, Grid } from '@mui/material';
import * as React from 'react';
import TestIds from 'Tests/TestIds';
import { useStyles } from '../../../Directories.styles';
import useTranslate from '../../../../../../../../Hooks/useTranslate';
import MergeDirectoryDialog from './Dialog';

export interface Props {
  person: IPersonWithAccount;
  directoryToMerge: string;
}

function MergeDirectory(props: Props) {
  const classes = useStyles();
  const { directoryToMerge, person } = props;
  const ts = useTranslate();

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  return (
    <>
      <Grid item container justifyContent="center" className={classes.action}>
        <Button
          color="primary"
          className={classes.cta}
          onClick={() => setOpenDialog(!openDialog)}
          size="medium"
          data-testid={
            TestIds.pages.profile.mergeAccount.button + directoryToMerge
          }
        >
          {ts('page.userProfile.mergeAction', { value: directoryToMerge })}
        </Button>
      </Grid>
      <MergeDirectoryDialog
        openDialog={openDialog}
        handleOpenDialog={() => setOpenDialog(!openDialog)}
        directoryName={directoryToMerge}
        person={person}
      />
    </>
  );
}

export default MergeDirectory;
