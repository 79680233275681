import './polyfills';
import * as ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import { CookiesProvider } from 'react-cookie';

import './index.css';
import AppInsightsContext from './Logs/AppInsights.context';

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MOCK === 'true'
) {
  const { worker } = require('./Connection');
  worker.start({ onUnhandledRequest: 'bypass' });
}

ReactDOM.render(
  <CookiesProvider>
    <AppInsightsContext>
      <App />
    </AppInsightsContext>
  </CookiesProvider>,
  document.getElementById('root') as HTMLElement
);

unregister();
