import { useCookies } from 'react-cookie';

const usePMCookie = () => {
  const cookie = useCookies(['PMSession']);

  return {
    cookie: cookie[0],
    setCookie: cookie[1],
    removeCookie: cookie[2]
  };
};

export default usePMCookie;
