import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import ErrorContent from './ErrorContent';
import balloons from 'Theme/assets/images/balloons.png';
import mountains from 'Theme/assets/images/mountains.png';
import { useStyles } from './PageError.styles';
import useTranslate from 'Hooks/useTranslate';

interface Props {
  accessDenied?: boolean;
  title?: string;
  textPrimary?: string;
  textSecondary?: string;
}

function PageError(props: Readonly<Props>) {
  const classes = useStyles();
  const ts = useTranslate()

  const { accessDenied, title, textPrimary, textSecondary } = props;

  return (
    <Grid
      container
      className={clsx(classes.root, accessDenied ? classes.invertedRoot : '')}
    >
      <Grid item sm={4} style={{ zIndex: 0 }}>
        <div>
          {accessDenied ? (
            <img
              src={mountains}
              alt="paper mountains"
              className={classes.customBg}
            />
          ) : (
            <img src={balloons} alt={ts('error.illustrationAlt.ballons')} className={classes.bg} />
          )}
        </div>
      </Grid>
      <Grid
        item
        sm={8}
        className={clsx(
          classes.content,
          accessDenied ? classes.invertedContent : ''
        )}
      >
        <Box p={3}>
          <ErrorContent title={title} textPrimary={textPrimary} textSecondary={textSecondary} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default PageError;
