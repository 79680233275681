import { Box, Card, CardContent, Typography } from '@mui/material';
import TableServicesIpRestrictions from 'Components/TableServicesIpRestrictions';
import FormIpFiltering from 'Components/FormIpFiltering';
import useTranslate from '../../../Hooks/useTranslate';

interface Props {
  serviceSubscription: IServiceSubscription;
}

const ServiceProfileRestrictions = ({ serviceSubscription }: Props) => {
  const ts = useTranslate();
  const formTitle = ts('page.servicesIpRestrictions.index.title', {
    service: serviceSubscription.service.name
  });
  const listTitle = ts('page.servicesIpRestrictions.index.tableTitle');

  return (
    <>
      <Card>
        <CardContent>
          <Box p={2}>
            <Typography variant="h4" children={formTitle} paragraph />
            <Box p={2} />
            <FormIpFiltering />
          </Box>
        </CardContent>
      </Card>
      <Box p={1} />
      <Card>
        <CardContent>
          <Box p={2}>
            <Typography variant="h5" children={listTitle} paragraph />
            <Box p={2} />
            <TableServicesIpRestrictions />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ServiceProfileRestrictions;
