export type TSessionStorageKeys =
  | 'WELCOME_MODAL_DISABLED'
/**
 * Hook to use the session storage object inside the browser window
 */
export const useSessionStorage = () => {
  const setSession = (name: TSessionStorageKeys, value: any) => {
    const valueToAdd = value.toString();
    sessionStorage.setItem(name, valueToAdd);
  };
  const getSession = (name: TSessionStorageKeys) =>
    sessionStorage.getItem(name);
  const removeSession = (name: TSessionStorageKeys) =>
    sessionStorage.removeItem(name);
  const clearSession = () => sessionStorage.clear();
  return { getSession, setSession, removeSession, clearSession };
};
