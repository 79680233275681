import { gql } from '@apollo/client';
import { LightPersonProfileFragment } from '../../fragments/person/LightPersonProfile';

export const GetLightPersonProfile = gql`
  query GetLightPersonProfile($email: String!) {
    lightPersonProfile(email: $email) {
      ...LightPersonProfile
    }
  }
  ${LightPersonProfileFragment}
`;
