import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
      },
      inputsContainer: {
        // !important is here to take precedence over <Grid />'s top padding
        paddingTop: `${theme.spacing(5)} !important`
      },
      illustrationContainer: {},
      wizard: {
        textAlign: 'center'
      },
      wizardHeading: {
        marginBottom: theme.spacing(1)
      },
      wizardImage: {
        marginBottom: theme.spacing(3)
      }
}))