import flat from 'flat';
import {
    AuditAction,
    AuditActionResult,
    ActivityType,
    PersonAccessRightStatus
} from '../../Apollo';
import { ApiKeyEventType } from '../../Apollo/fragments/apikey/ApiKeyEvent';
import TProfileManagerMessages from '../TProfileManagerMessages';
import { DomainValidationMode } from 'Apollo/fragments/organization/DomainValidationMode';

export default flat<TProfileManagerMessages, { [messageId: string]: string }>({
    applicationName: 'Gestió de comptes',
    common: {
        action: {
            loading: 'Carregant...',
            search: 'Cerca',
            subscribe: 'Subscriure',
            accept: 'Acceptar',
            confirm: 'Confirmar',
            deny: 'Rebutjar',
            add: 'Afegir',
            delete: 'Eliminar',
            edit: 'Editar',
            back: 'Enrere',
            export: 'Exportar',
            finish: 'Finalitzar',
            previous: 'Anterior',
            next: 'Següent',
            cancel: 'Cancel·lar',
            reset: 'Restablir',
            toEnd: 'Finalitzar',
            agree: "D'acord",
            retry: 'Reintentar',
            understand: "He entès",
            moreDetails: 'Més detalls',
            validate: 'Validar',
            migrate: 'Migrar',
            open: 'Obrir',
            close: 'Tancar',
            ignore: 'Ignorar',
            clear: 'Esborrar la cerca',
            yes: 'Sí',
            no: 'No',
            ok: 'D\'acord',
            backToHomePage: `Tornar a l'inici`,
            activate: 'Activar',
            deactivate: 'Desactivar',
            doNotModify: 'No modificar',
            seeProfile: 'Veure el perfil',
            login: 'Iniciar sessió',
            copyCode: 'Copiar el codi',
            see: {
                seeMore: 'Veure més',
                seeDescription: 'Veure la descripció',
                seeExpirationDate: "Veure la data de caducitat",
                seeGroupDetails: 'Veure els detalls del grup',
                seeTheList: 'Veure la llista'
            }
        },
        date: {
            editExpirationDate: `Editar la data de caducitat`,
            expireDetails: 'Caduca el {date} - {hour}'
        },
        person: {
            role: {
                adm: 'Administrador',
                superAdm: 'Super administrador',
                superAdmSentence: 'Estàs navegant amb drets de super administrador',
                superReaderSentence: 'Estàs navegant amb drets de super lector',
                admSuperReader: 'Administrador / Super lector',
                reader: 'Lector',
                superReader: 'Super lector',
                user: 'Usuari'
            }
        },
        service: {
            unavailable: 'Servei no disponible',
            access: 'Accés'
        },
        terminology: {
            accessRights: "Drets d'accés",
            alert: {
                info: 'Informació'
            },
            client: 'Compte de client',
            status: 'Estat',
            active: 'Actiu',
            inactive: 'Inactiu',
            account: 'Compte de client',
            accounts: 'Comptes de clients',
            description: 'Descripció',
            codeGroup: 'Codi de grup',
            famillies: 'famílies',
            groups: 'grups',
            localAccount: 'Compte local',
            menu: 'Menú',
            MSaccount: 'Compte Microsoft',
            GoogleAccount: 'Compte Google',
            required: 'Obligatori',
            services: 'serveis',
            tutorial: 'Tutorial',
            immutableId: 'Id immutable',
            email: 'Adreça de correu electrònic',
            or: 'o',
            groupName: 'Nom del grup',
            yes: 'Sí',
            no: 'No'
        },
        dates: {
            creationDate: 'Data de creació: {value}',
            today: "Avui",
            yesterday: 'Ahir',
            previously: 'Anteriorment',
            thisInstant: 'fa 0 segons',
            jours: 'dies'
        },
        label: {
            id: 'ID: ',
            page: 'Pàgina',
            rowPerPage: 'Resultats / pàgina',
            name: 'Nom'
        },
        pagination: {
            firstPage: 'Primera pàgina',
            lastPage: 'Última pàgina',
            previousPage: 'Pàgina anterior',
            nextPage: 'Pàgina següent',
            displayedRows: '{from}-{to} de {count}'
        },
        xhrMaxItemCountReached:
            'No pots seleccionar més de {XhrMaxItemCount} usuaris actualment.',
        welcome: 'Benvingut,',
        accountLabel: '{name} - {zipCode} {city} / {code}',
        switchAccount: 'Canviar de compte de client',
        disabledAccount: '(Desactivat)',
        tableHead: {
            name: 'Nom',
            status: 'Estat',
            active: 'Actiu',
            code: 'Codi',
            validationCode: 'Codi de validació',
            expiration: 'Caducitat'
        }
    },
    component: {
        modals: {
            createUser: {
                heading: 'Convidar usuaris',
                submit: 'Convidar',
                wizard: {
                    heading: 'Gestiona els teus usuaris',
                    subheading:
                        'Personalitza i administra fàcilment les dades, els accessos i els grups dels teus usuaris.'
                },
                labels: {
                    emails: 'Adreces de correu electrònic',
                    emailsHelperText:
                        'Afegeix diversos usuaris utilitzant la coma. Màxim 100 usuaris.',
                    accountCode: 'Codi del compte de client'
                },
                invitationSent:
                    'Has enviat {invitationsQuantity, plural, zero {{invitationsQuantity, number} invitació} one {{invitationsQuantity, number} invitació} other {{invitationsQuantity, number} invitacions}}',
                invitationFailed: "Les següents invitacions no s'han pogut crear:"
            }
        },
        form: {
            placeholder: {
                emailsInput: 'Ex: nom@empresa.com, nom1@empresa.com'
            },
            label: {
                emailInputCount: 'Total d\'adreces de correu electrònic a afegir: {emailsCount}'
            }
        },
        accessrights: {
            pendingRequest: {
                count: `{value, plural, zero { {value} sol·licitud } one { {value} sol·licitud } other { {value} sol·licituds }} `,
                sentence: `{value, plural, zero { {value} sol·licitud } one { {value} sol·licitud} other { {value} sol·licituds}} pendent`
            }
        },
        administratedAccountsSelector: {
            allAccounts: 'Tots els comptes',
            seeAll: 'Veure tots els comptes'
        },
        searchBars: {
            availabledResult: {
                noResultPrimary: 'Cap resultat coincideix amb la teva entrada',
                noResultSecondary:
                    'Troba un usuari pel seu nom, cognom o correu electrònic.'
            },
            userSelectedSearchBar: {
                sentence: `{total, plural, one {{total} sol·licitud coincideix amb la teva cerca} other {{total} sol·licituds coincideixen amb la teva cerca}}`
            },
            placeholder: {
                users: 'Cerca usuaris',
                services: 'Cerca serveis'
            }
        },
        table: {
            selectAll: 'Seleccionar tot ({count})',
            elementSelected: '{count} element(s) seleccionat(s)'
        },
        tableServiceOwners: {
            remove: {
                confirmation:
                    'Estàs a punt d\'eliminar el propietari del servei {name}',
                success: "L'usuari ja no és propietari del servei"
            }
        },
        editToggle: {
            editGroup: {
                update: {
                    success: 'El grup s\'ha actualitzat correctament'
                }
            }
        },
        userAccountUpdate: {
            tooltip: 'Modificar el compte de client de l\'usuari',
            unlink: `Desassociar del compte de client {value}`,
            link: 'Associar a un altre compte de client',
            unlinkDialogTitle:
                'Estàs a punt de desassociar l\'usuari del compte de client',
            irreversibleAction:
                'Aquesta acció és irreversible. Vols continuar?',
            linkDialogTitle:
                'Estàs a punt de modificar el compte de client associat a aquest usuari',
            whichNewAccount: 'A quin compte vols associar l\'usuari?',
            chooseAccount: 'Compte d\'associació',
            lostAccessCount: `L'usuari perdrà accés a {value, plural, one {{value} servei} other {{value} serveis}}.`,
            inheritedServicesFromGroup: ` - (servei heretat del grup {value})`,
            alertInfo: {
                previousMembersList: `L'usuari ja no apareixerà a la llista d'usuaris del compte de client {value}.`,
                previousAccountAccessRights: `L'usuari perdrà els seus accessos als següents serveis:`,
                previousExternalisableAccessRights: `Si l'usuari encara té accés a un servei externalitzable de {value} abans de la teva confirmació, aquest es mantindrà.`,
                newAccountMembersList: `L'usuari apareixerà als membres del compte de client {value}.`
            },
            alertWarning: {
                retainedAccessRights: `Els següents accessos es mantindran:`,
                unlinkGroups: `L'usuari serà desassociat de tots els grups del compte de client.`,
                alertWarningTitle: 'Atenció',
                noMoreAccessToProfile: `Ja no podràs accedir a aquest perfil d'usuari un cop es desassociï del compte de client {value}.`,
                removeAllAccessWarning: `Si vols retirar, totalment o parcialment, els accessos de l'usuari {value} als serveis esmentats anteriorment, assegura't de retirar-los abans de desassociar-lo del compte de client. Pots fer aquesta acció des de la seva llista de`,
                awareness:
                    'Aquesta acció és sensible. Sigues prudent abans de confirmar.'
            },
            switchAccount: {
                newAffiliationAccount: `Nou compte de client d'associació`,
                dialogTitle: `Estàs a punt de modificar el compte de client associat a aquest usuari. Vols continuar?`,
                newInheritedServices: `L'usuari obtindrà automàticament accés als serveis per defecte d'aquest compte de client.`,
                servicesListLink: `Veure la llista de serveis`,
                alertInfoTitle: `Per al nou compte de client d'associació`
            },
            accessToProfileFromNewAccount: `Podràs accedir a aquest perfil d'usuari des d'aquest compte de client.`,
            forSuperUserOnly: {
                newAccessToProfile: `Podràs accedir a aquest perfil d'usuari des de la llista de tots`,
                administratedUsers: `els usuaris que administres`
            },
            mutation: {
                linkToNewAccountSuccess: `L'usuari s'ha associat correctament al compte de client {value}.`,
                unLinkSuccess: `L'usuari s'ha desassociat correctament del compte de client {value}.`
            }
        }
    },
    selectorList: {
        ipRangeType: {
            persons: 'Usuari',
            apiKey: 'Clau API'
        }
    },
    error: {
        noAccountSelected: 'Cap compte de client seleccionat',
        selectAnAccount:
            'Seleccioneu prèviament un compte de client per veure els detalls d\'aquest servei.',
        somethingHappened: 'S\'ha produït un error',
        comeBackLater: 'Torneu-ho a provar més tard',
        oops: 'Ups.',
        copyText: 'Copiar el text',
        textCopied: 'Text copiat',
        details: 'Detalls dels errors',
        noDataAvailable: 'No hi ha dades disponibles!',
        infoWillBeDisplayedHere: 'La vostra informació es mostrarà aquí.',
        form: {
            invalidEmail: 'Aquesta adreça de correu electrònic no és vàlida',
            invalidEmailsLength: 'El nombre màxim de correus electrònics és de 100',
            emailsDuplication: 'Hi ha almenys un correu electrònic duplicat',
            invalidIp: 'Comproveu el format',
            invalidIpRange: 'Interval IP no vàlid',
            requiredField: 'El camp és obligatori',
            min: 'El valor mínim és {value}',
            max: 'El valor màxim és {value}',
            minLength: 'El text és massa curt. Mín. {value} caràcters',
            maxLength: 'El text és massa llarg. Màx. {value} caràcters'
        },
        type: {
            signin: 'Error d\'inici de sessió',
            activation: 'Error d\'activació',
            requiredField: 'El camp és obligatori.',
            userAlreadyExists: 'L\'usuari ja existeix.',
            network: 'S\'ha produït un error de xarxa. Estàs connectat a Internet?',
            batch: 'L\'acció s\'ha interromput a causa de diversos errors.',
            E101: 'L\'usuari ja existeix en aquest compte de client.',
            E104: 'Diversos usuaris amb aquests paràmetres.',
            E121: 'No està permès eliminar el propi perfil',
            E123: 'Ja existeix un compte amb aquesta adreça de correu electrònic.',
            E125: 'L\'adreça de correu electrònic és obligatòria.',
            E126: 'No està permès eliminar l\'últim usuari d\'un compte.',
            E139: 'No està permès eliminar aquest perfil',
            E145: 'El domini del correu electrònic està vinculat a un directori federat.',
            E247: 'No està permès l\'eliminació massiva',
            E403: 'Accés denegat',
            E404: 'Pàgina no trobada',
            E500: 'S\'ha produït un error, torneu-ho a provar més tard.',
            HC0045: 'Processament en curs, comproveu el resultat de la vostra operació en uns minuts',
            HC0045A: 'Sol·licitud pendent, actualitzeu el navegador per veure els resultats.',
            invalidDateFormat: 'El format de la data no és vàlid',
            superUserAccessRefused: 'No teniu accés de super usuari'
        },
        sentence: {
            emailChange: 'No s\'ha pogut modificar el correu electrònic',
            signin: 'S\'ha produït un error durant el vostre intent d\'inici de sessió',
            activation:
                'El vostre enllaç d\'activació ha caducat o el vostre perfil ja està activat.',
            E403: 'No teniu permís per accedir a aquest contingut.',
            E404: 'La pàgina sol·licitada no s\'ha trobat.'
        },
        illustrationAlt: {
            ballons: 'Il·lustració d\'una casa de paper que flota gràcies a uns globus'
        }
    },
    menu: {
        activities: 'Activitats',
        apiKey: 'Claus API',
        dashboard: 'Tauler de control',
        groups: 'Grups',
        management: 'Gestió',
        settings: 'Preferències',
        notifications: 'Notificacions',
        services: 'Serveis',
        accessRights: "Sol·licituds d'accés",
        users: 'Usuaris',
        validateRequest: 'Validar una sol·licitud',
        profileManager: "Tornar a la versió antiga",
        profile: 'Perfil',
        shortcuts: 'Dreceres',
        addUser: 'Convidar usuaris',
        addGroup: 'Afegir un grup',
        organizations: 'Organitzacions'
    },
    myMenu: {
        profile: 'Perfil',
        logout: 'Tancar sessió'
    },
    banner: {
        pendingRequest: `La vostra delegació temporal d'administració finalitza en {hours}h {minutes}mn`,
        bannerText: `Demaneu a un administrador del compte la delegació temporal d'administració.`,
        buttonText: `Demaneu una delegació d'administració`,
        accessRightRequestDialog: {
            title: `Demaneu l'accés a un administrador`,
            noAdministratorYet:
                "Aquest compte encara no té administrador. Poseu-vos en contacte amb el suport.",
            text: `Introduïu l'adreça de correu electrònic d'un administrador del compte de client per fer modificacions. Enviarem un correu electrònic de confirmació a l'administrador perquè validi la vostra sol·licitud.`,
            subtext: `El correu electrònic conté un enllaç de validació vàlid durant 5 minuts. Un cop acceptada la vostra sol·licitud, tindreu accés a l'administració del compte durant 2 hores.`,
            sendEmail: `Enviar el correu electrònic`,
            pendingRequest: `S'ha enviat un missatge a l'administrador per validar la sol·licitud de delegació.`,
            pendingRequestInfo: `El correu electrònic enviat a l'administrador del compte conté un enllaç vàlid durant 5 minuts. Aquest enllaç li permet acceptar la vostra sol·licitud. Un cop validada la vostra sol·licitud, tindreu accés temporal a l'administració del compte durant 2 hores.`
        }
    },
    delegation: {
        success: "Els drets d'administració s'han atorgat amb èxit.",
        error:
            "No s'han pogut afegir els drets d'administració. Si us plau, contacteu amb el suport."
    },
    onBoarding: {
        step1H5: 'Hem actualitzat la gestió de comptes.',
        step1Subtitle1:
            'Descobrim algunes funcionalitats de la nova versió.',
        step2H5: 'Visualitzeu fàcilment la informació important',
        step2Subtitle1:
            'Un tauler de control sintètic us permet visualitzar la informació principal dels comptes que administreu.',
        step3H5: 'Gestiona més fàcilment les subscripcions dels teus usuaris.',
        step3Subtitle1:
            'Les llistes us permeten seleccionar fàcilment diversos usuaris i serveis per obrir ràpidament els accessos als vostres usuaris.'
    },
    page: {
        accountProfile: {
            title: 'Compte {value}',
            youConsult: 'Estàs consultant el compte de client',
            aboutTitle: 'Informació administrativa',
            membersSection: {
                membersTitle: 'Membres',
                members: 'usuari(s)',
                seeAllMembers: 'Veure tots els usuaris',
                notificationSettings: 'Preferència de notificació',
                notificationSettingsTooltip:
                    "Els usuaris són notificats per correu electrònic quan se'ls afegeix accés a un servei."
            },
            sections: {
                administrators: {
                    title: 'Administradors',
                    numberOfAdministrators: `Nombre d'administrador(s)`,
                    seeAllAdministrators: 'Veure tots els administradors'
                },
                about: {
                    accountName: 'Nom del compte',
                    accountCode: 'Codi'
                },
                services: {
                    servicesCatalog: 'Servei(s) al catàleg',
                    seeCatalog: 'Veure el catàleg'
                },
                apikeys: {
                    title: "Claus d'API",
                    activedApiKeys: 'Claus Actives',
                    seeAll: "Veure totes les claus d'API"
                },
                groups: {
                    title: 'Grup(s)',
                    seeAllGroups: 'Veure tots els grups'
                },
                pendingRequests: {
                    title: "Sol·licitud(s) d'accés pendent",
                    manageRequests: 'Gestionar les sol·licituds'
                }
            }
        },
        apiKeyProfile: {
            info: {
                title: `Nom de la clau:`,
                subtitle: `ID: {value}`
            },
            technicalInformations: 'Informació tècnica',
            contact: 'Interlocutor',
            creator: 'Creador',
            creationDate: 'Data de creació',
            revocationDate: 'Data de revocació',
            relatedServices: 'El servei associat',
            noRelatedServices: `No hi ha cap servei associat a aquesta clau d'API per ara`,
            service: 'Servei',
            customerAccount: 'Compte de client',
            activities: 'Activitats',
            allActivities: 'Veure totes les activitats'
        },
        apikeyProfile: {
            menu: {
                general: 'General',
                audit: 'Activitats'
            },
            title: `Clau d'API {uuid}`,
            activities: {
                title: 'Activitats',
                type: `Tipus`,
                date: `Data`,
                author: `Autor`
            },
            activity: {
                type: `{value, select, ${ApiKeyEventType.Creation} {Creació} ${ApiKeyEventType.AccessGranted} {Accés autoritzat} ${ApiKeyEventType.Revocation} {Revocació} ${ApiKeyEventType.AccessDenied} {Accés denegat} other {-}}`,
                emptyStateTitle: "No hi ha historial per aquesta clau d'API",
                emptyStateText: 'Els propers esdeveniments apareixeran aquí'
            }
        },
        changeEmailValidation: {
            title: "Validació de la sol·licitud de canvi d'adreça de correu electrònic",
            subtitle:
                'La vostra nova adreça de connexió s\'ha actualitzat correctament: {email}',
            goToCegid: 'Anar a Cegid Account'
        },
        accessRights: {
            newRequests: "Novetats sol·licituds d'accés",
            title: "Sol·licituds d'accés",
            subtitle:
                "Consulteu i gestioneu les sol·licituds d'accés als serveis dels vostres usuaris",
            empty: {
                title: `Cap sol·licitud de subscripció`,
                subtitle: `Les properes sol·licituds d'accés apareixeran aquí.`
            },
            acceptRequestSuccess: `Acabeu d'acceptar la sol·licitud`,
            denyRequestSuccess: `Acabeu de rebutjar la sol·licitud`,
            updateRequestStatusSuccess: `La sol·licitud s'ha actualitzat correctament`,
            changeExpirationDate: `Modificar`,
            cancelExpirationDate: `Cancel·lar`
        },
        accountSelection: {
            title: 'Selecció del compte de client',
            whichAccount: 'En quin <b>compte de client</b> voleu treballar?',
            accountsFound: `{count, plural, zero {{count, number} compte de client trobat} one {{count, number} compte de client trobat} other {{count, number} comptes de clients trobats}}`,
            accountLabel: 'Codi del compte de client',
            loginToAccount: 'Entrar al compte',
            noAccountsFound: 'Cap compte trobat'
        },
        administrators: {
            title: 'Administradors',
            index: {
                administrators: 'Administradors',
                email: 'Correu electrònic',
                allStatus: 'Estat',
                language: 'Llengua'
            },
            accountAdministrators: 'Administradors del compte de client',
            emptyState: {
                title: 'Cap administrador definit',
                description:
                    'És important definir un administrador per a aquest tercer.'
            }
        },
        apiKeys: {
            title: `Claus d'API`,
            addApikeyTooltip:
                "Una clau API associada a un servei permet donar un dret d'accés a un programa informàtic.",
            requireAccountSelectionTooltip: `Seleccioneu prèviament un compte per generar una clau API.`,
            refreshTooltip: 'Actualitzar la llista',
            apikeyHistory: `Activitats de la clau {name}`,
            noApiKey: `Cap clau d'API`,
            futureApiKeysText: `Les futures claus d'API generades apareixeran aquí.`,
            creator: `Creador: {fullName}`,
            undefinedCreator: 'Creador desconegut',
            undefinedCreationDate: 'Data de creació desconeguda',
            revoke: 'Revocar',
            details: 'Detalls',
            history: 'Activitats',
            revocationConfirmationApiKeyName: `Revocaràs la clau d'API `,
            revocationConfirmationText: `. Aquesta acció serà definitiva. Vols continuar?`,
            revocationSuccessMessage: `La clau d'API {value} ha estat revocada amb èxit.`,
            revokedTitle: 'Revocades',
            activeTitle: 'Actives',
            generate: {
                apiKey: `Generar una clau d'API`,
                theApiKey: `Generar la clau d'API`,
                selectService: `Seleccionar un servei`,
                enterNewApiKeyName: `Introduir el nom d'una nova clau`,
                apiKeyNameAlreadyExist: `Aquest servei ja disposa d'una clau d'API amb el mateix nom.`,
                mandatoryService: `Heu de triar un servei.`,
                mandatoryName: `Heu d'introduir un nom per a aquesta nova clau.`
            },
            success: {
                title: `Clau d'API generada amb èxit`,
                yourApiKey: `La vostra clau d'API`,
                apiKeySecret: `Secret de la clau d'API`,
                warning: `El secret de la clau d'API generada no es podrà mostrar mai més.`
            }
        },
        createPersonGroup: {
            title: 'Afegir un grup',
            heading: 'Gestiona més fàcilment els teus usuaris',
            subheading:
                'Pots classificar els usuaris de la teva empresa en grups. Els serveis associats als grups s\'afegiran automàticament a cada usuari membre del grup.',
            stepInformation: {
                name: 'Nom del grup',
                description: 'Descripció',
                accountCode: 'Codi del compte de client'
            },
            success: `El grup {groupName} s'ha afegit amb èxit`,
            groupAdded: '• S\'ha afegit un grup.'
        },
        dashBoard: {
            title: 'Tauler de control',
            index: {
                accountList: 'Llista de comptes de clients',
                administrators: 'Administradors',
                initializing: 'Inicialització',
                subscriptionRequests: {
                    title: "Novetats sol·licituds d'accés als serveis",
                    noPendingRequests: "No hi ha sol·licituds d'accés pendents"
                },
                totalAdministrators: `{count, plural, one {{count, number} element} other {{count, number} elements}}`
            },
            disabledUser: "Aquest usuari ja no existeix"
        },
        groupProfile: {
            title: 'Grup {name}',
            youConsultGroup: 'Estàs consultant el grup',
            information: {
                title: 'Informació'
            },
            removeGroup: {
                removeGroupConfirmation: `Estàs a punt d'eliminar el grup {groupName}. Els membres actuals del grup perdran els seus accessos (via el grup) als serveis associats. Vols continuar?`,
                removeSuccess: `El grup {groupName} s'ha eliminat amb èxit.`
            },
            creationBy: ' per {creator}',
            editProfile: {
                title: 'Editar el grup',
                name: 'Nom',
                description: 'Descripció'
            },
            services: {
                subscription: {
                    removeConfirmation: `Estàs a punt d'eliminar el servei {serviceName} del grup {groupName}`,
                    addServicesTitle: `Afegir un/uns servei(s) al grup`,
                    deleteMessage: `El servei s'ha eliminat del grup.`,
                    successMessage: `{count, plural, one {{count, number} servei s'ha afegit al grup.} other {{count, number} serveis s'han afegit al grup.}} `
                },
                emptyState: {
                    title: 'Cap servei associat!',
                    description: 'Els futurs serveis apareixeran aquí.'
                }
            },
            addServices: {
                emptyState: {
                    title: 'La llista està buida',
                    description: 'Cap servei disponible'
                },
                selection:
                    '{count, plural, one {{count, number} servei s\'ha seleccionat} other {{count, number} serveis s\'han seleccionat}}',
                completedSuccess:
                    'Has afegit {count, plural, one {{count, number} servei} other {{count, number} serveis}}.'
            },
            members: {
                add: 'Afegir un membre',
                removeDialogTitle: 'Confirmació d\'eliminació',
                removeMemberConfirmation: `Estàs a punt d'eliminar l'usuari {fullName} del grup {groupName}`,
                removeSuccess: `L'usuari s'ha eliminat del grup.`,
                pageAllSelected:
                    '{count, plural, one {{count, number} membre s\'ha seleccionat} other {{count, number} membres s\'han seleccionat}} en aquesta pàgina.',
                selectAll:
                    'Seleccionar {count, plural, one {{count, number} membre} other {{count, number} membres}}',
                maxSelectedExceeded:
                    'La teva selecció supera el límit màxim de {count, plural, one {{count, number} membre} other {{count, number} membres}}. Si us plau, selecciona menys.',
                emptyState: {
                    title: 'Cap membre associat!',
                    description: 'Els futurs membres apareixeran aquí.'
                },
                addMembers: {
                    emptyState: {
                        title: 'Cap usuari es pot afegir a aquest grup',
                        description: 'Cap membre disponible'
                    },
                    completedSuccess:
                        'Has afegit {count, plural, one {{count, number} membre} other {{count, number} membres}}.',
                    selection:
                        '{count, plural, one {{count, number} membre s\'ha seleccionat} other {{count, number} membres s\'han seleccionat}}'
                }
            }
        },
        groups: {
            title: 'Grups',
            addGroupTooltip:
                "Crea grups per gestionar els drets d'accés d'un grup de persones",
            help:
                "El grup connecta un conjunt d'usuaris amb un conjunt de serveis.",
            index: {
                group: 'Grup',
                account: 'Compte de client'
            },
            emptyState: {
                title: 'Cap grup creat',
                description: 'Comenceu creant un nou grup',
                superReaderDescription: 'Els grups creats apareixeran aquí.'
            }
        },
        notifications: {
            title: 'Notificacions',
            help:
                'Gestiona els correus electrònics que tu i els teus col·laboradors voleu rebre de Cegid Account',
            subtitle: 'Seleccioneu els criteris que us convinguin.',
            email: 'Correu electrònic',
            newAccessRightsTitle: "Afegir un servei",
            newAccessRightsDescription:
                "Notifiqueu als vostres usuaris quan tinguin accés a un nou servei.",
            successfullySaved: `Les vostres preferències de notificació s'han actualitzat correctament per al compte de client {account}.`
        },
        organizationProfile: {
            pageTitle: 'Organització {value}',
            youConsultSentence: "Estàs consultant l'organització",
            uniqueIdentifier: 'Identificador únic',
            aboutTitle: 'Informació administrativa',
            creationDate: 'Data de creació',
            seeAllDomains: 'Veure els dominis',
            menu: {
                general: 'General',
                domains: 'Dominis'
            },
            countDomains: 'Dominis gestionats'
        },
        organizations: {
            title: 'Organitzacions',
            noOrganizationFound: 'Cap organització trobada'
        },
        organizationDomains: {
            title: "Dominis de l'organització {value}",
            domainsHelp: `
                Perquè un domini sigui certificat pels nostres serveis, us demanem que seguiu un procediment de verificació.
                Aquest procediment té com a objectiu garantir la vostra legitimitat sobre el domini inserint un codi de verificació en els vostres registres DNS (des del vostre gestor de dominis).
                Normalment es tracta del lloc on heu comprat el vostre domini o on allotgeu el vostre lloc web.
                Copieu el codi de verificació, posat a la vostra disposició des de la nostra interfície, per al domini en qüestió.
                Aneu al vostre gestor de domini, als registres DNS corresponents al domini a verificar;
                Creeu un nou registre TXT;
                Enganxeu el codi de validació.
                Deseu el vostre nou registre TXT.
                Un programa verificarà la presència d'aquest codi de verificació en els vostres DNS i validarà automàticament aquest domini pels nostres serveis.
            `,
            uniqueIdentifier: 'Identificador únic {value}',
            domains: 'Dominis',
            statusType: `{value, select, ${DomainValidationMode.Strong} {Certificat} ${DomainValidationMode.None} {Pendent de validació} ${DomainValidationMode.Weak} {A confirmar}}`,
            emptyState: 'Cap domini associat',
            startAddingDomain: 'Comenceu per afegir un domini'
        },
        services: {
            title: 'Serveis',
            tableTitle: 'Catàleg de serveis',
            help:
                "El vostre catàleg de serveis us permet visualitzar el conjunt d'aplicacions vinculades al vostre contracte Cegid. Des d'aquest tauler, podeu modificar les propietats d'accés a aquests serveis, donar un dret d'accés als vostres usuaris sobre una o més aplicacions, delegar l'administració d'un servei i consultar-ne el detall.",
            index: {
                family: 'Famílies',
                selectedServiceCount: `{count, plural, zero {{count, number} servei seleccionat} one {{count, number} servei seleccionat} other {{count, number} serveis seleccionats}}`,
                accountDistribution: '{label} • {serviceCount, number} serveis',
                fromAccount: ' (des de {accountSubscriber})',
                visibleServices: 'Serveis visibles • {value}',
                notVisibleServices: 'Serveis no visibles • {value}'
            },
            action: {
                subscribeExternalUser: 'Subscriure un usuari extern',
                subscribeExternalOwner: 'Afegir un propietari extern',
                updateServicesProperties: 'Canviar les propietats dels serveis',
                updateServicesPropertiesSuccess:
                    'Les propietats dels serveis s\'han actualitzat amb èxit'
            },
            createSubscription: {
                title: 'Subscriure un usuari',
                stepEmail: {
                    email: 'Correu electrònic',
                    invalidEmail: 'Aquesta adreça de correu electrònic no és vàlida.',
                    checkEmail: `Verificar l'adreça de correu electrònic`,
                    userDoesNotExist: "Aquest usuari no existeix."
                },
                stepInfo: {
                    checkInfo: 'Verificar la informació'
                }
            },
            updateProperties: {
                title: 'Editar els serveis seleccionats'
            },
            service: {
                availability: 'Disponibilitat',
                info: 'Informació'
            },
            table: {
                emptyStatePrimary: 'Cap servei',
                emptyStateSecondary: "No gestioneu l'accés als serveis"
            }
        },
         servicesIpRestrictions: {
            index: {
                title: "Afegir un rang d'IP restrictiu",
                tableTitle: 'Rangs d\'IP autoritzats',
                type: 'Tipus',
                description: 'Descripció',
                startIp: 'IP d\'inici',
                endIp: 'IP de final',
                enabled: 'Actiu',
                disabled: 'Inactiu',
                successAdd: 'El rang d\'IP s\'ha afegit',
                successRemove: 'El rang d\'IP s\'ha eliminat',
                successUpdate: 'El rang d\'IP s\'ha modificat'
            }
        },
        servicesMembers: {
            tableTitle: 'Usuaris amb accés al servei',
            emptyTitle: "Cap usuari té accés al servei",
            noAvailableUserTitle: 'Cap usuari pot ser afegit al servei',
            invite: 'Convidar',
            invitationResult: {
                external: "L'usuari ha estat convidat al servei",
                existing: 'Els usuaris han estat inscrits al servei'
            },
            groups: {
                tableTitle: 'Grups amb accés al servei',
                emptyTitle: "Cap grup té accés al servei",
                noAvailableGroupTitle: 'Cap grup pot ser afegit al servei',
                successAdd: 'El grup s\'ha afegit al servei'
            }
        },
        externalserviceProfile: {
            title: `Nom del servei:`,
            details: `{service} per al compte de {targetAccount}`
        },
        serviceProfile: {
            settings: {
                title: 'Configuració del servei'
            },
            serviceMembers: {
                alert: {
                    title: `Nombre màxim d'accés`,
                    accessLimit: `L'accés a aquest servei està limitat a {value} membres.`,
                    groupWarning:
                        'Atenció, els grups poden tenir accés a aquest servei i contenir diversos membres usuaris.'
                },
                add: 'Afegir un membre',
                expirationDate: `Caducitat`,
                removeExpiration: "Eliminar la data de caducitat",
                changeExpirationDate: `Definir o modificar la data de caducitat`,
                expirationDateAlert: {
                    accessUntil: `Accés fins al: `,
                    noLimitForOneAccessTitle: "L'accés a aquest servei ja no estarà limitat en el temps per a aquest usuari.",
                    noLimitForOneUserAndSeveralAccessTitle: "Els accessos de l'usuari als serveis seleccionats ja no estaran limitats en el temps.",
                    updateForOnServiceSeveralUsersTitle: `L'accés al servei ja no estarà limitat en el temps per a la vostra selecció.`,
                    expiryDateForOneUserSeveralServicesTitle: "L'usuari podrà accedir als serveis seleccionats fins al ",
                    selectedUserCanAccessTheServiceUntil: `{count, plural, one {L'accés seleccionat expirarà} other {Els accessos seleccionats expiraran}} el `,
                    existingExpiryDateForOneUserSeveralAccess: `Ja hi ha una data de caducitat programada per a un o més accessos entre la vostra selecció.`,
                    limitedAccessForOneUserAndOneService: `El dret d'accés a aquest servei expirarà el `,
                },
                tableTitle: 'Usuaris amb accés al servei',
                emptyTitle: "Cap usuari té accés al servei",
                giveAccess: 'Donar accés al servei',
                invite: 'Convidar',
                removeAccess: "Eliminar l'accés al servei",
                removeOneOrMoreAccess: `Eliminar {count, plural, one {l'accés} other {els accessos}} al servei`,
                confirmRemove: `Esteu segur que voleu eliminar l'accés al servei {serviceName} {count, plural, one { a l'usuari seleccionat} other { als {count, number} usuaris seleccionats}}?`,
                updateServiceEndDateForOneOrManyUser: `Trieu la data de caducitat {count, plural, one { del servei seleccionat} other { dels {count, number} serveis seleccionats}} per a l'usuari.`,
                updateServicesEndDateForOneUser: `Trieu la data de caducitat {count, plural, one { del servei seleccionat} other { dels {count, number} serveis seleccionats}} per a l'usuari.`,
                invitationResult: {
                    external: "L'usuari ha estat convidat al servei",
                    existing: 'Els usuaris han estat inscrits al servei'
                },
                manageAccess: `Gestionar l'accés`,
                remove: 'Eliminar',
                updateEndDateResult: {
                    one:
                        "L'accés d'aquest usuari a aquest servei s'ha actualitzat correctament",
                    many:
                        "Els accessos al servei per a aquests usuaris s'han actualitzat correctament"
                },
                removeResult: {
                    one:
                        "L'accés d'aquest usuari a aquest servei s'ha eliminat correctament",
                    many: "Els accessos al servei s'han eliminat correctament",
                    manyServiceOneUser: "Els accessos d'aquest usuari als serveis seleccionats s'han eliminat correctament",
                },
                groups: {
                    tableTitle: 'Grups amb accés al servei',
                    emptyTitle: "Cap grup té accés al servei",
                    noAvailableGroupTitle: 'Cap grup pot ser afegit al servei',
                    successAdd: 'El grup s\'ha afegit al servei correctament'
                }
            },
            title: `Servei - {service}`,
            youConsultService: `Esteu consultant el servei`,
            info: {
                title: `Nom del servei: {value}`,
                defaultService: `Accés assignat a tots els usuaris per defecte`,
                adminValidation: `Accés al servei subjecte a la validació d'un administrador`
            },
            label: {
                defaultService: `Accés automàtic`,
                adminValidation: `Validació de l'administrador`,
                serviceCode: `Codi del servei`
            },
            aboutService: `Sobre el servei`,
            security: {
                formTitle: `Afegir un rang d'IP autoritzat`,
                formSubTitle: `{serviceName} / {accountName}`,
                descriptionPlaceholder: 'Afegir una descripció',
                heading: 'Opció de seguretat',
                subheading: `L'opció de seguretat permet restringir l'accés a un servei a un o més rangs d'IP autoritzats.`,
                addRange: 'Afegir rang d\'IP',
                emptyTitle: "No hi ha restriccions",
                emptySubtitle: 'Utilitzeu el formulari per crear restriccions'
            },
            subscribers: 'Subscriptors',
            owners: {
                ownersTitle: 'Propietaris',
                help: "El propietari d'un servei pot administrar el servei, pot afegir/eliminar membres i canviar les propietats del servei",
                emptyTitle: `Aquest servei encara no té propietari`,
                owner: 'Propietari',
                add: 'Afegir un propietari',
                sucess: `Heu afegit {ownersCount} propietaris al servei correctament`
            }
        },
        userProfile: {
            title: 'Perfil de {fullName} ',
            yourProfile: 'El teu perfil',
            accountHelp: 'Número del compte client Cegid',
            acceptedServices: {
                help: "Agrupa tots els drets d'accés de l'usuari.",
                updateServiceEndDateForUser: `Trieu la data de caducitat del servei per a l'usuari seleccionat`
            },
            deniedServices: {
                help:
                    "Les sol·licituds de drets d'accés següents han estat rebutjades per un administrador."
            },
            suspendedServices: {
                help:
                    "Els drets d'accés següents han estat suspesos per un administrador. Per a més informació, contacteu amb el vostre administrador."
            },
            pendingServices: {
                help:
                    "Aquestes sol·licituds d'accés estan pendents de validació per un administrador."
            },
            accountDisabledAlert: {
                title: 'El compte està desactivat',
                message: 'Ho sentim, aquest compte ja no està disponible.'
            },
            section: {
                account: {
                    title: 'Compte'
                },
                administrators: {
                    title: 'Administradors'
                },
                directories: {
                    title: 'Directoris'
                },
                personal: {
                    title: 'Informació personal',
                    fullname: 'Nom',
                    password: 'Contrasenya',
                    securityLevel: 'Nivell de seguretat de la contrasenya: ',
                    email: 'Correu electrònic',
                    pendingEmailRequest:
                        "Sol·licitud de canvi d'adreça de correu electrònic en curs per a: {value}",
                    phone: 'Telèfon',
                    status: {
                        activated: 'Activat',
                        created: 'Creat',
                        toActivate: 'Per activar',
                        disabled: 'Desactivat'
                    }
                },
                technical: {
                    technicalInformation: 'Informació tècnica',
                    immutableId: 'Id immutable',
                    immutableGuid:  'Guid immutable',
                    identifier: 'Identificador: ',
                    identityIdentifier: 'Identificador d\'identitat: ',
                    tenantId: 'ID del llogater: ',
                    objectId: 'ID de l\'objecte: ',
                    upn: 'UPN: '
                }
            },
            personalInformations: {
                activatedServices: `{value, plural, one {Servei activat} other {Serveis activats} }`,
                pendingRequest: `{value, plural, zero {Sol·licitud pendent de validació} one {Sol·licitud pendent de validació} other {Sol·licituds pendents de validació} } `,
                cancelRequest: 'Cancel·lar la sol·licitud',
                cancelRequestConfirmationTitle: 'Confirmació de cancel·lació',
                cancelRequestConfirmationText:
                    'Esteu segur que voleu cancel·lar la sol·licitud de canvi de correu electrònic en curs?',
                cancelRequestSuccess: 'La sol·licitud de canvi d\'adreça de correu electrònic ha estat cancel·lada',
                renewRequest: 'Reenviar el correu',
                resendRequestSuccess:
                    'S\'ha enviat un correu electrònic a aquesta adreça de correu electrònic per a la confirmació.',
                activatedSince: 'Actiu des de',
                userNotActivated: 'Pendent d\'activació',
                seeAllServices: 'Veure tots els serveis activats'
            },
            lastActivities: {
                title: 'Activitats recents',
                activity: 'Activitat',
                result: 'Resultat',
                date: 'Data',
                activityType: `{value, select, ${AuditAction.Signin} {Connexió} ${AuditAction.ProfileEdited} {Perfil editat} ${AuditAction.PersonStatusChanged} {Estat modificat} ${AuditAction.MigrationToMicrosoft} {Migració a Microsoft} ${AuditAction.PersonGroupCreation} {Grup creat} other {-}}`,
                activityResult: `{value, select, ${AuditActionResult.Disabled} {Perfil desactivat} ${AuditActionResult.SigninSucceeded} {Èxit} ${AuditActionResult.PersonCreated} {Perfil creat} ${AuditActionResult.SigninFailed} {Fallat} ${AuditActionResult.PersonDisabled} {Perfil desactivat} ${AuditActionResult.PersonActivated} {Perfil activat} ${AuditActionResult.PersonToActivate} {Pendent d'activació} ${AuditActionResult.AuthenticationSucceeded} {Èxit} ${AuditActionResult.Activated} {Activat} other {-}}`,
                allActivities: 'Veure totes les activitats'
            },
            activities: {
                title: 'Activitats',
                filterLabel: 'Activitat',
                activityType: `{value, select, ${ActivityType.All} {Tot} ${ActivityType.Creation} {Creació} ${ActivityType.Update} {Modificació} ${ActivityType.Connection} {Connexió} ${AuditAction.PersonGroupCreation} {Grup creat} other {-}}`,
                emptyStateTitle: 'Cap activitat registrada per ara',
                emptyStateText: 'Els propers esdeveniments apareixeran aquí.'
            },
            services: {
                service: 'Servei',
                family: 'Família',
                numberOfSubscribers: `{subscribers}`,
                numberOfLicenses: `{licenses}`,
                withValidation: 'Validació',
                administratedValidation:
                    'Servei que requereix validació per part d\'un administrador',
                notAdministratedValidation:
                    'Servei que no requereix validació per part d\'un administrador',
                auto: 'Per defecte',
                accessByDefault:
                    'Servei per defecte afegit automàticament als usuaris',
                notAccessByDefault:
                    'Servei no afegit automàticament als usuaris',
                available: 'Disponible',
                unavailable: 'No disponible',
                inheritedFromGroup: 'Heredat del grup',
                accessToServiceSince: 'Accés al servei des de',
                expired: 'Caducat',
                refused: 'Rebutjat',
                suspendedLabel: 'Suspès',
                suspended: `{value, plural, zero {Servei suspès} one {Servei suspès} other {Serveis suspesos} } `,
                toValidate: 'Pendent de validació',
                add: 'Afegir un accés',
                removeConfirmation:
                    'Esteu a punt d\'eliminar l\'accés de l\'usuari a {count, plural, one {1 servei} other {{count, number} serveis}}, voleu continuar?',
                removeSuccess: 'Us heu donat de baixa de {count} serveis.',
                pageAllSelected:
                    '{count, plural, one {{count, number} servei seleccionat} other {{count, number} serveis seleccionats}} en aquesta pàgina.',
                selectAll:
                    'Seleccionar {count, plural, one {{count, number} servei} other {{count, number} serveis}}',
                maxSelectedExceeded:
                    'La vostra selecció supera el límit màxim de {count, plural, one {{count, number} servei} other {{count, number} serveis}}. Si us plau, seleccioneu menys.',
                completedPending:
                    '{count, plural, one {La vostra sol·licitud d\'afegir {count, number} servei ha estat enviada} other {Les vostres sol·licituds d\'afegir {count, number} serveis han estat enviades}}.',
                pendingServices: 'Pendent de validació',
                authorizedAccess: `{value, plural, zero {Accés autoritzat} one {Accés autoritzat} other {Accés autoritzats} } `,
                deniedServices: `{value, plural, zero {Accés rebutjat} one {Accés rebutjat} other {Accés rebutjats} } `,
                suspendedAccess: `{value, plural, zero {Accés suspès} one {Accés suspès} other {Accés suspesos} } `,
                emptyStateTitle: 'Cap servei trobat',
                emptyStateText:
                    'Els drets d\'accés als serveis autoritzats apareixeran aquí.',
                emptyState: {
                    title: 'Cap sol·licitud pendent.',
                    description:
                        'Les sol·licituds d\'accés a un o més serveis apareixeran aquí.'
                },
                subscriptionEndDate: 'Accés fins al {date} - {hour}',
                filterLabel: 'Estat',
                statusType: `{value, select, ${PersonAccessRightStatus.Accepted} {Autoritzat(s)} ${PersonAccessRightStatus.ToValidate} {Pendent de validació} ${PersonAccessRightStatus.Refused} {Rebutjat(s)} other {Suspès(s)}}`
            },
            editName: {
                firstName: 'Nom',
                lastName: 'Cognom',
                editName: 'Canviar el nom'
            },
            editPhone: {
                phoneNumber: 'Telèfon',
                mobileNumber: 'Telèfon mòbil',
                editPhone: 'Canviar el número de telèfon'
            },
            editLanguage: {
                language: 'Llengua',
                editLanguage: 'Canviar la llengua'
            },
            editSecurityLevel: {
                securityLevel: 'Nivell de seguretat',
                editSecurityLevel: 'Canviar el nivell de seguretat'
            },
            editEmail: {
                editEmail: 'Canviar l\'adreça de correu electrònic',
                email: 'Correu electrònic',
                confirmation: {
                    title: 'Correu electrònic enviat',
                    message: 'S\'ha enviat un correu electrònic a l\'adreça de correu electrònic {email} amb l\'assumpte "Confirmació del canvi de correu electrònic". Feu clic a l\'enllaç que conté per confirmar el canvi d\'adreça de correu electrònic.',
                    hint: 'Si no trobeu el correu electrònic, comproveu la carpeta de correu brossa. Si encara no trobeu el correu electrònic, comproveu que la nova adreça de correu electrònic sigui correcta i torneu-ho a intentar.'
                }
            },
            editPassword: 'Modificar la contrasenya',
            updatedProfile: 'El perfil s\'ha actualitzat correctament',
            identityMerged: 'El vostre compte s\'ha migrat correctament, haureu d\'autenticar-vos amb les vostres credencials {dir}.',
            beforeDirectoryMergeTitle: 'Seràs redirigit a la pàgina de connexió {dir}',
            beforeDirectoryMergeConditions: 'Per canviar el vostre compte Cegid al vostre compte {dir}',
            beforeDirectoryMergeText: 'Heu d\'iniciar sessió amb les vostres credencials {dir} existents. L\'adreça de correu electrònic utilitzada pot ser la mateixa que la del vostre compte Cegid.',
            mergeAccountInfo: 'Voleu identificar-vos mitjançant un compte {dir}',
            mergeAction: 'Enllaçar un compte {value}',
            yourInformation: 'Les teves dades',
            mergeValidationText: 'Després de la migració, la connexió es farà amb les vostres credencials {dir} per a totes les aplicacions Cegid.',
            mergeValidationSubText: 'Per sincronitzar el vostre compte {dir}, només cal que torneu a iniciar sessió.'
        },
        personGroupProfile: {
            tabServices: {
                addServiceButton: 'Afegir un servei'
            },
            about: {
                title: 'Sobre el grup'
            }
        },
        users: {
            title: 'Usuaris',
            help:
                "Des d'aquesta llista, podeu seleccionar un o més usuaris per revocar-los, exportar-los (format excel) o bé atorgar-los drets d'accés a una o més aplicacions (serveis del vostre catàleg).",
            name: 'Nom',
            firstname: 'Nom',
            action: {
                addUser: 'Convidar usuaris',
                delete: {
                    confirmation: `Esteu a punt d'eliminar {count, plural, one {{count, number} usuari} other {{count, number} usuaris}}, voleu continuar?`,
                    success: `{count, plural, one {{count, number} usuari ha estat eliminat.} other {{count, number} usuaris han estat eliminats.}}`
                }
            },
            pageSelectedUserCount:
                '{count, plural, one {{count, number} usuari d\'aquesta pàgina ha estat seleccionat} other {{count, number} usuaris d\'aquesta pàgina han estat seleccionats}}.',
            selectUserCount:
                'Seleccionar {count, plural, one {{count, number} usuari} other {{count, number} usuaris}}',
            maxLimitExceeded:
                'La vostra selecció supera el límit màxim de {count, plural, one {{count, number} usuari} other {{count, number} usuaris}}. Si us plau, seleccioneu menys.',
            index: {
                administrators: 'Administrador',
                users: 'Usuari',
                email: 'Correu electrònic',
                account: 'Compte client',
                creationDate: 'Data de creació',
                activationDate: "Data d'activació",
                language: 'Llengua',
                phoneNumber: 'Telèfon',
                mobileNumber: 'Telèfon mòbil',
                titleCode: 'Tractament',
                allGroups: 'Tots els grups',
                allRoles: 'Tots els rols',
                allStatus: 'Estat',
                item: {
                    email: '{value, select, null { } other {{value}} }',
                    group: '{value, select, TOCLASSIFY {Per classificar} other { }}',
                    role: '{value, select, null { } other {{value}} }',
                    status:
                        '{value, select, CREATED {Creat} TOACTIVATE {Per activar} ACTIVATED {Actiu} DISABLED {Inactiu} }'
                },
                selectedUserCount: `{count, plural, one {{count, number} usuari seleccionat} other {{count, number} usuaris seleccionats}}`,
                userStatus: {
                    actives: 'Actius • {count, number}',
                    pending: "En espera d'activació • {count, number}",
                    created: 'Creats • {count, number}'
                }
            },
            subscription: {
                success: {
                    meToOne: `Us heu subscrit a 1 servei.`,
                    meToMany: `Us heu subscrit a {servicesCount} serveis.`,
                    oneToOne: `L'usuari ha estat subscrit al servei.`,
                    oneToMany: `L'usuari ha estat subscrit a {servicesCount} serveis.`,
                    manyToOne: `{usersCount} usuaris han estat subscrits al servei.`,
                    manyToMany: `{usersCount} usuaris han estat subscrits a {servicesCount} serveis.`
                }
            },
            export: {
                lists: 'Llistes',
                columns: 'Columnes',
                help:
                    'Seleccioneu només les columnes que voleu exportar.',
                needServiceList:
                    'Seleccioneu només les llistes que voleu exportar.',
                itemToExport: 'Elements a exportar',
                withServiceList: 'Llista de serveis per usuari',
                accountCode: 'Codi del compte client',
                accountLabel: 'Etiqueta del compte client',
                creationDate: 'Data de creació',
                email: 'Correu electrònic',
                securityLevel: 'Nivell de seguretat',
                status: 'Estat',
                services: 'Llista de serveis per usuaris'
            }
        }
    },
    profile: {
        menu: {
            access: 'Accés',
            general: 'General',
            services: 'Serveis',
            restrictions: 'Restriccions',
            activities: 'Activitats',
            members: 'Membres',
            domains: 'Dominis'
        },
        about: {
            title: 'Sobre',
            creationDate: 'Creació',
            directories: 'Directoris',
            language: 'Llengua',
            outsourceableService: {
                title: 'Servei externalitzable'
            },
            serviceFamily: 'Família de servei'
        }
    },
    securityLevel: {
        weak: 'Feble',
        medium: 'Mitjà',
        high: 'Alt'
    },
    tableCollectionColumnSelection: {
        title: 'Opció de columnes',
        hint:
            "Seleccioneu les columnes a mostrar i arrossegueu-les per reorganitzar-les. Pot ser que no es mostrin totes les columnes en pantalles petites."
    },
    about: {
        google: {
            title: 'Connectar-se a Cegid Cloud amb el meu compte de Google',
            subtitle:
                "Cegid Cloud correspon a l'ecosistema d'aplicacions i serveis en línia de Cegid",
            connectToCegidProducts: `Podeu connectar-vos a la plataforma Cegid Cloud i a tots els serveis que suporta amb el vostre compte de Google.`,
            followSteps: `Per fer-ho, només heu de seguir els passos segons la vostra necessitat:`,
            whatYouWant: 'Què voleu fer?',
            connectToCegidApplicationWithYourGoogleAccount:
                'Associar el vostre compte Cegid existent amb el vostre compte de Google',
            activateYourCegidAccountWithGoogle:
                'Activar el vostre nou compte Cegid amb el vostre compte de Google',
            youAlrearyHaveACegidAccount:
                'Ja teniu un compte Cegid i voleu identificar-vos amb el vostre compte de Google: Fusiona el teu compte!',
            youNeedHelpWriteTo: `Si necessiteu ajuda per activar o connectar el vostre compte Cegid amb el vostre compte de Google, podeu escriure a `,
            forInformation: `Per a informació, podeu trobar`,
            ourCGV: `les nostres condicions generals d'ús `,
            andOur: `així com la nostra`,
            privacyPolitic: `política de privacitat i cookies`,
            userActivation: {
                youReceiveAnEmailToActivateYourAccount:
                    "Heu rebut un correu electrònic d'activació del compte Cegid i voleu utilitzar les credencials del vostre compte de Google per connectar-vos a les aplicacions Cegid?",
                step1Title: '1. Heu rebut un correu electrònic',
                step1Content:
                    "Feu clic a l'enllaç d'activació per continuar amb el procediment.",
                step2Title: "2. Heu estat redirigit a la pàgina d'activació del compte",
                step2Content: `Podeu activar el vostre compte Cegid utilitzant el vostre compte de Google fent clic al botó "Continuar amb Google"`,
                step3Title:
                    "3. Heu estat redirigit a la pàgina d'autenticació de Google.",
                step3Content: 'Introduïu les credencials del vostre compte de Google.',
                step4Title: "4. Heu estat redirigit a la interfície de Cegid Compte",
                step4Content: 'El vostre compte ha estat activat amb èxit.',
                step4Success:
                    'Ara hauríeu d\'estar connectat a Cegid Compte i accedir a la vostra informació.'
            },
            userUpdateIdp: {
                step1:
                    "1. Connecteu-vos a l'aplicació Cegid Compte i accediu al vostre perfil",
                step2:
                    '2. Des de la secció d\'annuari, podeu enllaçar el vostre compte de Google',
                step3: `3. Feu clic al botó "Enllaçar un compte de Google", se us mostrarà una informació. Feu clic a "He entès" per ser redirigit a una pantalla de connexió de Google.`,
                step4:
                    '4. Introduïu el vostre correu electrònic associat al compte de Google que voleu utilitzar per connectar-vos a les aplicacions Cegid',
                step5: `5. Verifiqueu la vostra informació, per continuar amb la fusió del compte, feu clic a "Migrar"`,
                step5End: `Se us desconnectarà de l'aplicació.`,
                step6: `6. Identifiqueu-vos amb el correu electrònic del compte de Google que acabeu d'enllaçar al vostre perfil Cegid.`,
                step7: `Ara hauríeu d'estar connectat al vostre perfil amb la informació actualitzada.`
            }
        }
    },
    helperText: {
        groupNameRequired: 'El nom del grup és obligatori',
        groupDescriptionRequired: 'La descripció del grup és obligatòria'
    },
    dataPrivacy: {
        title: 'Política de protecció de dades',
        userProfileDataPrivacy:
            "Cegid SAS tracta les vostres dades personals en el marc de la gestió del vostre compte Cegid Account. Disposeu dels drets d'accés, rectificació, supressió i portabilitat de les vostres dades, així com d'un dret d'oposició i limitació a certs tractaments. Per a més informació sobre el tractament i les modalitats d'exercici dels vostres drets, podeu consultar la política de protecció de dades.",
        sentence1:
            "Les dades personals introduïdes a Cegid Account són tractades per Cegid SAS per permetre-vos accedir als serveis de Cegid en el marc de la relació contractual.",
        sentence2:
            "Les vostres dades poden ser comunicades a les filials del responsable del tractament així com a proveïdors externs encarregats de la implementació del tractament o a tercers autoritzats en cas de sol·licituds per part de les autoritats competents. És necessari proporcionar un identificador (correu electrònic o matrícula, per exemple). En cas contrari, el tractament no es podrà dur a terme.",
        sentence3:
            'Les vostres dades personals es conserven {value} anys a partir de la vostra última activitat registrada al compte (per exemple, la vostra última connexió).',
        sentence4:
            "Les vostres dades personals poden ser objecte de transferències a països situats fora de la Unió Europea per a les finalitats detallades anteriorment. Aquestes transferències estan subjectes a un marc jurídic específic per tal que aquestes dades estiguin cobertes per un nivell de protecció adequat.",
        sentence5:
            "En compliment de la normativa aplicable en matèria de protecció de dades personals, disposeu d'un dret d'accés, rectificació, supressió i portabilitat de les vostres dades, així com d'un dret d'oposició i limitació a totes les dades que us concerneixen.",
        sentence6:
            "Aquests drets s'exerceixen escrivint al nostre delegat de protecció de dades enviant la vostra sol·licitud a dataprivacy@cegid.com. Podeu exercir el vostre dret de recurs en qualsevol moment davant l'Autoritat competent en matèria de protecció de dades personals (APDCAT)."
    }
});
