import { Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql, QueryResult } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import { colors } from 'Theme/constants';
import Doughnut from './Doughnut';

import TestIds from 'Tests/TestIds';
import useTranslate from 'Hooks/useTranslate';
import { useParams } from 'react-router-dom';

const GetUserCountByActivationStatus = gql`
  query UserStatusDistribution($accountCode: String) {
    userActivatedCountOf(accountCode: $accountCode) {
      activated
      activationPending
      created
    }
  }
`;

export const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    height: '100%',
    minHeight: 148
  },
  content: {
    flex: 1,
    display: 'flex'
  }
}));

interface IQueryData {
  userActivatedCountOf: {
    activated: number;
    activationPending: number;
    created: number;
  };
}

interface Props {}

function UserStatusDistribution(props: Props) {
  const ts = useTranslate();
  const classes = useStyles();
  const { accountCode } = useParams<{ accountCode: string }>();

  const variables = {
    accountCode: accountCode === 'all' ? null : accountCode
  };

  return (
    <Card classes={{ root: classes.card }}>
      <CardContent classes={{ root: classes.content }}>
        <Query query={GetUserCountByActivationStatus} variables={variables}>
          {({ data }: QueryResult<IQueryData>) => {
            const {
              userActivatedCountOf = {
                activated: 0,
                activationPending: 0,
                created: 0
              }
            } = data || {};
            const result = {
              datas: [
                {
                  color: colors.blue[500],
                  label: ts('page.users.index.userStatus.actives', {
                    count: userActivatedCountOf.activated
                  }),
                  value: userActivatedCountOf.activated,
                  testId: TestIds.pages.users.activatedUsersCount
                },
                {
                  color: colors.blue[300],
                  label: ts('page.users.index.userStatus.pending', {
                    count: userActivatedCountOf.activationPending
                  }),
                  value: userActivatedCountOf.activationPending,
                  testId: TestIds.pages.users.pendingUsersCount
                },
                {
                  color: colors.orange[500],
                  label: ts('page.users.index.userStatus.created', {
                    count: userActivatedCountOf.created
                  }),
                  value: userActivatedCountOf.created
                }
              ]
            };

            return <Doughnut.Display displayTitle="aside" {...result} />;
          }}
        </Query>
      </CardContent>
    </Card>
  );
}

export default UserStatusDistribution;
