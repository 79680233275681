import { useQuery } from '@apollo/client';
import { Typography, Button } from '@mui/material';
import Skeleton from '@mui/lab/Skeleton/Skeleton';
import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import { GetApiKeys } from 'Apollo/queries/apikey/GetApiKeys';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import { PrivateRoutes } from 'Router/Routes';
import DataRow from 'Theme/components/DataRow/DataRow';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  account: IAccount;
}

function SectionApiKeys(props: Readonly<Props>) {
  const { account } = props;
  const ts = useTranslate();
  const { onError } = useNotification();

  const variables = {
    accountFilter: account.code,
    state: ApiKeyState.Active
  };

  const { data, loading } = useQuery(GetApiKeys, {
    variables,
    onError
  });

  const basePath = generatePath(PrivateRoutes.apiKeyServices.path, {
    accountCode: account.code
  });

  return (
    <DataRow
      label={ts('page.accountProfile.sections.apikeys.activedApiKeys')}
      action={
        <Button
          component={RouterLink}
          to={generatePath(basePath)}
          variant="text"
          color="primary"
        >
          {ts('page.accountProfile.sections.apikeys.seeAll')}
        </Button>
      }
    >
      {loading && (
        <Skeleton width={120} height={30} variant="rectangular" color="primary" />
      )}
      {!loading && <Typography>{data?.apiKeys.totalCount}</Typography>}
    </DataRow>
  );
}

export default SectionApiKeys;
