import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CircularProgress, IconButton, Switch, Tooltip } from '@mui/material';
import { TableCollectionColumn } from '../Components/Shared/Table/Table';
import Skeleton from 'Theme/components/Skeleton';
import useTranslate from './useTranslate';
import { TProfileManagerMessagesKeys } from '../Languages/TProfileManagerMessages';
import { DeleteServiceSubscriptionIpRestriction } from '../Apollo/mutations/service/DeleteServiceSubscriptionIpRestriction';
import { UpdateServiceSubscriptionIpRestriction } from 'Apollo/mutations/service/UpdateServiceSubscriptionIpRestrictions';
import useNotification from './useNotification';
import { RemoveCircle } from '@mui/icons-material';

export const useColumnsServiceIpFiltering = (
  onReload: () => void
): TableCollectionColumn<IIpRestriction>[] => {
  const hidden = false;
  const ts = useTranslate();
  const { onError, onSuccess } = useNotification();
  const [current, setCurrent] = useState<string | null>(null);

  const [onDelete, { loading: deleteIsLoading }] = useMutation(
    DeleteServiceSubscriptionIpRestriction,
    {
      onError,
      onCompleted: () => {
        onReload();
        onSuccess('page.servicesIpRestrictions.index.successRemove');
        setCurrent(null);
      }
    }
  );

  const [onUpdate] = useMutation(UpdateServiceSubscriptionIpRestriction, {
    onError,
    onCompleted: () => {
      setCurrent(null);
      onSuccess('page.servicesIpRestrictions.index.successUpdate');
    }
  });

  const handleOnDelete = (immutableId: string) =>
    onDelete({ variables: { immutableId } });

  const handleOnUpdate = (row: IIpRestriction) => {
    const { immutableId, isActive } = row;

    onUpdate({
      variables: {
        serviceIPRangeItemForUpdate: { immutableId, isActive: !isActive }
      },
      optimisticResponse: {
        updateIPRange: { ...row, isActive: !isActive }
      }
    });
  };

  return [
    {
      key: 'description',
      width: 0.45,
      orderBy: {
        asc: 'ServiceSubscriptionOrder.NameAsc',
        desc: 'ServiceSubscriptionOrder.NameDesc'
      },
      hidden: hidden,
      renderHead: () => ts('page.servicesIpRestrictions.index.description'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({ row }: { row: IIpRestriction }) => row.description
    },
    {
      key: 'startIp',
      width: 0.2,
      orderBy: {
        asc: 'ServiceSubscriptionOrder.NameAsc',
        desc: 'ServiceSubscriptionOrder.NameDesc'
      },
      hidden: hidden,
      renderHead: () => ts('page.servicesIpRestrictions.index.startIp'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({ row }: { row: IIpRestriction }) => row.iPstart
    },
    {
      key: 'endIp',
      width: 0.2,
      orderBy: {
        asc: 'ServiceSubscriptionOrder.NameAsc',
        desc: 'ServiceSubscriptionOrder.NameDesc'
      },
      hidden: hidden,
      renderHead: () => ts('page.servicesIpRestrictions.index.endIp'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({ row }: { row: IIpRestriction }) => row.iPend
    },
    {
      key: 'enabled',
      orderBy: {
        asc: 'ServiceSubscriptionOrder.NameAsc',
        desc: 'ServiceSubscriptionOrder.NameDesc'
      },
      hidden: hidden,
      renderHead: () => '',
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({ row }: { row: IIpRestriction }) => {
        const title: TProfileManagerMessagesKeys = row.isActive
          ? 'common.action.deactivate'
          : 'common.action.activate';
        return (
          <Tooltip title={ts(title)}>
            <Switch
              checked={row.isActive}
              onChange={() => {
                setCurrent(row.immutableId as string);
                handleOnUpdate(row);
              }}
              name="ipFilteringSwitch"
            />
          </Tooltip>
        );
      }
    },
    {
      key: 'deleteIpRestriction',
      hidden: hidden,
      renderHead: () => '',
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({ row }: { row: IIpRestriction }) => {
        if (deleteIsLoading && current === row.immutableId)
          return <CircularProgress size={20} color="secondary" />;
        return (
          <Tooltip title={ts('common.action.delete')}>
            <IconButton
              onClick={() => {
                setCurrent(row.immutableId as string);
                handleOnDelete(row.immutableId as string);
              }}
              size="large"
            >
              <RemoveCircle style={{ cursor: 'pointer' }} />
            </IconButton>
          </Tooltip>
        );
      }
    }
  ];
};
