import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { FormattedDate } from 'react-intl';
import { Link, generatePath, useParams } from 'react-router-dom';
import joinURL from 'url-join';
import { PersonAccessRightStatus } from 'Apollo';
import { PrivateRoutes } from 'Router/Routes';
import Skeleton from 'Theme/components/Skeleton';
import TestIds from 'Tests/TestIds';
import accountLabel from 'Tools/LabelFormatter/accountLabel';
import { useStyles } from './PersonalInformation.styles';
import useTranslate from 'Hooks/useTranslate';
import PaperFooter from 'Components/Shared/PaperFooter/PaperFooter';
import ContextualHelp from 'Components/Shared/ContextualHelp';
import { useContext } from 'react';
import UserAccountUpdate from './UserAccountUpdate';
import { PersonContext } from 'Context/CurrentPerson';
import { CdsColorBackgroundNeutralMainDisabled } from '@cegid/design-tokens';
import UserStatusChip from 'Components/Shared/Chips/UserStatusChip/UserStatusChip';
import { displayDefaultColor } from 'Tools/displayDefaultColor';

interface Props {
  loading: boolean;
  person: IPersonWithAccount;
  accessRights: IPersonAccessRight[];
}

function PersonalInformation(props: Readonly<Props>) {
  const { accessRights, loading, person } = props;
  const { currentPersonRoles } = useContext(PersonContext);

  const classes = useStyles();
  const ts = useTranslate();
  const { accountCode } = useParams<IUriParams>();

  let accToValidate = accessRights.filter(
    acc => acc.status === PersonAccessRightStatus.ToValidate
  ).length;
  let accAccepted = accessRights.filter(
    acc => acc.status === PersonAccessRightStatus.Accepted
  ).length;

  const isDisabled = displayDefaultColor({...person});

  const isSuperAdmin = currentPersonRoles.canWriteAll;
  const isSuperReader =
  currentPersonRoles.canAskForAccountAdministrationDelegation;
  const isAccountAdmin = currentPersonRoles.isAccountAdmin;
  const canManageUserAccount = isSuperAdmin || isSuperReader || isAccountAdmin;

  return (
    <Paper classes={{ root: classes.root }}>
      <div
        data-testid={TestIds.pages.profile.personalInformation.header}
        className={classes.header}
        style={{
          backgroundColor: isDisabled
            ? CdsColorBackgroundNeutralMainDisabled
            : person?.avatar?.color
        }}
      >
        <Typography
          variant="h5"
          color="inherit"
          classes={{ root: classes.headerTitle }}
        >
          {ts('page.userProfile.section.personal.title')}
        </Typography>
        <Grid container={true} justifyContent="space-between">
          <Grid item={true} sm={10}>
            {person.account && (
              <div className={classes.accountWithHelp}>
                <Typography
                  variant="body1"
                  color="inherit"
                  classes={{ root: classes.headerSubTitle }}
                >
                  {accountLabel(person.account)}
                </Typography>
                <ContextualHelp
                  color="inherit"
                  content={ts('page.userProfile.accountHelp')}
                />
              </div>
            )}
          </Grid>
          <Grid
            item={true}
            sm={2}
            justifyContent="flex-end"
            style={{ display: 'flex' }}
          >
            {canManageUserAccount && (
              <UserAccountUpdate person={person} accessRights={accessRights} />
            )}
          </Grid>
        </Grid>
      </div>
      <Grid container={true} className={classes.body}>
        <Grid item={true} sm={4}>
          {loading ? (
            <div title="progressbar">
              <Skeleton width="5%" variant="rectangular" />
              <Skeleton width="30%" />
            </div>
          ) : (
            <>
              <Typography
                variant="h5"
                data-testid={
                  TestIds.pages.profile.personalInformation.accessRightsCount
                }
              >
                {accAccepted}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {ts('page.userProfile.personalInformations.activatedServices', {
                  value: accAccepted
                })}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item={true} sm={4}>
          {loading ? (
            <div title="progressbar">
              <Skeleton width="5%" variant="rectangular" />
              <Skeleton width="30%" />
            </div>
          ) : (
            <>
              <Typography
                variant="h5"
                data-testid={
                  TestIds.pages.profile.personalInformation
                    .pendingAccessRightsCount
                }
              >
                {accToValidate}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {ts('page.userProfile.personalInformations.pendingRequest', {
                  value: accToValidate
                })}
              </Typography>
            </>
          )}
        </Grid>
        {person.activationDate == null && (
          <Grid
            item={true}
            sm={4}
            display={'flex'}
            alignSelf={'center'}
            justifyContent={'flex-end'}
          >
            <UserStatusChip person={person} size="medium" />
          </Grid>
        )}
        {person.activationDate !== null && (
          <Grid item={true} sm={4} 
            display={'flex'} 
            flexDirection={'column'} 
            justifyContent={'flex-end'}
            alignItems={'flex-end'}
          >
            <Typography
              variant="h5"
              data-testid={TestIds.pages.userProfile.personalInfo.creationDate}
            >
              <FormattedDate value={person.activationDate} />
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {ts('page.userProfile.personalInformations.activatedSince')}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Divider />
      <PaperFooter>
        <Button color="primary">
          <Link
            data-testid={TestIds.pages.profile.personalInformation.servicesLink}
            to={generatePath(
              joinURL(PrivateRoutes.usersProfile.path, '/services'),
              {
                accountCode: accountCode,
                immutableId: person.immutableId
              }
            )}
          >
            {ts('page.userProfile.personalInformations.seeAllServices')}
          </Link>
        </Button>
      </PaperFooter>
    </Paper>
  );
}

export default PersonalInformation;
