import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  cardActions: {
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  seeMoreButton: {
    float: 'right'
  }
}));
