import { Container, Paper, useMediaQuery } from '@mui/material';
import TableCollection from 'Theme/components/TableCollection/TableCollection';
import Helmet from 'react-helmet';
import { useColumnsDomains } from 'Hooks/useColumnsDomains';
import PaperTitle from 'Theme/components/PaperTitle/PaperTitle';
import useTranslate from 'Hooks/useTranslate';
import { useColumnsDomainsLight } from 'Hooks/useColumnsDomainsLight';

interface Props {
  loading: boolean;
  domains: IOrganizationDomain[];
  totalCount: number;
  organization: IOrganization;
  currentPage: number;
  onChangePage: (newPage: number) => void;
  rowsPerPage: number;
  onChangeRowsPerPage: (rpp: number) => void;
}

export enum OrganizationDomainOrder {
  NameAsc = 'LASTNAMEASCTHENFIRSTNAMEASC',
  NameDesc = 'LASTNAMEDESCTHENFIRSTNAMEDESC',
  StatusAsc = 'STATUSASCTHENLASTNAMEASCTHENFIRSTNAMEASC',
  StatusDesc = 'STATUSDESCTHENLASTNAMEASCTHENFIRSTNAMEASC'
}

function OrganizationDomains({
  loading,
  domains,
  totalCount,
  organization,
  currentPage,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage
}: Readonly<Props>) {
  
  const isMobileP = useMediaQuery(
    '(max-width:600px) and (orientation: portrait)'
  );
  const columns = useColumnsDomains();
  const columnsLight = useColumnsDomainsLight();
  const ts = useTranslate();

  return (
    <>
      <Helmet title={ts('page.organizationDomains.title', {value: organization.name})} />
      <Container>
        <Paper>
          <PaperTitle
            variant="h5"
            title={ts('page.organizationDomains.domains')}
            info={ts('page.organizationDomains.domainsHelp')}
          />
          <TableCollection
            loading={loading}
            columns={!isMobileP ? columns : columnsLight}            
            rows={domains}
            getRowId={(d: IOrganizationDomain) => d.name}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            page={currentPage}
            onChangePage={onChangePage}
            isSelectionHidden={true}
            isSelectionDisabled={true}
            onUnselect={() => {}}
            selectedIds={[]}
            onSelect={() => {}}
            maxSelected={undefined}
            totalCount={totalCount}
          />
        </Paper>
      </Container>
    </>
  );
}

export default OrganizationDomains;
