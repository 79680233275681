import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import useTranslate from 'Hooks/useTranslate';
import TableServiceOwnersContainer from 'Components/TableServiceOwners/TableServiceOwners.container';
import { useMemo, useState } from 'react';
import DrawerServiceProfileOwnersContainer from 'Components/DrawerServiceProfileOwners';
import FormExternalOwner from 'Components/FormExternalOwner';
import TestIds from 'Tests/TestIds';
import { PaperTitle } from 'Theme/components';

enum EActions {
  ADD,
  INVITE,
  NONE
}
type Props = {};

const Owners = (props: Props) => {
  const ts = useTranslate();
  const [action, setAction] = useState(EActions.NONE);
  const tableServiceOwnersContainer = useMemo(
    () => <TableServiceOwnersContainer />,
    []
  );

  const onClose = () => setAction(EActions.NONE);

  return (
    <Paper>
      <PaperTitle
        title={ts('page.serviceProfile.owners.ownersTitle')}
        info={ts('page.serviceProfile.owners.help')}
        secondaryAction={
          <Button
            disableFocusRipple
            color="primary"
            onClick={() => setAction(EActions.ADD)}
            startIcon={<AddIcon />}
            data-testid={
              TestIds.pages.serviceProfile.manageOwners.addOwnerButton
            }
          >
            {ts('page.serviceProfile.owners.add')}
          </Button>
        }
      />
      <div>
        {tableServiceOwnersContainer}
        {action === EActions.ADD && (
          <DrawerServiceProfileOwnersContainer
            open={action === EActions.ADD}
            onClose={onClose}
            onInviteExternal={() => setAction(EActions.INVITE)}
          />
        )}
        <Dialog open={action === EActions.INVITE} maxWidth="xs" fullWidth>
          <DialogTitle>
            {ts('page.services.action.subscribeExternalOwner')}
          </DialogTitle>
          <DialogContent>
            <FormExternalOwner onCancel={onClose} />
          </DialogContent>
        </Dialog>
      </div>
    </Paper>
  );
};

export default Owners;
