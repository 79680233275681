import { useQuery } from '@apollo/client';
import { GetPersonAccessRights, PersonAccessRightStatus } from 'Apollo';
import useNotification from 'Hooks/useNotification';
import PersonalInformation from './PersonalInformation';

interface Props {
  person: IPersonWithAccount;
}

const PersonalInformationContainer = (props: Props) => {
  const { person } = props;
  const { onError } = useNotification();

  const { data, loading } = useQuery<GetPersonAccessRightsData>(
    GetPersonAccessRights,
    {
      fetchPolicy: 'no-cache',
      onError,
      variables: {
        personIdentifier: props.person.immutableId,
        status: [
          PersonAccessRightStatus.Accepted,
          PersonAccessRightStatus.ToValidate
        ]
      }
    }
  );

  const accessRights = data
    ? data.personAccessRights.edges.map(i => i.node)
    : [];

  return (
    <PersonalInformation
      loading={loading}
      person={person}
      accessRights={accessRights}
    />
  );
};

export default PersonalInformationContainer;
