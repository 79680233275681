import { gql } from '@apollo/client';

export const DirectoryAuthenticatingPersonFragment = gql`
fragment DirectoryAuthenticatingPerson on DirectoryAuthenticatingPersonType {
  customAttribute {
    objectId
    tenantId
    upn
  }
  identifier
  identityIdentifier
  isFederated
  label
  profileManagement {
    canCreateProfile
		canDeleteProfile
    readonlyProperties
  }
}
`;
