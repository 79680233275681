import { Divider, Grid } from '@mui/material';
import { Fragment } from 'react';
import Skeleton from 'Theme/components/Skeleton';

const TableServicesCandidatesSkeleton = () => {
  function createSkeletonLine() {
    return (
      <Grid container direction="row" justifyContent="space-between">
        <Grid item xs={1}>
          <Skeleton width="24px" height="20px" variant="rectangular" />
        </Grid>
        <Grid item xs={5}>
          <Skeleton width="30%" height="20px" variant="rectangular" />
        </Grid>
        <Grid item xs={6}>
          <Skeleton width="40%" height="20px" variant="rectangular" />
        </Grid>
      </Grid>
    );
  }

  const mockRows = Array.from(Array(4).keys());
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="row">
        {createSkeletonLine()}
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      {mockRows.map(i => {
        return (
          <Fragment key={i}>
            <Grid item>{createSkeletonLine()}</Grid>
            <Grid item>
              <Divider />
            </Grid>
          </Fragment>
        );
      })}
      <Grid item container>
        <div style={{ width: '80%' }}></div>
        <Skeleton width={'20%'} height="20px" variant="rectangular" />
      </Grid>
    </Grid>
  );
};

export default TableServicesCandidatesSkeleton;
