// tslint:disable: jsx-no-lambda
import {
  Button,
  Checkbox,
  Divider,
  AccordionActions,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import {
  ColumnId,
  COLUMNS_ORDER,
  CreateExportUsersRequest
} from '../../../Apollo';
import ButtonLoading from 'Theme/components/ButtonLoading';
import TestIds from 'Tests/TestIds';
import { useParams } from 'react-router-dom';
import useNotification from 'Hooks/useNotification';

interface Column {
  label: string;
  ids: ColumnId[];
}

const USER_COLUMNS: Column[] = [
  {
    label: 'page.users.name',
    ids: [ColumnId.LastName]
  },
  {
    label: 'page.users.firstname',
    ids: [ColumnId.FirstName]
  },
  {
    label: 'page.users.export.email',
    ids: [ColumnId.Email]
  },
  {
    label: 'page.users.export.creationDate',
    ids: [ColumnId.CreationDate]
  },
  {
    label: 'page.users.export.status',
    ids: [ColumnId.Status]
  },
  {
    label: 'page.users.export.accountCode',
    ids: [ColumnId.AccountCode]
  },
  {
    label: 'page.users.export.accountLabel',
    ids: [ColumnId.AccountLabel]
  }
];

const LISTS_COLUMNS: Column[] = [
  {
    label: 'page.users.export.services',
    ids: [ColumnId.ServiceLabel, ColumnId.ServiceAccount]
  }
];

export const useStyles = makeStyles(theme => ({
  expansionPanelDetails: {
    flexDirection: 'column',
    padding: `${theme.spacing(2)} 0`
  },
  content: {
    padding: `0 ${theme.spacing(3)}`
  },
  heading: {
    marginBottom: theme.spacing(3)
  },
  table: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  }
}));

interface Props {
  selectedUserIds: string[];
  selectAllUserEnabled: boolean;
  onCollapse: () => void;
  searchTerm: string;
}

const ExportUsers = (props: Props) => {
  const {
    searchTerm,
    onCollapse,
    selectedUserIds,
    selectAllUserEnabled
  } = props;
  const classes = useStyles();
  const { onError } = useNotification();
  const { accountCode } = useParams<IUriParams>();
  const [selectedColumns, setSelectedColumns] = React.useState<ColumnId[]>([]);

  const onCompleted = ({
    createExportUsersRequest
  }: CreateExportUsersRequestData) => {
    const iframe = window.document.createElement('iframe');

    iframe.onload = () =>
      setTimeout(() => window.document.body.removeChild(iframe));
    iframe.style.display = 'none';
    iframe.src = createExportUsersRequest;

    window.document.body.appendChild(iframe);

    setTimeout(() => {
      onCollapse();
    }, 1000);
  };

  const isAllUserFieldsChecked = USER_COLUMNS.every(column =>
    column.ids.every(id => selectedColumns.includes(id))
  );

  const isIndeterminate =
    !isAllUserFieldsChecked &&
    USER_COLUMNS.some(column =>
      column.ids.some(id => selectedColumns.includes(id))
    );

  const [createExportUser, { loading }] = useMutation<
    CreateExportUsersRequestData,
    CreateExportUsersRequestVariables
  >(CreateExportUsersRequest, {
    onCompleted,
    onError
  });

  const onToggleColumnsId = (ids: ColumnId[]) => {
    setSelectedColumns(
      ids.every(id => selectedColumns.includes(id))
        ? selectedColumns.filter(id => !ids.includes(id))
        : selectedColumns.concat(ids)
    );
  };

  const onToggleSelectAllUserColumns = () => {
    const obj = USER_COLUMNS.every(column =>
      column.ids.every(id => selectedColumns.includes(id))
    )
      ? selectedColumns.filter(
          id => USER_COLUMNS.find(col => col.ids.includes(id)) == null
        )
      : USER_COLUMNS.reduce(
          (selectedColumns: ColumnId[], col) =>
            selectedColumns.concat(
              // Only add the columns that were not selected already
              col.ids.filter(id => !selectedColumns.includes(id))
            ),
          selectedColumns
        );

    setSelectedColumns(obj);
  };

  return (
    <form
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (loading || selectedColumns.length < 1) {
          return;
        }

        createExportUser({
          variables: {
            accountCode: accountCode === 'all' ? null : accountCode,
            personIdentifiers: selectAllUserEnabled ? null : selectedUserIds,
            textSearch: searchTerm,
            // The columns must be sent in a deterministic order
            columns: COLUMNS_ORDER.filter(columnId =>
              selectedColumns.includes(columnId)
            )
          }
        });
      }}
      onReset={() => onCollapse()}
    >
      <Divider />
      <AccordionDetails classes={{ root: classes.expansionPanelDetails }}>
        <div className={classes.content}>
          <Typography variant="h4" classes={{ root: classes.heading }}>
            <FormattedMessage id="page.users.export.itemToExport" />
          </Typography>

          <Typography variant="h6" color="inherit">
            <FormattedMessage id="page.users.export.columns" />
          </Typography>
          <Typography variant="caption">
            <FormattedMessage id="page.users.export.help" />
          </Typography>
        </div>
        <Table classes={{ root: classes.table }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={isAllUserFieldsChecked}
                  onChange={onToggleSelectAllUserColumns}
                  indeterminate={isIndeterminate}
                />
              </TableCell>
              <TableCell align="left">
                <Typography>
                  <FormattedMessage id="page.users.export.columns" />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {USER_COLUMNS.map(column => {
              const isChecked = column.ids.every(id =>
                selectedColumns.includes(id)
              );
              return (
                <TableRow
                  key={column.ids.join('-')}
                  onClick={() => onToggleColumnsId(column.ids)}
                  role="checkbox"
                  aria-checked={isChecked}
                  tabIndex={-1}
                  selected={isChecked}
                  hover={true}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isChecked}
                      name={column.label}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">
                      <FormattedMessage id={column.label} />
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className={classes.content}>
          <Typography variant="h6" color="inherit">
            <FormattedMessage id="page.users.export.lists" />
          </Typography>
          <Typography variant="caption">
            <FormattedMessage id="page.users.export.needServiceList" />
          </Typography>
        </div>
        <List>
          {LISTS_COLUMNS.map(column => {
            const isChecked = column.ids.every(id =>
              selectedColumns.includes(id)
            );
            return (
              <ListItem key={column.ids.join('-')}>
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    onChange={() => onToggleColumnsId(column.ids)}
                    checked={isChecked}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<FormattedMessage id={column.label} />}
                  primaryTypographyProps={{
                    variant: 'body2'
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Button
          type="reset"
          color='inherit'
          data-testid={TestIds.common.snackbar.action.cancel}
        >
          <FormattedMessage id="common.action.cancel" />
        </Button>
        <ButtonLoading
          type="submit"
          color="primary"
          variant="contained"
          loading={loading}
        >
          <FormattedMessage id="common.action.export" />
        </ButtonLoading>
      </AccordionActions>
    </form>
  );
};

export default ExportUsers;
