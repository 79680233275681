import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Theme,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {enqueueSnackbar } from 'notistack';
import * as React from 'react';
import {
  ApolloError,
  MutationFunction,
  MutationResult,
  QueryResult
} from '@apollo/client';
import { Mutation, Query } from '@apollo/client/react/components';
import { FormattedMessage } from 'react-intl';
import { UpdatePerson, GetLanguages } from '../../../../../../Apollo';
import Skeleton from 'Theme/components/Skeleton';
import ButtonLoading from 'Theme/components/ButtonLoading';
import { ErrorHandler } from 'Theme/components/ErrorHandler';
import TestIds from 'Tests/TestIds';

interface Props {
  person: IPersonWithAccount;
  onClose: () => void;
}

interface State {
  languageCode: string;
}

const styles = (theme: Theme) =>
  createStyles({
    selectModal: {
      // The select modal is already in a modal with zIndex = appBar + 1
      // So to be on top of the modal, it has to be appBar + 1 + 1
      zIndex: `${theme.zIndex.appBar + 2} !important` as any
    }
  });

class EditLanguageDialog extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  public readonly state: State = {
    languageCode: this.props.person.language
      ? this.props.person.language.code
      : ''
  };

  public render() {
    const { person, onClose, classes } = this.props;
    const { languageCode } = this.state;

    return (
      <ErrorHandler.Consumer>
        {errorHandler => (
          <Mutation
            mutation={UpdatePerson({
              languageCode: languageCode
            })}
            variables={{
              personIdentifier: person.immutableId
            }}
            onError={(e: ApolloError) => errorHandler.onError(e)}
            onCompleted={this.onCompleted}
          >
            {(updatePerson: MutationFunction, { loading }: MutationResult) => (
              <Dialog
                open={true}
                onClose={onClose}
                maxWidth="xs"
                fullWidth={true}
              >
                <form
                  onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();

                    if (loading) {
                      return;
                    }

                    updatePerson();
                  }}
                >
                  <DialogTitle>
                    <FormattedMessage id="page.userProfile.editLanguage.editLanguage" />
                  </DialogTitle>
                  <DialogContent>
                    <Query query={GetLanguages} onError={errorHandler.onError}>
                      {({ data }: QueryResult<GetLanguagesData>) => {
                        if (data == null || data.languages == null) {
                          return <Skeleton />;
                        }
                        return (
                          <Select
                            fullWidth={true}
                            variant='outlined'
                            aria-label="editLanguage" 
                            name="languageCode"
                            data-testid={
                              TestIds.pages.userProfile.account.selectLanguage
                            }
                            onChange={(e: any) =>
                              this.setState({
                                languageCode: e.target!.value
                              })
                            }
                            value={this.state.languageCode}
                            MenuProps={{
                              PopoverClasses: {
                                root: classes.selectModal
                              }
                            }}
                          >
                            {data.languages.edges.map(({ node: language }) => (
                              <MenuItem
                                key={language.code}
                                data-testid={`option-${language.code}`}
                                value={language.code}
                                selected={language.code === languageCode}
                              >
                                {language.label}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    </Query>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onClose} data-testid={TestIds.common.snackbar.action.cancel} color='inherit'>
                      <FormattedMessage id="common.action.cancel" />
                    </Button>
                    <ButtonLoading
                      data-testid={TestIds.pages.profile.saveLanguageButton}
                      type="submit"
                      color="primary"
                      loading={loading}
                    >
                      <FormattedMessage id="common.action.validate" />
                    </ButtonLoading>
                  </DialogActions>
                </form>
              </Dialog>
            )}
          </Mutation>
        )}
      </ErrorHandler.Consumer>
    );
  }

  private onCompleted = () => {
    const { onClose } = this.props;

    enqueueSnackbar(<FormattedMessage id="page.userProfile.updatedProfile" />, {
      variant: 'success'
    });

    onClose();
  };
}

export default withStyles(styles)(EditLanguageDialog);
