import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GroupAvatar from 'Theme/components/Avatar/GroupAvatar';
import GroupInformation from './GroupInformation';
import About from './About';
import useTranslate from 'Hooks/useTranslate';

interface Props {
  group: IPersonGroup;
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(3)
  }
}));

function TabGeneral(props: Props) {
  const { group } = props;
  const ts = useTranslate();
  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <GroupAvatar
          group={group}
          size="larger"
          role="group-avatar"
        />
        <Typography variant="h5" color="textSecondary">
          {ts('page.groupProfile.youConsultGroup')}
        </Typography>
        <Typography variant="h5" color="textPrimary">
          {group.name}
        </Typography>
      </div>
      <GroupInformation group={group} />
      <About group={group} />
    </>
  );
}

export default TabGeneral;
