import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { FormattedMessage } from 'react-intl';
import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PersonContext } from 'Context/CurrentPerson';
import Container from 'Theme/components/Container';
import { GetAccounts } from 'Apollo/queries/account/GetAccounts';
import { GetAccount } from 'Apollo/queries/account/GetAccount';
import useTranslate from 'Hooks/useTranslate';
import FormCreateGroup from 'Components/FormCreateGroup';
import { QueryResult } from '@apollo/client';
import useNotification from 'Hooks/useNotification';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3)
  }
}));

interface Props {}

const PersonGroupCreate = (props: Props) => {
  const ts = useTranslate();
  const { currentPerson } = React.useContext(PersonContext);
  let { accountCode } = useParams<{ accountCode: string }>();
  const { onError } = useNotification();
  const classes = useStyles();

  return (
    <>
      <Helmet title={ts('page.createPersonGroup.title')} />
      <Container>
        <Paper classes={{ root: classes.paper }}>
          <Typography variant="h4">
            <FormattedMessage id="page.createPersonGroup.title" />
          </Typography>
          {accountCode === 'all' ? (
            <Query
              query={GetAccounts}
              variables={{ email: currentPerson.email }}
              onError={onError}
            >
              {({ data }: QueryResult<GetAccountsData>) => (
                <FormCreateGroup data={data} />
              )}
            </Query>
          ) : (
            <Query
              query={GetAccount}
              variables={{ accountCode }}
              onError={onError}
            >
              {({ data }: QueryResult<GetAccountData>) => {
                const accountData: GetAccountsData | undefined = data
                  ? {
                      ...data,
                      accounts: {
                        edges: [{ node: data.account }],
                        pageInfo: {
                          hasNextPage: false
                        },
                        totalCount: 1
                      }
                    }
                  : undefined;

                return <FormCreateGroup data={accountData} />;
              }}
            </Query>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default PersonGroupCreate;
