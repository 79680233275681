import { gql } from '@apollo/client';
import { LightPersonProfileFragment } from '../../fragments/person/LightPersonProfile';

export const GetLightProfileWithAccessRight = gql`
  query GetLightProfileWithAccessRight(
    $serviceSubscriptionIdentifier: serviceSubscriptionIdentifier!,
    $searchTerm: String
    $page: PageInput,
    $orderBy: PersonOrder
  ) {
    lightProfileWithAccessRight(
      identifier: $serviceSubscriptionIdentifier
      searchTerm: $searchTerm
      page: $page
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...LightPersonProfile
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${LightPersonProfileFragment}
`;
