import { gql } from '@apollo/client';
import { ServiceSubscriptionIpRestrictionFragment } from '../../fragments/service/ServiceSubscriptionIpRestriction';

export const UpdateServiceSubscriptionIpRestriction = gql`
  mutation UpdateServiceSubscriptionIpRestriction(
    $serviceIPRangeItemForUpdate: serviceIPRangeItemForUpdate!
  ) {
    updateIPRange(serviceIPRangeItemForUpdate: $serviceIPRangeItemForUpdate) {
      ...ServiceIPRange
    }
  }
  ${ServiceSubscriptionIpRestrictionFragment}
`;
