import { Typography, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTranslate from '../../../../../Hooks/useTranslate';
import { DataRow } from 'Theme/components/DataRow';
import PaperTitle from 'Theme/components/PaperTitle';
import TestIds from 'Tests/TestIds';

interface Props {
  person: IPersonWithAccount;
}

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}));

const TechnicalInformation = (props: Props) => {
  const { person } = props;
  const ts = useTranslate();
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.root }}>
      <PaperTitle
        title={ts('page.userProfile.section.technical.technicalInformation')}
      />
      <DataRow
        label={ts('page.userProfile.section.technical.immutableId')}
        divider={true}
      >
        <Typography
          variant="body2"
          data-testid={
            TestIds.pages.userProfile.technicalInformation.immutableId
          }
        >
          {person.immutableId}
        </Typography>
      </DataRow>
      <DataRow
        label={ts('page.userProfile.section.technical.immutableGuid')}
      >
        <Typography
          variant="body2"
          data-testid={
            TestIds.pages.userProfile.technicalInformation.immutableGuid
          }
        >
          {person.immutableGuid}
        </Typography>
      </DataRow>
    </Paper>
  );
};

export default TechnicalInformation;
