import { Box, Skeleton } from '@mui/material';

type Props = {};

const DashboardSkeleton = (props: Props) => {
  return (
    <Box>
      <Skeleton variant="rectangular" height={60} width="100%" />
      <Box mt={5} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Skeleton variant="rectangular" height={130} width="30%" />
        <Skeleton variant="rectangular" height={130} width="30%" />
        <Skeleton variant="rectangular" height={130} width="30%" />
      </Box>
      <Box mt={5} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <Skeleton variant="rectangular" height={350} width="55%" />
        <Skeleton variant="rectangular" height={350} width="38%" />
      </Box>
    </Box>
  );
};

export default DashboardSkeleton;
