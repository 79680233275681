import { useMutation } from '@apollo/client';
import TestIds from 'Tests/TestIds';
import { useState } from 'react';
import useNotification from 'Hooks/useNotification';
import RemoveButton from 'Components/Shared/RemoveButton';
import { RemoveServiceSubscriptionsFromPersonGroup } from 'Apollo/mutations/personGroup/RemoveServicesFromPersonGroup';
import useTranslate from 'Hooks/useTranslate';

interface Props {
  serviceSubscription: IServiceSubscription;
  group: IPersonGroup;
}

function RemoveServiceButton(props: Readonly<Props>) {
  const { serviceSubscription, group } = props;
  const {
    targetAccount,
    account: { code },
    service: { groupCode, name }
  } = serviceSubscription;
  const { code: targetAccountCode } = targetAccount ?? {};
  const ts = useTranslate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { onSuccess, onError } = useNotification();
  const serviceSubscriptionIdentifier: IServiceSubscriptionIdentifier = {
    accountCode: code,
    targetAccountCode,
    serviceCode: groupCode
  };

  const variables: RemoveServicesFromPersonGroupVars = {
    serviceSubscriptionsIdentifiers: [serviceSubscriptionIdentifier],
    groupCode: group.code
  };

  const [handleRemoveServicesFromPersonGroup, { loading }] = useMutation(
    RemoveServiceSubscriptionsFromPersonGroup,
    {
      onError,
      onCompleted: () => {
        onSuccess('page.groupProfile.services.subscription.deleteMessage');
        setIsOpen(false);
      },
      refetchQueries: ['GetPersonGroupServiceSubscriptions']
    }
  );

  const handleRemove = async () => {
    await handleRemoveServicesFromPersonGroup({
      variables: { ...variables },
      fetchPolicy: 'network-only'
    });
  };

  return (
    <RemoveButton
      actionId={TestIds.pages.profile.services.unsubscribeButton}
      confirmationId={TestIds.pages.profile.services.confirmDeletionButton}
      confirmationMessage={ts(
        'page.groupProfile.services.subscription.removeConfirmation',
        {
          groupName: group.name,
          serviceName: name
        }
      )}
      isOpen={isOpen}
      onSubmit={handleRemove}
      loading={loading}
    />
  );
}

export default RemoveServiceButton;
