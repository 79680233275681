import { Grid, useMediaQuery } from '@mui/material';
import Skeleton from './Skeleton';
import mediaQueries from 'Theme/constants/mediaQueries';

interface Props {}

function UserCellSkeleton(props: Readonly<Props>) {
  const isMobileP = useMediaQuery(mediaQueries.mobilePortrait);

  return (
    <Grid container={true} alignItems="center">
      <Skeleton variant="circle" width={24} height={24} />
      {!isMobileP && (
        <Skeleton variant="rectangular" width="60%" style={{ marginLeft: '16px' }} />
      )}
    </Grid>
  );
}

export default UserCellSkeleton;
