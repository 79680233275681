import { ActionsDrawer } from 'Components/Shared/ActionsDrawer';
import { useState } from 'react';
import { Button } from '@mui/material';
import { PersonAdd } from '@mui/icons-material';
import useTranslate from '../../Hooks/useTranslate';
import TableServiceOwnerCandidates from '../TableServiceOwnerCandidates';
import { useMutation } from '@apollo/client';
import { AddServiceSubscriptionOwners } from '../../Apollo/mutations/service/AddServiceSubscriptionOwners';
import { useRouteMatch } from 'react-router-dom';
import useNotification from '../../Hooks/useNotification';

type Props = {
  open: boolean;
  onClose(): void;
  onInviteExternal(): void;
};

const DrawerServiceProfileOwnersContainer = ({
  open,
  onClose,
  onInviteExternal
}: Props) => {
  const [search, setSearch] = useState('');
  const { onError, onSuccess } = useNotification();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { params: serviceSubscriptionIdentifier } = useRouteMatch<
    IServiceSubscriptionIdentifier
  >();
  const ts = useTranslate();

  const [addOwnersToServiceSubscription, { loading }] = useMutation<
    ILightPersonProfile,
    IAddServiceSubscriptionOwnerInput
  >(AddServiceSubscriptionOwners);

  const addOwner = async (personIdentifiers: string[]) => {
    await addOwnersToServiceSubscription({
      variables: {
        personIdentifiers,
        serviceSubscriptionIdentifier
      },
      onError: e => {
        onError(e);
      },
      onCompleted: data => {
        onSuccess('page.serviceProfile.owners.sucess', {
          ownersCount: selectedIds.length
        });
        setSelectedIds([]);
      },
      refetchQueries: ['GetServiceOwners']
    });
    onClose();
  };

  const actionButtons = [
    <Button
      key="Opt1"
      color="primary"
      startIcon={<PersonAdd />}
      onClick={onInviteExternal}
    >
      {ts('page.services.action.subscribeExternalOwner')}
    </Button>
  ];

  return (
    <ActionsDrawer
      open={open}
      title={ts('page.serviceProfile.owners.add')}
      allowExternalInvitation
      onAdd={() => addOwner(selectedIds)}
      loading={loading}
      onClose={onClose}
      onInviteExternal={onInviteExternal}
      onSearch={term => setSearch(term)}
      actions={actionButtons}
      isReady={selectedIds.length > 0}
    >
      <TableServiceOwnerCandidates
        search={search}
        selectedIds={selectedIds}
        setSelectedIds={(ids: string[]) => setSelectedIds(ids)}
      />
    </ActionsDrawer>
  );
};

export default DrawerServiceProfileOwnersContainer;
