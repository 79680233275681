import * as React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { EmailRounded as IconEmailRounded } from '@mui/icons-material';
import { ApolloError, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import {
  CreateEmailChangeRequest,
  CreateEmailChangeRequestMutation
} from '../../../../../../Apollo';
import { ErrorHandler } from 'Theme/components/ErrorHandler';
import TestIds from 'Tests/TestIds';
import {  enqueueSnackbar } from 'notistack';

import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps as InjectedIntlProps
} from 'react-intl';

interface Props {
  userId: string;
  newEmail: string;
}

class ResendEmailRequest extends React.Component<
  Props & InjectedIntlProps
> {
  public render() {
    const { userId, newEmail } = this.props;

    return (
      <ErrorHandler.Consumer>
        {errorHandler => (
          <Mutation<Object, CreateEmailChangeRequestVariables>
            mutation={CreateEmailChangeRequest}
            variables={{
              personIdentifier: userId,
              newEmail: newEmail
            }}
            onCompleted={this.onCompleted}
            onError={(e: ApolloError) => errorHandler.onError(e)}
          >
            {(
              createEmailChangeRequest: CreateEmailChangeRequestMutation,
              { loading, data }: MutationResult
            ) => (
              <form
                onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault();

                  if (loading) {
                    return;
                  }
                  createEmailChangeRequest();
                }}
              >
                <Tooltip
                  title={
                    <FormattedMessage id="page.userProfile.personalInformations.renewRequest" />
                  }
                >
                  <IconButton size="medium" type="submit">
                    <IconEmailRounded
                      color="primary"
                      data-testid={TestIds.pages.profile.resendEmailIcon}
                    />
                  </IconButton>
                </Tooltip>
              </form>
            )}
          </Mutation>
        )}
      </ErrorHandler.Consumer>
    );
  }

  private onCompleted = () => {
    enqueueSnackbar(
      <FormattedMessage id="page.userProfile.personalInformations.resendRequestSuccess" />,
      {
        variant: 'success'
      }
    );
  };
}

export default injectIntl(ResendEmailRequest);
