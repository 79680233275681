import { LinearProgress } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MuiTheme from 'Theme/MuiTheme';
import Router from './Router/Router';
import { createClient } from './Apollo';
import LocalStorage from './Tools/localStorage';
import axios from 'axios';
import { getSupportedLocale } from './Languages';
import PreviousLocation from './Router/PreviousLocation';
import DelegateConfirmation from 'Page/DelegateConfirmation';
import { Cookies, ReactCookieProps, withCookies } from 'react-cookie';
import PrivateRoute from './Router/PrivateRoute';
import { PublicRoutes } from './Router/Routes';
import CookieCheck from './Router/CookieCheck';
import AboutGoogle from './Page/About/AboutGoogle';
import SnackbarProvider from 'Components/Shared/Snackbar/SnackbarProvider';
import { PageError } from 'Page/PageError';
import { messages } from 'Languages/Translation';

interface Props extends ReactCookieProps {
  cookies: Cookies;
}

interface State {
  hasError: boolean;
}
const ENDPOINT_URI = window.AppSettings.GRAPHQL_ENDPOINT_URI;

class App extends React.Component<Props & ReactCookieProps, State> {
  public readonly state: State = {
    hasError: false
  };

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: true });
  }

  public render() {
    const { cookies } = this.props;
    return (
      <ApolloProvider client={createClient(ENDPOINT_URI)}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <PreviousLocation.Provider>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={MuiTheme}>
                <Switch>
                  <Route
                    path={PublicRoutes.delegateConfirmation.path}
                    children={() => {
                      const browserLocales = window.navigator.languages || [];
                      const locale = getSupportedLocale(browserLocales);

                      return (
                        <IntlProvider
                          locale={locale}
                          messages={messages[locale]}
                        >
                          <SnackbarProvider>
                            <DelegateConfirmation />
                          </SnackbarProvider>
                        </IntlProvider>
                      );
                    }}
                  />

                  <Route
                    cookies={this.props.cookies}
                    path={PublicRoutes.errorLogin.path}
                    children={() => {
                      // Must remove all authentication stuff to ensure proper next login (at the PM side)
                      cookies.remove('PMSession');
                      LocalStorage.CsrfToken = null;

                      const locale = getSupportedLocale(
                        window.navigator.languages || []
                      );
                      return (
                        <IntlProvider
                          locale={locale}
                          messages={messages[locale]}
                        >
                          <SnackbarProvider>
                            <PageError
                              title={messages[locale]['error.type.signin']}
                              textPrimary={
                                messages[locale]['error.sentence.signin']
                              }
                            />
                          </SnackbarProvider>
                        </IntlProvider>
                      );
                    }}
                  />

                  <Route
                    cookies={this.props.cookies}
                    path={PublicRoutes.accessDenied.path}
                    children={() => {
                      const locale = getSupportedLocale(
                        window.navigator.languages || []
                      );
                      return (
                        <IntlProvider
                          locale={locale}
                          messages={messages[locale]}
                        >
                          <SnackbarProvider>
                            <PageError
                              title={messages[locale]['error.type.E403']}
                              textPrimary={
                                messages[locale]['error.sentence.E403']
                              }
                              accessDenied={true}
                            />
                          </SnackbarProvider>
                        </IntlProvider>
                      );
                    }}
                  />

                  <Route
                    cookies={this.props.cookies}
                    path={PublicRoutes.activationError.path}
                    children={() => {
                      const locale = getSupportedLocale(
                        window.navigator.languages || []
                      );
                      return (
                        <IntlProvider
                          locale={locale}
                          messages={messages[locale]}
                        >
                          <SnackbarProvider>
                            <PageError
                              title={messages[locale]['error.type.activation']}
                              textPrimary={
                                messages[locale]['error.sentence.activation']
                              }
                            />
                          </SnackbarProvider>
                        </IntlProvider>
                      );
                    }}
                  />
                  <Route
                    cookies={this.props.cookies}
                    path={PublicRoutes.aboutGoogle.path}
                    children={() => {
                      const locale = getSupportedLocale(
                        window.navigator.languages || []
                      );
                      return (
                        <IntlProvider
                          locale={locale}
                          messages={messages[locale]}
                        >
                          <SnackbarProvider>
                            <AboutGoogle />
                          </SnackbarProvider>
                        </IntlProvider>
                      );
                    }}
                  />
                  <Route
                    cookies={this.props.cookies}
                    path={PublicRoutes.errorUndefined.path}
                    children={() => {
                      const locale = getSupportedLocale(
                        window.navigator.languages || []
                      );
                      return (
                        <IntlProvider
                          locale={locale}
                          messages={messages[locale]}
                        >
                          <SnackbarProvider>
                            <PageError
                              title={messages[locale]['error.oops']}
                              textPrimary={messages[locale]['error.type.E500']}
                            />
                          </SnackbarProvider>
                        </IntlProvider>
                      );
                    }}
                  />

                  <Route
                    cookies={this.props.cookies}
                    path={PublicRoutes.endLogin.path}
                    exact={false}
                    children={() => {
                      const baseAppUri = new URL(
                        `${window.AppSettings.PUBLIC_URI}`
                      ).toString();

                      // Check first the redirect to final uri
                      // Current uri is of the form: /end_login?returnUrl=XXX where XXX is user entry url, so we catch it in the returnUrl parameter
                      const urlToGo = new URL(
                        window.location.search.slice(1).split('returnUrl=')[1],
                        baseAppUri
                      ).toString();
                      if (`${urlToGo}/`.startsWith(baseAppUri)) {
                        const apiEndpointUri = `${window.AppSettings.API_ENDPOINT_URI}`;
                        const instance = axios.create({
                          baseURL: apiEndpointUri
                        });
                        axios
                          .get(`${apiEndpointUri}pmsession/csrftoken`)
                          .then(res => {
                            // Store csrf token in header and localstorage
                            instance.defaults.headers.common['X-CSRFToken'] =
                              res.data;
                            LocalStorage.CsrfToken = res.data;

                            window.location.href = urlToGo;
                          })
                          .catch(err => {
                            console.log(err);
                            delete instance.defaults.headers.common[
                              'X-CSRFToken'
                            ];

                            window.location.href = `${baseAppUri}error_login`;
                          });
                      } else {
                        console.log("Invalid redirect uri '" + urlToGo + "'");

                        window.location.href = `${baseAppUri}error_login`;
                      }

                      return <LinearProgress color="secondary" />;
                    }}
                  />

                  <Route
                    cookies={this.props.cookies}
                    path={PublicRoutes.superuseraccess.path}
                  >
                    {() => {
                      const apiEndpointUri = `${window.AppSettings.API_ENDPOINT_URI}`;
                      window.location.href = `${apiEndpointUri}pmsession/superuseraccess`;
                      return <LinearProgress color="secondary" />;
                    }}
                  </Route>

                  <CookieCheck>
                    <PrivateRoute>
                      <Router />
                    </PrivateRoute>
                  </CookieCheck>
                </Switch>
              </ThemeProvider>
            </StyledEngineProvider>
          </PreviousLocation.Provider>
        </BrowserRouter>
      </ApolloProvider>
    );
  }
}

export default withCookies(App);
