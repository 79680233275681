import { CdsColorBorderPrimaryHighEmphasis, CdsColorTextCriticalHighEmphasis } from '@cegid/design-tokens';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Skeleton,
} from '@mui/material';
import React from 'react';

interface Props  {
  testId: string;
  title: React.ReactNode;
  icon: React.ReactElement;
  counter?: React.ReactNode;
  action?: React.ReactNode;
  loading?: boolean;
  error?: string;
}

function CardCounter({
  testId,
  title,
  counter,
  icon,
  action,
  loading,
  error
}: Readonly<Props>) {
  return (
    <Card
      data-testid={testId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderTop: `4px solid ${CdsColorBorderPrimaryHighEmphasis}`,
        minHeight: 148
      }}
    >
      <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
        <div style={{flex: 1}}>
          <Typography variant="body2" color="textSecondary">
            {title}
          </Typography>
          {loading && <Skeleton variant="rectangular" width="100px" title="progressbar" />}
          {!loading && error && (
            <Typography
              variant="caption"
              color={CdsColorTextCriticalHighEmphasis}
            >
              {error}
            </Typography>
          )}
          {!loading && !error && counter && <Typography variant="h4">{counter}</Typography>}
        </div>
        <div style={{fontSize: 40}}>
          {React.cloneElement(icon, {
            fontSize: 'inherit',
            color: 'primary'
          })}
        </div>
      </CardContent>
      {action && (
        <CardActions
          style={{ flexDirection: 'column', alignItems: 'flex-end' }}
        >
          {action}
        </CardActions>
      )}
    </Card>
  );
}

export default CardCounter;
