import { gql } from '@apollo/client';

export const PersonGroupServiceAssignmentFragment = gql`
  fragment PersonGroupServiceAssignment on PersonGroupServiceAssignment {
    groupCode
    accountCode
    serviceCode
    targetAccountCode
    status
    error {
      code
      message
    }
  }
`;
