const findDuplicates = (arr: any[]) => {
  if (!Array.isArray(arr)) {
    throw new TypeError('The argument must be an array');
  }

  let duplicates = [];
  let uniqueVals = [];

  for (let val of arr) {
    const value = typeof val === 'object' ? JSON.stringify(val) : val;

    if (uniqueVals.indexOf(value) === -1) {
      uniqueVals.push(value);
    } else if (duplicates.indexOf(value) === -1) {
      duplicates.push(value);
    }
  }

  return duplicates;
};

export default findDuplicates;
