import { gql } from '@apollo/client';
import { ServiceSubscriptionIpRestrictionFragment } from '../../fragments/service/ServiceSubscriptionIpRestriction';

export const CreateServiceSubscriptionIpRestriction = gql`
  mutation CreateServiceSubscriptionIpRestriction(
    $serviceSubscriptionIdentifier: serviceSubscriptionIdentifier!
    $serviceIPRangeItemForCreate: serviceIPRangeItemForCreate!
  ) {
    createIPRange(
      serviceSubscriptionIdentifier: $serviceSubscriptionIdentifier
      serviceIPRangeItemForCreate: $serviceIPRangeItemForCreate
    ) {
      ...ServiceIPRange
    }
  }
  ${ServiceSubscriptionIpRestrictionFragment}
`;
