import { gql } from '@apollo/client';
import { AccountFragment } from '../account/Account';
import { ServiceFragment } from './Service';

export const ServiceSubscriptionWithCountFragment = gql`
  fragment ServiceSubscriptionWithCount on ServiceSubscriptionWithCount {
    service {
      ...Service
    }
    account {
      ...Account
    }
    targetAccount {
      ...Account
    }
    administratedValidation
    accessByDefault
    isAvailable
    maxSubscriptions
    numberOfSubscriptions
  }
  ${ServiceFragment}
  ${AccountFragment}
`;


