import React from 'react';
import { PersonAccessRightStatus } from '../../../../../Apollo';
import TestIds from 'Tests/TestIds';
import FilteredServices from '../FilteredServices';

interface Props {
  person: IPersonWithAccount;
}

const PendingServices = (props: Props) => {
  const {person} = props;
  return (
    <FilteredServices
      person={person}
      helpId={'page.userProfile.pendingServices.help'}
      titleId="page.userProfile.personalInformations.pendingRequest"
      emptyStatePrimary={'page.userProfile.services.emptyState.title'}
      emptyStateSecondary={'page.userProfile.services.emptyState.description'}
      tableTestIds={TestIds.pages.profile.pendingServices.table}
      servicesStatus={PersonAccessRightStatus.ToValidate}
    />
  );
};

export default PendingServices;
