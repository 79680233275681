import { generatePath, useParams } from 'react-router-dom';
import useTranslate from 'Hooks/useTranslate';
import useTabsApiKey from 'Hooks/useTabsApiKey';
import { IApiKey } from 'Apollo/fragments/apikey/apiKeyTypes';
import ProfilePageTemplate from 'Components/Shared/ProfileTemplate/ProfilePageTemplate';
import { PrivateRoutes } from 'Router/Routes';

interface Props {
  apiKey: IApiKey;
}

function ApikeyProfile({ apiKey }: Readonly<Props>) {
  const tabs = useTabsApiKey({ apiKey });
  const ts = useTranslate();

  const { accountCode, apikeyUuid } = useParams<{
    accountCode: string;
    apikeyUuid: string;
  }>();

  const basePath = generatePath(PrivateRoutes.apikeyProfile.path, {
    accountCode,
    apikeyUuid
  });

  return (
    <ProfilePageTemplate
      title={ts('page.apikeyProfile.title', { uuid: apiKey.name })}
      tabs={tabs}
      basePath={basePath}
    />
  );
}

export default ApikeyProfile;
