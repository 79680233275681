import React from 'react';
import {
  Table as MaterialTable,
  TableHead,
  TableBody,
  TableSortLabel,
  TablePagination,
  TableRow,
  TableFooter,
  TableCell,
  Checkbox,
  Typography,
  Button,
  Box,
  TableContainer
} from '@mui/material';
import { getColumnsWidth } from '../../../Tools';

import { useStyles } from './Table.styles';
import useTranslate from '../../../Hooks/useTranslate';
import { TProfileManagerMessagesKeys } from '../../../Languages/TProfileManagerMessages';
import TestIds from 'Tests/TestIds';
import { ROW_PER_PAGE_OPTIONS } from 'Theme/config';

export interface TableCollectionColumn<T> {
  /**Hint: Unique identifier of the column */
  key: string;
  /**Hint: Column order by (the string is given by the graphql model)*/
  orderBy?: {
    asc: string;
    desc: string;
  };
  /**Hint: Number between 0 and 1, being the width in % */
  width?: number; //
  /**Hint: User preferences: is it hidden ? */
  hidden?: boolean;
  /**Hint: Column label rendering */
  renderHead: () => React.ReactNode;
  /**Hint: Loading rendering in cell */
  renderSkeleton: () => React.ReactNode;
  /**Hint: Data cell rendering */
  renderCell: (props: { row: T }) => React.ReactNode;
}

export interface ITableMessages {
  maxSelectedExceeded: TProfileManagerMessagesKeys;
  pageAllSelected: TProfileManagerMessagesKeys;
  selectAll: TProfileManagerMessagesKeys;
}

const defaultTableMessages: ITableMessages = {
  maxSelectedExceeded: 'common.action.back',
  pageAllSelected: 'component.table.elementSelected',
  selectAll: 'component.table.selectAll'
};

export const getTablePage = (page: IPage): ITablePage => {
  return { number: Math.floor(page.offset / page.first), size: page.first };
};

export const getGraphQLPage = (page: ITablePage): IPage => {
  return { offset: page.size * page.number, first: page.size };
};

interface Props<T> {
  /**Hint: Table id*/
  id?: string;
  /**Hint: General state of loading (skeleton rendered)*/
  loading: boolean;
  /**Hint: */
  columns: TableCollectionColumn<T>[];
  /**Hint: Data loaded from api. Modeled via renderCell*/
  rows: T[];
  /**Hint: List of selected Ids*/
  selectedIds: string[];
  /**Hint: Callback fired when users changes the row selection*/
  onSelectionUpdate: (ids: string[]) => void;
  /**Hint: Custom function to calculate unique React Keys ids*/
  getRowId: (row: T) => string;
  /**Hint: Current Page number*/
  page: ITablePage;
  /**Hint: Callback that is called on Page Change*/
  onChangePage: (page: ITablePage) => void;
  /**Hint: */
  maxSelected: number;
  /**Hint: Total count of results from the API without pagination*/
  totalCount: number;
  /**Hint: Property to disable selection*/
  isSelectionHidden?: boolean;
  /**Hint: Property to disable select all*/
  isSelectAllHidden?: boolean;
  /**Hint: Data stored into localStorage with user preferences*/
  emptyStateData?: React.ReactNode;
  /**Hint: Mesages IDs to translate*/
  messages?: ITableMessages;
}

const Table = <T extends unknown>(props: Props<T>) => {
  const {
    id,
    loading,
    rows,
    selectedIds,
    getRowId,
    emptyStateData,
    onSelectionUpdate,
    isSelectionHidden: isSelectionHiddenInput,
    isSelectAllHidden,
    totalCount,
    maxSelected,
    messages: providedMessages,
    columns,
    page,
    onChangePage
  } = props;
  const ts = useTranslate();
  const classes = useStyles();
  const messages = providedMessages ?? defaultTableMessages;

  const isSelectionHidden: boolean = isSelectionHiddenInput ?? false;
  const totalSelected = selectedIds == null ? totalCount : selectedIds.length;
  const isMaxSelectedExceeded = totalSelected > maxSelected;

  const onSelect = (rows: string[]) => {
    const selection: string[] = (selectedIds ?? []).concat(rows);
    onSelectionUpdate(Array.from(new Set(selection)));
  };

  const onUnselect = (rows: string[]) => {
    const selection: string[] = (selectedIds ?? []).filter(
      id => !rows.includes(id)
    );
    onSelectionUpdate(Array.from(new Set(selection)));
  };

  const renderTableBody = (
    visibleColumns: TableCollectionColumn<T>[],
    visibleColumnsWidth: string[]
  ) => {
    const isEmptyState = !rows.length && emptyStateData;

    if (isEmptyState) {
      return (
        <TableRow
          classes={{
            root: classes.emptyRow
          }}
        >
          <TableCell
            classes={{
              root: classes.emptyCell
            }}
          >
            {emptyStateData}
          </TableCell>
        </TableRow>
      );
    }

    return rows.map(row => {
      const rowIsSelected = selectedIds?.includes(getRowId(row));
      return (
        <TableRow
          key={getRowId(row)}
          selected={rowIsSelected}
          className={!rowIsSelected ? '' : classes.selectedRow}
        >
          {!isSelectionHidden && (
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                onChange={event =>
                  event.target.checked
                    ? onSelect([getRowId(row)])
                    : onUnselect([getRowId(row)])
                }
                checked={selectedIds == null || rowIsSelected}
                inputProps={{
                  name: `checkbox-${getRowId(row)}`
                }}
              />
            </TableCell>
          )}

          {visibleColumns.map((column, i) => (
            <TableCell
              key={column.key}
              classes={{
                root: classes.cellRoot
              }}
              style={{
                width: visibleColumnsWidth[i] ?? 'auto'
              }}
            >
              {column.renderCell({ row })}
            </TableCell>
          ))}
        </TableRow>
      );
    });
  };

  const renderMaxSelectionRow = (
    visibleColumns: TableCollectionColumn<T>[]
  ) => {
    const isPageAllSelected =
      selectedIds != null &&
      rows.length > 0 &&
      rows.every(row => selectedIds.includes(getRowId(row)));

    const selectAll =
      !isSelectAllHidden &&
      isPageAllSelected &&
      totalCount <= maxSelected &&
      totalSelected < totalCount;

    const noMaxSelectedExceeded = selectAll ? (
      <TableRow className={classes.allSelectedRow}>
        <TableCell colSpan={visibleColumns.length + 1} align="center">
          <Box className={classes.notice}>
            <Typography
              variant="body2"
              data-testid={TestIds.table.selectAllElement.selectedElementCount}
              children={ts(messages.pageAllSelected, {
                count: totalSelected
              })}
            />
            <Button
              color="primary"
              onClick={() => onSelect(rows.map(getRowId))}
              data-testid={TestIds.table.selectAllElement.selectAllButton}
              children={ts(messages.selectAll, { count: totalCount })}
            />
          </Box>
        </TableCell>
      </TableRow>
    ) : null;

    return isMaxSelectedExceeded ? (
      <TableRow>
        <TableCell colSpan={visibleColumns.length + 1} align="center">
          <Box className={classes.notice}>
            <Typography
              variant="body2"
              color="error"
              children={ts(messages.maxSelectedExceeded, {
                count: maxSelected
              })}
            />
          </Box>
        </TableCell>
      </TableRow>
    ) : (
      noMaxSelectedExceeded
    );
  };

  const visibleColumns = columns.filter(column => !column.hidden);
  const visibleColumnsWidth = getColumnsWidth(
    visibleColumns.map(column => column.width)
  ).map(width => `${width * 100}%`);
  const isEmptyState = !rows.length && emptyStateData;

  return (
    <TableContainer>
      <MaterialTable
        classes={{
          root: classes.tableRoot
        }}
        id={id}
      >
        <TableHead>
          <TableRow>
            {!isSelectionHidden && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  onChange={() => {
                    if (
                      props.selectedIds == null ||
                      props.selectedIds.length > 0
                    ) {
                      onSelectionUpdate([]);
                    } else {
                      onSelect(rows.map(getRowId));
                    }
                  }}
                  checked={
                    props.selectedIds == null ||
                    props.selectedIds.length === props.totalCount
                  }
                  indeterminate={
                    props.selectedIds != null &&
                    props.selectedIds.length < props.totalCount &&
                    props.selectedIds.length > 0
                  }
                />
              </TableCell>
            )}
            {visibleColumns.map((column, index) => (
              <TableCell
                key={column.key}
                style={{
                  width: visibleColumnsWidth[index] ?? 'auto'
                }}
              >
                {!page.orderBy || column.orderBy == null ? (
                  column.renderHead()
                ) : (
                  <TableSortLabel
                    onClick={() => {
                      if (column.orderBy == null) {
                        return;
                      }
                      onChangePage({
                        ...page,
                        orderBy:
                          page.orderBy === column.orderBy.desc
                            ? column.orderBy.asc
                            : column.orderBy.desc
                      });
                    }}
                    active={
                      page.orderBy === column.orderBy.asc ||
                      page.orderBy === column.orderBy.desc
                    }
                    direction={
                      page.orderBy === column.orderBy.asc ? 'asc' : 'desc'
                    }
                  >
                    {column.renderHead()}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isSelectionHidden && renderMaxSelectionRow(visibleColumns)}
          {loading
            ? Array.from({ length: page.size }).map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell padding="checkbox">
                    <Checkbox color="primary" />
                  </TableCell>
                  {visibleColumns.map((column, i) => (
                    <TableCell
                      key={column.key}
                      style={{
                        width: visibleColumnsWidth[i] ?? 'auto'
                      }}
                    >
                      {column.renderSkeleton()}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : renderTableBody(visibleColumns, visibleColumnsWidth)}
        </TableBody>
        {!isEmptyState && !loading && (
          <TableFooter>
            <TableRow>
              <TablePagination
                count={totalCount}
                rowsPerPage={page.size}
                page={page.number}
                rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                onRowsPerPageChange={event =>
                  onChangePage({ number: 0, size: Number(event.target.value) })
                }
                onPageChange={(_, newPage: number) =>
                  onChangePage({ number: newPage, size: page.size })
                }
                labelRowsPerPage={ts('common.label.rowPerPage')}
                labelDisplayedRows={({ from, to, count: maxItemCount }) =>
                  ts('common.pagination.displayedRows', {
                    from,
                    to,
                    count: maxItemCount
                  })
                }
              />
            </TableRow>
          </TableFooter>
        )}
      </MaterialTable>
    </TableContainer>
  );
};

export default Table;
