import OrganizationDomainsContainer from 'Page/OrganizationDomains/OrganizationDomains.container';

interface Props {
  organization: IOrganization;
}

function TabDomains({ organization }: Readonly<Props>) {
  return <OrganizationDomainsContainer organization={organization} />;
}

export default TabDomains;
