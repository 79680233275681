import { ActivationStatus, PersonAccessRightStatus } from 'Apollo';
import {
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  Paper
} from '@mui/material';
import AcceptedServices from './AcceptedServices';
import PendingServices from './PendingServices';
import DeniedServices from './DeniedServices';
import SuspendedServices from './SuspendedServices';
import { FormattedMessage } from 'react-intl';
import { DataRow } from 'Theme/components/DataRow';
import { useStyles } from './TabServices.styles';
import { useState } from 'react';
import AlertDisabledAccount from 'Components/AlertDisabledAccount';

interface Props {
  person: IPersonWithAccount;
}

const TabServices = (props: Readonly<Props>) => {
  const { person } = props;
  const classes = useStyles();

  const [status, setStatus] = useState(PersonAccessRightStatus.Accepted);
  const isDisabled = person?.status === ActivationStatus.Disabled;

  return (
    <>
      {isDisabled && <AlertDisabledAccount />}
      <Paper>
        <DataRow
          label={
            <FormattedMessage id="page.userProfile.services.filterLabel" />
          }
        >
          <RadioGroup
            name="statusType"
            value={status}
            onChange={(event, newServiceStatus) => {
              setStatus(newServiceStatus as PersonAccessRightStatus);
            }}
            row
            classes={{ root: classes.radioGroup }}
          >
            {Object.keys(PersonAccessRightStatus).map(key => (
              <FormControlLabel
                key={key}
                classes={{ root: classes.formControlLabel }}
                value={
                  PersonAccessRightStatus[
                    key as keyof typeof PersonAccessRightStatus
                  ]
                }
                control={<Radio color="primary" />}
                label={
                  <FormattedMessage
                    id="page.userProfile.services.statusType"
                    values={{
                      value:
                        PersonAccessRightStatus[
                          key as keyof typeof PersonAccessRightStatus
                        ]
                    }}
                  />
                }
              />
            ))}
          </RadioGroup>
        </DataRow>
      </Paper>

      <Box p={1} />
      {status === PersonAccessRightStatus.Accepted && (
        <AcceptedServices person={person} />
      )}
      {status === PersonAccessRightStatus.ToValidate && (
        <PendingServices person={person} />
      )}
      {status === PersonAccessRightStatus.Refused && (
        <DeniedServices person={person} />
      )}

      {status === PersonAccessRightStatus.Suspended && (
        <SuspendedServices person={person} />
      )}
    </>
  );
};

export default TabServices;
