import { useState } from 'react';
import { getGraphQLPage } from 'Components/Shared/Table';
import { useColumnsServiceIpFiltering } from '../../Hooks/useColumnsServiceIpFiltering';
import useLocationPage from '../../Hooks/useLocationPage';
import TableNoSelection from '../Shared/Table/TableNoSelection';

interface Props {
  rows: IIpRestriction[];
  loading: boolean;
  totalCount: number;
  onReload(page: IPage): void;
}

const TableServicesIpRestrictions = ({
  rows,
  loading,
  onReload,
  totalCount
}: Props) => {
  const { currentPage } = useLocationPage();
  const [rowsPerPage, setRowsPerPage] = useState<number>(currentPage.first);
  const [page, setPage] = useState<number>(
    currentPage.offset / currentPage.first
  );
  const columns = useColumnsServiceIpFiltering(() =>
    onReload({ first: rowsPerPage, offset: page * rowsPerPage })
  );

  const onChangePage = (newPage: ITablePage) => {
    setPage(newPage.number);
    setRowsPerPage(newPage.size);
    onReload(getGraphQLPage(newPage));
  };

  return (
    <TableNoSelection
      loading={loading}
      columns={columns}
      rows={rows}
      getRowId={r => r.immutableId}
      totalCount={totalCount}
      page={{ size: rowsPerPage, number: page }}
      onChangePage={onChangePage}
    />
  );
};

export default TableServicesIpRestrictions;
