import { useMutation } from '@apollo/client';
import { AddMemberToGroupStatus, AddPersonsToPersonGroup } from 'Apollo';
import useNotification from 'Hooks/useNotification';
import { FormEvent } from 'react';
import FormAddGroupMember from './FormAddGroupMember';

interface Props {
  onCancel: () => void;
  selectedIds: string[] | null;
  totalCount: number;
  groupCode: string;
}

let invitationSuccess: IPersonGroupMembershipOperation[];

const FormAddGroupMemberContainer = (props: Props) => {
  const { onCancel, selectedIds, groupCode, totalCount } = props;

  const { onSuccess, onError } = useNotification();
  const [addPersonsToPersonGroup, { loading: isAddingPerson }] = useMutation<
    AddPersonsToPersonGroupData
  >(AddPersonsToPersonGroup, {
    onError,
    onCompleted: (data: AddPersonsToPersonGroupData) => {
      const addedPersonsArray = data?.addPersonsToPersonGroup;
      invitationSuccess = addedPersonsArray.filter(
        inv => inv.status === AddMemberToGroupStatus.Success
      );

      if (invitationSuccess.length > 0) {
        onSuccess(
          'page.groupProfile.members.addMembers.completedSuccess',
          { count: invitationSuccess.length },
          'persist'
        );
        onCancel();
      }
    },
    variables: {
      groupCode: groupCode,
      personIdentifiers: selectedIds
    }
  });

  const onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await addPersonsToPersonGroup({
      variables: { selectedIds: selectedIds },
      refetchQueries: ['GetPersonGroupPersons']
    });
  };

  return (
    <FormAddGroupMember
      onFormSubmit={onFormSubmit}
      loading={isAddingPerson}
      disabled={selectedIds !== null && selectedIds.length < 1}
      totalCount={totalCount}
      onCancel={onCancel}
    />
  );
};

export default FormAddGroupMemberContainer;
