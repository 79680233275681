import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        // the "inset" variant of the divider doesn't fit for this particular use case
        // so we customize the left spacing
        marginLeft: theme.spacing(2)
    }
}))