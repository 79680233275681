import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import List, { ListProps } from '@mui/material/List';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1)
  }
}));

interface Props extends ListProps {}

export default function NavigationList(props: Props) {
  const classes = useStyles();
  return <List {...props} classes={{ root: classes.root }} />;
}
