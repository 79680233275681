import {
  ApplicationInsights,
  ITelemetryItem
} from '@microsoft/applicationinsights-web';
import {
  ReactPlugin,
  withAITracking
} from '@microsoft/applicationinsights-react-js';

const connectionString =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_INSIGHTS
    : window.AppSettings.APP_INSIGHTS;

const frontTraceLabel = 'Profile Manager UI';
const cloudRole = 'Profile Manager UI';
const cloudRoleInstance = 'Profile Manager UI';

export class Insights {
  private ai: ApplicationInsights;
  private reactPlugin;
  constructor() {
    this.reactPlugin = new ReactPlugin();
    this.ai = new ApplicationInsights({
      config: {
        connectionString,
        extensions: [this.reactPlugin],
        disableFetchTracking: false,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableAutoRouteTracking: true
      }
    });
  }

  private telemetryInitializer = (envelope: ITelemetryItem) => {
    const { tags = {} as any } = envelope;
    tags['ai.cloud.role'] = cloudRole;
    tags['ai.cloud.roleInstance'] = cloudRoleInstance;
    tags['ai.application.ver'] = process.env.REACT_APP_VERSION;
    if (process.env.NODE_ENV === 'development') console.log(envelope);
  };

  public initialize = () => {
    const { ai } = this;
    try {
      ai.loadAppInsights();
      ai.trackPageView();
      ai.trackTrace({ message: frontTraceLabel });
      ai.addTelemetryInitializer(this.telemetryInitializer);
    } catch (e) {
      console.error('Telemetry has not been initialized.', e);
    }
  };

  public setAuthenticatedUserContext = (
    immutableId: string,
    accountId: string
  ) => {
    this.ai.setAuthenticatedUserContext(immutableId, accountId, true);
  };

  public clearAuthenticatedUserContext = () => {
    this.ai.clearAuthenticatedUserContext();
  };

  public getReactPlugin = () => {
    return this.reactPlugin;
  };

  public withAITracking = (component: any) => {
    return withAITracking(this.reactPlugin, component);
  };
}

const AppInsightsService = new Insights();

export { AppInsightsService };
