import { gql } from '@apollo/client';

export const AddServiceSubscriptionOwners = gql`
  mutation AddServiceSubscriptionOwners(
    $serviceSubscriptionIdentifier: serviceSubscriptionIdentifier!
    $personIdentifiers: [String!]
  ) {
    addServiceSubscriptionOwners(
      serviceSubscriptionIdentifier: $serviceSubscriptionIdentifier
      personIdentifiers: $personIdentifiers
    ) {
      immutableId
    }
  }
`;
