import { gql } from '@apollo/client';

export enum AccountOrder {
  CodeAsc = 'CODEASC',
  NameAsc = 'NAMEASC',
  ServiceDescCount = 'SERVICECOUNTDESCTHENCODEASC'
}

// TODO: we can't use the Account fragment here because /accounts is of type AccountWithSubscriptions
// We need to figure out a way to create a fragment that can be shared between Account and AccountWithSubscriptions.
// Or perhaps we should not have two types for the same object.
export const GetAccounts = gql`
  query GetAccounts(
    $page: PageInput
    $filter: String
    $orderBy: AccountOrder
    $editableAccountsOnly: Boolean
  ) {
    accounts(
      page: $page
      filter: $filter
      orderBy: $orderBy
      editableAccountsOnly: $editableAccountsOnly
    ) {
      edges {
        node {
          code
          name
          active
          type
          phase
          zipCode
          city
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
`;
