import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    button: {
        marginRight: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        maxWidth: 360,
        textAlign: 'center'
    },
    text: {
        marginBottom: theme.spacing(2)
    }
}))