import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { colors, typography } from 'Theme/constants';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6)
    }
  },
  customSubtitle1: {
    fontWeight: typography.fontWeightMedium,
    paddingBottom: theme.spacing(1)
  },
  action: {
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`
  },
  spacing: {
    marginRight: theme.spacing(1)
  },
  mergeBloc: {
    backgroundColor: 'yellow',
    overflow: 'auto',
    padding: theme.spacing(1)
  },
  step2MSmerge: {
    [theme.breakpoints.up('sm')]: {
      width: '714px'
    },
    margin: 'auto',
    maxWidth: '714px'
  },
  stepContent: {
    padding: `0 ${theme.spacing(4)} ${theme.spacing(3)}`
  },
  identitiesToMerge: {
    paddingBottom: theme.spacing(5)
  },
  subText: {
    color: colors.grey[800],
    lineHeight: '24px'
  },
  yourInformation: {
    paddingBottom: theme.spacing(3),
    color: colors.grey[800]
  },
  arrow: {
    alignSelf: 'center',
    padding: '1em'
  },
  logo: {
    height: theme.spacing(8)
  },
  mergeDirectoryHeader: {
    padding: '46px 0px 64px 0px'
  }
}));
