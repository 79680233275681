import { useMutation } from '@apollo/client';
import { mdiCheckCircle, mdiCircleOffOutline } from '@mdi/js';
import { PersonAccessRightStatus, ProcessAccessRightRequest } from 'Apollo';
import useNotification from 'Hooks/useNotification';
import AccessRightsCard from './AccessRightsCard';

type Props = { onRefetch(): void; data: IPersonAccessRight };

const AccessRightsCardContainer = ({ onRefetch, ...props }: Props) => {
  const { onSuccess, onError } = useNotification();
  const [updateAccessToService] = useMutation<
    any,
    IUpdateAccessRightRequestVars
  >(ProcessAccessRightRequest);

  const onCardUpdate = async (variables: IUpdateAccessRightRequestVars) => {
    await updateAccessToService({
      variables: { ...variables },
      fetchPolicy: 'network-only',
      onError,
      onCompleted: () => {
        if (variables.processStatus === PersonAccessRightStatus.Accepted) {
          onSuccess(
            'page.accessRights.acceptRequestSuccess',
            undefined,
            'persist',
            mdiCheckCircle
          );
        } else if (
          variables.processStatus === PersonAccessRightStatus.Refused
        ) {
          onSuccess(
            'page.accessRights.denyRequestSuccess',
            undefined,
            'persist',
            mdiCircleOffOutline
          );
        }
      }
    });
    onRefetch();
  };

  return <AccessRightsCard onCardUpdate={onCardUpdate} {...props} />;
};

export default AccessRightsCardContainer;
