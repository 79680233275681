import { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import TableServicesCandidates from './TableServicesCandidates';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { TServiceMemberList } from '../../Models';
import { GetLightProfileWithoutAccessRight, PersonOrder } from 'Apollo';
import useServiceSubscriptionRouteMatch from 'Hooks/useServiceSubscriptionRouteMatch';
import { calculateNewPage } from 'Tools/calculateNewPage';
import ErrorHandlerContext from 'Context/ErrorHandler.context';
import ErrorMsg from 'Components/Shared/ErrorMsg';

const loadingTotalCount = 1000;

interface Props {
  setSelectedIds: (ids: string[]) => void;
  search: string;
}

const TableServicesCandidatesContainer = ({
  setSelectedIds,
  search,
}: Props) => {

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);

  const handleChangeRowPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
    refetch();
  }

  const ErrorHandler = useContext(ErrorHandlerContext);

  const serviceSubscriptionIdentifier = useServiceSubscriptionRouteMatch();

  const { data, loading, error, refetch } = useQuery<{
    lightProfileWithoutAccessRight: TServiceMemberList;
  }>(GetLightProfileWithoutAccessRight, {
    onError: ErrorHandler.onError,
    variables: {
      page: {
        first: rowsPerPage,
        offset: currentPage * rowsPerPage
      },
      orderBy: PersonOrder.NameAsc,
      searchTerm: search,
      serviceSubscriptionIdentifier
    }
  });

  if (error) return <ErrorMsg />;

  const rows =
    data?.lightProfileWithoutAccessRight.edges.map(o => o.node)
      ?? [];

  return (
    <TableServicesCandidates
      totalCount={data?.lightProfileWithoutAccessRight.totalCount ?? loadingTotalCount}
      rows={rows}
      loading={loading}
      page={currentPage}
      rowsPerPage={rowsPerPage}
      onChangePage={(newPage: number) => setCurrentPage(newPage)}
      onChangeRowPerPage={handleChangeRowPerPage}
      onUpdateSelection={setSelectedIds}
    />
  );
};

export default TableServicesCandidatesContainer;
