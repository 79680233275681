import {
  ScheduleOutlined as IconScheduleOutlined,
  ErrorOutlineOutlined as IconErrorOutlineOutlined,
  CheckCircleOutline as IconCheckCircleOutline
} from '@mui/icons-material';
import clsx from 'clsx';
import { PersonAccessRightStatus } from '../../../../../../Apollo';
import { useStyles } from './AccessRightAvailability.styles';
import useTranslate from 'Hooks/useTranslate';
import IconButtonWithPopOver from 'Theme/components/IconButtonWithPopOver';

interface Props {
  accessRight: IPersonAccessRight;
}

function AccessRightAvailabilitySmallWidth(props: Readonly<Props>) {
  const classes = useStyles();
  const ts = useTranslate();
  const { accessRight } = props;

  let today = new Date();
  let comparedDate = new Date(accessRight.expirationDate!);

  const isexpired = accessRight.expirationDate !== null && comparedDate < today;

  if (accessRight.isAvailable) {
    switch (accessRight.status) {
      case PersonAccessRightStatus.ToValidate:
        return (
          <IconButtonWithPopOver
            customIcon={
              <IconScheduleOutlined
                fontSize="medium"
                titleAccess={ts('page.userProfile.services.toValidate')}
                className={clsx([
                  classes.availabilityIcon,
                  classes.availabilityIconToValidate
                ])}
              />
            }
            description={ts('page.userProfile.services.toValidate')}
          />
        );
      case PersonAccessRightStatus.Refused:
        return (
          <IconButtonWithPopOver
            customIcon={
              <IconErrorOutlineOutlined
                fontSize="medium"
                titleAccess={ts('page.userProfile.services.refused')}
                className={clsx([
                  classes.availabilityIcon,
                  classes.availabilityIconRefused
                ])}
              />
            }
            description={ts('page.userProfile.services.refused')}
          />
        );
      case PersonAccessRightStatus.Suspended:
        return (
          <IconButtonWithPopOver
            customIcon={
              <IconErrorOutlineOutlined
                fontSize="medium"
                titleAccess={ts('page.userProfile.services.suspendedLabel')}
                className={clsx([
                  classes.availabilityIcon,
                  classes.availabilityIconRefused
                ])}
              />
            }
            description={ts('page.userProfile.services.suspendedLabel')}
          />
        );
      default:
        return isexpired ? (
          <IconButtonWithPopOver
            customIcon={
              <IconErrorOutlineOutlined
                fontSize="medium"
                titleAccess={ts('page.userProfile.services.expired')}
                className={clsx([
                  classes.availabilityIcon,
                  classes.availabilityIconUnavailable
                ])}
              />
            }
            description={ts('page.userProfile.services.expired')}
          />
        ) : (
          <IconButtonWithPopOver
            customIcon={
              <IconCheckCircleOutline
                fontSize="medium"
                titleAccess={ts('page.userProfile.services.available')}
                className={clsx([
                  classes.availabilityIcon,
                  classes.availabilityIconAccepted
                ])}
              />
            }
            description={ts('page.userProfile.services.available')}
          />
        );
    }
  }

  return (
    <IconButtonWithPopOver
      customIcon={
        <IconErrorOutlineOutlined
          fontSize="medium"
          titleAccess={ts('page.userProfile.services.unavailable')}
          className={clsx([
            classes.availabilityIcon,
            classes.availabilityIconUnavailable
          ])}
        />
      }
      description={ts('page.userProfile.services.unavailable')}
    />
  );
}

export default AccessRightAvailabilitySmallWidth;
