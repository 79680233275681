import { Grid, TextField, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps as InjectedIntlProps
} from 'react-intl';
import { NewGroup } from './types';

const styles = (theme: Theme) =>
  createStyles({
    group: {
      marginBottom: theme.spacing(3),

      '&:last-child': {
        marginBottom: 0
      }
    }
  });

interface Props extends WithStyles<typeof styles>, InjectedIntlProps {
  accounts: IPaginatedList<IAccount>;
  accountCode: string;
  newGroup: NewGroup;
  onChangeNewGroup: (updatedNewGroup: Partial<NewGroup>) => void;
}

function StepInformation(props: Props) {
  const getInputProps = (prop: keyof NewGroup) => ({
    variant: 'outlined' as 'outlined',
    label: (
      <FormattedMessage id={`page.createPersonGroup.stepInformation.${prop}`} />
    ),
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChangeNewGroup({ [prop]: event.target.value });
    },
    value: props.newGroup[prop],
    name: prop,
    fullWidth: true
  });
  const selectedAccount = props.accounts.edges[0].node;

  return (
    <>
      <div className={props.classes.group}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField {...getInputProps('name')} />
          </Grid>
          <Grid item xs={12}>
            <TextField {...getInputProps('description')} />
          </Grid>
        </Grid>
      </div>

      <div className={props.classes.group}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {props.accountCode !== 'all' && selectedAccount ? (
              <TextField
                {...getInputProps('accountCode')}
                select
                SelectProps={{ native: true }}
                disabled
              >
                <option value={selectedAccount.code}>
                  {props.intl.formatMessage(
                    { id: 'common.accountLabel' },
                    {
                      name: selectedAccount.name,
                      zipCode: selectedAccount.zipCode,
                      city: selectedAccount.city,
                      code: selectedAccount.code
                    }
                  )}
                </option>
              </TextField>
            ) : (
              <TextField
                {...getInputProps('accountCode')}
                select
                SelectProps={{ native: true }}
              >
                {props.accounts.edges.map(({ node: account }) => (
                  <option key={account.code} value={account.code}>
                    {props.intl.formatMessage(
                      { id: 'common.accountLabel' },
                      {
                        name: account.name,
                        zipCode: account.zipCode,
                        city: account.city,
                        code: account.code
                      }
                    )}
                  </option>
                ))}
              </TextField>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default withStyles(styles)(injectIntl(StepInformation));
