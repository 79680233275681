type InputValue =
  | string
  | boolean
  | number
  | Array<InputValue>
  | null
  | InputJson;

export interface InputJson {
  [key: string]: InputValue;
}

function stringifyInput(value: InputValue, enums: string[] = []): string {
  if (
    value == null ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    return `${value}`;
  }

  if (typeof value === 'string') {
    return `"${value}"`;
  }

  if (Array.isArray(value)) {
    return `[${value.map(item => stringifyInput(item))}]`;
  }

  return `{${Object.keys(value)
    .reduce(
      (acc: string[], key) =>
        acc.concat(
          `${key}:${
            enums.includes(key) ? value[key] : stringifyInput(value[key])
          }`
        ),
      []
    )
    .join(',')}}`;
}

export default stringifyInput;
