import { gql } from '@apollo/client';

export interface GetPersonsTotalCountData {
  persons: {
    totalCount: number;
  };
}

export const GetPersonsTotalCount = gql`
  query GetPersonsTotalCount($personFilter: PersonFilterInputType) {
    persons(personFilter: $personFilter) {
      totalCount
    }
  }
`;