import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider
} from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import { TProfileManagerMessagesKeys } from 'Languages/TProfileManagerMessages';

type Props = {
  title: TProfileManagerMessagesKeys;
  isOpen: boolean;
  onClose(): void;
  maxWidth?: DialogProps['maxWidth'];
  minHeight?: number;
  height?: number;
  children?: JSX.Element;
  disableBackdropClick?: boolean;
};

const Modal = ({
  title,
  isOpen,
  onClose,
  maxWidth,
  minHeight,
  children,
  disableBackdropClick = false,
  height
}: Props) => {
  const ts = useTranslate();
  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      PaperProps={{
        style: { minHeight: minHeight || 500, height: height || 'auto' }
      }}
      maxWidth={maxWidth || 'lg'}
      keepMounted
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && disableBackdropClick) return;
        onClose();
      }}
    >
      <DialogTitle>{ts(title)}</DialogTitle>
      <Divider />
      <DialogContent
        style={{
          padding: 0,
          overflow: 'auto'
        }}
      >
        <Box p={4}>{children}</Box>
      </DialogContent>
      <Box m={1} />
    </Dialog>
  );
};

export default Modal;
