import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  drawerButtons: {
    display: 'flex',
    justifyContent: 'end',
    gap: theme.spacing(1),
    margin: `${theme.spacing(1)} 0`,
    position: 'sticky',
    bottom: 0,
    background: 'white',
    padding: '8px',
    borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    marginBottom: `0px`,
  },
  headerLeft: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: "center"
  },
}));
