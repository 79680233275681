import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    // This is included by default with the latest version of material-ui
    transition: theme.transitions.create('box-shadow')
  },
  keepHeight: theme.mixins.toolbar,
  keepMoreHeight: { height: '44px' },
  linkToHome: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  logo: {
    marginRight: theme.spacing(1)
  },
  pageTitle: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
    padding: `0 ${theme.spacing(2)}`,
    marginLeft: theme.spacing(2),
    maxWidth: '600px'
  },
  grow: {
    flex: 1
  },
  actionItem: {
    marginLeft: theme.spacing(1)
  },
  colorSecondary: {
    color: theme.palette.text.secondary
  },
  rightDivider: {
    borderRight: '1px solid rgba(0, 0, 0, 0.2)'
  }
}));