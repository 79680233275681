import { gql } from '@apollo/client';
import { ServiceSubscriptionIpRestrictionFragment } from '../../fragments/service/ServiceSubscriptionIpRestriction';

export const GetServiceSubscriptionIpRestrictions = gql`
  query GetServiceSubscriptionIpRestrictions(
    $page: PageInput
    $serviceSubscriptionIdentifier: serviceSubscriptionIdentifier!
  ) {
    serviceSubscriptionIPRestrictions(
      page: $page
      serviceSubscriptionIdentifier: $serviceSubscriptionIdentifier
    ) {
      edges {
        node {
          ...ServiceIPRange
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${ServiceSubscriptionIpRestrictionFragment}
`;
