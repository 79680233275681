import makeStyles from '@mui/styles/makeStyles';
import { colors } from 'Theme/constants';

export const useStyles = makeStyles(() => ({
  seeProfileLink: {
    color: colors.blue[500],
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  transformNone: {
    textTransform: 'none'
  }
}));
