import { Grid, Paper, Typography } from '@mui/material';
import { useStyles } from './GroupInformation.styles';
import accountLabel from 'Tools/LabelFormatter/accountLabel';
import RemoveGroupButton from './RemoveGroupButton';

interface Props {
  group: IPersonGroup;
}

function GroupInformation({ group }: Props) {
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.root }}>
      <div
        className={classes.header}
        style={{
          backgroundColor: group.avatar?.color ?? ''
        }}
      >
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item xs={12} md={10}>
            <Typography variant="h5" color="inherit" className={classes.headerGroupName}>
              {group.name}
            </Typography>
            <Typography variant="body2" color="inherit" paragraph>
              {group.description}
            </Typography>
            <Typography
              variant="body1"
              color="inherit"
              classes={{ root: classes.headerSubTitle }}
            >
              {group.account && accountLabel(group.account)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} className={classes.headerAction}>
            <RemoveGroupButton personGroup={group} />
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}

export default GroupInformation;
