import { gql } from '@apollo/client';
import { AuditFragment } from '../../fragments/audit/Audit';

export enum ActivityType {
  All = 'ALL',
  Creation = 'PROFILECREATED',
  Update = 'PROFILEMODIFIED',
  Connection = 'SIGNIN'
}

export const GetPersonActivities = gql`
  query GetPersonActivities(
    $personIdentifier: String!
    $activityType: ActivityType!
    $first: Int
    $offset: Int
  ) {
    personActivities(
      personIdentifier: $personIdentifier
      activityType: $activityType
      first: $first
      offset: $offset
    ) {
      edges {
        node {
          ...Audit
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${AuditFragment}
`;
