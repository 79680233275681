import { gql } from '@apollo/client';
import { AvatarFragment } from '../person/Avatar';

export enum AddServiceToGroupStatus {
  Accepted = 'ACCEPTED',
  Refused = 'REFUSED',
}

export enum AddMemberToGroupStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE'
}

export enum RemoveGroupStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE'
}

export const PersonGroupFragment = gql`
  fragment PersonGroup on PersonGroup {
    account {
      code
      name
      zipCode
      city
    }
    code
    description
    name
    avatar {
      ...Avatar
    }
    creationDate
    creator {
      firstName
      lastName
    }
  }
  ${AvatarFragment}
`;
