import { Button, Grid, Typography } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import ButtonLoading from 'Theme/components/ButtonLoading';

interface Props {
  loading: boolean;
  totalCount: number;
  disabled: boolean;
  testid?: string;
  onCancel: () => void;
  onFormSubmit: (e: any) => void;
}

const FormAddServicesToGroup = (props: Props) => {
  const {
    disabled,
    loading,
    totalCount,
    testid,
    onFormSubmit,
    onCancel
  } = props;
  const ts = useTranslate();

  return (
    <Grid container={true} justifyContent="space-between" alignItems="center">
      <Grid item style={{ paddingLeft: '1em' }}>
        {totalCount >= 1 && (
          <Typography variant="body2">
            {ts('page.groupProfile.addServices.selection', {
              count: totalCount
            })}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <form onSubmit={e => onFormSubmit(e)}>
          <Button onClick={onCancel} color='inherit'>
            {ts('common.action.cancel')}
          </Button>
          <ButtonLoading
            loading={loading}
            type="submit"
            color="primary"
            variant="contained"
            disabled={disabled}
            data-testid={testid}
          >
            {ts('common.action.add')}
          </ButtonLoading>
        </form>
      </Grid>
    </Grid>
  );
};

export default FormAddServicesToGroup;
