import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';

const styles = createStyles({
  contentLoading: {
    color: 'transparent'
  },
  circularProgress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

// Sadly, ButtonProps is not exported by @material-ui
type ButtonProps = any;

interface Props {
  loading: boolean;
  children: React.ReactNode;
}

class ButtonLoading extends React.Component<
  Props & WithStyles<typeof styles> & ButtonProps
> {
  public render() {
    const { classes, loading, children, ...props } = this.props;

    return (
      <Button {...props}>
        {loading && (
          <span className={classes.circularProgress}>
            <CircularProgress color="inherit" size={20} />
          </span>
        )}
        <span className={loading ? classes.contentLoading : null}>
          {children}
        </span>
      </Button>
    );
  }
}

export default withStyles(styles)(ButtonLoading);
