import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import { MAX_USER_DATA_RETENTION } from 'Theme/config';

type Props = {
  isOpen: boolean;
  onClose(): void;
};

const ModalDataPrivacy = (props: Props) => {
  const { isOpen, onClose } = props;
  const ts = useTranslate();

  return (
    <Dialog open={isOpen} {...props} maxWidth="md">
      <DialogTitle>{ts('dataPrivacy.title')}</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body2" color="textPrimary" paragraph>
          {ts('dataPrivacy.sentence1')}
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph>
          {ts('dataPrivacy.sentence2')}
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph>
          {ts('dataPrivacy.sentence3', {value: MAX_USER_DATA_RETENTION})}
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph>
          {ts('dataPrivacy.sentence4')}
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph>
          {ts('dataPrivacy.sentence5')}
        </Typography>
        <Typography variant="body2" color="textPrimary" paragraph>
          {ts('dataPrivacy.sentence6')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{ts('common.action.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalDataPrivacy;
