import {
  Paper,
  Theme,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import HistoryIcon from '@mui/icons-material/History';
import InfiniteScroll from 'Theme/components/InfiniteScroll';
import EmptyState from 'Components/Shared/EmptyState';
import { FormattedDate } from 'react-intl';
import { useQuery } from '@apollo/client';
import { GetApiKeyEvents } from 'Apollo/queries/apikey/GetApikeyActivities';
import { useParams } from 'react-router-dom';
import { userFullName } from 'Tools';
import { useStyles } from './ApiKeyProfileAudit.styles';
import useTranslate from 'Hooks/useTranslate';
import { ErrorMsg } from 'Components/Shared';
import { IApiKeyEvent } from 'Apollo/fragments/apikey/apiKeyTypes';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.white
    }
  })
)(TableCell);

function ApiKeyProfileAudit() {
  const { apikeyUuid } = useParams<IUriParams>();
  const classes = useStyles();
  const ts = useTranslate();

  let { data, fetchMore, error: queryError } = useQuery<GetApiKeyEventsData>(
    GetApiKeyEvents,
    {
      variables: {
        apiKeyUuid: apikeyUuid !== null && apikeyUuid
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  if (queryError) return <ErrorMsg />;

  return (
    <InfiniteScroll
      onFetchMore={() => {
        if (data == null || data.apiKeyEvents == null) {
          return;
        }

        if (data.apiKeyEvents.edges.length >= data.apiKeyEvents.totalCount) {
          return;
        }

        fetchMore({
          variables: {
            offset: data.apiKeyEvents.edges.length
          },
          updateQuery: (
            prev: GetApiKeyEventsData,
            { fetchMoreResult }
          ): GetApiKeyEventsData => {
            if (fetchMoreResult == null) {
              return prev;
            }
            return Object.assign({}, prev, {
              apiKeyEvents: Object.assign({}, prev.apiKeyEvents, {
                edges: prev.apiKeyEvents.edges.concat(
                  fetchMoreResult.apiKeyEvents.edges
                )
              })
            });
          }
        });
      }}
    >
      <Paper>
        <Typography variant="h5" classes={{ root: classes.blockTitle }}>
          {ts('page.apikeyProfile.activities.title')}
        </Typography>
        {data === undefined && (
          <EmptyState
            icon={<CircularProgress />}
            primary={' '}
            secondary={' '}
          />
        )}
        {data?.apiKeyEvents?.totalCount === 0 && (
          <EmptyState
            icon={<HistoryIcon color="inherit" />}
            primary={ts('page.apikeyProfile.activity.emptyStateTitle')}
            secondary={ts('page.apikeyProfile.activity.emptyStateText')}
          />
        )}
        {data !== undefined && data?.apiKeyEvents?.totalCount > 0 && (
          <Table>
            <TableHead>
              <TableRow key="TableHead">
                <StyledTableCell component="th" scope="row" key="type">
                  {ts('page.apikeyProfile.activities.type')}
                </StyledTableCell>
                <StyledTableCell key="date">
                  {ts('page.apikeyProfile.activities.date')}
                </StyledTableCell>
                <StyledTableCell key="author">
                  {ts('page.apikeyProfile.activities.author')}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.apiKeyEvents?.edges.map((e: { node: IApiKeyEvent }, i) => (
                <TableRow key={i as any}>
                  <TableCell>
                    {ts('page.apikeyProfile.activity.type', {
                      value: e.node.type
                    })}
                  </TableCell>
                  <TableCell>
                    <FormattedDate
                      value={e.node.date}
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                      hour="2-digit"
                      minute="2-digit"
                    />
                  </TableCell>
                  <TableCell>
                    {userFullName(
                      e.node.author?.firstName,
                      e.node.author?.lastName
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
    </InfiniteScroll>
  );
}

export default ApiKeyProfileAudit;
