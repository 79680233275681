import { TTabView } from '../Components/Shared/Tabs/Tabs';
import { PrivateRoutes } from '../Router/Routes';
import { useParams, generatePath } from 'react-router-dom';
import ApiKeyProfileGeneral from 'Page/ApikeyProfile/ApiKeyProfileGeneral';
import ApiKeyProfileAudit from 'Page/ApikeyProfile/ApiKeyProfileAudit';
import { IApiKey } from 'Apollo/fragments/apikey/apiKeyTypes';
import TestIds from 'Tests/TestIds';

interface Props {
  apiKey: IApiKey;
}

const useTabsApiKey = ({ apiKey }: Props): TTabView[] => {
  const { accountCode, apikeyUuid } = useParams<{
    accountCode: string;
    apikeyUuid: string;
  }>();

  const getPath = (path: string) =>
    generatePath(path, {
      accountCode,
      apikeyUuid
    });

  const tabs: TTabView[] = [
    {
      title: 'page.apikeyProfile.menu.general',
      path: PrivateRoutes.apikeyProfile.path,
      link: getPath(PrivateRoutes.apikeyProfile.path),
      component: <ApiKeyProfileGeneral apiKey={apiKey} />
    },
    {
      title: 'page.apikeyProfile.menu.audit',
      path: PrivateRoutes.apikeyProfile.path + '/audit',
      link: getPath(PrivateRoutes.apikeyProfile.path + '/audit'),
      component: <ApiKeyProfileAudit />,
      testid: TestIds.pages.apiKeyProfile.linkActivities
    }
  ];

  return tabs;
};

export default useTabsApiKey;
