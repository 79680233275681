import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTranslate from 'Hooks/useTranslate';
import { FormattedDate } from 'react-intl';
import Skeleton from 'Theme/components/Skeleton';
import { DataRow } from 'Theme/components/DataRow';
import PaperTitle from 'Theme/components/PaperTitle';
import { userFullName } from 'Tools';
import accountLabel from 'Tools/LabelFormatter/accountLabel';
import EditGroupToggle from './EditGroupToggle';

interface Props {
  group: IPersonGroup;
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  }
}));

function About({ group }: Props) {
  const classes = useStyles();
  const ts = useTranslate();

  return (
    <Paper classes={{ root: classes.root }}>
      <PaperTitle title={ts('page.personGroupProfile.about.title')} />

      {group == null ? (
        <>
          {Array.from({ length: 3 }).map((_, index) => (
            <DataRow
              key={index as any}
              label={<Skeleton variant="rectangular" width="100px" />}
              divider={index < 3}
            >
              <Skeleton variant="rectangular" width="200px" />
            </DataRow>
          ))}
        </>
      ) : (
        <>
          <DataRow label={ts('common.terminology.groupName')} divider={true}>
            <div className={classes.info}>
              <Typography variant="body2">{group.name}</Typography>
              <EditGroupToggle group={group} field="label" />
            </div>
          </DataRow>
          <DataRow label={ts('common.terminology.description')} divider={true}>
            <div className={classes.info}>
              <Typography variant="body2">{group.description}</Typography>
              <EditGroupToggle group={group} field="description" />
            </div>
          </DataRow>

          <DataRow label={ts('common.terminology.codeGroup')} divider={true}>
            <Typography variant="body2">{group.code}</Typography>
          </DataRow>
          <DataRow label={ts('common.terminology.account')} divider={true}>
            <Typography variant="body2">
              {group.account ? accountLabel(group.account) : '/'}
            </Typography>
          </DataRow>
          <DataRow label={ts('profile.about.creationDate')}>
            <Typography variant="body2">
              <FormattedDate value={group.creationDate} />
              {group.creator &&
                ts('page.groupProfile.creationBy', {
                  creator: userFullName(
                    group.creator.firstName,
                    group.creator.lastName
                  )
                })}
            </Typography>
          </DataRow>
        </>
      )}
    </Paper>
  );
}

export default About;
