import PageDashboard from 'Page/Dashboard';
import PageAccountSelection from 'Page/AccountSelection'
import PageAccountProfile from 'Page/AccountProfile';
import PageNotifications from 'Page/Notifications';
import PageServices from 'Page/Services';
import PageApiKeys from 'Page/ApiKeys';
import PageApikeyProfile from 'Page/ApikeyProfile';
import PageAdministrators from 'Page/Administrators';
import PageUsers from 'Page/Users';
import PagePersonGroups from 'Page/PersonGroups';
import PagePersonGroupCreate from 'Page/PersonGroupCreate';
import PageUsersProfile from 'Page/UsersProfile';
import PageOrganizations from 'Page/Organizations';
import PageOrganizationProfile from 'Page/OrganizationProfile';
import PageServiceProfile from 'Page/ServiceProfile';
import PageExternalServiceProfile from 'Page/ExternalServiceProfile';
import PagePersonGroupProfile from 'Page/PersonGroupProfile';
import AccessRights from 'Page/AccessRights';
import MergeDirectoryPreview from 'Page/MergeDirectory';
import ChangeEmailValidation from 'Page/ChangeEmailValidation';

type TPublicRouteKey =
  | 'aboutGoogle'
  | 'activationError'
  | 'accessDenied'
  | 'errorLogin'
  | 'delegateConfirmation'
  | 'errorUndefined'
  | 'endLogin'
  | 'superuseraccess';

type TPublicRoute = { path: string };

type TPublicRoutes = Record<TPublicRouteKey, TPublicRoute>;

export const PublicRoutes: TPublicRoutes = {
  aboutGoogle: {
    path: '/about/google'
  },
  activationError: {
    path: '/error_activation'
  },
  accessDenied: {
    path: '/access_denied'
  },
  errorLogin: {
    path: '/error_login'
  },
  delegateConfirmation: {
    path: '/delegate/confirmation'
  },
  errorUndefined: {
    path: '/error_undefined'
  },
  endLogin: {
    path: '/end_login'
  },
  superuseraccess: {
    path: '/superuseraccess'
  }
};

export const PrivateRoutes = {
  accountSelection: {
    path: '/:accountCode/account_selection',
    exact: true,
    isAdminOnly: true,
    children: () => <PageAccountSelection />
  },
  accountProfile: {
    path: '/:accountCode/account',
    exact: true,
    isAdminOnly: true,
    children: () => <PageAccountProfile />
  },
  apikeyProfile: {
    path: '/:accountCode/apikey/:apikeyUuid',
    exact: false,
    isAdminOnly: true,
    children: () => <PageApikeyProfile />
  },
  administrators: {
    path: '/:accountCode/administrators',
    exact: true,
    isAdminOnly: true,
    children: () => <PageAdministrators />
  },
  apiKeyServices: {
    path: '/:accountCode/apiKeyServices',
    exact: false,
    isAdminOnly: true,
    children: () => <PageApiKeys />
  },
  apiKeyServicesRevoked:{
    path: '/:accountCode/apiKeyServices/revoked',
    exact: false,
    isAdminOnly: true,
    children: () => <PageApiKeys />
  },
  dashboard: {
    path: '/:accountCode',
    exact: true,
    isAdminOnly: true,
    children: () => <PageDashboard />
  },
  mergeIdentity: {
    path: '/:accountCode/merge/:identityProvider',
    exact: true,
    isAdminOnly: false,
    children: () => <MergeDirectoryPreview />
  },
  notifications: {
    path: '/:accountCode/notifications',
    exact: true,
    isAdminOnly: true,
    children: () => <PageNotifications />
  },
  personGroupCreate: {
    path: '/:accountCode/groups/create',
    exact: true,
    isAdminOnly: true,
    children: () => <PagePersonGroupCreate />
  },
  personGroupProfile: {
    path: '/:accountCode/group/:groupCode',
    exact: false,
    isAdminOnly: true,
    children: () => <PagePersonGroupProfile />
  },
  personGroupProfileServices:{
    path: '/:accountCode/group/:groupCode/services',
    exact: false,
    isAdminOnly: true,
    children: () => <PagePersonGroupProfile />
  },
  personGroupProfileMembers:{
    path: '/:accountCode/group/:groupCode/members',
    exact: false,
    isAdminOnly: true,
    children: () => <PagePersonGroupProfile />
  },
  changeEmailValidation: {
    path: '/validation/email',
    exact: false,
    isAdminOnly: false,
    children: (props: { token: string }) => <ChangeEmailValidation {...props} />
  },
  personGroups: {
    path: '/:accountCode/groups',
    exact: true,
    isAdminOnly: true,
    children: () => <PagePersonGroups />
  },
  services: {
    path: '/:accountCode/services',
    exact: true,
    isAdminOnly: false,
    children: () => <PageServices />
  },
  externalServiceProfile: {
    path: '/:accountCode/services/external/:serviceCode/:targetAccountCode',
    exact: true,
    isAdminOnly: true,
    children: () => <PageExternalServiceProfile />
  },
  serviceProfile: {
    path: '/:accountCode/services/:serviceCode',
    exact: true,
    isAdminOnly: false,
    children: () => <PageServiceProfile />
  },
  serviceProfileRestrictions: {
    path: '/:accountCode/services/:serviceCode/restrictions',
    exact: true,
    isAdminOnly: false,
    children: () => <PageServiceProfile />
  },
  serviceProfileMembers: {
    path: '/:accountCode/services/:serviceCode/members',
    exact: true,
    isAdminOnly: false,
    children: () => <PageServiceProfile />
  },
  accessRights: {
    path: '/:accountCode/accessrights',
    exact: true,
    isAdminOnly: false,
    children: () => <AccessRights />
  },
  usersProfile: {
    path: '/:accountCode/users/:immutableId',
    exact: false,
    isAdminOnly: false,
    children: () => <PageUsersProfile />
  },
  usersProfileActivities: {
    path: '/:accountCode/users/:immutableId/activities',
    exact: true,
    isAdminOnly: false,
    children: () => <PageUsersProfile />
  },
  usersProfileServices:{
    path: '/:accountCode/users/:immutableId/services',
    exact: true,
    isAdminOnly: false,
    children: () => <PageUsersProfile />
  },
  organizations: {
    path: '/:accountCode/organizations',
    exact: true,
    isAdminOnly: false,
    children: () => <PageOrganizations />
  },
  organizationProfile: {
    path: '/:accountCode/organizations/:organizationCode',
    exact: true,
    isAdminOnly: false,
    children: () => <PageOrganizationProfile />
  },
  organizationProfileDomains: {
    path: '/:accountCode/organizations/:organizationCode/domains',
    exact: true,
    isAdminOnly: false,
    children: () => <PageOrganizationProfile />
  },
  users: {
    path: '/:accountCode/users',
    exact: true,
    isAdminOnly: true,
    children: () => <PageUsers />
  }
};
