import { gql } from '@apollo/client';
import { ServiceSubscriptionFragment } from '../../fragments/service/ServiceSubscription';

export const GetAvailableServicesForAccessRight = gql`
  query GetAvailableServicesForAccessRight(
    $servicesFilter: String
    $personIdentifier: String
    $page: PageInput
    $orderBy: ServiceSubscriptionOrder
  ) {
    availableServicesForAccessRight(
      servicesFilter: $servicesFilter
      personIdentifier: $personIdentifier
      page: $page
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...ServiceSubscription
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${ServiceSubscriptionFragment}
`;
