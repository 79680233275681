// tslint:disable: jsx-no-lambda
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {
  Error as IconError,
  FileCopy as IconFileCopy
} from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      position: 'relative'
    },
    copyButton: {
      position: 'absolute',
      top: '50%',
      right: theme.spacing(3),
      transform: 'translateY(-50%)'
    }
  });

interface Props {
  messages: string[];
  onClose: () => void;
}

interface State {
  // used to change the tooltip text from "copy" to "copied"
  isCopied: boolean;
}

class ErrorsDialog extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  public readonly state = {
    isCopied: false
  };

  private timeoutID: number | null = null;

  public componentWillUnmount() {
    if (this.timeoutID != null) {
      window.clearTimeout(this.timeoutID);
      this.timeoutID = null;
    }
  }

  public render() {
    const { classes, messages, onClose } = this.props;
    const { isCopied } = this.state;

    return (
      <Dialog open={true}>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <FormattedMessage id="error.details" />
          <Tooltip
            title={
              isCopied ? (
                <FormattedMessage id="error.textCopied" />
              ) : (
                <FormattedMessage id="error.copyText" />
              )
            }
            open={isCopied || undefined}
          >
            <IconButton classes={{ root: classes.copyButton }} onClick={this.onCopy} size="large">
              <IconFileCopy />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <List>
            {messages.map((message, index) => (
              <ListItem key={index} alignItems="flex-start">
                <ListItemIcon>
                  <IconError color="error" />
                </ListItemIcon>
                <ListItemText
                  primary={message}
                  primaryTypographyProps={{ variant: 'subtitle1' }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => onClose()}>
            <FormattedMessage id="common.action.agree" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private onCopy = () => {
    const { messages } = this.props;
    copy(messages.join('\n'));

    this.setState({ isCopied: true }, () => {
      this.timeoutID = window.setTimeout(() => {
        this.setState({ isCopied: false });
        this.timeoutID = null;
      }, 5000);
    });
  };
}

export default withStyles(styles)(ErrorsDialog);
