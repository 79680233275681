import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  cardTop: {
    padding: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardContent: {
    padding: `0px ${theme.spacing(2)}`,
    flexGrow: 1
  },
  cardBottom: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  card: {
    width: '212px',
    minHeight: '212px',
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    backgroundColor: 'rgba(139, 195, 74, 0.2)'
  }
}));
