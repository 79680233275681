import { ServiceSubscriptionOrder } from 'Apollo';
import TableCollection from 'Theme/components/TableCollection/TableCollection';
import { MAX_SELECTED, ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { Box, Grid } from '@mui/material';
import { Person as IconPerson } from '@mui/icons-material';
import useTranslate from 'Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import EmptyState from 'Components/Shared/EmptyState';
import { ErrorMsg, TableCollectionColumn } from 'Components/Shared';
import useTableHandlers from 'Hooks/useTableHandlers';
import { stringifyServiceSubscriptionNode } from 'Tools/stringifyServiceSubscriptionNode';
import ExpandedSearch from './../../Components/Shared/ExpandedSearch';

interface Props {
  loading: boolean;
  error: any;
  rows: IServiceSubscription[];
  selectedIds: string[] | null;
  onUpdate: any;
  totalCount: number;
  orderBy: ServiceSubscriptionOrder;
  currentPage: number;
  rowsPerPage: number;
  rowsPerPageOptions?: number[];
  onUpdateSearch: (search: string) => void;
  onChangeOrderBy: (orderBy: ServiceSubscriptionOrder) => void;
  onChangePage: (newPage: number) => void;
  onChangeRowsPerPage: (newRowsPerPage: number) => void;
  columns: TableCollectionColumn<IServiceSubscriptionWithCount>[];
}

function TableGroupAvailableServices(props: Readonly<Props>) {
  const {
    loading,
    selectedIds,
    rows,
    onUpdate,
    error,
    totalCount,
    orderBy,
    currentPage,
    rowsPerPage,
    rowsPerPageOptions,
    onUpdateSearch,
    onChangeOrderBy,
    onChangeRowsPerPage,
    onChangePage,
    columns
  } = props;

  const ts = useTranslate();
  const { handleSelect, handleUnselect } = useTableHandlers({
    onUpdate,
    selectedIds
  });

  return (
    <Grid container direction="column">
      <Grid item>
        <ExpandedSearch
          onChange={onUpdateSearch}
          testId={
            TestIds.pages.groupProfile.services.addServiceDrawer
              .searchServiceInput
          }
          placeholder={ts('component.searchBars.placeholder.services')}
        />
      </Grid>
      {error ? (
        <ErrorMsg />
      ) : (
        <Grid item>
          <Box height={600} style={{ overflow: 'auto' }}>
            <TableCollection
              loading={loading}
              columns={columns}
              rows={rows}
              getRowId={(r: IServiceSubscription) =>
                stringifyServiceSubscriptionNode(r)
              }
              orderBy={orderBy}
              onChangeOrderBy={onChangeOrderBy as (orderBy: string) => void}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions ?? [ROW_PER_PAGE_DEFAULT]}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={currentPage}
              onChangePage={onChangePage}
              onUnselect={handleUnselect}
              selectedIds={selectedIds}
              onSelect={handleSelect}
              maxSelected={MAX_SELECTED}
              totalCount={totalCount}
              messages={{
                maxSelectedExceeded:
                  'page.groupProfile.members.maxSelectedExceeded',
                pageAllSelected: 'page.groupProfile.members.pageAllSelected',
                selectAll: 'page.groupProfile.members.selectAll'
              }}
              emptyStateData={
                <EmptyState
                  icon={<IconPerson />}
                  primary={ts('page.groupProfile.addServices.emptyState.title')}
                  secondary={ts(
                    'page.groupProfile.addServices.emptyState.description'
                  )}
                />
              }
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default TableGroupAvailableServices;
