import { useHistory, useLocation } from 'react-router-dom';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';

interface IPageLocation {
  currentPage: IPage;
  changePage(x: IPage): void;
}

const useLocationPage = (): IPageLocation => {
  const { state, pathname } = useLocation<IPage>();
  const history = useHistory();

  const changePage = (nextPage: IPage) =>
    history.replace(pathname, { state: nextPage });

  return {
    currentPage: (!!state?.first && !!state?.offset
      ? state
      : { first: ROW_PER_PAGE_DEFAULT, offset: 0 }) as IPage,
    changePage
  };
};

export default useLocationPage;
