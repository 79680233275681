import { Typography } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import useTranslate from 'Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import { useStyles } from './ApiKey.styles';

function NoApiKey() {

  const classes = useStyles();
  const ts = useTranslate();
  
  return (
    <div
      className={classes.noApiKeySection}
      data-testid={TestIds.pages.apiKeys.noApiSection}
    >
      <VpnKeyIcon fontSize="large" className={classes.noApiKeyIcon} />
      <Typography variant="h5" classes={{ root: classes.noApiKeyH5 }}>
        {ts('page.apiKeys.noApiKey')}
      </Typography>
      <Typography variant="subtitle1">
        {ts('page.apiKeys.futureApiKeysText')}
      </Typography>
    </div>
  );
}

export default NoApiKey;
