import { gql } from '@apollo/client';

export const CreateExportUsersRequest = gql`
  mutation CreateExportUsersRequest(
    $accountCode: String
    $personIdentifiers: [String]
    $columns: [ExportedColumn!]
    $textSearch: String
  ) {
    createExportUsersRequest(
      accountCode: $accountCode
      personIdentifiers: $personIdentifiers
      columns: $columns
      textSearch: $textSearch
    )
  }
`;

export enum ColumnId {
  LastName = 'PERSONLASTNAME',
  FirstName = 'PERSONFIRSTNAME',
  Email = 'PERSONEMAIL',
  CreationDate = 'PERSONCREATIONDATE',
  Status = 'PERSONSTATUS',
  SecurityLevel = 'PERSONSECURITYLEVEL',
  AccountCode = 'ACCOUNTCODE',
  AccountLabel = 'ACCOUNTLABEL',
  ServiceLabel = 'SERVICELABEL',
  ServiceAccount = 'SERVICEACCOUNT'
}

export const COLUMNS_ORDER = [
  ColumnId.LastName,
  ColumnId.FirstName,
  ColumnId.Email,
  ColumnId.CreationDate,
  ColumnId.Status,
  ColumnId.SecurityLevel,
  ColumnId.AccountCode,
  ColumnId.AccountLabel,
  ColumnId.ServiceAccount,
  ColumnId.ServiceLabel
];
