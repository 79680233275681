import * as React from 'react';
import debounce from 'lodash.debounce';

interface Props {
  onFetchMore: () => void;
  children: React.ReactNode;
}

class InfiniteScroll extends React.Component<Props> {
  public componentDidMount() {
    window.document.addEventListener('scroll', this.onScroll);
  }

  public componentWillUnmount() {
    window.document.removeEventListener('scroll', this.onScroll);
  }

  private onScroll = debounce(() => {
    const { onFetchMore } = this.props;
    const scrollingElement =
      window.document.scrollingElement || window.document.documentElement;
    const scrollY = Math.round(scrollingElement.scrollTop + window.innerHeight);
    const bottomY = scrollingElement.scrollHeight;

    if (scrollY >= bottomY) {
      onFetchMore();
    }
  }, 150);

  public render() {
    const { children } = this.props;
    return children;
  }
}

export default InfiniteScroll;
