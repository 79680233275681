import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  MobileStepper,
  Hidden
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import onboarding_1Src from 'Theme/assets/images/onboarding_1.svg';
import logoSrc from 'Theme/assets/images/cegid-logo-blue.svg';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
  People as IconPeople,
  Lock as IconLock,
  BubbleChart as IconBubbleChart,
  KeyboardArrowLeft as IconKeyboardArrowLeft,
  KeyboardArrowRight as IconKeyboardArrowRight,
  Check as IconCheck
} from '@mui/icons-material';
import { colors } from 'Theme/constants';

import TestIds from 'Tests/TestIds';
import { Imgs } from '../../Assets';
import {
  TSessionStorageKeys,
  useSessionStorage
} from '../../Hooks/useSessionStorage';
import {
  TLocalStorageKeys,
  useLocalStorage
} from '../../Hooks/useLocalStorage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default
  },
  topBlock: {
    height: '308px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  bottomBlock: {
    background: colors.orange[500],
    color: colors.white,
    height: '130px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  },
  /* step 1 */
  logo: { marginTop: theme.spacing(2) },
  title: { textAlign: 'center' },
  step1Illustration: {
    maxWidth: '100%'
  },
  /* step 2 */
  step2Illustration: {
    position: 'relative',
    top: '40px',
    maxWidth: '505px',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
  },
  step2Subtitle1: { textAlign: 'center', marginTop: theme.spacing(1) },
  /* step 3 */
  okButton: { color: colors.white, display: 'flex', margin: '0 auto' },
  /* stepper with actions style */
  arrows: {
    color: colors.white
  },
  stepper: {
    backgroundColor: colors.orange[500]
  },
  leftAction: {
    justifyContent: 'left',
    color: colors.white,
    marginLeft: theme.spacing(1)
  },
  ignoreButton: {
    background: colors.orange[500]
  }
}));

interface Props {}

const Onboarding = (props: Props) => {
  const classes = useStyles();
  const { getSession, setSession } = useSessionStorage();
  const { getLocal, setLocal } = useLocalStorage();
  const name: TSessionStorageKeys & TLocalStorageKeys =
    'WELCOME_MODAL_DISABLED';
  const [open, setOpen] = useState(!(getSession(name) || getLocal(name)));
  const [step, setStep] = useState(0);

  const onIgnore = () => {
    setSession(name, true);
    setOpen(false);
  };

  const onAgree = () => {
    setLocal(name, true);
    setOpen(false);
  };

  const tutorialSteps = [
    {
      content: (
        <>
          {/* Top of the dialog */}
          <DialogContent className={classes.topBlock}>
            <DialogContentText className={classes.title} component="div">
              <img src={logoSrc} alt="Cegid logo" className={classes.logo} />
              <Typography variant="h5">
                <FormattedMessage id="applicationName" />
              </Typography>
            </DialogContentText>
            <img
              src={onboarding_1Src}
              alt="co-workers shaking hands"
              className={classes.step1Illustration}
            />
          </DialogContent>
          {/* Bottom of the dialog */}
          <DialogContent className={classes.bottomBlock}>
            <Grid container>
              <Hidden only="xs">
                <Grid item container justifyContent="space-around" sm={2}>
                  <Grid item>
                    <IconPeople />
                  </Grid>
                  <Grid item>
                    <IconLock />
                  </Grid>
                </Grid>
              </Hidden>
              <Grid item sm={10}>
                <Typography variant="h5">
                  <FormattedMessage id="onBoarding.step1H5" />
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Hidden only="xs">
                <Grid item sm={2} container justifyContent="space-around">
                  <IconBubbleChart />
                </Grid>
              </Hidden>
              <Grid item sm={10}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="onBoarding.step1Subtitle1" />
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )
    },
    {
      content: (
        <>
          {/* Top of the dialog */}
          <DialogContent className={classes.topBlock}>
            <img
              src={Imgs.onboarding1.src}
              alt={Imgs.onboarding1.alt}
              className={classes.step2Illustration}
            />
          </DialogContent>
          {/* Bottom of the dialog */}
          <DialogContent className={classes.bottomBlock}>
            <Grid container justifyContent="center">
              <Typography variant="h5">
                <FormattedMessage id="onBoarding.step2H5" />
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.step2Subtitle1}
              >
                <FormattedMessage id="onBoarding.step2Subtitle1" />
              </Typography>
            </Grid>
          </DialogContent>
        </>
      )
    },
    {
      content: (
        <>
          {/* Top of the dialog */}
          <DialogContent className={classes.topBlock}>
            <img
              src={Imgs.onboarding2.src}
              alt={Imgs.onboarding2.alt}
              className={classes.step2Illustration}
            />
          </DialogContent>
          {/* Bottom of the dialog */}
          <DialogContent className={classes.bottomBlock}>
            <Grid container justifyContent="center">
              <Typography variant="h5">
                <FormattedMessage id="onBoarding.step3H5" />
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.step2Subtitle1}
              >
                <FormattedMessage id="onBoarding.step3Subtitle1" />
              </Typography>
            </Grid>
          </DialogContent>
        </>
      )
    }
  ];

  if (!open) return null;

  return (
    <div>
      <Dialog
        sx={{ display: { xs: 'none', sm: 'block' } }}
        id="onboarding-dialog"
        open={open}
        onClose={onIgnore}
        aria-labelledby="dialog-onbording"
        maxWidth="sm"
        fullWidth
      >
        <Paper square elevation={0} className={classes.dialog}>
          {tutorialSteps[step].content}
          <Grid container className={classes.ignoreButton}>
            <Grid item xs={6} sm={4}>
              <Button
                type="button"
                onClick={onIgnore}
                color="inherit"
                className={classes.leftAction}
                disabled={step !== 0}
                data-testid={TestIds.onboarding.ignoreButton}
              >
                <FormattedMessage id="common.action.ignore" />
              </Button>
            </Grid>
            <Grid item xs={6} sm={4}>
              <MobileStepper
                steps={tutorialSteps.length}
                position="static"
                variant="dots"
                activeStep={step}
                backButton={
                  <Button
                    size="small"
                    onClick={() => setStep(step - 1)}
                    disabled={step === 0}
                    className={classes.arrows}
                    data-testid={TestIds.onboarding.backArrow}
                  >
                    <IconKeyboardArrowLeft />
                  </Button>
                }
                nextButton={
                  <Button
                    size="small"
                    onClick={() => setStep(step + 1)}
                    disabled={step === tutorialSteps.length - 1}
                    className={classes.arrows}
                    data-testid={TestIds.onboarding.nextArrow}
                  >
                    <IconKeyboardArrowRight />
                  </Button>
                }
                className={classes.stepper}
              />
            </Grid>
            <Grid item sm={4}>
              {step === tutorialSteps.length - 1 && (
                <Button
                  onClick={onAgree}
                  className={classes.okButton}
                  data-testid={TestIds.onboarding.agreeButton}
                >
                  <FormattedMessage id="common.action.understand" />
                  <IconCheck />
                </Button>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </div>
  );
};

export default Onboarding;
