import { gql } from '@apollo/client';
import { ServiceSubscriptionFragment } from '../../fragments/service/ServiceSubscription';

export const UpdateServiceSubscriptions = gql`
  mutation UpdateServiceSubscriptions(
    $serviceSubscriptionIdentifiers: [serviceSubscriptionIdentifier]
    $data: ServiceSubscriptionUpdateInput!
  ) {
    updateServiceSubscriptions(
      serviceSubscriptionIdentifiers: $serviceSubscriptionIdentifiers
      data: $data
    ) {
      ...ServiceSubscription
    }
  }
  ${ServiceSubscriptionFragment}
`;
