import { gql, MutationFunction } from '@apollo/client';
import { PersonGroupServiceAssignmentFragment } from 'Apollo/fragments/personGroup/PersonGroupServiceAssignment';

export const RemoveServiceSubscriptionsFromPersonGroup = gql`
  mutation removeServiceSubscriptionsFromPersonGroup(
    $groupCode: String!
    $serviceSubscriptionsIdentifiers: [serviceSubscriptionIdentifier]
  ) {
    removeServiceSubscriptionsFromPersonGroup(
      groupCode: $groupCode
      serviceSubscriptionsIdentifiers: $serviceSubscriptionsIdentifiers
    ) {
      ...PersonGroupServiceAssignment
    }
  }
  ${PersonGroupServiceAssignmentFragment}
`;

export type RemoveServicesFromPersonGroupMutation = MutationFunction<
  RemoveServicesFromPersonGroupData,
  RemoveServicesFromPersonGroupVars
>;
