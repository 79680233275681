const testIds = {
  common: {
    header: {
      navigationButton: 'common-header-navigation-button',
      profileMenu: 'common-header-profile-menu',
      profileMenuPopover: {
        profileLink: 'common-header-profile-menu-popover-profile-link',
        logoutButton: 'common-header-profile-menu-popover-logout-button'
      },
      switchAccountButton: 'common-header-switch-account-button'
    },
    RequestAdministrativeAccessBanner: {
      button: 'common-banner-button',
      accessRightRequestDialog: {
        dialog: 'common-banner-accessRightRequestDialog-dialog',
        select: 'common-banner-accessRightRequestDialog-select',
        cancelButton: 'common-banner-accessRightRequestDialog-cancel-button',
        sendEmailButton:
          'common-banner-accessRightRequestDialog-send-email-button',
        resendEmailButton:
          'common-banner-accessRightRequestDialog-resend-email-button',
        closeButton: 'common-banner-accessRightRequestDialog-close-button',
        confirmationText:
          'common-banner-accessRightRequestDialog-confirmation-text'
      }
    },
    navigation: {
      shortcuts: {
        addUser: 'common-navigation-shortcuts-add-user'
      },
      navigationItem: {
        home: 'common-navigation-navigationItem-home',
        users: 'common-navigation-navigationItem-users',
        services: 'common-navigation-navigationItem-services',
        groups: 'common-navigation-navigationItem-groups',
        notifications: 'common-navigation-navigationItem-notifications',
        apiKeyServices: 'common-navigation-navigationItem-api-key-services',
        accessRights: 'common-navigation-navigationItem-accessrights',
        organizations: 'common-navigation-navigationItem-organizations'
      },
      searchButton: 'common-navigation-search-button'
    },
    icons: {
      accepted: 'common-icon-accepted',
      pending: 'common-icon-pending',
      refused: 'common-icon-refused',
      unavailable: 'common-icon-unavailable'
    },
    profileMenu: {
      navigationItem: {
        profile: 'common-profileMenu-navigationItem-profile',
        services: 'common-profileMenu-navigationItem-services'
      }
    },
    snackbar: {
      element: 'common-snackbar-element',
      action: {
        cancel: 'common-snackbar-action-cancel',
        cancelNameChange: 'common-snackbar-action-cancelNameChange',
        close: 'common-snackbar-action-close'
      }
    },
    columnsSettings: 'columns-settings'
  },
  component: {
    apikeycard: {
      creationDate: 'component-apikeycard-creation-date-'
    },
    accountSelector: {
      select: 'component-accountSelector-select',
      allAccounts: 'component-accountSelector-allAccounts'
    },
    administratedUserSelector: {
      menuItem: 'component-administratedUserSelector-menu-item-'
    },
    accessRightAvailability: {
      status: {
        pending: 'component-accessRightAvailability-status-toValidate',
        denied: 'component-accessRightAvailability-status-refused',
        suspended: 'component-accessRightAvailability-status-suspended',
        expired: 'component-accessRightAvailability-status-expired',
        available: 'component-accessRightAvailability-status-available',
        disabled: 'component-accessRightAvailability-status-disabled'
      }
    },
    accessRightCard: {
      eventIcon: 'component-accessRightCard-define-expiration-date-event-icon',
      calendarTodayIcon:
        'component-accessRightCard-define-expiration-date-calendar-today-icon',
      defineExpirationDateButton:
        'component-accessRightCard-define-Expiration-date-button',
      datePickerButton: 'component-accessRightCard-datepicker-button'
    },
    accessRightRequests: {
      card: 'component-accessRightRequests-card',
      accountCode: 'component-accessRightRequests-account-code',
      accountName: 'component-accessRightRequests-account-name',
      serviceName: 'component-accessRightRequests-service-name-',
      userLink: 'component-accessRightRequests-user-link',
      activateButton: 'component-accessRightRequests-activate-button',
      denyButton: 'component-accessRightRequests-deny-button',
      closeDatePicker: 'component-accessRightRequests-close-datepicker-button'
    },
    searchByUser: {
      searchIcon: 'component-searchByUser-searchbar-search-icon',
      userSelectedSentence: 'component-searchByUser-user-selected-sentence',
      input: 'component-searchByUser-searchbar-input',
      closeIcon: 'component-searchByUser-searchbar-close-icon',
      selectedUser: {
        closeIcon: 'component-searchByUser-searchbar-selectedUser-close-icon'
      },
      accessRequestUserSearchBar:
        'component-searchByUser-searchbar-result-option-requests-count-by-user',
      loadingState: 'component-searchByUser-loading-state-circular-progress',
      noPendingRequest: 'component-searchByUser-no-data-empty-state',
      resultList: 'component-searchByUser-list-of-result',
      totalRequestOnUser: 'component-searchByUser-request-for-user'
    },
    setExpirationDate: {
      removeExpiryDateButton:
        'component-setExpirationDate-remove-expiry-date-button',
      confirmButton:
        'component-setExpirationDate-confirm-new-expiry-date-button'
    },
    actionsDrawer: {
      addButton: 'component-actionsDrawer-addButton'
    },
    inviteUserDialog: {
      emailInput: 'component-inviteUserDialog-emailInput'
    },
    userAccountUpdate: {
      unlinkAccountAwareness:
        'component-userAccountUpdate-unlinkAccountAwareness'
    },
    searchBar: {
      searchIcon: 'component-searchBar-search-icon',
      closeIcon: 'component-searchBar-close-icon',
      resultsList: 'component-searchBar-results-list',
      input: 'component-searchBar-input'
    }
  },
  pages: {
    accountProfile: {
      linkGeneral: 'pages-account-profile-link-general',
      accountName: 'pages-account-profile-account-name'
    },
    accessRightsRequests: {
      title: 'pages-accessRightsRequests-title',
      subtitle: 'pages-accessRightsRequests-subtitle',
      dateInfoTitle: 'pages-accessRightsRequests-request-date-information',
      noPendingRequest: 'pages-accessRightsRequests-no-pending-request-result',
      noMatchingPendingRequest:
        'pages-accessRightsRequests-no-matching-pending-request-result',
      userSelectedSearchBar:
        'pages-accessRightsRequests-searchBarRender-when-user-selected'
    },
    apiKeys: {
      apiSection: `pages-apiKeys-api-section`,
      noApiSection: `pages-apiKeys-empty-default-section`,
      card: {
        moreActionButton: `pages-apiKeys-card-action-more-button`,
        apiCardActionsMenu: `pages-apiKeys-card-actions-menu`,
        removeIconButton: `pages-apiKeys-card-actions-menu-revocation-button`,
        historyIconButton: `pages-apiKeys-card-actions-menu-history-button`
      },
      revocation: {
        confirmationText: `pages-apiKeys-revocation-popper-confirm-revocation-text`,
        cancelButton: `pages-apiKeys-revocation-popper-cancel-revocation-button`,
        confirmButton: `pages-apiKeys-revocation-popper-confirm-revocation-button`,
        successMessage: `pages-apiKeys-revocation-success-message`
      },
      history: {
        closeButton: `pages-apiKeys-history-popper-close-button`
      },
      linkActive: `pages-apiKeys-active-api-tab-link`,
      linkRevoked: `pages-apiKeys-revoked-api-tab-link`,
      createNewApiButton: `pages-apiKeys-create-new-api-button`,
      creation: {
        serviceSelect: `pages-apiKeys-create-new-api-dialog-service-select`,
        apiKeyNameField: `pages-apiKeys-create-new-api-dialog-apikey-name-field`,
        cancelButton: `pages-apiKeys-create-new-api-dialog-cancel-button`,
        generateButton: `pages-apiKeys-create-new-api-dialog-generate-button`
      }
    },
    administrators: {
      administratorsTable: 'pages-administrators-table',
      nameAdministrators: 'pages-administrators-name'
    },
    apiKeyProfile: {
      infoTitle: 'pages-apikey-profile-general-tab-info-title',
      linkActivities: 'pages-apikey-profile-link-activities'
    },
    apiKeyProfileGeneral: {
      revokeButton: 'pages-apikey-profile-tab-general-revoke-button'
    },
    error: {
      backToHomePage: 'pages-error-back-to-home-button'
    },
    accessDenied: {
      h1HiddenXs: 'pages-accessDenied-h1-Hidden-onlyXs',
      h3HiddenSmUp: 'pages-accessDenied-h3-Hidden-smUp',
      img: 'pages-accessDenied-h3-Hidden-img'
    },
    dashboard: {
      administrators: {
        card: 'pages-dashboard-administrators-card',
        link: 'pages-dashboard-administrators-link'
      },
      lastActivities: {
        creation: 'pages-dashboard-lastActivities-creation',
        connection: 'pages-dashboard-lastActivities-connection',
        modification: 'pages-dashboard-lastActivities-modification',
        link: 'pages-dashboard-lastActivities-link'
      },
      groups: {
        totalCount: 'pages-dashboard-groups-totalcount'
      },
      card: {
        users: 'pages-dashboard-card-users',
        userListButton: 'pages-dashboard-card-users-see-list-button',
        groups: 'pages-dashboard-card-groups',
        groupListButton: 'pages-dashboard-card-groups-see-list-button',
        services: 'pages-dashboard-card-services',
        serviceListButton: 'pages-dashboard-card-services-see-list-button'
      }
    },
    delegation: {
      success: 'pages-delegation-success'
    },
    notifications: {
      accountSelect: 'pages-notifications-account-select'
    },
    organizationProfile: {
      linkGeneral: 'pages-organization-profile-link-general',
      linkDomains: 'pages-organization-profile-link-domains'
    },
    addGroup: {
      cancel: 'pages-add-group-cancel-button',
      addButton: 'pages-add-group-add-button'
    },
    addUser: {
      previousButton: 'pages-add-user-previous-button',
      nextButton: 'pages-add-user-next-button'
    },
    groups: {
      addGroupButton: `pages-groups-add-group-button`
    },
    groupProfile: {
      linkGeneral: 'pages-group-profile-link-general',
      linkServices: 'pages-group-profile-link-services',
      linkUsers: 'pages-group-profile-link-users',
      removeGroup: {
        removeGroupButton: 'pages-group-profile-remove-group-button',
        confirmDeletionButton:
          'pages-group-profile-remove-group-confirm-deletion-button'
      },
      table: 'pages-group-profile-users-table',
      candidatesSearch:
        'pages-group-profile-add-members-candidates-search-by-user',
      servicesSearch:
        'pages-group-profile-add-members-candidates-search-by-service',
      services: {
        addServiceDrawer: {
          searchServiceInput:
            'pages-group-profile-services-add-service-drawer-search-input'
        },
        addServiceButton: 'personGroups-profile-services-add-service-button',
        serviceDescriptionIcon:
          'personGroups-profile-services-service-description-icon-',
        addServices: {
          addButton: 'personGroups-profile-services-drawer-add-services-button',
          closeAddOnServicesDrawerExpandMore:
            'personGroup-profile-services-add-services-drawer-close-button'
        }
      },
      members: {
        addMembersForm: 'personGroups-profile-members-add-members-form',
        addMembersButton: 'personGroups-profile-members-add-members-button',
        addButton: 'personGroups-profile-members-add-button',
        cancelButton: 'personGroups-profile-members-cancel-button'
      },
      closeCandidateDrawerExpandMore:
        'close-candidate-drawer-expand-more-button'
    },
    profile: {
      previousPageButton: 'pages-profile-previous-page-button',
      services: {
        addServiceButton: 'pages-profile-services-service-add-service-button',
        addServicesTableCollection:
          'pages-profile-services-service-add-services-tableCollection',
        serviceDescription: 'pages-profile-services-service-description',
        serviceExpirationDateIcon:
          'pages-user-profile-services-service-expiration-date-icon-',
        serviceDescriptionIcon:
          'pages-profile-services-service-description-icon-',
        unsubscribeButton: 'pages-profile-services-unsubscribe-button',
        confirmDeletionButton: 'pages-profile-services-confirm-deletion-button',
        success: {
          oneToOne:
            'pages-profile-services-subscription-success-onToOne-snackbar',
          oneToMany:
            'pages-profile-services-subscription-success-oneToMany-snackbar',
          meToOne:
            'pages-profile-services-subscription-success-meToOne-snackbar',
          meToMany:
            'pages-profile-services-subscription-success-meToMany-snackbar'
        },
        serviceValidityDate:
          'pages-profile-services-validity-date-for-subscribed-service-'
      },
      pendingServices: {
        table: 'pages-profile-pending-services-table'
      },
      acceptedServices: {
        table: 'pages-profile-accepted-services-table'
      },
      deniedServices: {
        table: 'pages-profile-denied-services-table'
      },
      suspendedServices: {
        table: 'pages-profile-suspended-services-table'
      },
      personalInformation: {
        header: 'pages-profile-personal-information-header',
        accessRightsCount:
          'pages-profile-personal-information-access-rights-count',
        pendingAccessRightsCount:
          'pages-profile-personal-information-pending-access-rights-count',
        servicesLink: 'pages-profile-personal-information-services-link',
        updateAccountButton:
          'pages-profile-personal-information-update-account-button'
      },
      directories: {
        directoriesList: 'pages-profile-merge-identity-directories-list',
        directoriesListItem: 'pages-profile-merge-directories-list-item-'
      },
      mergeAccount: {
        button: 'pages-profile-merge-account-button-',
        directoryDialog: 'pages-profile-merge-directory-dialog-',
        MSlogo: 'pages-profile-merge-directory-logo-microsoft',
        GoogleLogo: 'pages-profile-merge-directory-logo-google',
        CegidLogo: 'pages-profile-merge-directory-logo-cegid',
        AppleLogo: 'pages-profile-merge-directory-logo-apple',
        understandButton: 'pages-profile-merge-directory-understand-button'
      },
      editNameForm: 'pages-profile-edit-name-form',
      editNameButton: 'pages-profile-edit-name-button',
      saveNameButton: 'pages-profile-save-name-button',
      cancelNameButton: 'pages-profile-cancel-name-button',
      editPhoneButton: 'pages-profile-edit-phone-button',
      savePhoneButton: 'pages-profile-save-phone-button',
      editLanguageButton: 'pages-profile-edit-language-button',
      editEmailButton: 'pages-profile-edit-email-button',
      saveLanguageButton: 'pages-profile-save-language-button',
      linkGeneral: 'pages-profile-link-general',
      linkServices: 'pages-profile-link-services',
      linkActivities: 'pages-profile-link-activities',
      linkOrganizations: 'pages-profile-link-organizations',
      pendingEmailChange: 'pages-profile-pending-email-change',
      removeRequestIcon: 'pages-profile-remove-request-icon',
      removeRequest: {
        closeConfirmationDialog:
          'pages-profile-remove-request-close-confirmation-dialog',
        confirmationButton: 'pages-profile-remove-request-confirmation-button'
      },
      resendEmailIcon: 'pages-profile-resend-email-icon'
    },
    userProfile: {
      account: {
        fullnameValue: 'pages-user-profile-section-account-fullname-value',
        emailValue: 'pages-user-profile-section-account-email-value',
        phoneValue: 'pages-user-profile-section-account-phone-value',
        mobilePhoneValue:
          'pages-user-profile-section-account-mobilephone-value',
        languageValue: 'pages-user-profile-section-account-language-value',
        selectLanguage: 'pages-user-profile-section-account-select-language',
        creationValue: 'pages-user-profile-section-account-creation-date-value'
      },
      administrators: {
        adminItem: 'pages-user-profile-section-administrators-item',
        identity: 'pages-user-profile-section-administrators-identity'
      },
      directories: {
        dirItem: 'pages-user-profile-section-directories-item',
        directoryExtraInfoBloc:
          'pages-user-profile-section-directories-directoryExtraInfoBloc',
        identifier: 'pages-user-profile-section-directories-identifier',
        identityIdentifier:
          'pages-user-profile-section-directories-identity-identifier',
        tenantid: 'pages-user-profile-section-directories-tenantid',
        objectId: 'pages-user-profile-section-directories-objectId',
        upn: 'pages-user-profile-section-directories-upn'
      },
      personalInfo: {
        creationDate: 'pages-user-profile-personal-information-creation-date'
      },
      technicalInformation: {
        immutableId: 'pages-profile-technical-information-immutableId',
        immutableGuid: 'pages-profile-technical-information-immutableGuid'
      },
      tabServices: {
        addServices: {
          drawer: {
            head: 'pages-userProfile-tab-services-addService-drawer-head',
            title: 'pages-userProfile-tab-services-addService-drawer-title',
            search: 'pages-userProfile-tab-services-addService-drawer-search',
            addButton:
              'pages-userProfile-tab-services-addService-drawer-addbutton'
          }
        },
        serviceRow: {
          inheritedFromGroupIcon:
            'pages-userProfile-tab-services-service-row-inheritedFromGroup-icon-',
          description: 'pages-userProfile-tab-services-service-row-description-',
          setExpirationAction: 'pages-userProfile-tab-services-service-row-setExpiration-',
          setRemovalAction: 'pages-userProfile-tab-services-service-row-setRemoval-',
        }
      }
    },
    users: {
      inviteUserButton: 'pages-users-invite-users-button',
      inviteUserIcon: 'pages-users-invite-users-icon',
      totalCount: 'pages-users-totalCount',
      activatedUsersCount: 'pages-users-activated-users-count',
      pendingUsersCount: 'pages-users-pending-users-count',
      administratorsCount: 'pages-users-administrators-count',
      notAdministratorsCount: 'pages-users-not-administrators-count',
      subscriptionButton: 'pages-users-action-subscription',
      searchUserIconButton: 'pages-users-search-user-button',
      searchUserInput: 'pages-users-search-user-input',
      actionOnSelectedUsers: {
        removeUsersButton:
          'pages-users-selected-users-actionOnSelected-remove-button'
      }
    },
    services: {
      cardTotalCount: 'pages-services-card-totalCount',
      addExternalUserButton: 'pages-services-add-external-user-button',
      inviteUserButton: 'pages-services-invite-user-button',
      addSubscription: {
        cancelButton: 'pages-services-add-subscription-cancel-button',
        previousButton: 'pages-services-add-subscription-previous-button',
        checkEmailButton: 'pages-services-add-subscription-check-email-button',
        subscribeButton: 'pages-services-add-subscription-subscribe-button',
        errorUserDoesntExist:
          'pages-services-add-subscription-error-user-doesnt-exist',
        emailConfirmation: 'pages-services-add-subscription-email-confirmation',
        firstNameConfirmation:
          'pages-services-add-subscription-firstname-confirmation',
        lastNameConfirmation:
          'pages-services-add-subscription-lastname-confirmation'
      },
      captionOnAdm2Service: 'pages-services-caption-under-old-ADM2-service',
      cardServicesVisibility: {
        visible: 'pages-services-card-visibleServices',
        notVisible: 'pages-services-card-notVisibleServices'
      },
      totalCount: 'pages-services-totalCount',
      updateProperties: {
        featureButton: 'pages-services-update-properties-feature-button',
        cancelButton: 'pages-services-update-properties-cancel-button',
        submitButton: 'pages-services-update-properties-submit-button',
        defaultCheckbox: 'pages-services-update-properties-default-checkbox',
        adminValidCheckbox:
          'pages-services-update-properties-adminValid-checkbox'
      },
      searchServiceButton: 'pages-services-search-button',
      searchServiceInput: 'pages-services-search-input',
      numberServiceMembersLink: 'pages-services-members-link'
    },
    serviceProfile: {
      searchMemberInput: 'pages-service-profile-search-member-input',
      addMemberDrawer: {
        searchavailableUserInput:
          'pages-service-profile-add-member-drawer-search-available-user-input'
      },
      linkGeneral: 'pages-service-profile-link-general',
      linkSecurity: 'pages-service-profile-link-security',
      infoTitle: 'pages-service-profile-information-title',
      infoSubtitle: 'pages-service-profile-information-subtitle',
      defaultService: 'pages-service-profile-information-default-service',
      adminValidation:
        'pages-service-profile-information-administrated-validation',
      aboutTitle: 'pages-service-profile-about-title',
      serviceDescription: 'pages-service-profile-about-description',
      serviceFamily: 'pages-service-profile-about-family',
      serviceGroup: 'pages-service-profile-about-groupCode',
      serviceExternalisable: 'pages-service-profile-about-isExternalisable',
      serviceSecurityLevel: 'pages-service-profile-about-security-level',
      membersTable: 'pages-service-profile-members-table',
      membersTableRemoveAction: 'pages-service-profile-members-remove',
      membersTableSetExpirationAction:
        'pages-service-profile-members-set-expiration',
      drawerEditExpirationDateButton:
        'pages-service-profile-drawer-edit-expiration-date-button',
      drawerRemoveAccessButton:
        'pages-service-profile-drawer-remove-user-access-right-button',
      giveAccessButton: 'pages-service-profile-give-access-right-button',
      drawerAddAccessButton: 'pages-service-profile-add-access-right-button',
      addGroupButton: 'pages-service-profile-add-group-button',
      selectGroupField: 'pages-service-profile-select-group',
      submitSelectedGroupButton: 'pages-service-profile-submit-group-button',
      manageOwners: {
        addOwnerButton: 'pages-services-profile-add-owner-button'
      }
    },
    accountSelection: {
      profileLink: 'pages-account-selection-profile-link',
      accountSearch: 'pages-account-selection-account-search'
    }
  },
  onboarding: {
    ignoreButton: 'onboarding-ignore-button',
    agreeButton: 'onboarding-agree-button',
    backArrow: 'onboarding-back-arrow',
    nextArrow: 'onboarding-next-arrow'
  },
  table: {
    selectAllElement: {
      selectAllButton: 'table-selectAll-elements-confirmation-button',
      selectedElementCount: 'table-selectAll-elements-total-count'
    }
  },
  img: {
    letterBox: 'illustration-of-no-pending-requests'
  },
  survey: {
    link: 'survey-link'
  }
};

export default testIds;
