import { Box, Button, DialogActions, DialogContent, FormControl, IconButton, TextField, Typography } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { mdiContentCopy } from '@mdi/js';
import SvgIcon from 'Theme/components/SvgIcon/SvgIcon';
import useTranslate from 'Hooks/useTranslate';
import { useStyles } from './GenerateApiKey.styles';
import { copyToClipboard } from 'Tools/copyToClipboard';


interface INewApiKey {
  newApiKeyUuid: string;
  newApiKeySecret: string;
  onClose: () => void;
}

function SuccessStep(props: Readonly<INewApiKey>) {

  const ts = useTranslate();
  const classes = useStyles()
  const { newApiKeyUuid, newApiKeySecret, onClose } = props;

  return <>
    <DialogContent>
      <Box className={classes.alertBox}>
        <ReportProblemOutlinedIcon className={classes.icons} />
        <Typography variant="body2">
          {ts('page.apiKeys.success.warning')}
        </Typography>
      </Box>
      <div className={classes.copyZoneField}>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            label={ts("page.apiKeys.success.yourApiKey")}
            value={newApiKeyUuid}
            variant="outlined"
            className={classes.apiKeyFieldName}
            id="name"
          />
        </FormControl>
        <IconButton
          className={classes.copyPasteButton}
          onClick={() => copyToClipboard(newApiKeyUuid)}
          size="large">
          <SvgIcon path={mdiContentCopy} fill="#666666" width="24" />
        </IconButton>
      </div>
      <div className={classes.copyZoneField}>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            label={ts('page.apiKeys.success.apiKeySecret')}
            variant="outlined"
            className={classes.apiKeyFieldName}
            value={newApiKeySecret}
            id="secret"
          />
        </FormControl>
        <IconButton
          className={classes.copyPasteButton}
          onClick={() => copyToClipboard(newApiKeySecret)}
          size="large">
          <SvgIcon path={mdiContentCopy} fill="#666666" width="24" />
        </IconButton>
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        {ts('common.action.finish')}
      </Button>
    </DialogActions>
  </>;
}


export default SuccessStep;
