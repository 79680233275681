import { gql } from '@apollo/client';
import { PersonWithAccountFragment } from '../../fragments/person/PersonWithAccount';

export enum PersonOrder {
  NameAsc = 'LASTNAMEASCTHENFIRSTNAMEASC',
  NameDesc = 'LASTNAMEDESCTHENFIRSTNAMEDESC',
  StatusAsc = 'STATUSASCTHENLASTNAMEASCTHENFIRSTNAMEASC',
  StatusDesc = 'STATUSDESCTHENLASTNAMEASCTHENFIRSTNAMEASC'
}

export const GetPersonsWithAccount = gql`
  query GetPersonsWithAccount(
    $personFilter: PersonFilterInputType
    $offset: Int
    $first: Int
    $orderBy: PersonOrder
  ) {
    persons(
      personFilter: $personFilter
      offset: $offset
      first: $first
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...PersonWithAccount
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${PersonWithAccountFragment}
`;
