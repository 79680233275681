import React from 'react';
import { useFormikContext } from 'formik';
import { Button, ButtonProps } from '@mui/material';
import useTranslate from '../../../../Hooks/useTranslate';

interface Props extends ButtonProps {
  loading?: boolean;
}

function SubmitButton({ title, loading, ...rest }: Props) {
  const { dirty, isValid } = useFormikContext();
  const ts = useTranslate();
  const content = loading ? ts('common.action.loading') : title;

  return (
    <Button
      disabled={(dirty ? !isValid : true) || loading}
      aria-label="submit"
      variant="contained"
      color="primary"
      type="submit"
      children={content}
      {...rest}
    />
  );
}

export default SubmitButton;
