type Props = {
  onUpdate: any;
  selectedIds: string[] | null;
};

const useTableHandlers = (props: Props) => {
  const { onUpdate, selectedIds } = props;

  const handleUnselect = (ids: string[] | null) => {
    if (ids == null) {
      onUpdate([]);
      return;
    }

    onUpdate(selectedIds!.filter(id => !ids.includes(id)) ?? []);
  };

  const handleSelect = (ids: string[] | null) => {
    if (ids == null) {
      onUpdate(null);
      return;
    }

    onUpdate(
      ids.reduce(
        (acc, id) => (acc!.includes(id) ? acc : acc!.concat([id])),
        selectedIds
      ) ?? []
    );
  };

  return { handleUnselect, handleSelect };
};

export default useTableHandlers;
