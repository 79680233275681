import { useQuery } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import Skeleton from '@mui/lab/Skeleton/Skeleton';
import { GetFellowAdministrators } from 'Apollo';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import { PrivateRoutes } from 'Router/Routes';
import DataRow from 'Theme/components/DataRow/DataRow';
import { Link as RouterLink, generatePath } from 'react-router-dom';

interface Props {
  account: IAccount;
}

function SectionAdministrators(props: Readonly<Props>) {
  const { account } = props;
  const { onError } = useNotification();
  const ts = useTranslate();

  const { data, loading } = useQuery<GetFellowAdministratorsData>(
    GetFellowAdministrators,
    {
      variables: {
        accountCode: account.code,
        first: 5,
        offset: 0
      },
      onError
    }
  );

  return (
    <DataRow
      label={ts(
        'page.accountProfile.sections.administrators.numberOfAdministrators'
      )}
      action={
        <Button
          component={RouterLink}
          to={generatePath(PrivateRoutes.administrators.path, {
            accountCode: account.code
          })}
          variant="text"
          color="primary"
        >
          {ts(
            'page.accountProfile.sections.administrators.seeAllAdministrators'
          )}
        </Button>
      }
    >
      {loading && (
        <Skeleton width={120} height={30} variant="rectangular" color="primary" />
      )}
      {!loading && (
        <Typography>{data?.fellowAdministrators.totalCount}</Typography>
      )}
    </DataRow>
  );
}

export default SectionAdministrators;
