import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        justifyContent: 'space-between',
        "@media (max-width: 600px)": {
            flexDirection: 'column',
        },
        
    },
    title: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'self-start'
    }
}));