import { gql } from '@apollo/client';

export const RemovePersonGroup = gql`
  mutation RemovePersonGroup(
    $code: String!
  ) {
      removePersonGroup(
        code: $code
      ) {
        groupCode
        status
        error {
          code
          message
        }
      }
    }
`;

