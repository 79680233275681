import { gql, MutationFunction } from '@apollo/client';
import { PersonGroupMembershipOperationFragment } from '../../fragments/personGroup/PersonGroupMembershipOperationFragment';

export const RemovePersonsFromPersonGroup = gql`
  mutation RemovePersonsFromPersonGroup(
    $groupCode: String!
    $personIdentifiers: [String!]
  ) {
    removePersonsFromPersonGroup(
      groupCode: $groupCode
      personIdentifiers: $personIdentifiers
    ) {
      ...PersonGroupMembershipOperation
    }
  }
  ${PersonGroupMembershipOperationFragment}
`;

export type RemovePersonsFromPersonGroupMutation = MutationFunction<
  RemovePersonsFromPersonGroupData,
  RemovePersonsFromPersonGroupVars
>;
