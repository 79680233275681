import { gql } from '@apollo/client';
import { LightPersonProfileFragment } from '../../fragments/person/LightPersonProfile';

export const GetLightProfileWithoutAccessRight = gql`
  query GetLightProfileWithoutAccessRight(
    $serviceSubscriptionIdentifier: serviceSubscriptionIdentifier!,
    $page: PageInput,
    $orderBy: PersonOrder,
    $searchTerm: String
  ) {
    lightProfileWithoutAccessRight(
      identifier: $serviceSubscriptionIdentifier
      page: $page
      orderBy: $orderBy
      searchTerm: $searchTerm
    ) {
      edges {
        node {
          ...LightPersonProfile
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${LightPersonProfileFragment}
`;
