import { gql } from '@apollo/client';
import { AccountWithServicesSubscriptionsCountFragment } from '../..';

export const GetAccountsWithServicesSubscriptionsCount = gql`
  query GetAccountsWithServicesSubscriptionsCount {
    accountsWithServicesSubscriptionsCount {
      edges {
        node {
          ...AccountsWithServicesSubscriptionsCount
        }
      }
      totalCount
    }
  }
  ${AccountWithServicesSubscriptionsCountFragment}
`;
