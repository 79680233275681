import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { CdsColorTextLinkNeutralResting } from "@cegid/design-tokens";
import backgroundSrc from './assets/background.svg';
import { colors } from 'Theme/constants';

export const useStyles = makeStyles((theme: Theme) => ({
    '@global': {
        html: {
          backgroundImage: `url(${backgroundSrc})`,
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          backgroundColor: theme.palette.common.white
        }
      },
      root: {
        marginTop: 100
      },
      heading: {
        marginBottom: theme.spacing(2)
      },
      counter: {
        color: colors.success
      },
      enterButton: {
        margin: `${theme.spacing(2)} 0px ${theme.spacing(2)}`
      },
      linkTo: {
        color: CdsColorTextLinkNeutralResting,
        textDecoration: 'underline',
        alignSelf: 'flex-end'
      },
      listbox: {
        height: theme.spacing(25)
      },
      selectionButtons: {
        display: 'flex', 
        flexDirection: 'column', 
        paddingTop: '3em'
      }
}))