import * as React from 'react';
import {
  IconButton,
  Tooltip,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
  Button,
  Typography
} from '@mui/material';
import { HighlightOff as IconHighlightOff } from '@mui/icons-material';
import { ApolloError, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import {
  DeleteEmailChangeRequest,
  DeleteEmailChangeRequestMutation
} from '../../../../../../Apollo';
import { ErrorHandler } from 'Theme/components/ErrorHandler';
import TestIds from 'Tests/TestIds';
import { enqueueSnackbar } from 'notistack';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps as InjectedIntlProps
} from 'react-intl';

interface Props {
  userId: string;
  onCompleted: () => void;
}

interface State {
  openDialog: boolean;
}

class DeleteEmailRequest extends React.Component<
  Props & InjectedIntlProps,
  State
> {
  public readonly state: State = {
    openDialog: false
  };

  public render() {
    const { userId } = this.props;
    const { openDialog } = this.state;

    return (
      <ErrorHandler.Consumer>
        {errorHandler => (
          <Mutation<Object, DeleteEmailChangeRequestVariables>
            mutation={DeleteEmailChangeRequest}
            variables={{
              personIdentifier: userId
            }}
            onCompleted={this.onCompleted}
            onError={(e: ApolloError) => errorHandler.onError(e)}
          >
            {(
              deleteEmailChangeRequest: DeleteEmailChangeRequestMutation,
              { loading }: MutationResult
            ) => (
              <>
                <Tooltip
                  title={
                    <FormattedMessage id="page.userProfile.personalInformations.cancelRequest" />
                  }
                >
                  <IconButton
                    size="medium"
                    onClick={this.confirmationDialogOpen}
                  >
                    <IconHighlightOff
                      color="primary"
                      data-testid={TestIds.pages.profile.removeRequestIcon}
                    />
                  </IconButton>
                </Tooltip>
                <Dialog
                  open={openDialog}
                  onClose={() => this.setState({ openDialog: false })}
                >
                  <DialogTitle>
                    <FormattedMessage id="page.userProfile.personalInformations.cancelRequestConfirmationTitle" />
                  </DialogTitle>
                  <DialogContent>
                    <Typography variant="body1">
                      <FormattedMessage id="page.userProfile.personalInformations.cancelRequestConfirmationText" />
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <form
                      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();

                        if (loading) {
                          return;
                        }
                        deleteEmailChangeRequest();
                      }}
                    >
                      <Button
                        onClick={() => this.setState({ openDialog: false })}
                        color="primary"
                        data-testid={
                          TestIds.pages.profile.removeRequest
                            .closeConfirmationDialog
                        }
                      >
                        <FormattedMessage id="common.action.no" />
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        data-testid={
                          TestIds.pages.profile.removeRequest.confirmationButton
                        }
                      >
                        <FormattedMessage id="common.action.yes" />
                      </Button>
                    </form>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Mutation>
        )}
      </ErrorHandler.Consumer>
    );
  }

  private confirmationDialogOpen = () => {
    this.setState({ openDialog: true });
  };

  private onCompleted = () => {
    const { onCompleted } = this.props;

    enqueueSnackbar(
      <FormattedMessage id="page.userProfile.personalInformations.cancelRequestSuccess" />,
      {
        variant: 'success'
      }
    );

    onCompleted();
  };
}

export default injectIntl(DeleteEmailRequest);
