import useTranslate from 'Hooks/useTranslate';
import { useStyles } from './PageError.styles';
import { Button, Typography } from '@mui/material';
import { Imgs } from 'Assets/Img';

interface Props {
  title?: string;
  textPrimary?: string;
  textSecondary?: string;
  notFound?: boolean;
}

function ErrorContent(props: Readonly<Props>) {

  const ts = useTranslate();
  const classes = useStyles();
  const { title, textPrimary, textSecondary, notFound } = props;

  const handleClick = () => {
    window.location.href = '/';
  }

  return (
    <div className={notFound ? classes.errorContent : ''}>
      {notFound &&<img src={Imgs.manSearching.src} alt={Imgs.manSearching.alt} />}
      <Typography variant="h1" gutterBottom className={classes.errorType}>
        {title ?? ts('error.oops')}
      </Typography>
      <Typography variant="h5" className={classes.errorSentence}>
        {textPrimary ?? ts('error.type.E500')}
      </Typography>
      {textSecondary && <Typography variant="h5">{textSecondary}</Typography>}
      <Button
        variant='outlined'  
        color='primary'
        className={classes.redirectionButton}
        onClick={handleClick}
      >
        {ts('common.action.backToHomePage')}
      </Button>
    </div>
  );
}

export default ErrorContent;
