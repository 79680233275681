import { BubbleChart as IconBubbleChart } from '@mui/icons-material';
import EmptyState from 'Components/Shared/EmptyState';
import { useState } from 'react';
import { useStyles } from './PersonGroupServices.styles';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { calculateNewPage } from 'Tools/calculateNewPage';
import useTranslate from 'Hooks/useTranslate';
import { ApolloError } from '@apollo/client';
import { ErrorMsg } from 'Components/Shared';
import PersonGroupServicesTable from '../PersonGroupServicesTable/PersonGroupServicesTable';

interface Props {
  group: IPersonGroup;
  loading: boolean;
  totalCount: number;
  serviceSubscriptions: IServiceSubscription[];
  error: ApolloError | undefined;
  onRefetch: any;
}

const PersonGroupServices = (props: Props) => {
  const {
    loading,
    serviceSubscriptions,
    totalCount,
    error,
    onRefetch,
    group
  } = props;
  const classes = useStyles();
  const ts = useTranslate();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage);
    onRefetch();
  };

  const onChangeRowsPerPage = (rpp: number) => {
    setRowsPerPage(rpp);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, rpp));
    onRefetch();
  };

  return (
    <>
      {!loading && totalCount > 0 && (
        <PersonGroupServicesTable
          group={group}
          loading={loading}
          serviceSubscriptions={serviceSubscriptions}
          totalCount={totalCount}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          onChangePage={p => onChangePage(p)}
          onChangeRowsPerPage={rpp => onChangeRowsPerPage(rpp)}
          onRefetch={onRefetch}
        />
      )}

      {!loading && totalCount === 0 && (
        <EmptyState
          icon={<IconBubbleChart />}
          primary={ts('page.groupProfile.services.emptyState.title')}
          secondary={ts('page.groupProfile.services.emptyState.description')}
          className={classes.emptyServices}
        />
      )}
      {error && <ErrorMsg />}
    </>
  );
};

export default PersonGroupServices;
