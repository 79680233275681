import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useQuery } from '@apollo/client';
import ErrorMsg from 'Components/Shared/ErrorMsg';
import { useParams } from 'react-router-dom';
import EmptyState from 'Components/Shared/EmptyState';
import { GetServiceOwners } from 'Apollo/queries/service/GetServiceOwners';
import TableServiceOwners from './TableServiceOwners';
import useNotification from 'Hooks/useNotification';

const TableServiceOwnersContainer = () => {
  const rowsPerPage = 5;
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { accountCode, serviceCode, targetAccountCode } = useParams<{
    accountCode: string;
    serviceCode: string;
    targetAccountCode: string;
  }>();

  const variables = {
    serviceSubscriptionIdentifier: {
      accountCode: accountCode,
      targetAccountCode: targetAccountCode,
      serviceCode: serviceCode
    },
    page: {
      first: rowsPerPage,
      offset: currentPage * rowsPerPage
    }
  };

  const { onError } = useNotification();

  const { data, loading, error } = useQuery<
    GetServiceOwnersData,
    GetServiceVars
  >(GetServiceOwners, {
    onError,
    variables,
    fetchPolicy: 'cache-first'
  });

  if (error) return <ErrorMsg />;

  if (loading)
    return (
      <EmptyState icon={<CircularProgress />} primary={' '} secondary={' '} />
    );

  const rows =
    data && data.serviceOwners?.edges
      ? data.serviceOwners.edges.map(o => o.node)
      : [];

  return (
    <TableServiceOwners
      totalCount={data?.serviceOwners.totalCount || 0}
      rows={rows}
      loading={loading}
      page={currentPage}
      setPage={(p: number) => setCurrentPage(p)}
    />
  );
};

export default TableServiceOwnersContainer;
