import { gql } from '@apollo/client';
import { PersonAccessRightFragment } from '../../fragments/person/PersonAccessRight';

export const ProcessAccessRightRequest = gql`
  mutation ProcessAccessRightRequest(
    $accountCode: String!
    $expiryDate: DateTime
    $processStatus: PersonAccessRightProcessStatus
    $profileImmutableId: String!
    $serviceCode: String!
  ) {
    processAccessRightRequest(
      accountCode: $accountCode
      expiryDate: $expiryDate
      processStatus: $processStatus
      profileImmutableId: $profileImmutableId
      serviceCode: $serviceCode
    ) {
      ...PersonAccessRight
    }
  }
  ${PersonAccessRightFragment}
`;
