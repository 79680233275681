import InfiniteScroll from 'react-infinite-scroll-component';
import AccessRightsSkeleton from './AccessRightsCards.skeleton';
import AccessRightsCard from '../AccessRightsCard';
import { Box, Paper, Typography } from '@mui/material';
import { TProfileManagerMessagesKeys } from '../../Languages/TProfileManagerMessages';
import useTranslate from '../../Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import { useStyles } from './AccessRightsCards.styles';
import {
  isToday,
  organizedElementsByDate,
  wasYesterday
} from '../../Tools/dateManagement';

interface Props {
  data: IPersonAccessRight[];
  loading: boolean;
  hasNextPage?: boolean;
  onNextPage(): void;
  onRefetch(): void;
  onFilter?(a: string): void;
}

const AccessRightsCards = ({
  data = [],
  loading,
  hasNextPage = false,
  onNextPage,
  onRefetch
}: Props) => {
  const ts = useTranslate();
  const itemsByDate = organizedElementsByDate<IPersonAccessRight>(
    data,
    'creationDate'
  );

  const classes = useStyles();

  if (loading) return <AccessRightsSkeleton />;

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={onNextPage}
      hasMore={hasNextPage}
      loader={<AccessRightsSkeleton />}
    >
      {Object.keys(itemsByDate).map((item, o) => {
        const accessRightRequests =
          itemsByDate[item as TProfileManagerMessagesKeys];
        if (!!accessRightRequests && accessRightRequests.length > 0) {
          return (
            <Box key={'section.' + item} data-testid={'section.' + item}>
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.date}
              >
                {ts(item as TProfileManagerMessagesKeys)}
              </Typography>
              {accessRightRequests.map((obj: IPersonAccessRight, i: number) => {
                const requestCreationDate = new Date(obj.creationDate!);
                return (
                  <div
                    key={i}
                    data-testid={
                      `service-request-card-` +
                      obj.service.groupCode +
                      '-' +
                      obj.person.email
                    }
                  >
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      className={classes.date}
                    >
                      {!isToday(requestCreationDate) &&
                        !wasYesterday(requestCreationDate) &&
                        requestCreationDate.toLocaleDateString()}
                    </Typography>
                    <Box my={2}>
                      <Paper>
                        <Box p={2}>
                          <AccessRightsCard
                            key={i}
                            data={obj}
                            onRefetch={onRefetch}
                            data-testid={
                              `${TestIds.component.accessRightRequests.card}-` +
                              i
                            }
                          />
                        </Box>
                      </Paper>
                    </Box>
                  </div>
                );
              })}
            </Box>
          );
        }
        return null;
      })}
    </InfiniteScroll>
  );
};

export default AccessRightsCards;
