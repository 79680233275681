import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useMediaQuery
} from '@mui/material';
import ButtonLoading from 'Theme/components/ButtonLoading';
import { DEBOUNCE_DELAY } from 'Theme/config';
import { useContext, useMemo, useState } from 'react';
import Alert from 'Components/Shared/Alert';
import shortAccountlabel from 'Tools/LabelFormatter/shortAccountLabel';
import { LocalContext } from 'Context/Local.context';
import useTranslate from 'Hooks/useTranslate';
import Modal from 'Components/Shared/Modal';
import { userFullName } from 'Tools';
import { Link, generatePath } from 'react-router-dom';
import { useStyles } from '../UserAccountUpdate.styles';
import { PrivateRoutes } from 'Router/Routes';
import SearchByAccount from 'Components/Shared/SearchBars/SearchByAccount/SearchByAccount';
import { debounce } from 'Tools/debounce';
import { useCurrentUserInfo } from 'Hooks/useCurrentUserInfo';
import mediaQueries from 'Theme/constants/mediaQueries';

interface Props {
  person: IPersonWithAccount;
  accessRights: IPersonAccessRight[];
  isOpen: boolean;
  loading: boolean;
  onClose: () => void;
  updateAccount: (accountCode: string) => void;
  accounts: IAccount[];
  onSearch: (value: string) => void;
}

function LinkToNewAccount(props: Readonly<Props>) {
  const {
    person,
    accessRights,
    loading,
    accounts,
    onClose,
    onSearch,
    updateAccount
  } = props;
  const { availableAccounts } = useContext(LocalContext);
  const { hasTooManyAccounts, hasLimitedAccounts } = useCurrentUserInfo();

  const classes = useStyles();
  const ts = useTranslate();

  const isMobileP = useMediaQuery(mediaQueries.mobilePortrait);

  const [selectedAccountCode, setSelectedAccountCode] = useState<string>('');
  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);
  const [filterValue, setFilterValue] = useState<null | string>(null);

  const accountVeryShortLabel = person.account?.name ?? person.account?.code;
  const userNotExternalisableAccessRights = accessRights.filter(
    acc =>
      acc.account?.code === person.account?.code &&
      !acc.service.isExternalisable
  );
  const userExternalisableAccessRights = accessRights.filter(
    acc =>
      acc.account?.code === person.account?.code && acc.service.isExternalisable
  );

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedAccountCode(event.target.value);
  };

  const debouncedSendRequest = useMemo(() => {
    return debounce(onSearch, DEBOUNCE_DELAY);
  }, [onSearch]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    setFilterValue(currentValue.trim());
    debouncedSendRequest(currentValue);
  };

  const handleChooseAccount = (account: IAccount) => {
    setSelectedAccountCode(account.code);
    setSelectedAccount(account);
  };

  const handleClose = () => {
    setFilterValue(null);
    onSearch('');
    setSelectedAccountCode('');
    setSelectedAccount(null);
    onClose();
  };

  const handleClear = () => {
    setFilterValue(null);
    setSelectedAccountCode('');
    setSelectedAccount(null);
  };

  const sendUpdate = () => {
    setFilterValue(null);
    onSearch('');
    setSelectedAccount(null);
    updateAccount(selectedAccountCode);
    setSelectedAccountCode('');
  };

  return (
    <Modal
      {...props}
      title="component.userAccountUpdate.linkDialogTitle"
      maxWidth="md"
    >
      <>
        <Grid container spacing={2}>
          {person.account && (
            <Grid item md={12} data-testid="previousAccountInfo">
              <Typography variant="body1">
                {ts(
                  'component.userAccountUpdate.alertInfo.previousMembersList',
                  {
                    value: accountVeryShortLabel ?? ''
                  }
                )}
              </Typography>
              <Typography variant="body1">
                {ts('component.userAccountUpdate.alertWarning.unlinkGroups')}
              </Typography>
              {userNotExternalisableAccessRights.length > 0 &&
                userNotExternalisableAccessRights.length <= 5 && (
                  <>
                    <Typography variant="body1">
                      {ts(
                        'component.userAccountUpdate.alertInfo.previousAccountAccessRights'
                      )}
                    </Typography>
                    {userNotExternalisableAccessRights.map(
                      (acc: IPersonAccessRight) => {
                        return (
                          <Typography
                            variant="caption"
                            color="inherit"
                            key={
                              acc.service.groupCode + '-' + acc.account?.name
                            }
                            component="li"
                          >
                            {acc.service.name}
                            {acc.personGroupSource &&
                              ts(
                                'component.userAccountUpdate.inheritedServicesFromGroup',
                                { value: acc.personGroupSource.name }
                              )}
                          </Typography>
                        );
                      }
                    )}
                  </>
                )}
              {userNotExternalisableAccessRights.length > 5 && (
                <Typography variant="body1">
                  {ts('component.userAccountUpdate.lostAccessCount', {
                    value: userNotExternalisableAccessRights.length
                  })}
                  <Link
                    to={generatePath(
                      PrivateRoutes.usersProfile.path + '/services',
                      {
                        accountCode: person.account?.code,
                        immutableId: person.immutableId
                      }
                    )}
                    className={classes.quickLinks}
                  >
                    {ts(
                      'component.userAccountUpdate.switchAccount.servicesListLink'
                    )}
                  </Link>
                </Typography>
              )}
            </Grid>
          )}
          {userExternalisableAccessRights.length > 0 && (
            <Alert
              severity="warning"
              title={ts(
                'component.userAccountUpdate.alertWarning.alertWarningTitle'
              )}
              content={
                <>
                  {userExternalisableAccessRights.length > 0 && (
                    <>
                      {ts(
                        'component.userAccountUpdate.alertWarning.retainedAccessRights'
                      )}
                    </>
                  )}
                  {userExternalisableAccessRights.length > 0 &&
                    userExternalisableAccessRights.map(
                      (acc: IPersonAccessRight) => (
                        <Typography
                          variant="caption"
                          color="inherit"
                          key={acc.service.groupCode + '-' + acc.account?.name}
                          component="li"
                        >
                          {acc.service.name}
                        </Typography>
                      )
                    )}
                  {userExternalisableAccessRights.length > 0 && (
                    <>
                      <br />
                      {ts(
                        'component.userAccountUpdate.alertWarning.removeAllAccessWarning',
                        {
                          value: userFullName(person.firstName, person.lastName)
                        }
                      )}
                      <Link
                        to={generatePath(
                          PrivateRoutes.usersProfile.path + '/services',
                          {
                            accountCode: person.account?.code,
                            immutableId: person.immutableId
                          }
                        )}
                        className={classes.quickLinks}
                      >
                        {ts('profile.menu.access')}
                      </Link>
                    </>
                  )}
                </>
              }
            />
          )}
          <Grid item md={12}>
            <Typography variant="subtitle2" paragraph>
              {ts('component.userAccountUpdate.whichNewAccount')}
            </Typography>
            {hasTooManyAccounts && (
              <SearchByAccount
                name="account"
                accounts={accounts}
                selectedAccount={selectedAccount}
                filterValue={filterValue ?? ''}
                handleSearch={handleSearch}
                handleClear={handleClear}
                handleChooseAccount={(acc: IAccount) =>
                  handleChooseAccount(acc)
                }
                inputLabel={ts(
                  'component.userAccountUpdate.switchAccount.newAffiliationAccount'
                )}
              />
            )}
            {hasLimitedAccounts && (
              <Box py={1}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="new-account">{ts('common.terminology.account')}</InputLabel>
                  <Select
                    id="new-account-select"
                    value={selectedAccountCode}
                    defaultValue={''}
                    label={ts('component.userAccountUpdate.chooseAccount')}
                    onChange={e => handleChange(e)}
                    variant="outlined"
                    inputProps={{ id: 'new-account' }}
                  >
                    {availableAccounts
                    .filter(acc => acc.code !== person.account?.code)
                    .map((acc: IAccount) => {
                      const tiersName = shortAccountlabel(acc);
                      return (
                        <MenuItem
                          key={acc.code}
                          value={acc.code}
                          role="<option>"
                        >
                          {isMobileP ? acc.code : `${tiersName}`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            )}
          </Grid>
          {selectedAccountCode !== '' && (
            <Grid item md={12}>
              <Alert
                severity="info"
                title={ts(
                  'component.userAccountUpdate.switchAccount.alertInfoTitle'
                )}
                content={
                  <>
                    {ts(
                      'component.userAccountUpdate.alertInfo.newAccountMembersList',
                      { value: selectedAccountCode }
                    )}
                    <br />
                    {ts(
                      'component.userAccountUpdate.switchAccount.newInheritedServices'
                    )}
                    <br />
                    {ts(
                      'component.userAccountUpdate.accessToProfileFromNewAccount'
                    )}
                  </>
                }
              />
              <Alert
                severity="warning"
                title={ts('component.userAccountUpdate.alertWarning.awareness')}
                content={ts(
                  'component.userAccountUpdate.switchAccount.dialogTitle'
                )}
              />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={6}></Grid>
          <Grid
            item
            md={6}
            style={{
              display: 'flex',
              alignSelf: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              size="medium"
              // className={classes.button}
              color="inherit"
              onClick={handleClose}
              style={{ marginRight: 8 }}
            >
              {ts('common.action.cancel')}
            </Button>
            <ButtonLoading
              loading={loading}
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              disabled={selectedAccountCode === ''}
              onClick={sendUpdate}
            >
              {ts('common.action.confirm')}
            </ButtonLoading>
          </Grid>
        </Grid>
      </>
    </Modal>
  );
}

export default LinkToNewAccount;
