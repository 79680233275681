import React from 'react';
import { TextField, IconButton } from '@mui/material';
import { Search as IconSearch } from '@mui/icons-material';
import { useStyles } from './SearchBars.styles';

interface Props {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  testId?: string;
  placeholder?: string;
  inputLabel?: string;
  name?: string;
  disabled?: boolean;
}

const SearchBar = (props: Props) => {
  const {
    name,
    inputLabel,
    value,
    disabled,
    onChange,
    testId,
    placeholder
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.searchbar}>
      <TextField
        variant="outlined"
        data-testid={testId}
        type="text"
        name={name}
        label={inputLabel}
        placeholder={placeholder}
        value={value}
        autoFocus={true}
        fullWidth={true}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <IconButton data-testid={testId} size="large">
              <IconSearch />
            </IconButton>
          ),
          autoComplete: 'off'
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default SearchBar;
