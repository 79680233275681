import { useState } from 'react';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { frFR } from '@mui/x-date-pickers/locales';
import {
  Button,
  TextField,
  TextFieldProps,
  TextFieldVariants,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import useTranslate from '../../../Hooks/useTranslate';
import CurrentPerson from '../../../Context/CurrentPerson';
import { CdsColorTextNeutralMediumEmphasis } from '@cegid/design-tokens';
import colors from 'Theme/constants/colors';

type Props = {
  variant?: TextFieldVariants ;
  onAccept(e: Date): void;
  selectedExpiryDate?: Date | null;
};

const DatePickerButton = ({
  onAccept,
  variant,
  selectedExpiryDate
}: Props) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(
    selectedExpiryDate ? dayjs(selectedExpiryDate) : dayjs(new Date())
  );
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down('md'));

  const ts = useTranslate();

  const renderTextField = (params: TextFieldProps) => {
    return (
      <TextField
        {...params}
        component={Button}
        InputProps={{
          startAdornment: (
            <Typography
              variant="inherit"
              color={CdsColorTextNeutralMediumEmphasis}
              style={{
                lineHeight: '20px',
                fontWeight: 500,
                fontSize: 14,
                cursor: 'pointer'
              }}
            >
              {ts('page.serviceProfile.serviceMembers.changeExpirationDate')}
            </Typography>
          )
        }}
        sx={{
          padding: '10px 16px',
          border: '1px solid rgba(0, 44, 82, 0.2)',
          borderRadius: '6px',
          fontSize: '14px',
          '&:hover': {
            border: `1px solid ${colors.blue[500]}`
          }
        }}
      />
    );
  };

  return (
    <CurrentPerson.Consumer>
      {({ currentPerson }: CurrentPersonContext) => {
        let browserLg = navigator.language;
        let userLanguage =
          currentPerson.language?.code.split('-')[0] || browserLg;
        let formatLg = userLanguage === 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
        let format = isBelowSm ? formatLg : `${formatLg} hh:mm a`;

        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={userLanguage}
            localeText={
              frFR.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <MobileDateTimePicker
              format={format}
              ampm={true}
              disablePast
              value={selectedDate}
              onChange={(date: any) => {
                setSelectedDate(date);
              }}
              onAccept={() => onAccept(selectedDate.toDate())}
              slotProps={{
                textField: {
                  variant: variant ?? 'standard',
                  size: 'small',
                  inputProps: {
                    style: { display: 'none' }
                  }
                }
              }}
              slots={{
                textField: renderTextField
              }}
            />
          </LocalizationProvider>
        );
      }}
    </CurrentPerson.Consumer>
  );
};

export default DatePickerButton;
