import * as React from 'react';
import CheckBox from '@mui/material/Checkbox';
import { Tooltip } from '@mui/material';
import useTranslate from '../../../../Hooks/useTranslate';

interface Props {
  value: boolean | null;
  testid: string;
  onClick(): void;
}

const getTooltipResource = (value: boolean | null) => {
  if (value) {
    return 'common.action.activate';
  }
  if (value === false) {
    return 'common.action.deactivate';
  }
  return 'common.action.doNotModify';
};

const CheckboxWithIndetermined = ({ value, onClick, testid }: Props) => {
  const ts = useTranslate();

  const tooltip = ts(getTooltipResource(value));

  return (
    <Tooltip title={tooltip}>
      <CheckBox
        color="primary"
        checked={value ?? false}
        indeterminate={value === null}
        onClick={onClick}
        data-testid={testid}
      />
    </Tooltip>
  );
};

export default CheckboxWithIndetermined;
