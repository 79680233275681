import { Box, Button, Grid, Typography } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import ButtonLoading from 'Theme/components/ButtonLoading';

interface Props {
  onCancel(): void;
  onSubmit(): void;
  loading: boolean;
  title: string;
}

const ConfirmRemove = ({ onCancel, onSubmit, loading, title }: Props) => {
  const ts = useTranslate();

  return (
    <Box m={5}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="subtitle1"
            align="center"
            sx={{ marginBottom: '16px' }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            type="button"
            size="medium"
            onClick={onCancel}
            color="inherit"
          >
            {ts('common.action.cancel')}
          </Button>
          <ButtonLoading
            type="submit"
            size="medium"
            color="primary"
            variant="contained"
            loading={loading}
            onClick={onSubmit}
          >
            {ts('common.action.validate')}
          </ButtonLoading>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfirmRemove;
