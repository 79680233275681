import { gql } from '@apollo/client';
import { ServiceSubscriptionFragment } from '../../fragments/service/ServiceSubscription';

export const GetPersonGroupServiceSubscriptions = gql`
  query GetPersonGroupServiceSubscriptions(
    $code: String!
    $first: Int
    $offset: Int
  ) {
    personGroupServiceSubscriptions(
      code: $code
      first: $first
      offset: $offset
    ) {
      edges {
        node {
          ...ServiceSubscription
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${ServiceSubscriptionFragment}
`;
