import { gql, MutationFunction } from '@apollo/client';

export const DeletePersonAccessRight = gql`
  mutation DeletePersonAccessRight(
    $data: [PersonAccessRightDeletion]!
  ) {
    deletePersonAccessRight(
      data: $data
    )
  }
`;

export type DeletePersonAccessRightMutation = MutationFunction<
  IDeletePersonAccessRightData,
  IDeletePersonAccessRightVariables
>;
