import CardCounter from 'Theme/components/CardCounter';
import { useQuery } from '@apollo/client';
import { generatePath, useParams } from 'react-router';
import { SvgIcon } from 'Theme/components/SvgIcon/SvgIcon';
import { mdiAccountStar } from '@mdi/js';
import { CdsColorBackgroundPrimaryMain } from '@cegid/design-tokens';
import { Button } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import { Link as RouterLink } from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';
import IconArrowForward from '@mui/icons-material/ArrowForward';
import TestIds from 'Tests/TestIds';
import { GetFellowAdministratorsCount } from 'Apollo/queries/person/GetFellowAdministratorsCount';

function AdministratorsCard() {
  const { accountCode } = useParams<IUriParams>();
  const ts = useTranslate();

  const variables = {
    accountCode: accountCode === 'all' ? null : accountCode,
    first: 3,
    offset: 0,
  };

  const { data, loading, error } = useQuery<GetFellowAdministratorsCountData>(
    GetFellowAdministratorsCount,
    {
      variables: variables,
      fetchPolicy: 'cache-first'
    }
  );

  return (
    <CardCounter
      title={ts('page.administrators.title')}
      testId="administrators-counter"
      loading={loading}
      error={error?.message ?? undefined}
      counter={ data ? <div data-testid={TestIds.pages.users.administratorsCount}>{data?.fellowAdministrators.totalCount}</div> : undefined}
      icon={
        <SvgIcon
          width={40}
          path={mdiAccountStar}
          fill={CdsColorBackgroundPrimaryMain}
        />
      }
      action={
        <Button
          component={RouterLink}
          to={generatePath(PrivateRoutes.administrators.path, {
            accountCode: accountCode
          })}
          variant="text"
          color="primary"
          endIcon={<IconArrowForward />}
        >
          {ts('common.action.see.seeTheList')}
        </Button>
      }
    />
  );
}

export default AdministratorsCard;
