import { Box, Container, Grid, Hidden, Typography, useTheme, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Link } from "react-router-dom";
import { Imgs } from "../../Assets";
import useTranslate from "../../Hooks/useTranslate";

function AboutGoogle() {

    const ts = useTranslate();
    const theme = useTheme();

    const classes = useStyles();

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                className={classes.aboutGoogleHeader}
            >
                <Grid item>
                    <Box m={3}>
                        <img
                            src={Imgs.cegid.src}
                            alt={Imgs.cegid.alt}
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <Box m={3}>
                        <Link to={"/"}>
                            <Typography variant="button" color="primary">{ts("common.action.login")}</Typography>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            <Container className={classes.aboutGoogleContainer} maxWidth="md">
                <Grid
                    container
                    direction="column"
                    alignContent="center"
                    spacing={2}
                >
                    <Grid item>
                        <Typography variant="h4" component={"h1"} className={classes.styledColor}>{ts("about.google.title")}</Typography>
                        <Typography variant="subtitle1" component={"h2"} style={{ paddingTop: theme.spacing(1) }}>
                            {ts("about.google.subtitle")}
                        </Typography>
                    </Grid>
                    <Grid item container wrap="wrap">
                        <Grid item md={8}>
                            <Typography variant="body2" style={{ paddingTop: theme.spacing(3) }} component="h3">
                                {ts("about.google.connectToCegidProducts")}
                            </Typography>
                            <Typography variant="body2" style={{ paddingTop: theme.spacing(3) }} component="h3">
                                {ts("about.google.followSteps")}
                            </Typography>
                            <Box marginTop={3} marginBottom={2}>
                                <Typography variant="h5" className={classes.styledColor}>{ts("about.google.whatYouWant")}</Typography>
                                <Typography variant="subtitle1" style={{ paddingTop: theme.spacing(2) }}><a href="#user_activation">A - {ts("about.google.activateYourCegidAccountWithGoogle")}</a></Typography>
                                <Typography variant="subtitle1" style={{ paddingTop: theme.spacing(1) }}><a href="#merge_account">B - {ts("about.google.connectToCegidApplicationWithYourGoogleAccount")}</a></Typography>
                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Hidden only={["xs", "sm"]}>
                                <Box py={1} alignContent="center" className={classes.flexCenter}>
                                    <img
                                        src={Imgs.flyingAirballoonCloud.src}
                                        alt={Imgs.flyingAirballoonCloud.alt}
                                        className={classes.topIllustration}
                                    />
                                </Box>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" component="h6" id="user_activation" color="secondary">A - {ts("about.google.activateYourCegidAccountWithGoogle")}</Typography>
                        <Typography variant="subtitle1">{ts("about.google.userActivation.youReceiveAnEmailToActivateYourAccount")}</Typography>
                    </Grid>
                    <Grid item className={classes.card}>
                        <Typography variant="body1" paragraph>{ts("about.google.userActivation.step1Title")}</Typography>
                        <Typography variant="body2" paragraph>{ts("about.google.userActivation.step1Content")}</Typography>
                        <Box py={2} alignContent="center" className={classes.flexCenter}>
                            <img
                                src={Imgs.userActivationStep1.src}
                                alt={Imgs.userActivationStep1.alt}
                                className={classes.imgMax}
                            />
                        </Box>
                    </Grid>
                    <Grid item className={classes.card}>
                        <Typography variant="body1" paragraph>{ts("about.google.userActivation.step2Title")}</Typography>
                        <Typography variant="body2" paragraph>{ts("about.google.userActivation.step2Content")}</Typography>
                        <Box py={2} alignContent="center" className={classes.flexCenter}>
                            <img
                                src={Imgs.userActivationStep2.src}
                                alt={Imgs.userActivationStep2.alt}
                                className={classes.imgMax}
                            />
                        </Box>
                    </Grid>
                    <Grid item className={classes.card}>
                        <Typography variant="body1" paragraph>{ts("about.google.userActivation.step3Title")}</Typography>
                        <Typography variant="body2" paragraph>{ts("about.google.userActivation.step3Content")}</Typography>
                        <Box py={2} alignContent="center" className={classes.flexCenter}>
                            <img
                                src={Imgs.userActivationStep3.src}
                                alt={Imgs.userActivationStep3.alt}
                                className={classes.imgMax}
                            />
                        </Box>
                    </Grid>
                    <Grid item className={classes.card}>
                        <Typography variant="body1" paragraph>{ts("about.google.userActivation.step4Title")}</Typography>
                        <Box py={2} alignContent="center" className={classes.flexCenter}>
                            <img
                                src={Imgs.userActivationStep4.src}
                                alt={Imgs.userActivationStep4.alt}
                                className={classes.imgMax}
                            />
                        </Box>
                        <Box mt={5} className={classes.infoBloc}>
                            <Typography variant="body1" color="primary" paragraph>{ts("about.google.userActivation.step4Content")}</Typography>
                            <Typography variant="body2" color="primary">{ts("about.google.userActivation.step4Success")}</Typography>
                        </Box>
                    </Grid>
                    <Box height={theme.spacing(5)} />
                    <Grid item>
                        <Typography variant="h6" component="h6" id="merge_account" color="secondary">B - {ts("about.google.connectToCegidApplicationWithYourGoogleAccount")}</Typography>
                        <Typography variant="subtitle1">{ts("about.google.youAlrearyHaveACegidAccount")}</Typography>
                    </Grid>
                    <Grid item className={classes.card}>
                        <Typography variant="body1" paragraph>{ts("about.google.userUpdateIdp.step1")}</Typography>
                        <Box py={2} alignContent="center" className={classes.flexCenter}>
                            <img
                                src={Imgs.step1Connect.src}
                                alt={Imgs.step1Connect.alt}
                                className={classes.imgMax}
                            />
                        </Box>
                    </Grid>
                    <Grid item className={classes.card}>
                        <Typography variant="body1" paragraph>{ts("about.google.userUpdateIdp.step2")}</Typography>
                        <Box py={2} alignContent="center" className={classes.flexCenter}>
                            <img
                                src={Imgs.step2GoToDirectories.src}
                                alt={Imgs.step2GoToDirectories.alt}
                                className={classes.imgMax}
                            />
                        </Box>
                    </Grid>
                    <Grid item className={classes.card}>
                        <Typography variant="body1" paragraph>{ts("about.google.userUpdateIdp.step3")}</Typography>
                        <Box py={2} alignContent="center" className={classes.flexCenter}>
                            <img
                                src={Imgs.step3Agree.src}
                                alt={Imgs.step3Agree.alt}
                                className={classes.imgMax}
                            />
                        </Box>
                    </Grid>
                    <Grid item className={classes.card}>
                        <Typography variant="body1" >{ts("about.google.userUpdateIdp.step4")}</Typography>
                        <Box py={2} alignContent="center" className={classes.flexCenter}>
                            <img
                                src={Imgs.step4SigninGoogle.src}
                                alt={Imgs.step4SigninGoogle.alt}
                                className={classes.imgMax}
                            />
                        </Box>
                    </Grid>
                    <Grid item className={classes.card}>
                        <Typography variant="body1" paragraph>{ts("about.google.userUpdateIdp.step5")}</Typography>
                        <Box py={2} alignContent="center" className={classes.flexCenter}>
                            <img
                                src={Imgs.step5Confirm.src}
                                alt={Imgs.step5Confirm.alt}
                                className={classes.imgMax}
                            />
                        </Box>
                        <Box mt={5} className={classes.warningBloc}>
                            <Typography variant="subtitle2" color="secondary">{ts("about.google.userUpdateIdp.step5End")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item className={classes.card}>
                        <Typography variant="body1" paragraph>{ts("about.google.userUpdateIdp.step6")}</Typography>
                        <Box py={2} alignContent="center" className={classes.flexCenter}>
                            <img
                                src={Imgs.step6Logout.src}
                                alt={Imgs.step6Logout.alt}
                                className={classes.imgMax}
                            />
                        </Box>                       
                    </Grid>
                    <Grid item className={classes.card}>                       
                        <Box py={2} alignContent="center" className={classes.flexCenter}>
                            <img
                                src={Imgs.step7NewIdentity.src}
                                alt={Imgs.step7NewIdentity.alt}
                                className={classes.imgMax}
                            />
                        </Box>
                        <Box mt={5}  className={classes.infoBloc}>
                            <Typography variant="subtitle2" color="primary">{ts("about.google.userUpdateIdp.step7")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item className={classes.aboutGoogleFooter}>
                        <Typography variant="body2">
                            {ts("about.google.youNeedHelpWriteTo")} <a href="mailto: accounts-support@cegid.com" className={classes.styledLink}>accounts-support@cegid.com</a>.
                        </Typography>
                        <Typography variant="caption">
                            {ts("about.google.forInformation")} <a href={"https://www.cegid.com/en/terms-and-conditions/"} className={classes.styledLink}> {ts("about.google.ourCGV")}</a> {ts("about.google.andOur")} <a href={"https://www.cegid.com/en/privacy-policy/"} className={classes.styledLink}> {ts("about.google.privacyPolitic")}</a>.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    aboutGoogleHeader: {
        position: 'relative',
        borderBottom: '1px solid #00000014',
        background: 'white',
    },
    aboutGoogleFooter: {
        textAlign: "center",
        margin: '1rem auto',
        "& > p": {
            maxWidth: '556px',
            margin: '0 auto',
            paddingBottom: "1em"
        }
    },
    aboutGoogleContainer: {
        paddingTop: theme.spacing(5),
    },
    card: {
        background: 'white',
        borderRadius: '6px',
        padding: '2rem 3rem !important',
        marginBottom: '24px',
        boxShadow: "0px 1px 1px 0px rgb(0 44 82 / 8%), 0px 2px 1px -1px rgb(0 44 82 / 12%), 0px 1px 3px 0px rgb(0 44 82 / 12%)"
    },
    flexCenter: {
        display: 'flex',
        justifyContent: "center",
    },
    imgMax: {
        maxWidth: "100%"
    },
    topIllustration: {
        position: 'absolute',
        top: '15%',
        right: '0',
    },
    infoBloc: {
        background: '#0046fe0a',
        padding: '1em',
        borderRadius: '4px',
        border: '1px solid #0046fe'
    },
    styledLink: {
        color: "blue"
    },
    styledColor: {
        color: "#002c52"
    },
    warningBloc: {
        background: '#ff5c350f',
        padding: '1em',
        borderRadius: '4px',
        border: '1px solid #ff5c35'
    }
}))

export default AboutGoogle;
