import { Link, generatePath, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import GroupAvatar from 'Theme/components/Avatar/GroupAvatar';
import { PrivateRoutes } from 'Router/Routes';

const useStyles = makeStyles(theme => ({
  link: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  name: {
    display: 'inline-block',
    marginLeft: theme.spacing(2)
  }
}));

interface Props {
  group: ILightPersonGroup;
}

function PersonGroupNameCell(props: Readonly<Props>) {
  const { accountCode } = useParams<{ accountCode: string }>();
  const classes = useStyles();

  return (
    <Link
      to={generatePath(PrivateRoutes.personGroupProfile.path, {
        accountCode,
        groupCode: props.group.code
      })}
      className={classes.link}
    >
      <GroupAvatar size={'smaller'} group={props.group} />
      <span className={classes.name} data-testid={props.group.name}>
        {props.group.name}
      </span>
    </Link>
  );
}

export default PersonGroupNameCell;
