import { gql, MutationFunction } from '@apollo/client';

export const CreateApiKey = gql`
  mutation CreateApiKey(
    $accountCode: String!
    $name: String!
    $serviceGroupCode: String!
  ) {
    createApiKey(
      accountCode: $accountCode
      name: $name
      serviceGroupCode: $serviceGroupCode
    ) {
      uuid
      secret
    }
  }
`;

export type CreateApiKeyMutation = MutationFunction<
  CreateApiKeyData,
  CreateApiKeyVariables
>;
