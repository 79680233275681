import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  subheaderActionButtons: {
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  icons: {
    marginRight: '8px'
  },
  refreshButton: {
    padding: '6px 0px 6px 4px',
    marginRight: theme.spacing(1)
  }
}));
