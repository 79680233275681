import { ApolloError } from '@apollo/client';
import { Button, Dialog, DialogContent, DialogTitle, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Add as AddIcon } from '@mui/icons-material';
import { useState } from 'react';
import ErrorMsg from 'Components/Shared/ErrorMsg';
import TableServicesAvailableGroupsContainer from '../../../Components/TableServicesAvailableGroups/TableServicesAvailableGroups.container';
import TableServicesGroups from '../../../Components/TableServicesGroups/TableServicesGroups';
import useTranslate from '../../../Hooks/useTranslate';
import { TServiceGroupList } from '../../../Models';
import TestIds from 'Tests/TestIds';

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    gap: theme.spacing(1)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2)
  }
}));

interface Props {
  loadingError?: ApolloError;
  data?: TServiceGroupList;
  loading: boolean;
  page: number;
  rowPerPage: number;
  onChangePage: (page: number) => void;
  onChangeRowPerPage: (rowsPerPage: number) => void;
  refresh(): void;
}

const ServiceGroupsPanel = (props: Props) => {
  const ts = useTranslate();
  const [isAddGroupMode, setIsAddGroupMode] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h5" paragraph>
          {ts('page.serviceProfile.serviceMembers.groups.tableTitle')}
        </Typography>
        <Button
          color="primary"
          onClick={() => setIsAddGroupMode(true)}
          data-testid={TestIds.pages.serviceProfile.addGroupButton}
        >
          <AddIcon />
          {ts('menu.addGroup')}
        </Button>
      </div>
      {props.loadingError ? (
        <ErrorMsg />
      ) : (
        <TableServicesGroups
          loading={props.loading}
          rowPerPage={props.rowPerPage}
          page={props.page}
          onChangePage={props.onChangePage}
          onChangeRowsPerPage={props.onChangeRowPerPage}
          data={props.data}
        />
      )}
      <Dialog open={isAddGroupMode} maxWidth="xs" fullWidth>
        <DialogTitle>{ts('menu.addGroup')}</DialogTitle>
        <DialogContent>
          <TableServicesAvailableGroupsContainer
            onClose={() => {
              setIsAddGroupMode(false);
              props.refresh();
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ServiceGroupsPanel;
