import { Typography } from '@mui/material';
import {
  Link,
  generatePath,
} from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';
import useTranslate from '../../Hooks/useTranslate';
import TestIds from '../../Tests/TestIds';

interface Props {
  serviceSubscription: IServiceSubscriptionWithCount;
}

function ServiceProfileMembersLink(props: Props) {
  const { serviceSubscription } = props;
  const ts = useTranslate();

  return (
    <Link
      to={generatePath(PrivateRoutes.serviceProfileMembers.path, {
        accountCode: serviceSubscription.account.code,
        serviceCode: serviceSubscription.service.groupCode,
      })}
      data-testid={TestIds.pages.services.numberServiceMembersLink}
    >
      <Typography variant="caption" color="primary" style={{fontWeight: 'bold'}}>
        {ts("page.userProfile.services.numberOfSubscribers", {subscribers: serviceSubscription.numberOfSubscriptions})}
        {!!serviceSubscription.maxSubscriptions && (
          <> / {ts("page.userProfile.services.numberOfLicenses", { licenses: serviceSubscription.maxSubscriptions} )}</>
        )}
      </Typography>
    </Link>
  );
}

export default ServiceProfileMembersLink;
