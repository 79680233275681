import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  containerSuccess: {
    height: '100%'
  },
  failureBox: {
    height: 200,
    overflowY: 'auto'
  },
  content: {
    flex: 1
  },
  wizardIllustration: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    maxHeight: 280,
    alignItems: 'start'
  }
}));
