import { gql } from '@apollo/client';

export const InvitePersonsToAccount = gql`
  mutation InvitePersonsToAccount($accountCode: String!, $emails: [String!]) {
    invitePersonsToAccount(accountCode: $accountCode, emails: $emails) {
      error {
        code
        message
      }
      userEmail
      userImmutableId
    }
  }
`;
