import { useRouteMatch } from 'react-router-dom';

const useServiceSubscriptionRouteMatch = (): IServiceSubscriptionIdentifier => {
  const { params: serviceSubscriptionIdentifier } = useRouteMatch<
    IServiceSubscriptionIdentifier
  >();
  Object.keys(serviceSubscriptionIdentifier).forEach(function(key) {
    const typedKey = key as keyof IServiceSubscriptionIdentifier;
    const value = serviceSubscriptionIdentifier[typedKey];
    if (value) {
      serviceSubscriptionIdentifier[typedKey] = decodeURIComponent(value);
    }
  });
  return serviceSubscriptionIdentifier;
};

export default useServiceSubscriptionRouteMatch;
