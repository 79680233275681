import { CSSProperties, ReactNode } from 'react';
import {
  Formik,
  FormikHelpers,
  FormikValues,
  Form as FormikForm,
  FormikConfig
} from 'formik';

export interface IFormProps<T> extends FormikConfig<T> {
  initialValues: any;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
  validationSchema?: any;
  children: ReactNode;
  enableReinitialize?: boolean;
  style?: CSSProperties;
  className?: string;
}

const Form = <T extends FormikValues>(props: IFormProps<T>) => {
  const {
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize = false,
    children,
    style,
    className
  } = props;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={enableReinitialize}
    >
      {() => {
        return (
          <FormikForm style={style} className={className}>
            {children}
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default Form;
