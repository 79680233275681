import { CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { Mutation } from '@apollo/client/react/components';
import { ApolloError, MutationResult } from '@apollo/client';
import qs from 'qs';
import {
  CreateAdministrativeAccessRight,
  PersonAccessRightStatus
} from '../../Apollo';
import TestIds from 'Tests/TestIds';
import logoCegid from 'Theme/assets/images/cegid-logo-blue.svg';
import accessRightSrc from 'Theme/assets/images/confirmedAccessRight.svg';
import { useStyles } from './DelegateConfirmation.styles';
import useTranslate from '../../Hooks/useTranslate';
import { useLocation } from 'react-router-dom';

interface Props {}

function DelegateConfirmation(props: Props) {
  const classes = useStyles();
  const ts = useTranslate();
  const location = useLocation();
  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.root}
    >
      <img src={logoCegid} className={classes.logo} alt="Cegid logo" />
      <Paper className={classes.paper}>
        <Mutation<
          CreateAdministrativeAccessRightData,
          CreateAdministrativeAccessRightVariables
        >
          mutation={CreateAdministrativeAccessRight}
          onError={(error: ApolloError) => {
            console.error(error);
          }}
        >
          {(
            createAdministrativeAccessRight,
            { called, loading, data, error }: MutationResult
          ) => {
            if (!called) {
              //  Get the token from the query string in url
              //  qs.parse change the query string into an object
              const queryParameters = qs.parse(location.search.substring(1));
              createAdministrativeAccessRight({
                variables: {
                  token: queryParameters.token
                }
              });
            }

            if (loading) {
              return (
                <CircularProgress
                  disableShrink
                  size={44}
                  color="secondary"
                  className={classes.circularProgressRoot}
                />
              );
            }

            return (
              <>
                {data?.createAdministrativeAccessRight.status ===
                  PersonAccessRightStatus.Accepted && (
                  <>
                    <img
                      src={accessRightSrc}
                      alt={ts('delegation.success')}
                      className={classes.shield}
                    />
                    <Typography
                      variant="h5"
                      className={classes.content}
                      data-testid={TestIds.pages.delegation.success}
                    >
                      {ts('delegation.success')}
                    </Typography>
                  </>
                )}
                {error && (
                  <div className={classes.content}>
                    <Typography variant="h5">
                      {ts('delegation.error')}
                    </Typography>
                  </div>
                )}
              </>
            );
          }}
        </Mutation>
      </Paper>
    </Grid>
  );
}

export default DelegateConfirmation;
