import { PersonAccessRightStatus } from '../../../../../Apollo';
import TestIds from 'Tests/TestIds';
import FilteredServices from '../FilteredServices';

interface Props {
  person: IPersonWithAccount;
}

const SuspendedServices = (props: Props) => {
  const { person } = props;
  return (
    <FilteredServices
      person={person}
      helpId={'page.userProfile.suspendedServices.help'}
      titleId={'page.userProfile.services.suspendedAccess'}
      emptyStatePrimary={'page.userProfile.services.emptyStateTitle'}
      tableTestIds={TestIds.pages.profile.suspendedServices.table}
      servicesStatus={PersonAccessRightStatus.Suspended}
    />
  );
};

export default SuspendedServices;
