import { Theme } from '@mui/material';
import { CdsSizeRadius } from '@cegid/design-tokens';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  accountWithHelp: {
    display: 'flex', 
    alignItems: 'center', 
    color: "white"
  },
  header: {
    color: theme.palette.common.white,
    // Header background is the same as Avatar's background color,
    // if avatar have no color value it should matches with the default PersonAvatar
    backgroundColor: theme.palette.grey[400],
    padding: theme.spacing(4),
    borderRadius: `${CdsSizeRadius} ${CdsSizeRadius} 0 0`
  },
  headerTitle: {
    marginBottom: theme.spacing(3)
  },
  headerSubTitle: {
    opacity: 0.6
  },
  body: {
    padding: theme.spacing(4)
  }
}))