import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { ApolloError, MutationFunction, MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { FormattedMessage } from 'react-intl';
import { ReadonlyProperty, UpdatePerson } from '../../../../../../Apollo';
import ButtonLoading from 'Theme/components/ButtonLoading';
import { ErrorHandler } from 'Theme/components/ErrorHandler';
import TestIds from 'Tests/TestIds';

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      marginBottom: theme.spacing(3),

      '&:last-child': {
        marginBottom: 0
      }
    }
  });

interface Props {
  person: IPersonWithAccount;
  onClose: () => void;

  // List of fields to disable.
  // e.g [ReadonlyProperty.LastName] will disable lastname field in dialog
  readonlyProperties: ReadonlyProperty[];
}

interface State {
  lastName: string;
  firstName: string;
}

class EditNameDialog extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  public readonly state: State = {
    lastName: this.props.person.lastName || '',
    firstName: this.props.person.firstName || ''
  };

  public render() {
    const { person, readonlyProperties, onClose, classes } = this.props;
    const { firstName, lastName } = this.state;

    const getInputProps = (name: keyof State) => ({
      classes: { root: classes.textField },
      name,
      label: <FormattedMessage id={`page.userProfile.editName.${name}`} />,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState<any>({
          [name]: event.target.value
        });
      },
      fullWidth: true,
      value: this.state[name]
    });

    return (
      <ErrorHandler.Consumer>
        {errorHandler => (
          <Mutation
            mutation={UpdatePerson({
              firstName,
              lastName
            })}
            variables={{
              personIdentifier: person.immutableId
            }}
            onError={(e: ApolloError) => errorHandler.onError(e)}
            onCompleted={this.onCompleted}
          >
            {(updatePerson: MutationFunction, { loading }: MutationResult) => (
              <Dialog
                open={true}
                onClose={onClose}
                maxWidth="xs"
                fullWidth={true}
              >
                <form
                  onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();

                    if (loading) {
                      return;
                    }

                    updatePerson();
                  }}
                  data-testid={TestIds.pages.profile.editNameForm}
                >
                  <DialogTitle>
                    <FormattedMessage id="page.userProfile.editName.editName" />
                  </DialogTitle>
                  <DialogContent>
                    {!readonlyProperties.includes(
                      ReadonlyProperty.LastName
                    ) && (
                      <TextField
                        {...getInputProps('lastName')}
                        variant='outlined'
                        autoFocus={true}
                      />
                    )}

                    {!readonlyProperties.includes(
                      ReadonlyProperty.FirstName
                    ) && <TextField {...getInputProps('firstName')} variant='outlined'/>}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={onClose}
                      color='inherit'
                      data-testid={
                        TestIds.common.snackbar.action.cancelNameChange
                      }>
                      <FormattedMessage id="common.action.cancel" />
                    </Button>
                    <ButtonLoading
                      data-testid={TestIds.pages.profile.saveNameButton}
                      type="submit"
                      color="primary"
                      loading={loading}
                    >
                      <FormattedMessage id="common.action.validate" />
                    </ButtonLoading>
                  </DialogActions>
                </form>
              </Dialog>
            )}
          </Mutation>
        )}
      </ErrorHandler.Consumer>
    );
  }

  private onCompleted = () => {
    const { onClose } = this.props;

    enqueueSnackbar(<FormattedMessage id="page.userProfile.updatedProfile" />, {
      variant: 'success'
    });

    onClose();
  };
}

export default withStyles(styles)(EditNameDialog);
