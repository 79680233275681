import { gql } from '@apollo/client';
import { LanguageFragment } from '../language/Language';
import { AvatarFragment } from './Avatar';


export enum ActivationStatus {
  Activated = 'ACTIVATED',
  Created = 'CREATED',
  ToActivate = 'TOACTIVATE',
  Disabled = 'DISABLED'
}

export const PersonFragment = gql`
  fragment Person on Person {
    status
    avatar {
      ...Avatar
    }
    lastName
    firstName
    immutableId
    immutableGuid
    email
    phoneNumber
    mobileNumber
    language {
      ...Language
    }
    professionCode
    titleCode
    sicId
    activationDate
    creationDate
  }
  ${AvatarFragment}
  ${LanguageFragment}
`;
