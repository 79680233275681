import {
  CdsColorBrandPrimary50,
  CdsColorExtendedGrey30,
  CdsColorExtendedGrey40,
  CdsColorExtendedGrey50,
  CdsColorExtendedWhite,
  CdsColorInteractionNeutralHovered
} from "@cegid/design-tokens";

const colors = {
  white: CdsColorExtendedWhite,
  success: '#4caf50',
  error: '#f44336',
  warn: '#f9a825',
  blue: {
    700: '#0030e5',
    500: CdsColorBrandPrimary50,
    400: '#4965ff',
    300: '#c7c9ff',
    50: '#e9eaff',
    40: '#e0e6fc',
    30: '#ebeffd',
    20: '#f5f7fe'
  },
  orange: {
    700: '#e64f2c',
    500: '#ff5c35',
    400: '#fe7451'
  },
  grey: {
    200: CdsColorInteractionNeutralHovered /** ListItem hover, MuiTableRow hover*/,
    250: '#ebebeb',
    300: '#e0e0e0',
    500: CdsColorExtendedGrey50,
    600: CdsColorExtendedGrey40,
    700: CdsColorExtendedGrey30,
    800: '#424242'
  }
};

export default colors;
