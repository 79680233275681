import useTranslate from 'Hooks/useTranslate';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IconButtonWithPopOver from 'Theme/components/IconButtonWithPopOver';
import {
  CdsColorTextCriticalHighEmphasis,
  CdsColorTextSuccessHighEmphasis,
  CdsColorTextWarnHighEmphasis
} from '@cegid/design-tokens';
import { DomainValidationMode } from 'Apollo/fragments/organization/DomainValidationMode';

interface Props {
  domain: IOrganizationDomain;
}

function DomainStatusPopover({ domain }: Readonly<Props>) {
    const ts = useTranslate();

    switch (domain.validationMode) {
        case DomainValidationMode.Strong:
            return (
                <IconButtonWithPopOver
                    customIcon={
                        <CheckCircleOutlineIcon
                            fontSize="small"
                            style={{ color: CdsColorTextSuccessHighEmphasis }}
                        />
                    }
                    description={ts('page.organizationDomains.statusType', {
                        value: (domain.validationMode as unknown) as keyof typeof DomainValidationMode
                    })}
                    role='strong-validation-domain-popover'
                />
            );
        case DomainValidationMode.None:
            return (
                <IconButtonWithPopOver
                    customIcon={
                        <AccessTimeIcon
                            fontSize="small"
                            style={{ color: CdsColorTextCriticalHighEmphasis }}
                        />
                    }
                    description={ts('page.organizationDomains.statusType', {
                        value: (domain.validationMode as unknown) as keyof typeof DomainValidationMode
                    })}
                    role='none-validation-domain-popover'
                />
            );
        case DomainValidationMode.Weak:
            return (
                <IconButtonWithPopOver
                    customIcon={
                        <ErrorOutlineIcon
                            fontSize="small"
                            style={{ color: CdsColorTextWarnHighEmphasis }}
                        />
                    }
                    description={ts('page.organizationDomains.statusType', {
                        value: (domain.validationMode as unknown) as keyof typeof DomainValidationMode
                    })}
                    role='weak-validation-domain-popover'
                />
            );
        default:
            return null;
    }
}

export default DomainStatusPopover;
