
/**
 * 
 * @param page current page
 * @param rowsPerPage current results per page
 * @param newRowPerPage new results per page settings chosen by user
 * @returns the new page of results on which user should be redirected (based on the first index viewed before change)
 */

export const calculateNewPage = (page: number, rowsPerPage: number, newRowPerPage: number) => {
    let newPage: number = 0;
    
    // first result viewed by user before rowsPerPage's settings change
    let firstIndex = (page * rowsPerPage) + 1;

    // after rowsPerPage's settings change, calculate the currentPage of result to display
    newPage = Math.floor(firstIndex/newRowPerPage);

    return newPage
  }