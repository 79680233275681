import { colors } from 'Theme/constants';
import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  alertBox: {
    backgroundColor: colors.warn,
    padding: theme.spacing(1),
    display: 'flex',
    marginBottom: theme.spacing(1)
  },
  apiKeyFieldName: {
    marginTop: theme.spacing(2)
  },
  copyPasteButton: {
    marginLeft: '8px',
    width: '32px'
  },
  copyZoneField: {
    display: 'flex',
    alignItems: 'baseline'
  },
  formControl: {
    width: '100%'
  },
  icons: {
    marginRight: '8px'
  }
}));
