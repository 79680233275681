import { ReactNode } from "react";
import { useStyles } from "./PaperFooter.styles";

interface Props {
    children: ReactNode;
}

function PaperFooter(props: Props) {
    const classes = useStyles();
    const { children } = props;
    
    return(
        <div className={classes.footer}>
            {children}
        </div>
    )
}

export default PaperFooter;