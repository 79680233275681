import useTranslate from 'Hooks/useTranslate';
import { CheckCircle as IconCheckCircle, RadioButtonUnchecked as IconRadioButtonUnchecked, } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

interface Props {
  serviceSubscription: IServiceSubscription;
}

function CellAccessByDefault(props: Readonly<Props>) {
  const ts = useTranslate();
  const { serviceSubscription } = props;

  if(serviceSubscription.accessByDefault){
    return(
        <Tooltip title={ts('page.userProfile.services.accessByDefault')}>
            <IconCheckCircle color="disabled" />
        </Tooltip>
    )
  } 
  return(
    <Tooltip title={ts('page.userProfile.services.notAccessByDefault')}>
        <IconRadioButtonUnchecked color="disabled" />
    </Tooltip>
  )
}

export default CellAccessByDefault;
