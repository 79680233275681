import { gql } from '@apollo/client';

export const AdministrationResourceAccess = gql`
  fragment AdministrationResourceAccess on AdministrationResourceAccess {
    canAskForAccountAdministrationDelegation
    canReadAll
    canWriteAll
    hasAccessToAccounts
    isAccountAdmin
    isApiKeyOwner
    isOrganizationOwner
    isDirectoryOwner
    isServiceAdmin
    lastSuperUserAttemptId
  }
`;
