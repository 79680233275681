import { gql } from '@apollo/client';
import { AccessRightCreationFragment } from '../../fragments/person/AccessRightCreation';

export const CreateAdministrativeAccessRight = gql`
  mutation CreateAdministrativeAccessRight($token: String!) {
    createAdministrativeAccessRight(token: $token) {
      ...AccessRightCreation
    }
  }
  ${AccessRightCreationFragment}
`;
