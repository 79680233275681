import { gql } from '@apollo/client';
import { PersonGroupFragment } from '../../fragments/personGroup/PersonGroup';

export const GetPersonGroup = gql`
  query GetPersonGroup($code: String!) {
    personGroup(code: $code) {
      ...PersonGroup
    }
  }
  ${PersonGroupFragment}
`;
