import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GetPersonWithAccount } from 'Apollo';
import ProfilePageSkeleton from 'Theme/components/ProfilePageSkeleton';
import useTranslate from 'Hooks/useTranslate';
import useNotification from 'Hooks/useNotification';
import { PersonContext } from 'Context/CurrentPerson';
import { useContext } from 'react';
import { PageError } from 'Page/PageError';
import UsersProfile from './UsersProfile';

function UsersProfileContainer() {
  const ts = useTranslate();
  const { currentPerson, currentPersonRoles } = useContext(PersonContext);

  const { immutableId } = useParams<{
    accountCode: string;
    immutableId: string;
  }>();

  const { onError } = useNotification();

  const { data, loading } = useQuery<GetPersonWithAccountData>(
    GetPersonWithAccount,
    {
      onError,
      variables: {
        identifier: {
          immutableId
        }
      },
      fetchPolicy: 'cache-first'
    }
  );

  if (
    currentPerson.immutableId !== immutableId &&
    !currentPersonRoles.hasAccessToAccounts &&
    !currentPersonRoles.isAccountAdmin
  ) {
    return (
      <PageError
        title={ts('error.type.E403')}
        textPrimary={ts('error.sentence.E403')}
        accessDenied={true}
      />
    );
  }

  if (loading) {
    return <ProfilePageSkeleton shape="circle" />;
  }

  return <UsersProfile person={data?.person} />;
}

export default UsersProfileContainer;
