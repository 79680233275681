import { Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet';

import Accounts from './Accounts/Accounts';
import Administrators from './Administrators';
import { UsersCounter, GroupsCounter, ServicesCounter } from 'Page/Components';
import { PersonContext } from 'Context/CurrentPerson';
import Onboarding from 'Components/Onboarding/Onboarding';
import { withCookies, ReactCookieProps } from 'react-cookie';

import DashBoardSubscriptionRequests from 'Components/DashboardSubscriptionRequests';
import useTranslate from 'Hooks/useTranslate';
import { useContext } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  administratorsContainer: {
    marginBottom: theme.spacing(3)
  }
}));

interface Props extends ReactCookieProps {}

function PageDashboard(props: Props) {
  const classes = useStyles();
  const ts = useTranslate();
  const { currentPersonRoles } = useContext(PersonContext);

  return (
    <>
      <Helmet title={ts('page.dashBoard.title')} />
      {(currentPersonRoles.hasAccessToAccounts ||
        currentPersonRoles.isAccountAdmin) && <Onboarding />}
      <Grid container spacing={3}>
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={4}>
            <UsersCounter showButton />
          </Grid>
          <Grid item xs={12} sm={4}>
            <GroupsCounter showButton />
          </Grid>
          <Grid item xs={12} sm={4}>
            <ServicesCounter showButton />
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item xs={12} md={7}>
            <DashBoardSubscriptionRequests />
          </Grid>
          <Grid container item xs={12} md={5}>
            <Grid
              container
              direction="column"
              xs={12}
              item
              className={classes.administratorsContainer}
            >
              <Administrators />
            </Grid>
            <Grid container direction="column" xs={12} item>
              <Accounts />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default withCookies(PageDashboard);
