import { useQuery } from "@apollo/client";
import { FormControl, InputLabel, Select, MenuItem, Skeleton } from "@mui/material";
import { GetServiceSubscriptions } from "Apollo";
import useNotification from "Hooks/useNotification";
import useTranslate from "Hooks/useTranslate";
import TestIds from "Tests/TestIds";
import { useStyles } from "../GenerateApiKey.styles";

interface Props {
    accountCode: string;
    selectedServiceCode: string;
    handleChangeSelectedService: (code: string) => void;
    selectedServiceCodeError: boolean;

}

function ServicesSelector(props: Props) {

    const { onError } = useNotification();
    const classes = useStyles();

    const { accountCode, selectedServiceCode, handleChangeSelectedService, selectedServiceCodeError } = props;

    const ts = useTranslate();

    const variables = {
        accountCode: accountCode,
        apiKeyEligibleOnly: true
    }


    const { data, loading } = useQuery<GetServiceSubscriptionsData>(GetServiceSubscriptions, {
        onError,
        variables,
        fetchPolicy: 'cache-first'
    });

    if(loading){
        return (
            <Skeleton
                variant="rectangular"
                width="100%"
                height="54px"
            />
        );
    }

    return (
        <>
            <FormControl
                variant="outlined"
                className={classes.formControl}
                placeholder={ts('page.apiKeys.generate.selectService')}
            >
                <InputLabel htmlFor="outlined-service-native-simple" error={selectedServiceCodeError}>
                    {ts("page.apiKeys.generate.selectService")}
                </InputLabel>
                <Select
                    data-testid={
                        TestIds.pages.apiKeys.creation
                            .serviceSelect
                    }
                    value={selectedServiceCode}
                    defaultValue=""
                    onChange={event =>
                        // Custom selects have a particular DOM structure so we need to use event.target rather than event.currentTarget here.
                        handleChangeSelectedService(
                            event.target.value
                        )
                    }
                    label={ts('page.apiKeys.generate.selectService')}
                    inputProps={{
                        name: '',
                        id: 'outlined-service-native-simple'
                    }}
                    error={selectedServiceCodeError}
                >
                    {data?.serviceSubscriptions.edges.map(
                        (item) => (
                            <MenuItem
                                key={item.node.service.groupCode}
                                value={item.node.service.groupCode}
                                role="option"
                            >
                                {`${item.node.service.name}`}
                            </MenuItem>
                        )
                    )}
                </Select>
            </FormControl>
        </>
    )
}

export default ServicesSelector