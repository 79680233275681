import { Card, Avatar } from '@mui/material';
import { useStyles } from './CardWithAvatar.styles';


interface Props {
  avatar: JSX.Element;
  cardHeader: JSX.Element;
  cardContent: JSX.Element;
  cardFooter: JSX.Element;
  testId?: string;
  role?: string;
}

function CardWithAvatar({testId, role, avatar, cardHeader, cardContent, cardFooter}: Readonly<Props>) {
  const classes = useStyles();

  return (
    <Card classes={{ root: classes.card }} data-testid={testId} role={role}>
      <div className={classes.cardTop}>
        <Avatar classes={{ root: classes.avatar }}>
          {avatar}
        </Avatar>
        {cardHeader}
      </div>
      <div className={classes.cardContent}>
        {cardContent}
      </div>
      <div className={classes.cardBottom}>
        {cardFooter}
      </div>
    </Card>
  );
}

export default CardWithAvatar;
