import { ApolloError } from '@apollo/client';

/**
 * Set a new message for a specific error code
 * @param error ApolloError
 * @param targetCode String - Is the code of the error to change
 * @param newMessage String - Is the new message of the error
 * @param newCode String - Is the new code of the error if it is different from the targetCode
 *
 * @returns ApolloError
 */

export const setErrorMessage = (
  error: ApolloError,
  targetCode: string,
  newMessage: string,
  newCode?: string
): ApolloError => {
  error.graphQLErrors = error.graphQLErrors.map(graphQLError => {
    if (graphQLError.extensions?.code === targetCode) {
      if (newCode) {
        graphQLError.extensions.code = newCode;
      }
      error.networkError = null;
      graphQLError.message = newMessage;
    }
    return graphQLError;
  });
  error.message = newMessage;
  return error;
};
