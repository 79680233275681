import { gql } from '@apollo/client';
import { PersonGroupMembershipOperationFragment } from '../../fragments/personGroup/PersonGroupMembershipOperationFragment';

export const AddPersonsToPersonGroup = gql`
  mutation AddPersonsToPersonGroup(
    $groupCode: String!
    $personIdentifiers: [String!]
  ) {
    addPersonsToPersonGroup(
      groupCode: $groupCode
      personIdentifiers: $personIdentifiers
    ) {
      ...PersonGroupMembershipOperation
    }
  }
  ${PersonGroupMembershipOperationFragment}
`;
