import { Avatar } from '@mui/material';
import { AvatarProps } from '@mui/material/Avatar';
import { LocalLibrary as LocalLibraryIcon } from '@mui/icons-material';
import { colors } from 'Theme/constants';
import { useStyles } from './Square.styles';

interface Props extends AvatarProps {
  directory: IDirectoryAuthenticatingPersonType;
  size: 'small' | 'medium' | 'large' | 'larger';
}

function DirectoryAvatar(props: Readonly<Props>) {
  const { directory, size } = props;
  const classes = useStyles();

  return (
    <Avatar
      {...props}
      variant='square'
      role="avatar"
      classes={{
        root: classes[size] ?? classes['larger']
      }}
      style={{
        backgroundColor: directory.isFederated
          ? colors.grey[300]
          : colors.blue[500]
      }}
    >
      {directory.label ? directory.label.charAt(0).toUpperCase() : <LocalLibraryIcon className={classes.icon} />}
    </Avatar>
  );
}

export default DirectoryAvatar;
