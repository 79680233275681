import { FormattedMessage } from 'react-intl';
import { Avatar, Grid, Paper, Switch, Typography } from '@mui/material';
import { NotificationImportant as IconNotificationImportant } from '@mui/icons-material';
import { updateAccount, NotificationKind } from '../../Apollo';
import Container from 'Theme/components/Container';
import { PersonContext } from 'Context/CurrentPerson';
import shortAccountlabel from 'Tools/LabelFormatter/shortAccountLabel';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useNotification from 'Hooks/useNotification';
import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import ContextualHelp from 'Components/Shared/ContextualHelp';
import useTranslate from 'Hooks/useTranslate';
import { useStyles } from './NotificationsForm.styles';

interface ComponentProps {
  refetchQueries: any;
}

type Props = Pick<GetAccountsWithNotificationSettingsData, 'accounts'> &
  ComponentProps;

const NotificationsForm = (props: Props) => {
  const { accountCode } = useParams<{ accountCode: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { onError } = useNotification();
  const { accounts, refetchQueries } = props;
  const { currentPersonRoles: personRoles } = useContext(PersonContext);
  const isAdmin = personRoles.canWriteAll || personRoles.isAccountAdmin;
  const ts = useTranslate();
  const classes = useStyles();

  let currentAccountSettings = accounts.edges.find(
    acc => acc.node.code === accountCode
  )?.node.notificationSettings;

  const [notificationSettings, setNotificationSettings] = useState<
    TTypeName<INotificationSettings> | undefined
  >(currentAccountSettings ?? undefined);

  const onCompleted = () => {
    const account = accounts.edges.find(
      ({ node }) => node.code === accountCode
    );

    const accountName = account ? shortAccountlabel(account.node) : '';

    enqueueSnackbar(
      <FormattedMessage
        id="page.notifications.successfullySaved"
        values={{ account: accountName }}
      />,
      {
        variant: 'success'
      }
    );
  };

  const [onUpdateAccount, { loading }] = useMutation<
    Object,
    UpdateAccountVariables
  >(updateAccount, { onCompleted, onError, refetchQueries });

  const handleSwitch = (code: string, checked: boolean) => {
    if (loading) {
      return;
    }

    const newNotificationKind = checked
      ? NotificationKind.None
      : NotificationKind.Email;

    const newNotificationKindString = {
      ...notificationSettings,
      newUserAccessRights: newNotificationKind
    };

    setNotificationSettings(newNotificationKindString);

    /**
     * to avoid sending __typename to the server
     * The field `__typename` does not exist on any GrarphQl type
     */
    delete newNotificationKindString.__typename;

    onUpdateAccount({
      variables: {
        accountCode: code,
        data: {
          notificationSettings: newNotificationKindString
        }
      }
    });
  };

  return (
    <Container>
      <div className={classes.section}>
        <div className={classes.titleWithHelp}>
          <Typography variant="h5" component="span">
            {ts('page.notifications.title')}
          </Typography>
          <ContextualHelp content={ts('page.notifications.help')} />
        </div>
        <Typography variant="body2" color="textSecondary">
          {ts('page.notifications.newAccessRightsDescription')}
        </Typography>
        {props.accounts.edges.map(acc => (
          <Paper className={classes.notificationPaper} key={acc.node.code}>
            <Grid container>
              <Grid item xs={10} className={classes.leftSide}>
                <Avatar classes={{ root: classes.avatar }}>
                  <IconNotificationImportant />
                </Avatar>
                <div className={classes.notificationTitle}>
                  <Typography variant="h6" color="textPrimary">
                    {ts('page.notifications.newAccessRightsTitle')}
                  </Typography>
                  <Typography variant="caption" color="textPrimary">
                    {acc.node.name ?? acc.node.code}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2} className={classes.switchZone}>
                <Switch
                  color="primary"
                  disabled={!isAdmin}
                  checked={
                    acc.node.notificationSettings.newUserAccessRights ===
                    NotificationKind.Email
                  }
                  onChange={() =>
                    handleSwitch(
                      acc.node.code,
                      acc.node.notificationSettings.newUserAccessRights ===
                        NotificationKind.Email
                    )
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        ))}
      </div>
    </Container>
  );
};

export default NotificationsForm;
