import { TTabView } from '../Components/Shared/Tabs/Tabs';
import { useParams, generatePath } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TestIds from 'Tests/TestIds';
import TabRevoked from 'Page/ApiKeys/Tabs/TabRevoked';
import TabActive from 'Page/ApiKeys/Tabs/TabActive';
import { PrivateRoutes } from 'Router/Routes';


const useTabsApiKeys = (): TTabView[] => {
  const { accountCode } = useParams<{
    accountCode: string;
  }>();

  const getPath = (path: string) =>
    generatePath(path, {
      accountCode,
    });

  const tabs: TTabView[] = [
    {
        icon: <CheckCircleOutlineIcon />,
        path: PrivateRoutes.apiKeyServices.path,
        link: getPath(PrivateRoutes.apiKeyServices.path),
        title: 'page.apiKeys.activeTitle',
        component: TabActive,
        testid: TestIds.pages.apiKeys.linkActive
      },
      {
        icon: <HighlightOffIcon />,
        path: PrivateRoutes.apiKeyServicesRevoked.path,
        link: getPath(PrivateRoutes.apiKeyServicesRevoked.path),
        title: 'page.apiKeys.revokedTitle',
        component: TabRevoked,
        testid: TestIds.pages.apiKeys.linkRevoked
      }
  ];

  return tabs;
};

export default useTabsApiKeys;
