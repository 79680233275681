import { useMutation } from '@apollo/client';
import TestIds from 'Tests/TestIds';
import { RemovePersonsFromPersonGroup } from 'Apollo';
import { useState } from 'react';
import useNotification from 'Hooks/useNotification';
import { userFullName } from 'Tools';
import RemoveButton from 'Components/Shared/RemoveButton';
import useTranslate from 'Hooks/useTranslate';

interface Props {
  person: IPerson;
  personGroup: IPersonGroup;
  onRefetch: () => void;
}

function RemoveMemberButton(props: Readonly<Props>) {
  const { person, personGroup, onRefetch } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { onSuccess, onError } = useNotification();
  const ts = useTranslate();

  const variables: RemovePersonsFromPersonGroupVars = {
    personIdentifiers: [person.immutableId],
    groupCode: personGroup.code
  };

  const [removePersonsFromPersonGroup, { loading, error }] = useMutation(
    RemovePersonsFromPersonGroup,
    {
      
      onCompleted: () => {
        if(error){
          onError(error);
        }
        onSuccess('page.groupProfile.members.removeSuccess');
        setIsOpen(false);
        onRefetch();
      }
    }
  );

  const handleRemove = async () => {
    await removePersonsFromPersonGroup({
      variables: { ...variables },
      fetchPolicy: 'network-only'
    });
  };

  return (
    <RemoveButton
      actionId={TestIds.pages.profile.services.unsubscribeButton}
      confirmationId={TestIds.pages.profile.services.confirmDeletionButton}
      confirmationMessage={ts(
        'page.groupProfile.members.removeMemberConfirmation',
        {
          groupName: personGroup.name,
          fullName: userFullName(person.firstName, person.lastName)
        }
      )}
      isOpen={isOpen}
      onSubmit={handleRemove}
      loading={loading}
    />
  );
}

export default RemoveMemberButton;
