import { gql, MutationFunction } from '@apollo/client';

export const DeleteEmailChangeRequest = gql`
  mutation DeleteEmailChangeRequest($personIdentifier: String!) {
    deleteEmailChangeRequest(personIdentifier: $personIdentifier)
  }
`;

export type DeleteEmailChangeRequestMutation = MutationFunction<
  {},
  DeleteEmailChangeRequestVariables
>;
