import { useQuery } from '@apollo/client';
import { GetServiceSubscriptions, ServiceSubscriptionOrder } from 'Apollo';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import Services from './Services';
import { useMediaQuery } from '@mui/material';
import { calculateNewPage } from 'Tools/calculateNewPage';
import mediaQueries from 'Theme/constants/mediaQueries';

const ServicesContainer = () => {
  const { accountCode } = useParams<IUriParams>();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);
  const [orderBy, setOrderBy] = useState<ServiceSubscriptionOrder>(
    ServiceSubscriptionOrder.NameAsc
  );
  const [servicesFilter, setServicesFilter] = useState<string>('');

  const isMobileP = useMediaQuery(mediaQueries.mobilePortrait);

  const initialVariables = {
    page: { first: rowsPerPage, offset: currentPage * rowsPerPage },    
    servicesFilter: servicesFilter,
    accountCode: accountCode === 'all' ? null : accountCode,
    apiKeyEligibleOnly: false,
    orderBy: orderBy
  };

  const { data, loading } = useQuery<GetServiceSubscriptionsData>(
    GetServiceSubscriptions,
    { variables: initialVariables }
  );

  const handleChangeServicesSearch = (value: string) => {
    const newFilter = value.trim();
    setServicesFilter(newFilter);
    setCurrentPage(0);
  };

  const handleChangeOrderBy = (order: ServiceSubscriptionOrder) => {
    setOrderBy(order);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
  };

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };


  const Rows: IServiceSubscription[] =
    data?.serviceSubscriptions.edges.map(({ node }) => {
      return node;
    }) || [];

  return (
    <Services
      rows={Rows}
      totalCount={
        data?.serviceSubscriptions.totalCount ??
        0
      }
      loading={loading}
      isMobileP={isMobileP}
      currentPage={currentPage}
      orderBy={orderBy}
      rowsPerPage={rowsPerPage}
      onChangeOrderBy={handleChangeOrderBy}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onChangePage={handleChangePage}
      onChangeServicesSearch={handleChangeServicesSearch}
    />
  );
};

export default ServicesContainer;
