import { Typography } from '@mui/material';
import * as React from 'react';
import { Link, generatePath } from 'react-router-dom';
import useTranslate from '../../../Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import { PrivateRoutes } from './../../../Router/Routes';

interface Props {
  serviceSubscription: IServiceSubscription;
}

function Adm2ServiceProfileLink(props: Props) {
  const { serviceSubscription } = props;
  const ts = useTranslate();

  return (
    <Link
      to={generatePath(PrivateRoutes.externalServiceProfile.path, {
        accountCode: serviceSubscription.account.code,
        serviceCode: serviceSubscription.service.groupCode,
        targetAccountCode:
          serviceSubscription.targetAccount !== null
            ? serviceSubscription.targetAccount.code
            : undefined
      })}
    >
      <Typography variant="body1">
        {props.serviceSubscription.service.name}
      </Typography>
      {props.serviceSubscription.account.code !==
        props.serviceSubscription.targetAccount!.code && (
        <Typography
          variant="caption"
          data-testid={TestIds.pages.services.captionOnAdm2Service}
        >
          {ts('page.services.index.fromAccount', {
            accountSubscriber: props.serviceSubscription.account.name
          })}
        </Typography>
      )}
    </Link>
  );
}

export default Adm2ServiceProfileLink;
