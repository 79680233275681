import { gql, MutationFunction } from '@apollo/client';

export const CreatePersonAccessRightsByEmail = gql`
  mutation CreatePersonAccessRightsByEmail(
    $email: String!
    $serviceSubscriptionIdentifiers: [serviceSubscriptionIdentifier!]
  ) {
    createPersonAccessRightsByEmail(
      email: $email
      serviceSubscriptionIdentifiers: $serviceSubscriptionIdentifiers
    )
  }
`;

export type CreatePersonAccessRightByEmailMutation = MutationFunction<
  CreatePersonAccessRightByEmailData,
  CreatePersonAccessRightByEmailVariables
>;
