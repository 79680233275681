import { Drawer, Grid } from '@mui/material';

import { useStyles } from './DrawerBottom.styles';

interface Props {
    drawerHead: any;
    drawerBody: any;
}

function DrawerBottom(props: Readonly<Props>) {
    const {drawerHead, drawerBody } = props;
    const classes = useStyles();

    return (
        <Drawer
            anchor="bottom"
            variant="persistent"
            open={true}
            classes={{ paper: classes.drawer }}
        >
            <Grid container className={classes.drawerContainer}>
                {drawerHead}
            </Grid>
            <Grid container style={{justifyContent: 'center'}}>
                {drawerBody}
            </Grid>        
        </Drawer>
    );
}

export default DrawerBottom;
