import { gql } from '@apollo/client';
import { LightPersonGroupFragment } from '../../fragments/personGroup/LightPersonGroup';

export const GetLightPersonGroupWithoutAccessToServiceSubscription = gql`
  query GetLightPersonGroupWithoutAccessToServiceSubscription(
    $serviceSubscriptionIdentifier: serviceSubscriptionIdentifier!,
    $page: PageInput
  ) {
    lightPersonGroupWithoutAccessToServiceSubscription(
      serviceSubscriptionIdentifier: $serviceSubscriptionIdentifier
      page: $page
    ) {
      edges {
        node {
          ...LightPersonGroup
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${LightPersonGroupFragment}
`;
