import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginBottom: theme.spacing(2)
  },
  emptyServices: {
    padding: `0 ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(
      2
    )}`
  }
}));
