import { useQuery } from '@apollo/client';
import useServiceSubscriptionRouteMatch from 'Hooks/useServiceSubscriptionRouteMatch';
import { useContext, useState } from 'react';
import { GetLightPersonGroupWithAccessToServiceSubscription } from '../../../Apollo/queries/personGroup/GetLightPersonGroupWithAccessToServiceSubscription';
import { TServiceGroupList } from '../../../Models';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import ServiceGroupsPanel from './ServiceGroupsPanel';
import { calculateNewPage } from 'Tools/calculateNewPage';

import ErrorHandlerContext from 'Context/ErrorHandler.context';

const ServiceGroupsPanelContainer = () => {

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);
  
  const onChangePage = (page: number) => {
    setCurrentPage(page);
    refetch();
  };

  const onChangeRowPerPage = (rpp: number) => {
    setRowsPerPage(rpp);
    setCurrentPage(calculateNewPage(rowsPerPage, currentPage, rpp));
    refetch();
  }

  const serviceSubscriptionIdentifier = useServiceSubscriptionRouteMatch();

  const ErrorHandler = useContext(ErrorHandlerContext);

  const { data, loading, error, refetch } = useQuery<{
    lightPersonGroupWithAccessToServiceSubscription: TServiceGroupList;
  }>(GetLightPersonGroupWithAccessToServiceSubscription, {
    onError: ErrorHandler.onError,
    variables: {
      page: {
        first: rowsPerPage,
        offset: currentPage * rowsPerPage
      },
      serviceSubscriptionIdentifier
    }
  });

  return (
    <ServiceGroupsPanel
      loadingError={error}
      data={data?.lightPersonGroupWithAccessToServiceSubscription}
      loading={loading}
      page={currentPage}
      rowPerPage={rowsPerPage}
      onChangePage={onChangePage}
      onChangeRowPerPage={onChangeRowPerPage}
      refresh={refetch}
    />
  );
};

export default ServiceGroupsPanelContainer;
