import { useMutation } from '@apollo/client';
import ServiceProfileGeneral from './ServiceProfileGeneral';
import { UpdateServiceSubscriptions } from '../../../Apollo/mutations/service/UpdateServiceSubscriptions';
import useServiceSubscriptionRouteMatch from 'Hooks/useServiceSubscriptionRouteMatch';
import useNotification from 'Hooks/useNotification';

type Props = {
  serviceSubscription: IServiceSubscription;
};

export type TServiceSubscriptionInput = Partial<
  Pick<IServiceSubscription, 'administratedValidation' | 'accessByDefault'>
>;

const ServiceProfileGeneralContainer = ({ serviceSubscription }: Props) => {
  const serviceSubscriptionIdentifier = useServiceSubscriptionRouteMatch();
  const { onError } = useNotification();

  const [updateService] = useMutation(UpdateServiceSubscriptions, {
    onError
  });

  const onUpdate = (data: TServiceSubscriptionInput) => {
    updateService({
      variables: {
        serviceSubscriptionIdentifiers: [serviceSubscriptionIdentifier],
        data
      }
    });
  };

  return (
    <ServiceProfileGeneral
      serviceSubscription={serviceSubscription}
      onUpdate={onUpdate}
    />
  );
};

export default ServiceProfileGeneralContainer;
