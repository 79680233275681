import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import townSrc from 'Theme/assets/images/town.svg';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: `url(${townSrc}) no-repeat bottom`,
    height: '100vh'
  },
  paper: {
    maxWidth: '80%'
  },
  content: {
    margin: theme.spacing(3)
  },
  logo: {
    position: 'absolute',
    left: theme.spacing(3),
    top: theme.spacing(2)
  },
  shield: {
    textAlign: 'center',
    display: 'block',
    margin: '0 auto',
    padding: theme.spacing(2)
  },
  circularProgressRoot: {
    margin: theme.spacing(5)
  }
}))