import { FormattedMessage } from 'react-intl';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTranslate from '../../Hooks/useTranslate';
import Error from './Error';
import TestIds from 'Tests/TestIds';

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    gap: theme.spacing(1)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1)
  },
  selectModal: {
    zIndex: `${theme.zIndex.appBar + 2} !important` as any,
    marginTop: '50px'
  },
  emptyBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3)
  },
  emptyIcon: {
    fontSize: theme.spacing(6),
    color: theme.palette.primary.main
  }
}));

interface Props {
  rows: ILightPersonGroup[];
  loading: boolean;
  loadingError: boolean;
  selectedGroup: ILightPersonGroup | null;
  setSelectedGroup(group: ILightPersonGroup | null): void;
  onCancel(): void;
  onAdd(): void;
}

const TableServicesAvailableGroups = ({
  rows,
  loading,
  loadingError,
  selectedGroup,
  setSelectedGroup,
  onCancel,
  onAdd
}: Props) => {
  const classes = useStyles();
  const ts = useTranslate();
  const isEmpty = rows.length === 0;

  const error = Error({
    loadingError,
    isEmpty,
    emptyMessage: ts(
      'page.serviceProfile.serviceMembers.groups.noAvailableGroupTitle'
    )
  });

  return <>
    {error ?? (
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="available_groups_select">
          {ts('page.groups.index.group')}
        </InputLabel>
        <Select
          value={selectedGroup?.code ?? ''}
          onChange={e =>
            setSelectedGroup(
              rows.find(g => g.code === e.target.value) ?? null
            )
          }
          label={ts('page.groups.index.group')}
          data-testid={TestIds.pages.serviceProfile.selectGroupField}
          MenuProps={{
            PopoverClasses: {
              root: classes.selectModal
            }
          }}
          disabled={loading}
          inputProps={{ id: 'available_groups_select' }}
        >
          {rows.map(r => (
            <MenuItem key={r.code} value={r.code}>
              {r.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
    <Box className={classes.buttons}>
      <Button type="reset" onClick={onCancel} color='inherit'>
        <FormattedMessage id="common.action.cancel" />
      </Button>
      {!isEmpty && !loadingError && (
        <Button
          color="primary"
          type="submit"
          onClick={onAdd}
          disabled={loading || !selectedGroup}
          data-testid={TestIds.pages.serviceProfile.submitSelectedGroupButton}
        >
          <FormattedMessage id="common.action.add" />
        </Button>
      )}
    </Box>
  </>;
};

export default TableServicesAvailableGroups;
