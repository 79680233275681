import {
  Button,
  CardActions,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ListItemProps } from '@mui/material/ListItem';
import { ButtonProps } from '@mui/material/Button';
import { Link, generatePath, useParams } from 'react-router-dom';
import { ArrowForward as IconArrowForward } from '@mui/icons-material';
import * as React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { GetFellowAdministrators } from '../../Apollo';
import { PrivateRoutes } from 'Router/Routes';
import { PersonAvatar } from 'Theme/components/Avatar';
import PaperTitle from 'Theme/components/PaperTitle';
import Skeleton from 'Theme/components/Skeleton';
import TestIds from 'Tests/TestIds';
import useNotification from 'Hooks/useNotification';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column'
  },
  cardActions: {
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  linkTo: {
    float: 'right',
    padding: theme.spacing(1)
  }
}));

interface Props {}

function Administrators(props: Props) {
  const { accountCode } = useParams<{ accountCode: string }>();
  const classes = useStyles();
  const { onError } = useNotification();

  const { data, loading } = useQuery<GetFellowAdministratorsData>(
    GetFellowAdministrators,
    {
      variables: {
        accountCode: accountCode === 'all' ? null : accountCode,
        first: 3
      },
      onError,
      fetchPolicy: 'cache-and-network'
    }
  );

  return (
    <Paper classes={{ root: classes.root }} data-testid={TestIds.pages.dashboard.administrators.card}>
      <PaperTitle
        variant="subtitle1"
        title={<FormattedMessage id="page.dashBoard.index.administrators" />}
      />

      {/*
       * The wrapping <div> and its style is a hack for the child <Wait> component
       * to be properly sized, which is an issue.
       */}
      <div className={classes.content}>
        {loading && (
          <List>
            {Array.from({ length: 3 }).map((_, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemAvatar>
                    <Skeleton variant="circle" width={40} height={40} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Skeleton
                        component="span"
                        variant="rectangular"
                        width="20%"
                      />
                    }
                    secondary={
                      <Skeleton component="span" variant="text" width="40%" />
                    }
                  />
                </ListItem>
                {index !== 2 && <Divider variant="inset" />}
              </React.Fragment>
            ))}
          </List>
        )}
        {data !== undefined && (
          <>
            <List>
              {data.fellowAdministrators.edges.map(
                (adm: { node: IPerson }, index: number) => (
                  <li key={index}>
                    <ListItem
                      component={React.forwardRef<Link, ListItemProps>(
                        ({ className, children }, ref) => (
                          <Link
                            ref={ref}
                            to={generatePath(PrivateRoutes.usersProfile.path, {
                              accountCode: accountCode,
                              immutableId: adm.node.immutableId
                            })}
                            className={className}
                          >
                            {children}
                          </Link>
                        )
                      )}
                    >
                      <ListItemAvatar>
                        <PersonAvatar component="span" person={adm.node} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={`${
                          adm.node.firstName ?? ''
                        } ${
                          adm.node.lastName ?? ''
                        }`}
                        secondary={adm.node.email}
                      />
                    </ListItem>
                    <Divider variant="inset" />
                  </li>
                )
              )}
            </List>
            <CardActions classes={{ root: classes.cardActions }}>
              <Button
                color="primary"
                className={classes.linkTo}
                component={React.forwardRef<Link, ButtonProps>(
                  ({ className, children }, ref) => (
                    <Link
                      ref={ref}
                      data-testid={TestIds.pages.dashboard.administrators.link}
                      className={className}
                      to={generatePath(PrivateRoutes.administrators.path, {
                        accountCode: accountCode
                      })}
                    >
                      {children}
                    </Link>
                  )
                )}
              >
                <FormattedMessage
                  id="page.dashBoard.index.totalAdministrators"
                  values={{ count: data.fellowAdministrators.totalCount }}
                />
                <IconArrowForward />
              </Button>
            </CardActions>
          </>
        )}
      </div>
    </Paper>
  );
}

export default Administrators;
