import { gql } from '@apollo/client';

export const DomainFragment = gql`
  fragment Domain on Domain {
    name
    validated
    validationMode
    creationDate
    validationCodeExpirationDate
    validationDate
    validationCode
  }
`;