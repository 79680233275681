import { gql } from '@apollo/client';
import { AvatarFragment } from '../../fragments/person/Avatar';

export const GetLightPersonAccessRightRequests = gql`
  query GetLightPersonAccessRightRequests($personAccessRightFilter: PersonAccessRightFilter!, $page: PageInput ) {
    lightPersonAccessRightRequests(
        personAccessRightFilter: $personAccessRightFilter
        page: $page
    ){
      edges {
        node {
          countAccessRequests
          personProfile {
            immutableId
            avatar {
              ...Avatar
            }
            firstName
            lastName
            email
          }
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
  }
    }
  ${AvatarFragment}
`;
