import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GetPersonGroup } from '../../Apollo';
import useNotification from 'Hooks/useNotification';
import ProfilePageSkeleton from 'Theme/components/ProfilePageSkeleton';
import ErrorMsg from 'Components/Shared/ErrorMsg/ErrorMsg';
import PersonGroupProfile from './PersonGroupProfile';

function PersonGroupProfileContainer() {
  const { groupCode } = useParams<{
    accountCode: string;
    groupCode: string;
  }>();

  const { onError } = useNotification();

  const { data, loading, error } = useQuery<GetPersonGroupData>(
    GetPersonGroup,
    {
      onError,
      variables: {
        code: groupCode
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  if (loading) return <ProfilePageSkeleton shape="rectangular" />;
  if (error || !data) return <ErrorMsg />;

  return <PersonGroupProfile group={data?.personGroup} />;
}
export default PersonGroupProfileContainer;
