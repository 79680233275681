import { QueryResult, useQuery } from '@apollo/client';
import { Typography, Button } from '@mui/material';
import { GetServiceSubscriptions } from 'Apollo';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import { PrivateRoutes } from 'Router/Routes';
import { DataRow } from 'Theme/components/DataRow';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from '@mui/lab/Skeleton/Skeleton';

interface Props {
  account: IAccount;
}

function SectionServices(props: Readonly<Props>) {
  const { account } = props;
  const ts = useTranslate();
  const { onError } = useNotification();

  const { data, loading }: QueryResult<GetServiceSubscriptionsData> = useQuery(
    GetServiceSubscriptions,
    {
      variables: {
        accountCode: account.code,
        apiKeyEligibleOnly: false
      },
      onError
    }
  );

  return (
    <DataRow
      label={ts('page.accountProfile.sections.services.servicesCatalog')}
      divider={true}
      action={
        <Button
          component={RouterLink}
          to={generatePath(PrivateRoutes.services.path, {
            accountCode: account.code
          })}
          variant="text"
          color="primary"
        >
          {ts('page.accountProfile.sections.services.seeCatalog')}
        </Button>
      }
    >
      {loading && (
        <Skeleton width={120} height={30} variant="rectangular" color="primary" />
      )}
      {!loading && (
        <Typography>{data?.serviceSubscriptions.totalCount}</Typography>
      )}
    </DataRow>
  );
}

export default SectionServices;
