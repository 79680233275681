import { gql } from '@apollo/client';
import { PersonFragment } from '../../fragments/person/Person';

export const GetAdministrators = gql`
  query GetAdministrators(
    $personIdentifier: String
    $first: Int
    $offset: Int
  ) {
    administrators(
      personIdentifier: $personIdentifier
      first: $first
      offset: $offset
    ) {
      edges {
        node {
          ...Person
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${PersonFragment}
`;
