import { gql } from '@apollo/client';

export const AccessRightCreationFragment = gql`
  fragment AccessRightCreation on AccessRightCreation {
    accountCode
    status
    error {
      code
      message
    }
    personIdentifier
    serviceCode
    targetAccountCode
  }
`;
