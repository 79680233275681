import { Button, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gql, ApolloError, useMutation } from '@apollo/client';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import ButtonLoading from 'Theme/components/ButtonLoading';
import TestIds from 'Tests/TestIds';
import { GetPersonsTotalCount } from 'Apollo/queries/person/GetPersonsTotalCount';
import useNotification from 'Hooks/useNotification';
import { GraphQLError } from 'graphql';

interface RemovePersonData {
  deletePersons: boolean;
}

interface RemovePersonVariables {
  personIds: string[] | null;
}

export const mutationRemoveUsers = gql`
  mutation DeletePersons($personIds: [String]) {
    deletePersons(personIdentifiers: $personIds)
  }
`;

export const useStyles = makeStyles(theme => ({
  button: {
    marginRight: 8
  },
  paper: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    maxWidth: 300,
    textAlign: 'center'
  },
  text: {
    marginBottom: theme.spacing(2)
  }
}));

interface Props {
  selectedUserIds: string[];
  selectAllUserEnabled: boolean;
  selectedUserCount: number;
  onCollapse: () => void;
  onReloadUsers: () => void;
}

const RemoveUsers = (props: Props) => {
  const classes = useStyles();
  const { onError, onSuccess } = useNotification({ domain: 'user' });
  const [pendingUsersCount, setPendingUsersCount] = React.useState(0);
  const { accountCode } = useParams<IUriParams>();
  const {
    selectedUserIds,
    selectAllUserEnabled,
    selectedUserCount,
    onCollapse,
    onReloadUsers
  } = props;

  const onCompleted = () => {
    onSuccess('page.users.action.delete.success', { count: selectedUserCount });
    onCollapse();
    onReloadUsers();
  };

  const onMutationError = (error: ApolloError) => {
    onError(error);
    onCollapse();

    if (error.graphQLErrors == null) {
      return;
    }

    /*
     * The delete users mutation might partially fail (and thus partially succeed).
     * In this situation, users should be reloaded as some of the selected users are gone.
     */
    const batchError = error.graphQLErrors
      .map(graphQLError => graphQLError.extensions)
      .find(apiError => apiError != null && apiError.code === 'E247');
    if (
      batchError &&
      batchError.innerErrors &&
      // If there are less errors than the number of users to delete,
      // then it's a partial success
      (batchError.innerErrors as Array<GraphQLError>).length < pendingUsersCount
    ) {
      onReloadUsers();
    }
  };

  const [onRemoveUsers, { loading }] = useMutation<
    RemovePersonData,
    RemovePersonVariables
  >(mutationRemoveUsers, {
    onError: onMutationError,
    onCompleted,
    refetchQueries: [
      {
        query: GetPersonsTotalCount,
        variables: {
          personFilter: {
            accountCode: accountCode === 'all' ? null : accountCode
          }
        }
      }
    ]
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    onRemoveUsers({
      variables: {
        personIds: selectAllUserEnabled ? null : selectedUserIds
      }
    });

    setPendingUsersCount(selectedUserCount);
  };

  return (
    <form onSubmit={onSubmit} onReset={onCollapse}>
      <Paper classes={{ root: classes.paper }}>
        <Typography variant="body2" classes={{ root: classes.text }}>
          <FormattedMessage
            id="page.users.action.delete.confirmation"
            values={{ count: selectedUserCount }}
          />
        </Typography>
        <Button
          type="reset"
          size="medium"
          color='inherit'
          classes={{ root: classes.button }}
          data-testid={TestIds.common.snackbar.action.cancel}
        >
          <FormattedMessage id="common.action.cancel" />
        </Button>
        <ButtonLoading
          type="submit"
          size="medium"
          color="primary"
          variant="contained"
          loading={loading}
        >
          <FormattedMessage id="common.action.delete" />
        </ButtonLoading>
      </Paper>
    </form>
  );
};

export default RemoveUsers;
