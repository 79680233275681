import ipFiltering from './ipFiltering.svg';
import arrow from './arrow.svg';
import step1Connect from './step1-connect.png';
import step2GoToDirectories from './step2-directories.png';
import step3Agree from './step3-agree.png';
import step4SigninGoogle from './step4-signinWithGoogle.png';
import step6Logout from './step5-2-logout.png';
import step7NewIdentity from './step6-newIdentity.png';
import step5Confirm from './step5-confirm.png';
import userActivationStep1 from './userActivation-step1.png';
import userActivationStep2 from './userActivation-step2.png';
import userActivationStep3 from './userActivation-step3.png';
import userActivationStep4 from './userActivation-step4.png';
import searchNo from './Search_No.svg';
import cegidCorners from './Cegid_Corners.svg';
import cegid from './cegid.svg';
import google from './Google__G__Logo.svg';
import microsoft from './Microsoft_logo.svg';
import apple from './Apple_logo.svg';
import letterBox from './letterBox.svg';
import onboarding1 from './onboardingScreen2.png';
import onboarding2 from './onboardingScreen3.png';
import flyingAirballoonCloud from './FlyingAirballoonCloud.svg';
import wizardIllustration from './WizardIllustration.svg';
import mailIcon from './mailIcon.png';
import manSearching from './manSearching.svg';

export type TImg = {
  alt: string;
  src: string;
};

export type TImgKeys =
  | 'flyingAirballoonCloud'
  | 'userActivationStep1'
  | 'userActivationStep2'
  | 'userActivationStep3'
  | 'userActivationStep4'
  | 'step1Connect'
  | 'step2GoToDirectories'
  | 'step3Agree'
  | 'step4SigninGoogle'
  | 'step5Confirm'
  | 'step6Logout'
  | 'step7NewIdentity'
  | 'arrow'
  | 'ipFiltering'
  | 'cegidCorners'
  | 'cegid'
  | 'google'
  | 'microsoft'
  | 'apple'
  | 'onboarding1'
  | 'onboarding2'
  | 'mailIcon'
  | 'searchNo'
  | 'wizardIllustration'
  | 'letterBox'
  | 'manSearching';

export const Imgs: Record<TImgKeys, TImg> = {
  manSearching: {
    alt: 'No group found',
    src: manSearching
  },
  wizardIllustration: {
    alt: 'Create User illustatrion',
    src: wizardIllustration
  },
  flyingAirballoonCloud: {
    alt: 'flying Airballoon Cloud illustration',
    src: flyingAirballoonCloud
  },
  userActivationStep1: {
    alt: 'You receive an activation email, click on activation link',
    src: userActivationStep1
  },
  userActivationStep2: {
    alt: 'You are redirected to an activation page',
    src: userActivationStep2
  },
  userActivationStep3: {
    alt:
      'You can activate your Cegid account on click on "Continue with Google"',
    src: userActivationStep3
  },
  userActivationStep4: {
    alt: 'You should be redirected Cegid Profile Manager interface',
    src: userActivationStep4
  },
  step1Connect: {
    alt: 'Connect to you Cegid Profile',
    src: step1Connect
  },
  step2GoToDirectories: {
    alt: 'Go to directories bloc',
    src: step2GoToDirectories
  },
  step3Agree: {
    alt: 'on clic on Agree button, you will be redirected to a Google page',
    src: step3Agree
  },
  step4SigninGoogle: {
    alt: 'signin with your Google account, enter your email',
    src: step4SigninGoogle
  },
  step5Confirm: {
    alt: `Before merge your account, you have your current information on the left, and the new ones on the rights. On click on "Merge" button, your account will be merged`,
    src: step5Confirm
  },
  step6Logout: {
    alt: `You are redirected after logout, you should enter your email (new one)`,
    src: step6Logout
  },
  step7NewIdentity: {
    alt: `You are connected with your Google identity`,
    src: step7NewIdentity
  },
  arrow: {
    alt: 'arrow',
    src: arrow
  },
  ipFiltering: {
    alt: 'Ip filtering',
    src: ipFiltering
  },
  cegidCorners: {
    alt: 'Cegid Corners',
    src: cegidCorners
  },
  cegid: {
    alt: 'Cegid logo',
    src: cegid
  },
  google: {
    alt: 'Google logo',
    src: google
  },
  microsoft: {
    alt: 'Microsoft logo',
    src: microsoft
  },
  apple: {
    alt: 'Apple logo',
    src: apple
  },
  onboarding1: {
    alt: 'co-workers shaking hands',
    src: onboarding1
  },
  onboarding2: {
    alt: 'co-workers shaking hands',
    src: onboarding2
  },
  letterBox: {
    alt: '',
    src: letterBox
  },
  searchNo: {
    alt: 'no result found',
    src: searchNo
  },
  mailIcon: {
    alt: 'mailIcon',
    src: mailIcon
  }
};
