import { generatePath, useParams } from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';
import useTranslate from 'Hooks/useTranslate';
import { PersonContext } from 'Context/CurrentPerson';
import { userFullName } from 'Tools';
import { useContext } from 'react';
import { PageError } from 'Page/PageError';
import useTabsUser from 'Hooks/useTabsUser';
import ProfilePageTemplate from 'Components/Shared/ProfileTemplate/ProfilePageTemplate';

interface Props {
  person: any;
}

function UsersProfile({ person }: Readonly<Props>) {
  const ts = useTranslate();
  const { currentPerson, currentPersonRoles } = useContext(PersonContext);
  const tabs = useTabsUser({ person });

  const { accountCode, immutableId } = useParams<{
    accountCode: string;
    immutableId: string;
  }>();
  const basePath = generatePath(PrivateRoutes.usersProfile.path, {
    accountCode,
    immutableId
  });

  if (
    currentPerson.immutableId !== immutableId &&
    !currentPersonRoles.hasAccessToAccounts &&
    !currentPersonRoles.isAccountAdmin
  ) {
    return (
      <PageError
        title={ts('error.type.E403')}
        textPrimary={ts('error.sentence.E403')}
        accessDenied={true}
      />
    );
  }

  return (
    <ProfilePageTemplate
      title={
        currentPerson?.immutableId !== person?.immutableId
          ? ts('page.userProfile.title', {
              fullName: userFullName(person.firstName, person.lastName)
            })
          : ts('page.userProfile.yourProfile')
      }
      basePath={basePath}
      tabs={tabs}
    />
  );
}

export default UsersProfile;
