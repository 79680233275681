const mediaQueries = {
    xsDevice: '(max-width:300px)',
    mobile: '(max-width:740px)',
    mobilePortrait: '(max-width:600px) and (orientation: portrait)',
    portrait: '(orientation: portrait)',
};

export default mediaQueries;


