import { Box, Button, Drawer, Grid, Typography } from '@mui/material';
import { MIN_SEARCH_LENGTH } from 'Theme/config';
import { useState } from 'react';
import { useStyles } from './AddServicesDrawer.styles';
import ExpandedSearch from 'Components/Shared/ExpandedSearch';
import TestIds from 'Tests/TestIds';
import useTranslate from 'Hooks/useTranslate';
import AvailableServicesForAccessRightContainer from './AvailableServicesForAccessRight.container';

interface Props {
  person: IPerson;
  isAdminMode: boolean;
  onCancel: () => void;
  onUpdate: (value: string[] | null) => void;
  selectedIds: string[] | null;
  isCreatingSubscription: boolean;
  onCreate: () => void;
  isMobileP?: boolean;
}

function AddServicesDrawer(props: Props) {
  const {
    person,
    isAdminMode,
    onCancel,
    isCreatingSubscription,
    onCreate,
    onUpdate,
    selectedIds,
    isMobileP = false
  } = props;
  const classes = useStyles();
  const ts = useTranslate();
  const [servicesFilter, setServicesFilter] = useState<string | null>(null);

  function onChangeServicesSearch(value: string) {
    const newFilter = value.trim();
    if (newFilter && newFilter.length < MIN_SEARCH_LENGTH) {
      return;
    }
    setServicesFilter(newFilter);
  }

  return (
    <Drawer
      anchor="bottom"
      variant="temporary"
      open={true}
      classes={{ paper: classes.drawer }}
    >
      <Box
        className={classes.headerLeft}
        mb={2}
        data-testid={
          TestIds.pages.userProfile.tabServices.addServices.drawer.head
        }
      >
        <Typography
          variant="h5"
          data-testid={
            TestIds.pages.userProfile.tabServices.addServices.drawer.title
          }
        >
          {ts('page.userProfile.services.add')}
        </Typography>
      </Box>
      <Grid item>
        <ExpandedSearch
          onChange={onChangeServicesSearch}
          testId={
            TestIds.pages.userProfile.tabServices.addServices.drawer.search
          }
          placeholder={ts('component.searchBars.placeholder.services')}
        />
      </Grid>
      <Box>
        <AvailableServicesForAccessRightContainer
          servicesFilter={servicesFilter}
          person={person}
          isAdminMode={isAdminMode}
          onUpdate={onUpdate}
          selectedIds={selectedIds}
          isMobileP={isMobileP}
        />
      </Box>
      <Box className={classes.drawerButtons}>
        <Button onClick={() => onCancel()} color='inherit'>
          {ts('common.action.cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onCreate}
          disabled={isCreatingSubscription}
          data-testid={
            TestIds.pages.userProfile.tabServices.addServices.drawer.addButton
          }
        >
          {ts('common.action.add')}
        </Button>
      </Box>
    </Drawer>
  );
}

export default AddServicesDrawer;
