import { TProfileManagerMessagesKeys } from "../Languages/TProfileManagerMessages";

export const organizedElementsByDate = <T extends object>(
    elements: T[],
    key: keyof T
) => {
    let elementsWithTag: Partial<Record<
        TProfileManagerMessagesKeys,
        T[] | null
    >> = {
        'common.dates.today': null,
        'common.dates.yesterday': null,
        'common.dates.previously': null
    };
    const todayDate = new Date().toDateString();
    const yesterdayDate = (new Date(new Date().getTime() - 86400000)).toDateString();

    elements.forEach(obj => {
        const objDateString = (
            new Date((obj[key] as unknown) as string)
        ).toDateString();

        const isToday = todayDate === objDateString;
        const isYesterday = yesterdayDate === objDateString;


        if (isToday) {
            elementsWithTag['common.dates.today'] = [
                ...(elementsWithTag['common.dates.today'] || []),
                obj
            ];
        }
        else if (isYesterday) {
            elementsWithTag['common.dates.yesterday'] = [
                ...(elementsWithTag['common.dates.yesterday'] || []),
                obj
            ];
        }
        else {
            elementsWithTag['common.dates.previously'] = [
                ...(elementsWithTag['common.dates.previously'] || []),
                obj
            ];
        }
    });
    return elementsWithTag;
};

export function isToday(date: Date) {
    return date.toDateString() === new Date().toDateString();
}

export function wasYesterday(date: Date) {
    const yesterdayInMilliseconds = new Date(new Date().getTime() - 86400000);
    return date.toDateString() === yesterdayInMilliseconds.toDateString();
}

