import { useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { PersonAccessRightStatus } from 'Apollo/fragments/person/PersonAccessRight';
import { GetPersonAccessRightRequests } from '../../Apollo/queries/accessRights/GetPersonAccessRightRequests';
import { CARD_PER_PAGE_DEFAULT } from 'Theme/config';
import AccessRightsCards from './AccessRightsCards';
import { ResultSentence } from 'Components/Shared/SearchBars/SearchByUser/ResultSentence';
import TestIds from 'Tests/TestIds';
import useTranslate from 'Hooks/useTranslate';
import EmptyState from 'Components/Shared/EmptyState';
import { Imgs } from 'Assets';
import { useStyles } from './AccessRightsCards.styles';

interface Props {
  selectedAccountCode: string;
  currentSearch: string | undefined;
  selectedUserId: string | undefined;
}

const AccessRightsCardsContainer = (props: Readonly<Props>) => {
  const { selectedAccountCode, currentSearch, selectedUserId } = props;
  const ts = useTranslate();
  const classes = useStyles();

  const [pageOffset, setPageOffset] = useState<number>(0);

  let accCode = selectedAccountCode !== 'default' ? selectedAccountCode : '';

  const initialVariables = {
    accountCode: accCode,
    immutableId: selectedUserId,
    infoPerson: currentSearch,
    status: PersonAccessRightStatus.ToValidate,
    page: { first: CARD_PER_PAGE_DEFAULT, offset: 0 }
  };

  let { data, loading: loadingQuery, fetchMore, refetch } = useQuery<
    IAdministratedPersonAccessRightsData,
    IAdministratedPersonAccessRightsVars
  >(GetPersonAccessRightRequests, {
    variables: { ...initialVariables },
    fetchPolicy: 'cache-and-network'
  });

  const onNextPage = () => {
    const newOffset = pageOffset + CARD_PER_PAGE_DEFAULT;
    fetchMore({
      variables: {
        page: {
          offset: newOffset,
          first: CARD_PER_PAGE_DEFAULT
        }
      }
    });
    setPageOffset(newOffset);
  };

  const handleRefetch = () => {
    refetch({
      ...initialVariables
    });
  };

  const accessRightRequests =
    data?.personAccessRightRequests.edges?.map(({ node }, i) => node) ?? [];

  let isUsingSearch =
    selectedUserId !== undefined || currentSearch !== undefined;

  return (
    <Box pt={4} mb={2}>
      <ResultSentence
        loading={loadingQuery}
        nbResult={
          data?.personAccessRightRequests.totalCount ??
          accessRightRequests.length
        }
      />
      {!loadingQuery && accessRightRequests.length < 1 && (
        <Grid
          container
          justifyContent="center"
          direction="column"
          data-testid={
            isUsingSearch
              ? TestIds.pages.accessRightsRequests.noMatchingPendingRequest
              : TestIds.pages.accessRightsRequests.noPendingRequest
          }
        >
          <Grid item className={classes.boxMargin}>
            {isUsingSearch ? (
              <img src={Imgs.searchNo.src} alt={Imgs.searchNo.alt} />
            ) : (
              <img
                src={Imgs.letterBox.src}
                alt={Imgs.letterBox.alt}
                data-testid={TestIds.img.letterBox}
              />
            )}
          </Grid>
          <Grid item>
            <EmptyState
              primary={
                isUsingSearch
                  ? ts(
                      'component.searchBars.availabledResult.noResultPrimary'
                    )
                  : ts('page.accessRights.empty.title')
              }
              secondary={
                isUsingSearch
                  ? ts(
                      'component.searchBars.availabledResult.noResultSecondary'
                    )
                  : ts('page.accessRights.empty.subtitle')
              }
            />
          </Grid>
        </Grid>
      )}
      <AccessRightsCards
        key={selectedAccountCode}
        loading={loadingQuery}
        data={accessRightRequests}
        hasNextPage={data?.personAccessRightRequests.pageInfo?.hasNextPage}
        onNextPage={onNextPage}
        onRefetch={handleRefetch}
      />
    </Box>
  );
};

export default AccessRightsCardsContainer;
