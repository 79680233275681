import { gql } from '@apollo/client';
import { PersonWithAccountFragment } from '../../fragments/person/PersonWithAccount';

export const GetMe = gql`
  query GetMe {
    me {
      ...PersonWithAccount
    }
  }
  ${PersonWithAccountFragment}
`;
