import { Helmet } from 'react-helmet';
import AdministratorsTable from './AdministratorsTable';
import useTranslate from 'Hooks/useTranslate';
import Container from 'Theme/components/Container';

interface Props {}

function PageAdministrators(props: Props) {
  const ts = useTranslate();

  return (
    <>
      <Helmet title={ts('page.administrators.title')} />
      <Container>
        <AdministratorsTable />
      </Container>
    </>
  );
}

export default PageAdministrators;
