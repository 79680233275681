import { useContext, useState } from 'react';
import { Button, Paper, Tooltip, Typography } from '@mui/material';
import { Add as IconAdd, Group as IconGroup } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { generatePath, Link } from 'react-router-dom';
import { GetPersonGroups, PersonGroupsOrder } from '../../../Apollo';
import { PrivateRoutes } from 'Router/Routes';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { TableCollection } from 'Theme/components/TableCollection';
import EmptyState from 'Components/Shared/EmptyState';
import { useStyles } from './PersonGroupsTable.styles';
import useTranslate from 'Hooks/useTranslate';
import { PersonContext } from 'Context/CurrentPerson';
import useNotification from 'Hooks/useNotification';
import TestIds from 'Tests/TestIds';
import ContextualHelp from 'Components/Shared/ContextualHelp';
import { useColumnsGroups } from 'Hooks/useColumnsGroups';
import { calculateNewPage } from 'Tools/calculateNewPage';
import { LocalContext } from 'Context/Local.context';

function PersonGroupsTable() {
  const { currentAccount } = useContext(LocalContext);

  const classes = useStyles();
  const ts = useTranslate();
  const { currentPersonRoles } = useContext(PersonContext);
  const { onError } = useNotification();

  const columns = useColumnsGroups({});

  const [orderBy, setOrderBy] = useState<PersonGroupsOrder>(
    PersonGroupsOrder.AccountNameAsc
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);

  const variables = {
    accountCode: currentAccount?.code ?? null,
    orderBy,
    first: rowsPerPage,
    offset: currentPage * rowsPerPage
  };

  const { loading: fetching, data } = useQuery<GetPersonGroupsData>(
    GetPersonGroups,
    {
      onError,
      variables,
      fetchPolicy: 'cache-and-network'
    }
  );

  let loading = fetching;
  let rows: Array<{ node: IPersonGroup }> = [];
  let totalCount = rowsPerPage;

  if (data?.personGroups) {
    rows = data.personGroups.edges;
    totalCount = data.personGroups.totalCount;
  } else {
    loading = true;
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.toolbar}>
        <div className={classes.toolbarLeft}>
            <Typography sx={{display: {xs: 'none', sm: "block"}}} variant="h5">{ts('page.groups.title')}</Typography>
          <ContextualHelp content={ts('page.groups.help')} />
        </div>
        <div className={classes.toolbarRight}>
          {currentPersonRoles.isAccountAdmin && (
            <Tooltip title={ts('page.groups.addGroupTooltip')}>
              <Button
                component={Link}
                to={generatePath(PrivateRoutes.personGroupCreate.path, {
                  accountCode : currentAccount?.code ?? 'all'
                })}
                startIcon={<IconAdd />}
                color="primary"
                data-testid={TestIds.pages.addGroup.addButton}
                style={{ marginLeft: 'auto' }}
              >
                {ts('menu.addGroup')}
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
      <TableCollection
        loading={loading}
        columns={columns}
        rows={rows}
        page={currentPage}
        selectedIds={[]}
        isSelectionHidden={true}
        maxSelected={0}
        getRowId={({ node: personGroup }: { node: IPersonGroup }) =>
          personGroup.code
        }
        orderBy={orderBy}
        onChangeOrderBy={(orderBy: string) =>
          setOrderBy(orderBy as PersonGroupsOrder)
        }
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(newRowsPerPage: number) => {
          setRowsPerPage(newRowsPerPage);
          setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
        }}
        onChangePage={(newPage: number) => setCurrentPage(newPage)}
        onUnselect={() => {}}
        onSelect={() => {}}
        totalCount={totalCount}
        emptyStateData={
          <EmptyState
            icon={<IconGroup />}
            primary={ts('page.groups.emptyState.title')}
            secondary={
              currentPersonRoles.isAccountAdmin
                ? ts('page.groups.emptyState.description')
                : ts('page.groups.emptyState.superReaderDescription')
            }
            actions={
              currentPersonRoles.isAccountAdmin ? (
                <Button
                  component={Link}
                  to={generatePath(PrivateRoutes.personGroupCreate.path, {
                    accountCode: currentAccount?.code ?? 'all'
                  })}
                  color="primary"
                  startIcon={<IconAdd />}
                  variant="contained"
                  data-testid={TestIds.pages.groups.addGroupButton}
                >
                  {ts('menu.addGroup')}
                </Button>
              ) : null
            }
          />
        }
      />
    </Paper>
  );
}

export default PersonGroupsTable;
