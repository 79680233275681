import { userFullName } from "./userFullName";


function accountLabel( acc: IAccount ){

    let part1: string = "";
    let part2: string = "";
    let part3: string = "";

    let noZipCode = acc.zipCode === null;
    let noCity = acc.city === null;
    let noName = acc.name === null;
    let noCode = acc.code === null;

    let addressIsNull = noZipCode && noCity;
    let addressIsPartiallyNull = noZipCode || noCity;


    if(!noName && !addressIsNull && !addressIsPartiallyNull && !noCode) {
        part1 = acc.name!;
        part2 = ' - ' + acc.zipCode + ' ' + acc.city;
        part3 = ' / ' + acc.code;
        return part1 +  part2 +  part3
    }

    if(noName && !noCode){

        let addressToRetreive = userFullName(acc.zipCode, acc.city)
       
        if(addressIsNull){
            return acc.code
        } else {
            return acc.code + " - " + addressToRetreive
        }
    }

    if(addressIsNull && !noName  && !noCode){
        return acc.name + ' / ' + acc.code
    }
    
    if(!noName && !addressIsNull && addressIsPartiallyNull && !noCode) {
        let part1 = acc.name;
        let part2 = userFullName(acc.zipCode, acc.city);
        let part3 = acc.code
        return part1 + ' - ' + part2 + ' / ' + part3
    }

}

export default accountLabel;