import { matchPath, useLocation } from 'react-router';
import joinURL from 'url-join';

type Props = {
  tabs: any[];
  basePath?: string;
};

const useActiveTabSelection = ({ tabs, basePath }: Props) => {
  const location = useLocation();

  const index = tabs.findIndex(({ path }) => {
    return Boolean(
      matchPath(location.pathname, {
        path: basePath ? joinURL(basePath, path) : path,
        exact: true
      })
    );
  });

  return index === -1 ? 0 : index;
};

export default useActiveTabSelection;
