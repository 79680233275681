import { gql } from '@apollo/client';
import { PersonEmailChangeFragment } from '../../fragments/person/PersonEmailChange';

export const GetEmailChangeRequest = gql`
  query GetEmailChangeRequest($personIdentifier: String) {
    emailChangeRequest(personIdentifier: $personIdentifier) {
      ...PersonEmailChange
    }
  }
  ${PersonEmailChangeFragment}
`;
