import { List, ListItem, ListItemText, Divider, Skeleton } from '@mui/material';
import { useStyles } from './AccountsList.styles';

export const AccountsListSkeleton = () => {
  const classes = useStyles();

  return (
    <List data-testid="loading-accountlist">
      <ListItem>
        <ListItemText
          primary={<Skeleton variant="rectangular" width="20%" />}
        />
      </ListItem>
      <Divider classes={{ root: classes.divider }} />
      <ListItem>
        <ListItemText
          primary={<Skeleton variant="rectangular" width="30%" />}
        />
      </ListItem>
    </List>
  );
};
