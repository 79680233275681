import { useQuery } from '@apollo/client';
import useNotification from 'Hooks/useNotification';
import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  GetPersonsAddableToServiceSubscriptionOwner,
  PersonOrder
} from '../../Apollo';
import { TServiceMemberList } from '../../Models';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { ErrorMsg } from '../Shared';
import TableServiceOwnerCandidates from './TableServiceOwnerCandidates';
import { calculateNewPage } from 'Tools/calculateNewPage';

type Props = {
  selectedIds: string[];
  setSelectedIds(ids: string[] | null): void;
  search: string;
};

const TableServiceOwnersCandidatesContainer = ({
  search,
  selectedIds,
  setSelectedIds
}: Props) => {
  const { onError } = useNotification();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);

  const handleChangeRowPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
  };

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const { params: serviceSubscriptionIdentifier } = useRouteMatch<
    IServiceSubscriptionIdentifier
  >();

  const { data, loading, error } = useQuery<{
    personsAddableToServiceSubscriptionOwner: TServiceMemberList;
  }>(GetPersonsAddableToServiceSubscriptionOwner, {
    onError,
    variables: {
      page: {
        first: rowsPerPage,
        offset: currentPage * rowsPerPage
      },
      orderBy: PersonOrder.NameAsc,
      searchTerm: search,
      serviceSubscriptionIdentifier
    }
  });

  if (error) return <ErrorMsg />;

  const rows =
    data?.personsAddableToServiceSubscriptionOwner.edges.map(o => o.node) ?? [];

  const totalCount =
    data?.personsAddableToServiceSubscriptionOwner.totalCount ??
    ROW_PER_PAGE_DEFAULT;

  return (
    <TableServiceOwnerCandidates
      key={totalCount}
      totalCount={totalCount}
      rows={rows}
      loading={loading}
      page={currentPage}
      rowsPerPage={rowsPerPage}
      onChangePage={handleChangePage}
      onChangeRowPerPage={handleChangeRowPerPage}
      onUpdateSelection={setSelectedIds}
      selected={selectedIds}
    />
  );
};

export default TableServiceOwnersCandidatesContainer;
