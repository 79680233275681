import { gql } from '@apollo/client';

export const PersonGroupMembershipOperationFragment = gql`
  fragment PersonGroupMembershipOperation on PersonGroupMembershipOperation {
    personIdentifier
    status
    error {
      code
      message
    }
    groupCode
  }
`;
