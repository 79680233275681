import ListItemText, {
  ListItemTextProps
} from '@mui/material/ListItemText';
import * as React from 'react';

interface Props extends ListItemTextProps {}

function NavigationListItemText(props: Props) {
  return (
    <ListItemText
      primaryTypographyProps={{ variant: 'body2', color: 'inherit' }}
      {...props}
    />
  );
}

export default NavigationListItemText;
