import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: theme.spacing(2)
      },
      addUserButtonIcon: {
        marginRight: theme.spacing(1)
      },
      toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2)
      },
      toolbarLeft: {
        display: 'flex',
        alignItems: 'center',
      },
      toolbarRight: {
        display: 'flex',
      }
}))
