import { IconButton, Tooltip } from '@mui/material';
import { Create as IconCreate } from '@mui/icons-material';
import useTranslate from 'Hooks/useTranslate';
import React from 'react';
import EditGroupDialog from './EditGroupDialog';

interface Props {
  iconButtonId?: string;
  group: IPersonGroup;
  field: 'label' | 'description';
}

const EditGroupToggle = (props: Props) => {
  const ts = useTranslate();
  const { iconButtonId, group, field } = props;

  const[isDialogOpen, setIsDialogOpen] = React.useState(false);
    
    return <>
      <Tooltip
        title={ts("common.action.edit")}
        placement="bottom"
      >
        <IconButton
          data-testid={iconButtonId}
          onClick={()=>setIsDialogOpen(!isDialogOpen)}
          size="large">
          <IconCreate />
        </IconButton>
      </Tooltip>
      {isDialogOpen && (
        <EditGroupDialog
          group={group}
          field={field}
          onClose={()=>setIsDialogOpen(false)}
        />
      )}
    </>;
  }


export default EditGroupToggle;
