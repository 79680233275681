import { gql } from '@apollo/client';
import { AccountFragment } from './Account';

export const AccountWithServicesSubscriptionsCountFragment = gql`
  fragment AccountsWithServicesSubscriptionsCount on AccountsWithServicesSubscriptionsCount {
    account {
      ...Account
    }
    subscriptionCount
  }
  ${AccountFragment}
`;
