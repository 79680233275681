import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import ApikeyProfile from './ApikeyProfile';
import ProfilePageSkeleton from 'Theme/components/ProfilePageSkeleton';
import { GetApiKey } from 'Apollo/queries/apikey/GetApiKey';
import { ErrorMsg } from 'Components/Shared';

interface Props {}

const ApiKeyProfileContainer = (props: Props) => {
  const { apikeyUuid } = useParams<IUriParams>();

  const variables = {
    apiKeyId: apikeyUuid
  };

  const { data, loading, error } = useQuery<GetApiKeyData>(GetApiKey, {
    variables
  });

  if (!loading && error) return <ErrorMsg />;

  if (loading ?? !data) return <ProfilePageSkeleton shape="circle" />;

  return <ApikeyProfile apiKey={data.apiKey} />;
};

export default ApiKeyProfileContainer;
