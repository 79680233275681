import { Grid } from '@mui/material';
import Skeleton from 'Theme/components/Skeleton';

const FormUsersCreationSkeleton = () => {
  const mockRows = Array.from(Array(2).keys());
  return (
    <Grid container direction="column" spacing={6} role="progressbar">
      <Grid item container justifyContent="center">
        <Skeleton width={150} height={150} variant="circle" />
      </Grid>
      {mockRows.map(i => {
        return (
          <Grid container justifyContent="center" item key={i}>
            <Skeleton width={'80%'} height={60} variant="rectangular" />
          </Grid>
        );
      })}
      <Grid item container justifyContent="center" spacing={2}>
        <Grid item>
          <Skeleton width={200} height={60} variant="rectangular" />
        </Grid>
        <Grid item>
          <Skeleton width={200} height={60} variant="rectangular" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormUsersCreationSkeleton;
