import { Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { useStyles } from './SuperUserBanner.styles';
import { mdiShieldCrown } from '@mdi/js';
import SvgIcon from 'Theme/components/SvgIcon/SvgIcon';
import colors from 'Theme/constants/colors';
import useTranslate from 'Hooks/useTranslate';

interface Props {}

function SuperUserBanner(props: Props) {
  const classes = useStyles();
  const ts = useTranslate();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={clsx(classes.banner, classes.requestBanner)}
    >
      <Grid item className={classes.bannerText}>
        <div className={classes.svgIcon}>
          <SvgIcon path={mdiShieldCrown} fill={colors.white} />
        </div>
        <Typography variant="body2" color="inherit">
          {ts('common.person.role.superAdmSentence')}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SuperUserBanner;
