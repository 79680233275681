import { FormattedMessage } from 'react-intl';
import UserNameCell from 'Components/Shared/UserNameCell/UserNameCell';
import UserCellSkeleton from 'Theme/components/UserCellSkeleton';
import Skeleton from 'Theme/components/Skeleton';
import { useParams } from 'react-router-dom';
import { PersonOrder } from 'Apollo';
import { TableCollectionColumn } from 'Components/Shared/Table';
import RemoveServiceOwnerConfirmation from 'Components/TableServiceOwners/RemoveServiceOwnerConfirmation';

export const useColumnsLightOwnerProfile = (): TableCollectionColumn<
  ILightPersonProfile
>[] => {
  const { accountCode, serviceCode, targetAccountCode } = useParams<
    IServiceSubscriptionIdentifier
  >();

  const variables = {
    serviceSubscriptionIdentifier: {
      accountCode: accountCode,
      serviceCode: serviceCode,
      targetAccountCode: targetAccountCode
    }
  };

  return [
    {
      key: 'owners',
      width: 0.45,
      orderBy: {
        asc: PersonOrder.NameAsc,
        desc: PersonOrder.NameDesc
      },
      hidden: false,
      renderHead: () => (
        <FormattedMessage id="page.serviceProfile.owners.owner" />
      ),
      renderSkeleton: () => <UserCellSkeleton />,
      renderCell: ({ row }: { row: ILightPersonProfile }) => (
        <UserNameCell person={row} />
      )
    },
    {
      key: 'email',
      width: 0.45,
      hidden: false,
      renderHead: () => <FormattedMessage id="page.users.index.email" />,
      renderSkeleton: () => <Skeleton variant="rectangular" width="30%" />,
      renderCell: ({ row }: { row: ILightPersonProfile }) => row.email
    },
    {
      key: 'delete',
      renderHead: () => '',
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({ row }: { row: ILightPersonProfile }) => {
        return (
          <RemoveServiceOwnerConfirmation
            owner={row}
            serviceSubscriptionIdentifier={
              variables.serviceSubscriptionIdentifier
            }
          />
        );
      }
    }
  ];
};
