import { gql } from '@apollo/client';
import { ServiceSubscriptionFragment } from '../../fragments/service/ServiceSubscription';

export const GetServiceSubscription = gql`
  query GetServiceSubscription(
    $serviceSubscriptionIdentifier: serviceSubscriptionIdentifier!
  ) {
    serviceSubscription(
      serviceSubscriptionIdentifier: $serviceSubscriptionIdentifier
    ) {
      ...ServiceSubscription
    }
  }
  ${ServiceSubscriptionFragment}
`;
