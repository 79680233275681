import { UserNameCell } from 'Components/Shared/UserNameCell';
import UserCellSkeleton from 'Theme/components/UserCellSkeleton';
import Skeleton from 'Theme/components/Skeleton';
import { TableCollectionColumn } from 'Components/Shared/Table';
import { PersonOrder } from 'Apollo';
import useTranslate from './useTranslate';

export const useColumnsCandidates = (): TableCollectionColumn<
  ILightPersonProfile
>[] => {
  const ts = useTranslate();

  return [
    {
      key: 'user',
      orderBy: {
        asc: PersonOrder.NameAsc,
        desc: PersonOrder.NameDesc
      },
      hidden: false,
      renderHead: () => ts('page.users.index.users'),
      renderSkeleton: () => <UserCellSkeleton />,
      renderCell: ({ row }: { row: ILightPersonProfile }) => (
        <UserNameCell person={row} />
      )
    },
    {
      key: 'email',
      hidden: false,
      renderHead: () => ts('page.users.index.email'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({ row }: { row: ILightPersonProfile }) => row.email
    }
  ];
};
