import * as React from 'react';
import { Box, Container } from '@mui/material';
import { SubHeader } from '../../Components/Shared';
import ExternalServiceProfileGeneral from './ExternalServiceProfileGeneral/ExternalServiceProfileGeneral';
import useTabsExternalService from '../../Hooks/useTabsExternalService';


interface Props {
  service: IExternalServiceSubscription;
}

const ExternalServiceProfile = ({ service }: Props) => {
  const tabs = useTabsExternalService({ service });
  return (
    <>
      <SubHeader tabs={tabs} />
      <Container>
        <Box p={4} />
        <ExternalServiceProfileGeneral serviceSubscription={service} />
      </Container>
    </>
  );
};

export default ExternalServiceProfile;
