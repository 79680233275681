// "palette" is a mapping of our colors to @material-ui's palette.
import colors from './colors';
import {
  CdsColorTextNeutralHighEmphasis,
  CdsColorTextNeutralMediumEmphasis
} from '@cegid/design-tokens';

const palette = {
  border: {
    default: 'rgba(0, 44, 82, 0.2)'
  },
  text: {
    primary: CdsColorTextNeutralHighEmphasis,
    secondary: CdsColorTextNeutralMediumEmphasis,
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)'
  },
  background: {
    default: '#f5f5f5',
    paper: colors.white,
    dark: '#212121' // equal to 'rgba(0, 0, 0, 0.87)'
  },
  error: {
    contrastText: colors.white,
    dark: '#d32f2f',
    light: '#e57373',
    main: '#f44336'
  },
  primary: {
    contrastText: colors.white,
    light: colors.blue[400],
    main: colors.blue[500],
    dark: colors.blue[700]
  },
  secondary: {
    contrastText: colors.white,
    light: colors.orange[400],
    main: colors.orange[500],
    dark: colors.orange[700]
  }
};

export default palette;
