import { Typography } from '@mui/material';
import * as React from 'react';
import {
  Link,
  generatePath,
} from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';

interface Props {
  serviceSubscription: IServiceSubscription;
}

function ServiceProfileLink(props: Readonly<Props>) {
  const { serviceSubscription } = props;

  return (
    <Link
      to={generatePath(PrivateRoutes.serviceProfile.path, {
        accountCode: serviceSubscription.account.code ?? 'all',
        serviceCode: serviceSubscription.service.groupCode,
      })}
    >
      <Typography variant="body1">
        {props.serviceSubscription.service.name}
      </Typography>
    </Link>
  );
}

export default ServiceProfileLink;
