import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CdsColorBackgroundNeutralMainDisabled, CdsColorBackgroundWhiteMain, CdsSizeRadius } from '@cegid/design-tokens';

export const useStyles = makeStyles((theme: Theme) => ({
  availabledResult: {
    width: '100%'
  },
  buttonIcon: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: '50%',
    minWidth: 'unset'
  },
  searchbarBackground: {
    padding: '1em',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  searchbar: {
    width: '100%',
    margin: '0 auto',
  },
  searchbarTextField: {
    background: CdsColorBackgroundWhiteMain,
  },
  selectedUserSearchbar: {
    color: CdsColorBackgroundNeutralMainDisabled,
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    background: CdsColorBackgroundWhiteMain,
    border: `1px solid ${CdsColorBackgroundNeutralMainDisabled}`,
    borderRadius: CdsSizeRadius
  },
  circularProgressRoot: {
    margin: theme.spacing(1)
  },
  overflow: {
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden'
  },
  resultList: {
    width: '100%',
  },
  userAvatar: {
    marginRight: theme.spacing(2)
  },
  userSelectedFab: {
    zIndex: 1,
    padding: '0px 12px 0px 16px',
  },
  textAlignCenter: {
    textAlign: 'center'
  }
}));
