
/**
 * 
 * @param date1 Date
 * @param date2 Date
 * @returns true if the 2 date are equal (millisecond excluded)
 */

export const compareDates = (date1: Date, date2: Date) => {
    const timestamp1 = Math.floor(date1.getTime() / 1000); // Convert to seconds
    const timestamp2 = Math.floor(date2.getTime() / 1000); // Convert to seconds
    return timestamp1 === timestamp2;
}