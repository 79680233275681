import { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { TServiceGroupList } from '../../Models';
import TableServicesGroups from './TableServicesAvailableGroups';
import { GetLightPersonGroupWithoutAccessToServiceSubscription } from '../../Apollo/queries/personGroup/GetLightPersonGroupWithoutAccessToServiceSubscription';
import { AddServiceSubscriptionsToPersonGroup } from '../../Apollo/mutations/personGroup/AddServiceSubscriptionsToPersonGroup';
import useNotification from '../../Hooks/useNotification';
import useServiceSubscriptionRouteMatch from 'Hooks/useServiceSubscriptionRouteMatch';
import ErrorHandlerContext from 'Context/ErrorHandler.context';

interface Props {
  onClose(): void;
}

const TableServicesAvailableGroupsContainer = (props: Props) => {
  const { onSuccess, onError } = useNotification();
  const [selectedGroup, setSelectedGroup] = useState<ILightPersonGroup | null>(
    null
  );

  const ErrorHandler = useContext(ErrorHandlerContext);

  const serviceSubscriptionIdentifier = useServiceSubscriptionRouteMatch();

  const { data, loading, error } = useQuery<{
    lightPersonGroupWithoutAccessToServiceSubscription: TServiceGroupList;
  }>(GetLightPersonGroupWithoutAccessToServiceSubscription, {
    onError: ErrorHandler.onError,
    variables: {
      page: { first: ROW_PER_PAGE_DEFAULT, offset: 0 },
      serviceSubscriptionIdentifier
    }
  });

  const [addGroupToService] = useMutation(
    AddServiceSubscriptionsToPersonGroup,
    {
      onCompleted: () =>
        onSuccess('page.serviceProfile.serviceMembers.groups.successAdd'),
      onError
    }
  );

  const onGroupSelected = async () => {
    if (selectedGroup !== null) {
      await addGroupToService({
        variables: {
          groupCode: selectedGroup.code,
          serviceSubscriptions: [serviceSubscriptionIdentifier]
        }
      });
    }
    props.onClose();
  };

  const groups = data?.lightPersonGroupWithoutAccessToServiceSubscription;
  const rows = (groups?.edges ?? []).map(o => o.node);

  return (
    <TableServicesGroups
      rows={rows}
      loading={loading}
      loadingError={!!error}
      selectedGroup={selectedGroup}
      setSelectedGroup={setSelectedGroup}
      onCancel={() => props.onClose()}
      onAdd={() => onGroupSelected()}
    />
  );
};

export default TableServicesAvailableGroupsContainer;
