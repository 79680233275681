import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
  IconButton,
  Divider,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PowerSettingsNew as IconPowerSettingsNew } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { generatePath, Link, useParams } from 'react-router-dom';
import Authentication from '../../Tools/auth';
import { PersonContext } from '../../Context/CurrentPerson';
import { PrivateRoutes } from 'Router/Routes';
import { PersonAvatar, NavigationListItem } from 'Theme/components';
import TestIds from 'Tests/TestIds';
import clsx from 'clsx';
import { userFullName } from 'Tools';
import { useContext, useState } from 'react';
import useTranslate from 'Hooks/useTranslate';

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    marginTop: theme.spacing(1)
  },
  paper: {
    background: 'none'
  },
  modal: {
    opacity: '1',
    transform: 'none',
    transition:
      'opacity 278ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 185ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    right: '12px',
    transformOrigin: `${theme.spacing(28)} 0px`,
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    padding: `0  ${theme.spacing(2)}`,
    '&:first-child': {
      paddingLeft: 0
    }
  },
  profileButton: {
    marginTop: theme.spacing(2)
  }
}));

const HeaderProfileMenu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { currentPerson, currentPersonRoles } = useContext(PersonContext);
  const classes = useStyles();
  const { accountCode } = useParams<{ accountCode: string }>();
  
  const ts = useTranslate();
  const onClose = () => {
    setIsOpen(false);
  };
  const onHandleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const getRole = (personRoles: IAdministrationResourceAccess) => {
    const { canWriteAll, hasAccessToAccounts, isAccountAdmin, canAskForAccountAdministrationDelegation } = personRoles;

    if (canWriteAll) {
      return ts('common.person.role.superAdm');
    }

    if (canAskForAccountAdministrationDelegation) {
      return ts('common.person.role.superReader');
    }

    if (isAccountAdmin && !hasAccessToAccounts) {
      return ts('common.person.role.adm');
    }
   




    return ts('common.person.role.user');
  };

  return <>
      <IconButton
        data-testid={TestIds.common.header.profileMenu}
        onClick={onHandleClick}
        focusRipple={true}
        centerRipple={true}
        size="large">
        <PersonAvatar person={currentPerson} />
      </IconButton>
      {isOpen && (
        <>
          <div className="triangle-with-shadow" />
          <Popover
            elevation={4}
            open={isOpen}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            className={clsx([classes.modal, classes.paper])}
          >
            <List onClick={onClose}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <PersonAvatar person={currentPerson} />
                </ListItemAvatar>
                <div className={classes.listItemText}>
                  <Typography variant="body1">
                    {userFullName(
                      currentPerson.firstName,
                      currentPerson.lastName
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {getRole(currentPersonRoles)}
                  </Typography>
                  <Button
                    component={Link}
                    data-testid={
                      TestIds.common.header.profileMenuPopover.profileLink
                    }
                    to={generatePath(PrivateRoutes.usersProfile.path, {
                      accountCode:
                        accountCode || currentPerson.account?.code || 'all',
                      immutableId: currentPerson.immutableId
                    })}
                    variant="contained"
                    color="primary"
                    classes={{ root: classes.profileButton }}
                  >
                    {ts('myMenu.profile')}
                  </Button>
                </div>
              </ListItem>
              <Divider variant="middle" classes={{ root: classes.divider }} />
              <NavigationListItem
                data-testid={
                  TestIds.common.header.profileMenuPopover.logoutButton
                }
                component="li"
                button={true}
                onClick={() => Authentication.logout()}
              >
                <ListItemIcon style={{ color: 'inherit' }}>
                  <IconPowerSettingsNew />
                </ListItemIcon>

                <ListItemText
                  primary={<FormattedMessage id="myMenu.logout" />}
                />
              </NavigationListItem>
            </List>
          </Popover>
        </>
      )}
    </>;
};

export default HeaderProfileMenu;
