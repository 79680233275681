import { createRef, useState } from 'react';
import {
  Drawer,
  Accordion,
  AccordionSummary,
  IconButton,
  Popper,
  Tooltip,
  Typography,
  Theme
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {
  GetApp as IconGetApp,
  ExpandMore as IconExpandMore,
  Person as IconPerson,
  BubbleChart as IconBubbleChart,
  Delete as IconDelete
} from '@mui/icons-material';
import TestIds from 'Tests/TestIds';
import CurrentPerson from 'Context/CurrentPerson';
import AddSubscriptions from './AddSubscriptions';
import ExportUsers from './ExportUsers';
import RemoveUsers from './RemoveUsers';
import useTranslate from 'Hooks/useTranslate';

interface Props {
  selectedIds: string[];
  selectAllEnabled: boolean;
  selectedCount: number;
  onReloadUsers: () => void;
  disabled: boolean;
  searchTerm: string;
}

type DisplayAction = 'addSubscriptions' | 'removeUsers' | 'exportUsers';

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      borderRadius: '20px 20px 0 0',
      maxHeight: '90%'
    },
    expansionPanel: {
      backgroundColor: 'transparent'
    },
    expansionPanelSummary: {
      cursor: 'auto',
      // Required for the text ellipsis
      overflow: 'hidden'
    },
    header: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      // Required for the text ellipsis
      overflow: 'hidden'
    },
    headerLeft: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    headerLeftIcon: {
      marginRight: theme.spacing(1),
      verticalAlign: 'middle'
    },
    headerRight: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      padding: `0 ${theme.spacing(3)}`
    },

    // Taken from https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/popper/ScrollPlayground.js#L43-L101
    popper: {
      // This popper is used in a drawer so it needs to appear on top of it
      zIndex: theme.zIndex.modal + 1,
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
        }
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '1em 1em 0 1em',
          borderColor: `${theme.palette.background.paper} transparent transparent transparent`
        }
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 1em 1em 0',
          borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
        }
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: '-0.9em',
        height: '3em',
        width: '1em',
        '&::before': {
          borderWidth: '1em 0 1em 1em',
          borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
        }
      },
      willChange:
        'auto !important' /* the default value 'transform' blurs the text */
    },
    arrow: {
      position: 'absolute',
      fontSize: 7,
      width: '3em',
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid'
      }
    }
  });

function ActionOnSelectedUsers(props: Props & WithStyles<typeof styles>) {
  const ts = useTranslate();
  const {
    selectedCount,
    selectedIds,
    selectAllEnabled,
    onReloadUsers,
    disabled,
    classes,
    searchTerm
  } = props;
  const [displayAction, setDisplayAction] = useState<DisplayAction | null>(
    null
  );
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const buttonRef = createRef<HTMLButtonElement>();

  if (selectedCount <= 0 && Boolean(displayAction)) {
    // If the drawer is expanded and all users are unselected, collapse it
    setDisplayAction(null);
  }

  const isExpanded =
    // removeUsers doesn't need the drawer to be expanded
    Boolean(displayAction) && displayAction !== 'removeUsers';

  return (
    <CurrentPerson.Consumer>
      {({ currentPersonRoles }: CurrentPersonContext) => (
        <>
          {selectedCount > 0 && <div style={{ height: '72px' }} />}
          <Drawer
            anchor="bottom"
            variant={isExpanded ? 'temporary' : 'persistent'}
            open={selectedCount > 0}
            classes={{ paper: classes.drawer }}
          >
            <Accordion
              expanded={isExpanded}
              classes={{ root: classes.expansionPanel }}
            >
              <AccordionSummary
                classes={{ content: classes.expansionPanelSummary }}
                expandIcon={
                  !isExpanded ? null : (
                    <IconExpandMore
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (displayAction) {
                          setDisplayAction(null);
                        }
                      }}
                    />
                  )
                }
              >
                <div className={classes.header}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    classes={{ root: classes.headerLeft }}
                  >
                    <IconPerson
                      color="primary"
                      classes={{ root: classes.headerLeftIcon }}
                    />
                    {ts('page.users.index.selectedUserCount', {
                      count: selectedCount
                    })}
                  </Typography>
                  <div className={classes.headerRight}>
                    {currentPersonRoles.isAccountAdmin && (
                      <>
                        <Tooltip title={ts('common.action.subscribe')}>
                          <IconButton
                            component="button"
                            data-testid={TestIds.pages.users.subscriptionButton}
                            color={
                              displayAction === 'addSubscriptions'
                                ? 'primary'
                                : 'default'
                            }
                            onClick={() => setDisplayAction('addSubscriptions')}
                            disabled={disabled}
                            size="large"
                          >
                            <IconBubbleChart />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={ts('common.action.delete')}>
                          <IconButton
                            ref={buttonRef}
                            component="button"
                            color={
                              displayAction === 'removeUsers'
                                ? 'primary'
                                : 'default'
                            }
                            onClick={() => {
                              setDisplayAction('removeUsers');
                              setAnchorEl(buttonRef.current);
                            }}
                            disabled={disabled}
                            data-testid={
                              TestIds.pages.users.actionOnSelectedUsers
                                .removeUsersButton
                            }
                            size="large"
                          >
                            <IconDelete />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    <Tooltip title={ts('common.action.export')}>
                      <IconButton
                        color={
                          displayAction === 'exportUsers'
                            ? 'primary'
                            : 'default'
                        }
                        onClick={() => setDisplayAction('exportUsers')}
                        disabled={disabled}
                        size="large"
                      >
                        <IconGetApp />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </AccordionSummary>
              {displayAction === 'addSubscriptions' && (
                <AddSubscriptions
                  selectedUserIds={selectedIds}
                  selectAllUserEnabled={selectAllEnabled}
                  selectedUserCount={selectedCount}
                  onCollapse={onCollapse}
                />
              )}
              {displayAction === 'removeUsers' && (
                <RemoveUsers
                  selectedUserIds={selectedIds}
                  selectAllUserEnabled={selectAllEnabled}
                  selectedUserCount={selectedCount}
                  onCollapse={onCollapse}
                  onReloadUsers={onReloadUsers}
                />
              )}
              {displayAction === 'removeUsers' && (
                <Popper
                  open={true}
                  placement="top"
                  anchorEl={anchorEl}
                  modifiers={[
                    {
                      name: 'arrow',
                      enabled: true,
                      options: {
                        element: arrowRef
                      }
                    }
                  ]}
                  className={classes.popper}
                >
                  <span
                    className={classes.arrow}
                    ref={element => setArrowRef(element)}
                  />
                  <RemoveUsers
                    selectedUserIds={selectedIds}
                    selectAllUserEnabled={selectAllEnabled}
                    selectedUserCount={selectedCount}
                    onCollapse={onCollapse}
                    onReloadUsers={onReloadUsers}
                  />
                </Popper>
              )}
              {displayAction === 'exportUsers' && (
                <ExportUsers
                  selectedUserIds={selectedIds}
                  searchTerm={searchTerm}
                  selectAllUserEnabled={selectAllEnabled}
                  onCollapse={onCollapse}
                />
              )}
            </Accordion>
          </Drawer>
        </>
      )}
    </CurrentPerson.Consumer>
  );

  function onCollapse() {
    setDisplayAction(null);
  }
}

export default withStyles(styles)(ActionOnSelectedUsers);
