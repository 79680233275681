import { PersonAccessRightStatus } from '../../../../../Apollo';
import TestIds from 'Tests/TestIds';
import FilteredServices from '../FilteredServices';

interface Props {
  person: IPersonWithAccount;
}

const DeniedServices = ({ person }: Props) => {
  return (
    <FilteredServices
      person={person}
      titleId={'page.userProfile.services.deniedServices'}
      helpId={'page.userProfile.deniedServices.help'}
      emptyStatePrimary={'page.userProfile.services.emptyStateTitle'}
      tableTestIds={TestIds.pages.profile.deniedServices.table}
      servicesStatus={PersonAccessRightStatus.Refused}
    />
  );
};

export default DeniedServices;
