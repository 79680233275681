import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { GetFellowAdministrators } from 'Apollo';
import { useParams } from 'react-router-dom';
import useTranslate from 'Hooks/useTranslate';
import { useQuery } from '@apollo/client';
import useNotification from 'Hooks/useNotification';
import FellowAdministratorsSelector from './FellowAdmininistratorsSelector';

interface Props {
  onUpdate: (event: any) => void;
  value: string;
}

const FellowAdministratorsSelectorContainer = (props: Props) => {
  const { onError } = useNotification();
  const { accountCode } = useParams<IUriParams>();
  const ts = useTranslate();
  const { onUpdate, value } = props;

  const handleChange = (event: any) => {
    onUpdate(event.target.value);
  }

  const variables = {
    accountCode: accountCode === 'all' ? null : accountCode
  };

  const { data, loading } = useQuery<GetFellowAdministratorsData>(GetFellowAdministrators, {
      onError,
      variables,
      fetchPolicy: 'cache-first'
    });

  if (loading) {
    return (
      <CircularProgress
        disableShrink
        size={20}
        color="secondary"
        style={{ display: 'flex', margin: '0 auto' }}
      />
    );
  }

  if ((!loading && data?.fellowAdministrators === undefined ) || (!loading && data?.fellowAdministrators.totalCount === 0 )) {
    return (
      <Typography variant='caption' color='error'>
        {ts('banner.accessRightRequestDialog.noAdministratorYet')}
      </Typography>
    )
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <FellowAdministratorsSelector data={data!.fellowAdministrators} value={value} onChange={handleChange}/>
    </FormControl>
  );
}

export default FellowAdministratorsSelectorContainer;