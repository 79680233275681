import { useContext } from 'react';
import { GetPersonGroupServiceSubscriptions } from 'Apollo';
import { useQuery } from '@apollo/client';
import PersonGroupServices from './PersonGroupServices';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { useParams } from 'react-router-dom';
import ErrorHandlerContext from 'Context/ErrorHandler.context';

interface Props {
  group: IPersonGroup;
}

const PersonGroupServicesContainer = ({ group }: Props) => {
  const { groupCode } = useParams<IGroupIdentifier>();
  const ErrorHandler = useContext(ErrorHandlerContext);

  const { data, loading, error: queryError, refetch } = useQuery<
    GetPersonGroupServiceSubscriptionsData
  >(GetPersonGroupServiceSubscriptions, {
    onError: ErrorHandler.onError,
    onCompleted() {},
    variables: {
      code: groupCode,
      first: ROW_PER_PAGE_DEFAULT,
      offset: 0
    }
  });

  let totalCount = data?.personGroupServiceSubscriptions.totalCount ?? 0;

  const rows =
    data?.personGroupServiceSubscriptions?.edges.map(o => o.node) ?? [];

  return (
    <PersonGroupServices
      group={group}
      loading={loading}
      totalCount={totalCount}
      serviceSubscriptions={rows}
      onRefetch={refetch}
      error={queryError}
    />
  );
};

export default PersonGroupServicesContainer;
