import { GetServiceSubscriptionIpRestrictions } from '../../Apollo/queries/service/GetServiceSubscriptionIpRestrictions';
import useLocationPage from '../../Hooks/useLocationPage';
import FormIpFiltering from './FormIpFiltering';
import { useMutation } from '@apollo/client';
import { CreateServiceSubscriptionIpRestriction } from '../../Apollo/mutations/service/CreateServiceSubscriptionIpRestriction';
import { IServiceFormIpFiltering } from '../../Models';
import useNotification from '../../Hooks/useNotification';
import useServiceSubscriptionRouteMatch from 'Hooks/useServiceSubscriptionRouteMatch';

interface Props {}

const FormIpFilteringContainer = (props: Props) => {
  const { currentPage } = useLocationPage();
  const { onSuccess, onError } = useNotification();
  const serviceSubscriptionIdentifier = useServiceSubscriptionRouteMatch();

  const onRefetch = () => [
    {
      query: GetServiceSubscriptionIpRestrictions,
      variables: {
        page: currentPage,
        serviceSubscriptionIdentifier
      }
    }
  ];

  const [createIpRestriction, { loading }] = useMutation(
    CreateServiceSubscriptionIpRestriction,
    {
      onCompleted: () =>
        onSuccess('page.servicesIpRestrictions.index.successAdd'),
      onError,
      refetchQueries: onRefetch
    }
  );

  const onCreateRestriction = (restriction: IServiceFormIpFiltering) => {
    createIpRestriction({
      variables: {
        serviceSubscriptionIdentifier,
        serviceIPRangeItemForCreate: restriction
      }
    });
  };

  return (
    <FormIpFiltering
      onCreateRestriction={onCreateRestriction}
      loading={loading}
    />
  );
};

export default FormIpFilteringContainer;
