import { useQuery } from '@apollo/client';
import { Typography, Button } from '@mui/material';
import Skeleton from '@mui/lab/Skeleton/Skeleton';
import { PersonAccessRightStatus } from 'Apollo';
import { GetPersonAccessRightRequests } from 'Apollo/queries/accessRights/GetPersonAccessRightRequests';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import { PrivateRoutes } from 'Router/Routes';
import DataRow from 'Theme/components/DataRow/DataRow';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  account: IAccount;
}

function SectionPendingRequests(props: Readonly<Props>) {
  const { account } = props;
  const { onError } = useNotification();
  const ts = useTranslate();

  const { data, loading } = useQuery(GetPersonAccessRightRequests, {
    variables: {
      accountCode: account.code,
      status: PersonAccessRightStatus.ToValidate,
      page: {
        first: 1,
        offset: 0
      }
    },
    onError
  });

  return (
    <DataRow label={ts('page.accountProfile.sections.pendingRequests.title')}>
      {loading && <Skeleton width={120} height={30} variant="rectangular" color='primary'/>}
      {!loading && <Typography>{data.personAccessRightRequests.totalCount}</Typography>}
      <div>
        <Button
          component={RouterLink}
          to={generatePath(PrivateRoutes.accessRights.path, {
            accountCode: account.code
          })}
          variant="text"
          color="primary"
        >
          {ts('page.accountProfile.sections.pendingRequests.manageRequests')}
        </Button>
      </div>
    </DataRow>
  );
}

export default SectionPendingRequests;
