import { PersonOrder } from 'Apollo';
import Skeleton from 'Theme/components/Skeleton';
import useTranslate from './useTranslate';
import { TableCollectionColumn } from 'Components/Shared/Table';
import AccessRightAvailability from 'Page/UsersProfile/Tabs/TabServices/AccessRightsTable/AccessRightAvailability/AccessRightAvailability';
import CellServiceWithAccount from 'Components/Shared/CellServiceWithAccount/CellServiceWithAccount';
import CellServiceInfo from 'Components/Shared/CellServiceInfo/CellServiceInfo';

interface IUserAccessColumnsConfig {
  isServiceHidden?: boolean;
  isStatusHidden?: boolean;
  isInfoHidden?: boolean;
}


export const useColumnsUserServices = (
  columnsConfig: IUserAccessColumnsConfig
): TableCollectionColumn<IPersonAccessRight>[] => {
  const ts = useTranslate();

  const {
    isServiceHidden = false,
    isStatusHidden = false,
    isInfoHidden = false
  }: IUserAccessColumnsConfig = columnsConfig;

  return [
    {
      key: 'service',
      width: 0.4,
      hidden: columnsConfig.isServiceHidden ?? isServiceHidden,
      renderHead: () => ts('page.userProfile.services.service'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({ row: accRight }: { row: IPersonAccessRight }) => (
        <CellServiceWithAccount access={accRight} />
      )
    },
    {
      key: 'status',
      width: 0.1,
      orderBy: {
        asc: PersonOrder.StatusAsc,
        desc: PersonOrder.StatusDesc
      },
      hidden:
        columnsConfig.isStatusHidden ?? isStatusHidden,
      renderHead: () => ts('page.users.index.allStatus'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="10%" />,
      renderCell: ({ row: accRight }: { row: IPersonAccessRight }) =>
        <AccessRightAvailability accessRight={accRight} />
    },
    {
      key: 'info',
      width: 0.1,
      orderBy: {
        asc: PersonOrder.StatusAsc,
        desc: PersonOrder.StatusDesc
      },
      hidden: columnsConfig.isInfoHidden ?? isInfoHidden, 
      renderHead: () => '',
      renderSkeleton: () => <Skeleton variant="rectangular" width="10%" />,
      renderCell: ({ row: accessRight }: { row: IPersonAccessRight }) => {
        return (
          <CellServiceInfo access={accessRight}/>
        );
      }
    }
  ];
};
