import { Box, Button, Grid, Typography } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import TableServicesCandidatesContainer from 'Components/TableServicesCandidates';
import { mdiAccountArrowRight } from '@mdi/js';
import SvgIcon from 'Theme/components/SvgIcon/SvgIcon';
import ButtonLoading from 'Theme/components/ButtonLoading';
import { colors } from 'Theme/constants';
import { useState } from 'react';
import TestIds from 'Tests/TestIds';
import ExpandedSearch from '../Shared/ExpandedSearch';
import { useStyles } from './ServiceCandidates.styles';

interface Props {
  setSelectedNewUsersIds: (ids: string[]) => void;
  onClose(): void;
  onInvite(): void;
  onInviteExternal(): void;
  allowExternalInvitation: boolean;
  loading: boolean;
}

const ServiceCandidates = ({
  setSelectedNewUsersIds,
  onClose,
  onInvite,
  onInviteExternal,
  allowExternalInvitation,
  loading
}: Props) => {
  const classes = useStyles();
  const ts = useTranslate();
  const [search, setSearch] = useState('');

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleSelect = (ids: string[]) => {
    let newSelectedIds = ids.reduce(
      (acc, id) => (acc.includes(id) ? acc : acc.concat([id])),
      selectedIds) ?? [];
    setSelectedIds(newSelectedIds);
    setSelectedNewUsersIds(newSelectedIds);
  };

  return (
    <>
      <Box className={classes.header} mb={2}>
        <Box className={classes.headerLeft}>
          <Typography variant="h5">
            {ts('page.serviceProfile.serviceMembers.add')}
          </Typography>
        </Box>
        {allowExternalInvitation && (
          <Button color="primary" onClick={onInviteExternal}>
            <SvgIcon path={mdiAccountArrowRight} fill={colors.blue['500']} />
            <Box ml="0.5em">
              {ts('page.services.action.subscribeExternalUser')}
            </Box>
          </Button>
        )}
      </Box>
      <Grid>
        <ExpandedSearch
          onChange={setSearch}
          testId={
            TestIds.pages.serviceProfile.addMemberDrawer
              .searchavailableUserInput
          }
          placeholder={ts('component.searchBars.placeholder.users')}
        />
      </Grid>
      <TableServicesCandidatesContainer
        setSelectedIds={handleSelect}
        search={search}
      />
      <Box className={classes.drawerButtons}>
        <Button onClick={onClose} color='inherit'>{ts('common.action.cancel')}</Button>
        <ButtonLoading
          loading={loading}
          onClick={onInvite}
          color="primary"
          variant="contained"
          data-testid={TestIds.pages.serviceProfile.drawerAddAccessButton}
        >
          {ts('common.action.add')}
        </ButtonLoading>
      </Box>
    </>
  );
};

export default ServiceCandidates;
