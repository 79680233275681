import { gql } from '@apollo/client';
import { CatalogInfosFragment } from '../../fragments/catalog/CatalogInfos';

export const GetCatalogInfos = gql`
  query GetCatalogInfos($accountCode: String) {
    catalogInfos(accountCode: $accountCode) {
      ...CatalogInfos
    }
  }
  ${CatalogInfosFragment}
`;
