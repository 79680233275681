import { gql } from '@apollo/client';
import { PersonGroupServiceAssignmentFragment } from '../../fragments/personGroup/PersonGroupServiceAssignment';

export const AddServiceSubscriptionsToPersonGroup = gql`
  mutation AddServiceSubscriptionsToPersonGroup(
    $groupCode: String!
    $serviceSubscriptions: [ServiceSubscriptionInput!]
  ) {
    addServiceSubscriptionsToPersonGroup(
      groupCode: $groupCode
      serviceSubscriptions: $serviceSubscriptions
    ) {
      ...PersonGroupServiceAssignment
    }
  }
  ${PersonGroupServiceAssignmentFragment}
`;
