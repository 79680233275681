import { gql } from '@apollo/client';
import { AccountFragment } from '../account/Account';
import { PersonFragment } from './Person';

export const PersonWithAccountFragment = gql`
  fragment PersonWithAccount on Person {
    ...Person
    account {
      ...Account
    }
  }
  ${PersonFragment}
  ${AccountFragment}
`;
