import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Theme
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import Skeleton from 'Theme/components/Skeleton';
import Container from 'Theme/components/Container';

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      minHeight: 150
    },
    accountSelector: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(3),
      // Negate the parent's padding
      margin: `-${theme.spacing(3)} -${theme.spacing(3)} 0 -${theme.spacing(3)}`
    },
    section: {
      marginTop: theme.spacing(3)
    }
  });

interface Props extends WithStyles<typeof styles> {}

function NotificationsFormSkeleton(props: Props) {
  return (
    <>
      <Paper
        square={true}
        elevation={0}
        classes={{ root: props.classes.accountSelector }}
      >
        <Skeleton variant="rectangular" width={300} height={32} />
      </Paper>

      <Container>
        <div className={props.classes.section}>
          <Skeleton variant="text" width="20%" />
          <Skeleton variant="text" width="60%" />
          <Paper>
            <List>
              <ListItem
                alignItems="flex-start"
                classes={{ root: props.classes.listItem }}
              >
                <ListItemAvatar>
                  <Skeleton variant="circle" width={40} height={40} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Skeleton
                      component="span"
                      variant="rectangular"
                      width="20%"
                    />
                  }
                  secondary={
                    <Skeleton component="span" variant="text" width="60%" />
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </div>
      </Container>
    </>
  );
}

export default withStyles(styles)(NotificationsFormSkeleton);
