import useTranslate from 'Hooks/useTranslate';
import { TableCollection } from 'Theme/components/TableCollection';
import EmptyState from 'Components/Shared/EmptyState';
import { SvgIcon } from 'Theme/components/SvgIcon/SvgIcon';
import { mdiBriefcaseAccount } from '@mdi/js';
import { useColumnsLightOwnerProfile } from 'Hooks/useColumnsLightOwnerProfile';

interface Props {
  rows: ILightPersonProfile[];
  loading: boolean;
  totalCount: number;
  page: number;
  setPage: (page: number) => void;
}

const TableServiceOwners = ({
  rows,
  setPage,
  totalCount,
  loading,
  page
}: Props) => {
  const columns = useColumnsLightOwnerProfile();
  const ts = useTranslate();

  const onChangePage = (newPage: number) => {
    setPage(newPage);
  };

  if (!rows.length && !loading)
    return (
      <EmptyState
        icon={
          <SvgIcon path={mdiBriefcaseAccount} fill="#666666" width="60px" />
        }
        primary={ts('page.serviceProfile.owners.emptyTitle')}
      />
    );

  return (
    <TableCollection
      loading={false}
      columns={columns}
      rows={rows}
      getRowId={r => r.immutableId}
      totalCount={totalCount}
      rowsPerPageOptions={[5]}
      onChangeRowsPerPage={() => {}}
      selectedIds={[]}
      rowsPerPage={5}
      onChangePage={onChangePage}
      isSelectionDisabled={true}
      isSelectionHidden={true}
      page={page}
      onSelect={() => {}}
      onUnselect={() => {}}
    />
  );
};

export default TableServiceOwners;
