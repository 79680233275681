import { GetServiceSubscription } from '../../Apollo';
import ProfilePageSkeleton from 'Theme/components/ProfilePageSkeleton';
import ErrorMsg from 'Components/Shared/ErrorMsg';
import ExternalServiceProfile from './ExternalServiceProfile';
import { useQuery } from '@apollo/client';
import useServiceSubscriptionRouteMatch from 'Hooks/useServiceSubscriptionRouteMatch';

const ExternalServiceProfileContainer = () => {
  const serviceSubscriptionIdentifier = useServiceSubscriptionRouteMatch();

  const { data, loading, error } = useQuery<GetExternalServiceSubscriptionData>(
    GetServiceSubscription,
    {
      variables: {
        serviceSubscriptionIdentifier: {
          accountCode: serviceSubscriptionIdentifier.accountCode,
          serviceCode: serviceSubscriptionIdentifier.serviceCode,
          targetAccountCode: serviceSubscriptionIdentifier.targetAccountCode
        }
      },
      fetchPolicy: 'cache-first'
    }
  );

  if (loading ?? data === undefined)
    return <ProfilePageSkeleton shape="rectangular" />;
  if (error) return <ErrorMsg />;

  return <ExternalServiceProfile service={data.serviceSubscription} />;
};

export default ExternalServiceProfileContainer;
