import { gql } from '@apollo/client';
import { PersonFragment } from '../../fragments/person/Person';

export const GetAvailablePersonsForPersonGroup = gql`
  query GetAvailablePersonsForPersonGroup(
    $personGroupCode: String!
    $page: PageInput
    $searchTerm: String
    $orderBy: PersonOrder
  ) {
    availablePersonsForPersonGroup(
      personGroupCode: $personGroupCode
     page: $page
      searchTerm: $searchTerm
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...Person
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${PersonFragment}
`;
