import { Button, Grid, Typography } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import ButtonLoading from 'Theme/components/ButtonLoading';

interface Props {
  loading: boolean;
  totalCount: number;
  disabled: boolean;
  onCancel: () => void;
  onFormSubmit: (e: any) => void;
}

const FormAddGroupMember = (props: Props) => {
  const { disabled, loading, totalCount, onFormSubmit, onCancel } = props;
  const ts = useTranslate();

  return (
    <Grid container={true} justifyContent="space-between" alignItems="center">
      <Grid item style={{ paddingLeft: '1em' }}>
        <Typography variant="body2">
          {ts('page.groupProfile.members.addMembers.selection', {
            count: totalCount
          })}
        </Typography>
      </Grid>
      <Grid item>
        <form onSubmit={e => onFormSubmit(e)}>
          <Button onClick={onCancel} color='inherit'>
            {ts('common.action.cancel')}
          </Button>
          <ButtonLoading
            loading={loading}
            type="submit"
            color="primary"
            variant="contained"
            disabled={disabled}
            data-testid={TestIds.pages.groupProfile.members.addButton}
          >
            {ts('common.action.add')}
          </ButtonLoading>
        </form>
      </Grid>
    </Grid>
  );
};

export default FormAddGroupMember;
