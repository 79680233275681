import { gql } from '@apollo/client';
import { PersonFragment } from '../../fragments/person/Person';
import stringifyInput, { InputJson } from '../../../Tools/stringifyInput';

export const UpdatePerson = (data: UpdatePersonInput) => gql`
  mutation UpdatePerson($personIdentifier: String!) {
    updatePerson(personIdentifier: $personIdentifier, data: ${stringifyInput(
      data as InputJson,
      []
    )}) {
      ...Person
    }
  }
  ${PersonFragment}
`;
