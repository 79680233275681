import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import * as React from 'react';
import { ROW_PER_PAGE_OPTIONS } from 'Theme/config';
import Skeleton from 'Theme/components/Skeleton';
import PersonGroupPersonsRow from './PersonGroupPersonsRow';
import { TableCollectionPagination } from 'Theme/components/TableCollection/TableCollectionPagination';

interface Props {
  testId?: string;
  currentPage: number;
  rowsPerPage: number;
  onRefetch: any;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rpp: number) => void;
  loading: boolean;
  persons: IPersonWithAccount[];
  personGroup: IPersonGroup;
  totalCount: number;
}

function PersonGroupPersonsTable(props: Readonly<Props>) {
  return <>
    <Table data-testid={props.testId}>
      <TableBody>
        {props.loading
          ? Array.from({ length: props.rowsPerPage }, (_, i) => i + 1).map(
              _ => (
                <TableRow key={_}>
                  <TableCell style={{ width: '10%' }}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell style={{ width: '30%' }}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell style={{ width: '40%' }}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                </TableRow>
              )
            )
          : props.persons.map(person => (
              <PersonGroupPersonsRow
                key={person.immutableId + person.account?.code}
                person={person}
                personGroup={props.personGroup}
                onRefetch={props.onRefetch}
              />
            ))}
      </TableBody>
    </Table>
    <TableCollectionPagination
      variant="outlined"
      rowsPerPage={props.rowsPerPage}
      rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      onChangeRowsPerPage={(rpp: number) => props.onChangeRowsPerPage(rpp)}
      page={props.currentPage}
      onChangePage={(page: number) => props.onChangePage(page)}
      totalCount={props.totalCount}
    />
  </>;
}

export default PersonGroupPersonsTable;
