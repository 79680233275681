import { MutationFunction, gql } from '@apollo/client';

export const RequestAdministrativeAccess = gql`
  mutation requestAdministrativeAccessMutation(
    $accountCode: String!
    $administratorImmutableId: String!
    $callBackUrl: String!
  ) {
    requestAdministrativeAccess(
      accountCode: $accountCode
      administratorImmutableId: $administratorImmutableId
      callBackUrl: $callBackUrl
    )
  }
`;

export type RequestAdministrativeAccessMutation = MutationFunction<
  IRequestAdministrativeAccessData,
  IRequestAdministrativeAccessVariables
>;
