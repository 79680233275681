import { gql, MutationFunction } from '@apollo/client';

export const CreateEmailChangeRequest = gql`
  mutation CreateEmailChangeRequest(
    $personIdentifier: String!
    $newEmail: String
  ) {
    createEmailChangeRequest(
      personIdentifier: $personIdentifier
      newEmail: $newEmail
    )
  }
`;

export type CreateEmailChangeRequestMutation = MutationFunction<
  {},
  CreateEmailChangeRequestVariables
>;
