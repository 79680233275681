import { ArrowBack as IconArrowBack } from '@mui/icons-material';
import {
  AppBar,
  Button,
  Grid,
  Toolbar,
  Typography
} from '@mui/material';
import { useStyles } from './SubHeader.styles';
import { useHistory } from 'react-router-dom';
import useTranslate from '../../../Hooks/useTranslate';
import Tabs from 'Components/Shared/Tabs';
import { TTabView } from '../Tabs/Tabs';
import TestIds from 'Tests/TestIds';
import { useContext } from 'react';
import { PersonContext } from 'Context/CurrentPerson';
import { locationHistoryContext } from '../../../Router/PreviousLocation';

interface Props {
  tabs?: TTabView[];
  actionsButton?: React.ReactNode;
}

const SubHeader = ({ tabs, actionsButton }: Props) => {
  const { previousLocation } = useContext(locationHistoryContext);
  const classes = useStyles();
  const history = useHistory();
  const ts = useTranslate();

  const { currentPersonRoles } = useContext(PersonContext);

  const isSimpleUserOnly = !currentPersonRoles.isAccountAdmin;
  const displayPreviousButton = (previousLocation !== null) && !isSimpleUserOnly
  const displayActionsButton = actionsButton !== undefined;

  return (
    <AppBar
      component="div"
      color="default"
      className={
        currentPersonRoles.hasAccessToAccounts ? classes.subHeaderForSuperUser : classes.subHeader
      }
      elevation={1}
    >
      <Toolbar variant="dense">
        <Grid container flexWrap='wrap'>
          <Grid item xs={displayActionsButton ? 3 : 2} alignSelf={'center'}>
            {displayPreviousButton && (
              <Button
                onClick={() => history.goBack()}
                startIcon={<IconArrowBack />}
                classes={{ root: classes.backButton }}
                data-testid={TestIds.pages.profile.previousPageButton}
              >
                  <Typography sx={{display: {xs: 'none', md: "block"}}} variant="button" color="textSecondary">
                    {ts('common.action.back')}
                  </Typography>
              </Button>
            )}
          </Grid>
          {/* Caution: when actions buttons are present in subheader, be carefull of more than 2 tabs on small device  */}
          <Grid item xs={displayActionsButton ? 5 : 8} justifyContent="center" display={'flex'}>
            {!!tabs && <Tabs views={tabs} />}
          </Grid>
          <Grid item xs={displayActionsButton ? 4 :2} justifyContent="flex-end" display={'flex'}>
            {displayActionsButton && (actionsButton)}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default SubHeader;
