import { gql } from '@apollo/client';
import { AccountWithSubscriptionsFragment } from '../..';

export const GetAccountsWithSubscriptions = gql`
  query GetAccountsWithSubscriptions($first: Int) {
    accounts(first: $first) {
      edges {
        node {
          ...AccountWithSubscriptions
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${AccountWithSubscriptionsFragment}
`;
