import { useMutation } from '@apollo/client';
import {
  Button,
  IconButton,
  Paper,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';
import { RemoveSelectedDelegationAdministration } from 'Apollo/mutations/delegation/RemoveSelectedDelegationAdministration';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import React, { useContext, useState } from 'react';
import TestIds from 'Tests/TestIds';
import ButtonLoading from 'Theme/components/ButtonLoading';
import { useStyles } from 'Components/Popover/Popover.styles';
import { userFullName } from 'Tools';
import { generatePath } from 'react-router-dom';
import { PublicRoutes } from 'Router/Routes';
import { PersonContext } from 'Context/CurrentPerson';

interface Props {
  owner: ILightPersonProfile;
  serviceSubscriptionIdentifier: IServiceSubscriptionIdentifier;
}

function RemoveServiceOwnerConfirmation(props: Props) {
  const { owner, serviceSubscriptionIdentifier } = props;
  const { currentPerson } = useContext(PersonContext);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const ts = useTranslate();
  const { onError, onSuccess } = useNotification();

  const basePath = generatePath(PublicRoutes.accessDenied.path);

  const [onRemove] = useMutation(RemoveSelectedDelegationAdministration, {
    onError,
    onCompleted: () => {
      setLoading(false);
      setIsOpen(false);
      onSuccess('component.tableServiceOwners.remove.success');
      if (currentPerson?.immutableId === owner.immutableId) {
        window.location.href = basePath;
      }
    },
    refetchQueries: ['GetServiceOwners']
  });

  const handleOnRemove = () => {
    setLoading(true);
    onRemove({
      variables: {
        serviceSubscriptionIdentifier: serviceSubscriptionIdentifier,
        personIdentifier: owner.immutableId
      }
    });
  };

  const buttonRef = React.createRef<HTMLButtonElement>();

  return (
    <div id={owner.immutableId}>
      <Tooltip title={ts('common.action.delete')}>
        <IconButton
          component="button"
          style={{ float: 'right' }}
          data-testid={'button-delete-' + owner.immutableId}
          ref={buttonRef}
          onClick={() => {
            setIsOpen(true);
            setAnchorEl(buttonRef.current);
          }}
          size="large">
          <RemoveCircle style={{ cursor: 'pointer' }} />
        </IconButton>
      </Tooltip>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Paper className={classes.paper}>
          <Typography variant="body2" className={classes.text}>
            {ts('component.tableServiceOwners.remove.confirmation', {
              name: userFullName(owner.firstName, owner.lastName)
            })}
          </Typography>
          <Button
            size="medium"
            className={classes.button}
            color='inherit'
            onClick={() => {
              setIsOpen(false);
              setAnchorEl(null);
            }}
          >
            {ts('common.action.cancel')}
          </Button>
          <ButtonLoading
            data-testid={TestIds.pages.profile.services.confirmDeletionButton}
            loading={loading}
            type="button"
            size="medium"
            color="primary"
            variant="contained"
            onClick={handleOnRemove}
          >
            {ts('common.action.delete')}
          </ButtonLoading>
        </Paper>
      </Popover>
    </div>
  );
}

export default RemoveServiceOwnerConfirmation;
