import { BusinessCenter } from '@mui/icons-material';
import { Box, Badge } from '@mui/material';
import { useStyles } from './Header.styles';

interface Props {
  isAccountDisabled: boolean;
}

function AccountBadge({ isAccountDisabled }: Readonly<Props>) {
  const classes = useStyles();
  return (
    <Box sx={{ display: { xs: 'none', lg: 'block' } }} paddingRight={1}>
      <Badge
        overlap="rectangular"
        badgeContent={isAccountDisabled ? '!' : null}
        color="secondary"
        hidden={true}
      >
        <BusinessCenter className={classes.colorSecondary} />
      </Badge>
    </Box>
  );
}

export default AccountBadge;
