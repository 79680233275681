import { gql } from '@apollo/client';
import { AdministrationResourceAccess } from 'Apollo/fragments/person/AdministrationResourceAccess';

export const GetPersonAdministrationRights = gql`
  query GetPersonAdministrationRights {
    administrationResourceAccess {
      ...AdministrationResourceAccess
    }
  }
  ${AdministrationResourceAccess}
`;
