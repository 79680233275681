import { gql } from '@apollo/client';

export const UpdatePersonGroup = gql`
  mutation UpdatePersonGroup(
    $groupIdentifier: String!
    $label: String!
    $description: String!
  ) {
    updatePersonGroup(
      groupIdentifier: $groupIdentifier
      data: {
        label: $label
        description: $description
      }
    ) 
  }
`;
