import { useState } from 'react';
import {
  GetPersonAccessRights,
  PersonAccessRightStatus
} from '../../../../Apollo';
import AccessRightsTable from './AccessRightsTable';
import { PaperTitle } from 'Theme/components';
import EmptyState from 'Components/Shared/EmptyState';
import { CircularProgress, Paper } from '@mui/material';
import { useStyles } from './TabServices.styles';
import { BubbleChart as IconBubbleChart } from '@mui/icons-material';
import ErrorMsg from 'Components/Shared/ErrorMsg';
import { calculateNewPage } from '../../../../Tools/calculateNewPage';
import useTranslate from 'Hooks/useTranslate';
import { TProfileManagerMessagesKeys } from 'Languages/TProfileManagerMessages';
import { useQuery } from '@apollo/client';
import ExpandedSearch from 'Components/Shared/ExpandedSearch';
import useNotification from 'Hooks/useNotification';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import TestIds from 'Tests/TestIds';

interface Props {
  person: IPersonWithAccount;
  helpId: TProfileManagerMessagesKeys;
  titleId: TProfileManagerMessagesKeys;
  secondaryAction?: React.ReactNode;
  emptyStatePrimary: TProfileManagerMessagesKeys;
  emptyStateSecondary?: TProfileManagerMessagesKeys;
  tableTestIds: string;
  servicesStatus:
    | PersonAccessRightStatus.Accepted
    | PersonAccessRightStatus.ToValidate
    | PersonAccessRightStatus.Refused
    | PersonAccessRightStatus.Suspended;
}

const FilteredServices = ({
  person,
  titleId,
  helpId,
  emptyStatePrimary,
  emptyStateSecondary,
  tableTestIds,
  servicesStatus,
  secondaryAction
}: Props) => {
  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);
  const [search, setSearch] = useState<string>('');
  const ts = useTranslate();
  const { onError } = useNotification();

  function onChangePage(page: number) {
    setCurrentPage(page);
  }

  const handleRowsChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
  };

  const qVariables = {
    status: [servicesStatus],
    personIdentifier: person.immutableId,
    searchTerm: search,
    page: { first: rowsPerPage, offset: currentPage * rowsPerPage }
  };

  const { data, loading, error } = useQuery<{
    personAccessRights: IPaginatedList<IPersonAccessRight>;
  }>(GetPersonAccessRights, {
    variables: qVariables,
    onError,
    fetchPolicy: 'network-only'
  });

  const handleChange = (search: string) => {
    setSearch(search);
    setCurrentPage(0);
  };

  return (
    <Paper className={classes.tabResultBlock}>
      <PaperTitle
        title={ts(titleId, { value: data?.personAccessRights.totalCount ?? 0 })}
        info={ts(helpId)}
        secondaryAction={secondaryAction}
      />
      <ExpandedSearch
        onChange={handleChange}
        testId={TestIds.pages.services.searchServiceInput + 'FilteredServices'}
        placeholder={ts('component.searchBars.placeholder.services')}
      />
      {loading && (
        <EmptyState
          icon={<CircularProgress size={44} color="inherit" />}
          primary=""
          secondary=""
          className={classes.circularClearer}
        />
      )}

      {error && <ErrorMsg />}
      {!loading && !error && data?.personAccessRights.totalCount === 0 && (
        <EmptyState
          icon={<IconBubbleChart />}
          primary={ts(emptyStatePrimary)}
          secondary={emptyStateSecondary ? ts(emptyStateSecondary) : ''}
          className={classes.emptyServices}
        />
      )}
      {!loading && !error && data?.personAccessRights.totalCount !== 0 && (
        <AccessRightsTable
          person={person}
          testId={tableTestIds}
          loading={loading}
          accessRights={
            data?.personAccessRights?.edges.map(({ node }) => node) ?? []
          }
          totalCount={data?.personAccessRights.totalCount ?? 0}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          onChangePage={(page: number) => onChangePage(page)}
          onChangeRowsPerPage={(rPp: number) => handleRowsChange(rPp)}
        />
      )}
    </Paper>
  );
};

export default FilteredServices;
