import { Divider, Typography } from '@mui/material';
import { useStyles } from './DataRow.styles';

interface Props {
  label?: React.ReactNode;
  children: React.ReactNode;
  action?: React.ReactNode;
  divider?: boolean;
}

const DataRow = (props: Props) =>{
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Typography
          variant="overline"
          color="textSecondary"
          className={classes.label}
          role="tr"
        >
          {props.label}
        </Typography>
        <div className={classes.content}>{props.children}</div>
        {!!props.action && props.action}
      </div>
      {props.divider && <Divider className={classes.divider} />}
    </>
  );
}

export default DataRow;
