import { gql } from '@apollo/client';
import { AccountWithSubscriptionsFragment } from '../..';

export const GetAccountWithSubscriptions = gql`
  query GetAccountWithSubscriptions($accountCode: String!) {
    account(accountCode: $accountCode) {
      ...AccountWithSubscriptions
    }
  }
  ${AccountWithSubscriptionsFragment}
`;
