import { ServiceSubscriptionOrder } from 'Apollo';
import useTranslate from './useTranslate';
import Skeleton from 'Theme/components/Skeleton';
import { useMediaQuery } from '@mui/material';
import { MoreHorizOutlined as MoreIcon } from '@mui/icons-material'
import {
  CellAccessByDefault,
  TableCollectionColumn
} from '../Components/Shared';
import IconButtonWithPopOver from 'Theme/components/IconButtonWithPopOver';
import CellService from 'Components/Shared/CellService';
import CellAdministratedValidation from 'Components/Shared/CellAdministratedValidation';
import mediaQueries from 'Theme/constants/mediaQueries';



export interface IGroupServicesColumnsConfig {
  isServiceHidden?: boolean;
  isDescriptionHidden?: boolean;
  isFamilyHidden?: boolean;
  isAccountHidden?: boolean;
  isAdminValidationHidden?: boolean;
  isAccessByDefaultHidden?: boolean;
}

export const defaultServicesConfig: IGroupServicesColumnsConfig = {
  isServiceHidden: false,
  isDescriptionHidden: false,
  isFamilyHidden: false,
  isAccountHidden: false,
  isAdminValidationHidden: false,
  isAccessByDefaultHidden: false
};

export const useColumnsGroupServices = (columnsConfig: IGroupServicesColumnsConfig): TableCollectionColumn<IServiceSubscriptionWithCount>[] => {

  const ts = useTranslate();

  const isMobileP = useMediaQuery(mediaQueries.mobilePortrait);

  return [
    {
      key: 'service',
      orderBy: {
        asc: ServiceSubscriptionOrder.NameAsc,
        desc: ServiceSubscriptionOrder.NameDesc
      },
      hidden: columnsConfig.isServiceHidden ?? defaultServicesConfig.isServiceHidden,
      renderHead: () => ts('page.userProfile.services.service'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => (
        <CellService serviceSubscription={serviceSubscription} />
)
    },
    {
      key: 'description',
      hidden: columnsConfig.isDescriptionHidden ?? defaultServicesConfig.isDescriptionHidden,
      renderHead: () => ts('common.terminology.description'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => !isMobileP ? serviceSubscription.service.description : (
          <IconButtonWithPopOver
            customIcon={<MoreIcon />}
            description={serviceSubscription.service.description ?? ''}
          />
        )
    },
    {
      key: 'family',
      orderBy: {
        asc: ServiceSubscriptionOrder.FamilyAscThenNameAsc,
        desc: ServiceSubscriptionOrder.FamilyDescThenNameAsc
      },
      hidden: columnsConfig.isFamilyHidden ?? defaultServicesConfig.isFamilyHidden,
      renderHead: () => ts('page.userProfile.services.family'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => serviceSubscription.service.family
    },
    {
      key: 'account',
      orderBy: {
        asc: ServiceSubscriptionOrder.AccountLabelAscThenNameAsc,
        desc: ServiceSubscriptionOrder.AccountLabelDescThenNameAsc
      },
      hidden: columnsConfig.isAccountHidden ?? defaultServicesConfig.isAccountHidden,
      renderHead: () => ts('common.terminology.account'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) =>
        serviceSubscription.service.groupCode === 'ADM-2'
          ? serviceSubscription.targetAccount!.name
          : serviceSubscription.account.name
    },
    {
      key: 'administratedValidation',
      hidden: columnsConfig.isAdminValidationHidden ?? defaultServicesConfig.isAdminValidationHidden,
      renderHead: () => ts('page.userProfile.services.withValidation'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="100%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => (<CellAdministratedValidation serviceSubscription={serviceSubscription} />)
    },
    {
      key: 'accessByDefault',
      hidden: columnsConfig.isAccessByDefaultHidden ?? defaultServicesConfig.isAccessByDefaultHidden,
      renderHead: () => ts('page.userProfile.services.auto'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="100%" />,
      renderCell: ({
        row: serviceSubscription
      }: {
        row: IServiceSubscription;
      }) => (<CellAccessByDefault serviceSubscription={serviceSubscription} />)
    }
  ];
};
