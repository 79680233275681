import { Grid, IconButton, Tooltip } from '@mui/material';
import Skeleton from 'Theme/components/Skeleton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { TableCollectionColumn } from 'Components/Shared/Table';
import SvgIcon from 'Theme/components/SvgIcon/SvgIcon';
import { mdiCalendarClock } from '@mdi/js';
import TestIds from 'Tests/TestIds';
import { CdsColorActionNeutralActive } from '@cegid/design-tokens';
import useTranslate from './useTranslate';
import { PersonAccessRightStatus } from 'Apollo/fragments/person/PersonAccessRight';
import { useContext } from 'react';
import { PersonContext } from 'Context/CurrentPerson';

export function useActionsOnUserServicesColumns(
  onRemoveAccess: (row: IPersonAccessRight) => void,
  onUpdateUserAccess: (row: IPersonAccessRight) => void
): TableCollectionColumn<IPersonAccessRight>[] {
  const ts = useTranslate();
  const { currentPersonRoles } = useContext(PersonContext);

  let canEdit =
    currentPersonRoles.isAccountAdmin || currentPersonRoles.isServiceAdmin;

  return [
    {
      key: 'manage',
      width: 0.1,
      hidden: false,
      renderHead: () => <span></span>,
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({ row }: { row: IPersonAccessRight }) => (
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          {canEdit &&
            row.status === PersonAccessRightStatus.Accepted &&
            row.personGroupSource === null && (
              <Grid item>
                <Tooltip
                  title={ts(
                    'common.date.editExpirationDate'
                  )}
                >
                  <IconButton
                    onClick={() => onUpdateUserAccess(row)}
                    data-testid={
                      TestIds.pages.userProfile.tabServices.serviceRow
                        .setExpirationAction +
                      row.service.name +
                      '-' +
                      row.creationDate
                    }
                    size="large"
                  >
                    <SvgIcon
                      path={mdiCalendarClock}
                      fill={CdsColorActionNeutralActive}
                      width="24"
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          {canEdit &&
            row.status === PersonAccessRightStatus.Accepted &&
            row.personGroupSource === null && (
              <Tooltip
                title={ts('page.serviceProfile.serviceMembers.removeAccess')}
              >
                <IconButton
                  onClick={() => onRemoveAccess(row)}
                  style={{ float: 'right' }}
                  data-testid={
                    TestIds.pages.userProfile.tabServices.serviceRow
                      .setRemovalAction +
                    row.service.name +
                    '-' +
                    row.creationDate
                  }
                  size="large"
                >
                  <RemoveCircleIcon color="inherit" />
                </IconButton>
              </Tooltip>
            )}
        </Grid>
      )
    }
  ];
}
