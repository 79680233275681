import { gql } from '@apollo/client';
import { PersonAccessRightFragment } from '../../fragments/person/PersonAccessRight';

export const GetPersonAccessRightRequests = gql`
  query GetPersonAccessRightRequests(
    $accountCode: String
    $status: PersonAccessRightRequestStatus
    $immutableId: String
    $page: PageInput
    $infoPerson: String
  ) {
    personAccessRightRequests(
      personAccessRightSpecifiedFilter: {
        accountCode: $accountCode
        status: $status
        immutableId: $immutableId
        infoPerson: $infoPerson
      }
      page: $page
    ) {
      edges {
        node {
          ...PersonAccessRight
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${PersonAccessRightFragment}
`;
