import { useMutation } from '@apollo/client';
import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import { CreateApiKey } from 'Apollo/mutations/apiKey/createApiKey';
import { GetApiKeys } from 'Apollo/queries/apikey/GetApiKeys';
import { useState } from 'react';
import GenerateApiKeyDialog from './GenerateApiKeyDialog';

interface Props {
  accountCode: string;
  onClose: () => void;
  isOpen: boolean;
}

const GenerateApiKeyDialogContainer = (props: Props) => {
  const { accountCode, isOpen, onClose } = props;

  const [activeStep, setActiveStep] = useState<number>(0);
  const [newApiKeyName, setNewApiKeyName] = useState<string>('');
  const [newApiKeyUuid, setApiKeyUuid] = useState<string>('');
  const [selectedServiceCode, setSelectedServiceCode] = useState<string>('');
  const [newApiKeySecret, setNewApiKeySecret] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const onChangeSelectedService = (code: string) => {
    setSelectedServiceCode(code);
    setErrorMessage(null);
  };

  const onChangeApiKeyName = (name: string) => {
    setNewApiKeyName(name);
    setErrorMessage(null);
  };

  const [onCreateApiKey, { loading }] = useMutation(CreateApiKey, {
    onCompleted: (data: CreateApiKeyData) => {
      setApiKeyUuid(data.createApiKey.uuid);
      setNewApiKeySecret(data.createApiKey.secret);
      errorMessage === null && handleNext();
    },
    onError: error => setErrorMessage(error.message),
    refetchQueries: [
      {
        query: GetApiKeys,
        variables: {
          accountFilter: accountCode,
          page: { first: 14, offset: 0 },
          serviceFilter: null,
          state: ApiKeyState.Active
        },
        fetchPolicy: 'cache-first'
      },
      'GetApiKeys'
    ]
  });

  const handleCreateApiKey = () => {
    onCreateApiKey({
      variables: {
        accountCode: accountCode,
        name: newApiKeyName,
        serviceGroupCode: selectedServiceCode
      }
    });
  };

  return (
    <GenerateApiKeyDialog
      isOpen={isOpen}
      onClose={onClose}
      activeStep={activeStep}
      onSelectService={onChangeSelectedService}
      onUpdateApiKeyName={onChangeApiKeyName}
      onSubmit={handleCreateApiKey}
      isCreating={loading}
      accountCode={accountCode}
      errorDuringCreation={errorMessage}
      selectedServiceCode={selectedServiceCode}
      newApiKeyName={newApiKeyName}
      newApiKeySecret={newApiKeySecret}
      newApiKeyUuid={newApiKeyUuid}
    />
  );
};

export default GenerateApiKeyDialogContainer;
