import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Typography
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useStyles } from '../../ServiceProfile/ServiceProfileGeneral/ServiceProfileGeneral.styles';
import useTranslate from '../../../Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import { DataRow } from 'Theme/components/DataRow';

interface Props {
  serviceSubscription: IExternalServiceSubscription;
}

const General = ({ serviceSubscription: subscription }: Props) => {
  const classes = useStyles();
  const {
    accessByDefault,
    administratedValidation,
    service,
    account,
    targetAccount
  } = subscription;
  const ts = useTranslate();

  const boolToText = (o: boolean) =>
    ts(o ? 'common.action.yes' : 'common.action.no');

  const defaultServiceT = boolToText(accessByDefault);
  const adminValidationT = boolToText(administratedValidation);
  const isExternalisableT = boolToText(service.isExternalisable);

  return (
    <Container>
      <Box className={classes.header}>
        <Avatar variant="square" classes={{ root: classes.avatar }}>
          {service.name?.charAt(0)}
        </Avatar>
        <Typography variant="h5" color="textSecondary">
          <FormattedMessage id="page.serviceProfile.youConsultService" />
        </Typography>
        <Typography variant="h5">{service.name}</Typography>
      </Box>
      <Card className={classes.blueCard}>
        <CardContent>
          <Box p={2}>
            <Typography
              className={classes.colorWhite}
              variant="h5"
              data-testid={TestIds.pages.serviceProfile.infoTitle}
              children={ts('page.externalserviceProfile.title')}
              paragraph
            />
            <Typography
              className={classes.colorWhiteB}
              variant="body2"
              data-testid={TestIds.pages.serviceProfile.infoTitle}
              children={ts('page.externalserviceProfile.details', {
                service: service.name,
                targetAccount: targetAccount.name
              })}
            />
            <Box p={2} />
            <Typography
              variant="body1"
              className={classes.colorWhite}
              data-testid={TestIds.pages.serviceProfile.infoSubtitle}
              children={ts('common.accountLabel', {
                ...account
              })}
            />
            <Box p={1} />
            <ul>
              <li className={classes.colorWhite}>
                <Typography
                  className={classes.colorWhite}
                  variant="body1"
                  data-testid={TestIds.pages.serviceProfile.defaultService}
                >
                  {ts('page.serviceProfile.info.defaultService')}{' '}
                  <b>{defaultServiceT}</b>
                </Typography>
              </li>
              <li className={classes.colorWhite}>
                <Typography
                  className={classes.colorWhite}
                  variant="body1"
                  data-testid={TestIds.pages.serviceProfile.adminValidation}
                >
                  {ts('page.serviceProfile.info.adminValidation')}{' '}
                  <b>{adminValidationT}</b>
                </Typography>
              </li>
            </ul>
          </Box>
        </CardContent>
      </Card>
      <Box p={1} />
      <Card>
        <CardContent>
          <Box p={2}>
            <Typography
              variant="h5"
              color="inherit"
              data-testid={TestIds.pages.serviceProfile.aboutTitle}
              children={ts('page.serviceProfile.aboutService')}
            />
            <Box p={2} />
          </Box>
          <>
            <DataRow label={ts('common.terminology.description')} divider>
              <Typography
                variant="body2"
                data-testid={TestIds.pages.serviceProfile.serviceDescription}
                children={service.description}
              />
            </DataRow>
            <DataRow label={ts('profile.about.serviceFamily')} divider>
              <Typography
                variant="body2"
                children={service.family}
                data-testid={TestIds.pages.serviceProfile.serviceFamily}
              />
            </DataRow>
            <DataRow label="Code du service" divider>
              <Typography
                variant="body2"
                children={service.groupCode}
                data-testid={TestIds.pages.serviceProfile.serviceGroup}
              />
            </DataRow>
            <DataRow label={ts('profile.about.outsourceableService.title')}>
              <Typography
                variant="body2"
                children={isExternalisableT}
                data-testid={TestIds.pages.serviceProfile.serviceExternalisable}
              />
            </DataRow>
          </>
        </CardContent>
      </Card>
    </Container>
  );
};

export default General;
