import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggableRubric,
  DraggableLocation
} from 'react-beautiful-dnd';
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
  Button,
  IconButton,
  Popover,
  Theme,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {
  DragIndicator as IconDragIndicator,
  ViewColumn as IconViewColumn
} from '@mui/icons-material';
import { zIndex, colors } from '../../constants';
import { TableCollectionColumn } from './TableCollection';
import TestIds from 'Tests/TestIds';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 360
    },
    header: {
      padding: theme.spacing(3)
    },
    headerTitle: {
      marginBottom: theme.spacing(1)
    },
    fiveFirstLi: {
      maxHeight: 248,
      overflow: 'auto'
    },
    footer: {
      display: 'flex',
      padding: theme.spacing(1)
    },
    footerLeft: {
      flex: 1
    },
    footerRight: {
      '& > *': {
        marginLeft: theme.spacing(1)
      }
    },
    isActive: {
      color: colors.blue[500],
      backgroundColor: colors.blue[30]
    },
    isActiveAndDragged: {
      color: colors.blue[500],
      backgroundColor: colors.blue[40]
    },
    isHiddenAndDragged: {
      backgroundColor: colors.grey[250]
    },
    modal: {
      zIndex: zIndex.appBar + 1
    }
  });

interface Props {
  onChange: (columns: TableCollectionColumn[]) => void;
  onReset: () => void;
  columns: TableCollectionColumn[];
}

interface State {
  isOpen: boolean;
  anchorEl: any;
  columns: TableCollectionColumn[];
}

class TableCollectionColumnSelection extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  public readonly state: State = {
    isOpen: false,
    anchorEl: null,
    columns: []
  };

  private buttonRef = React.createRef<HTMLButtonElement>();

  public render() {
    const { classes } = this.props;
    const { isOpen, columns, anchorEl } = this.state;

    // <Draggable /> is in the <Popper /> which has a position: fixed; and transform: ...;
    // It's causing issues with positionning, the documented way to work around that is to use "renderClone":
    // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/draggable.md#warning-position-fixed
    // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/reparenting.md
    const renderItem = (
      provided: DraggableProvided,
      snapshot: DraggableStateSnapshot,
      rubric: DraggableRubric
    ) => {
      const columnIndex = (rubric as DraggableRubric & {
        // The type definition for DraggableRubric is not up to date and misses the "source".
        source: DraggableLocation;
      }).source.index;
      const { key, renderHead, hidden } = columns[columnIndex];

      return (
          <ListItem
            ref={provided.innerRef}
            button
            disableRipple
            onMouseEnter={(
              event: React.MouseEvent<HTMLDivElement, MouseEvent>
            ) =>
              !hidden
                ? (event.currentTarget.style.backgroundColor = colors.blue[20])
                : (event.currentTarget.style.backgroundColor = colors.grey[200])
            }
            onMouseLeave={(
              event: React.MouseEvent<HTMLDivElement, MouseEvent>
            ) => (event.currentTarget.style.backgroundColor = '')}
            className={
              !hidden && !snapshot.isDragging
                ? classes.isActive
                : !hidden && snapshot.isDragging
                ? classes.isActiveAndDragged
                : hidden && snapshot.isDragging
                ? classes.isHiddenAndDragged
                : ''
            }
            ContainerProps={{
              ...provided.draggableProps,
              style: {
                ...provided.draggableProps.style,

                // The below style is usually inherited from the parent <List />
                // but it's lost when cloned as the <li> is taken out of its parent <ul>.
                listStyle: 'none',
                margin: 0,
                padding: 0
              }
            }}
          >
            <ListItemIcon
              {...provided.dragHandleProps}
              {...snapshot.isDragging}
            >
              <IconDragIndicator color={!hidden ? 'primary' : 'inherit'} />
            </ListItemIcon>
            <ListItemText primary={renderHead()} />
            <ListItemSecondaryAction>
              <Switch
                color="primary"
                name={key}
                onChange={({
                  target: { checked }
                }: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState(currentState => ({
                    columns: currentState.columns.map(column =>
                      column.key === key
                        ? { ...column, hidden: !checked }
                        : column
                    )
                  }))
                }
                checked={!hidden}
                inputProps={{
                  name: key,
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
      );
    };

    return <>
      <IconButton
        ref={this.buttonRef}
        onClick={this.onOpen}
        data-testid={TestIds.common.columnsSettings}
        size="large">
        <IconViewColumn />
      </IconButton>
      <Popover
        open={isOpen}
        onClose={this.onCancel}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        classes={{
          root: classes.modal
        }}
      >
        <Paper classes={{ root: classes.root }}>
          <div className={classes.header}>
            <Typography variant="h6" classes={{ root: classes.headerTitle }}>
              <FormattedMessage id="tableCollectionColumnSelection.title" />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id="tableCollectionColumnSelection.hint" />
            </Typography>
          </div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable" renderClone={renderItem}>
              {provided => (
                  <List
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={classes.fiveFirstLi}
                  >
                    {columns.map(({ key }, index) => (
                      <Draggable key={key} draggableId={key} index={index}>
                        {renderItem}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
              )}
            </Droppable>
          </DragDropContext>
          <div className={classes.footer}>
            <div className={classes.footerLeft}>
              <Button onClick={this.onReset}>
                <FormattedMessage id="common.action.reset" />
              </Button>
            </div>
            <div className={classes.footerRight}>
              <Button onClick={this.onCancel} color='inherit'>
                <FormattedMessage id="common.action.cancel" />
              </Button>
              <Button color="primary" onClick={this.onSave}>
                <FormattedMessage id="common.action.ok" />
              </Button>
            </div>
          </div>
        </Paper>
      </Popover>
    </>;
  }

  private onDragEnd = (result: DropResult) => {
    if (result.destination == null) {
      return;
    }

    this.setState(({ columns }) => {
      const reordered = Array.from(columns);
      const [removed] = reordered.splice(result.source.index, 1);
      reordered.splice(result.destination!.index, 0, removed);

      return { columns: reordered };
    });
  };

  private onOpen = () => {
    const { columns } = this.props;

    this.setState({
      isOpen: true,
      anchorEl: this.buttonRef.current,
      columns
    });
  };

  private onCancel = () => {
    this.setState({
      isOpen: false,
      anchorEl: null
    });
  };

  private onReset = () => {
    const { columns } = this.props;

    this.setState({
      columns
    });
  };

  private onSave = () => {
    const { columns } = this.state;

    this.setState({ isOpen: false }, () => {
      this.props.onChange(columns);
    });
  };
}

export default withStyles(styles)(TableCollectionColumnSelection);
