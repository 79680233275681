import React from 'react';
import { useFormikContext } from 'formik';
import { Button } from '@mui/material';

interface Props {
  title: string | React.ReactNode;
}

function ResetButton({ title }: Props) {
  const { resetForm } = useFormikContext();
  return (
    <Button
      aria-label="reset"
      variant="text"
      onClick={resetForm as any}
      color='inherit'
    >
      {title}
    </Button>
  );
}

export default ResetButton;
