import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    paper: {
      marginBottom: theme.spacing(2)
    },
    blockTitle: {
      padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
      height: theme.spacing(8)
    },
    formControlLabel: {
      marginRight: theme.spacing(4)
    }
  })
)