import { gql } from '@apollo/client';
import { LightPersonProfileFragment } from '../../fragments/person/LightPersonProfile';

export const GetServiceOwners = gql`
  query GetServiceOwners(
    $serviceSubscriptionIdentifier: serviceSubscriptionIdentifier!
    $page: PageInput
  ) {
    serviceOwners(
      serviceSubscriptionIdentifier: $serviceSubscriptionIdentifier
      page: $page
    ) {
        edges {
            node {
              ...LightPersonProfile
            }
        }
        pageInfo {
            hasNextPage
        }
        totalCount
    }
  }
  ${LightPersonProfileFragment}
`;
