import { gql } from '@apollo/client';

export const RemoveSelectedDelegationAdministration = gql`
  mutation RemoveSelectedDelegationAdministration(
    $serviceSubscriptionIdentifier: serviceSubscriptionIdentifier!
    $personIdentifier: String!
  ) {
    removeSelectedDelegationAdministration(
      serviceSubscriptionIdentifier: $serviceSubscriptionIdentifier
      personIdentifier: $personIdentifier
    )
  }
`;
