import { LocalStorageKeys } from "Tools/localStorage";

export type TLocalStorageKeys =
  | 'WELCOME_MODAL_DISABLED'
  | LocalStorageKeys.CsrfToken
  | LocalStorageKeys.ServicesColumns
  | LocalStorageKeys.LightServicesColumns
  | LocalStorageKeys.UsersColumns
  | LocalStorageKeys.LightUsersColumns
  | LocalStorageKeys.GroupAvailabledServices
  | LocalStorageKeys.LightGroupAvailabledServices
  | LocalStorageKeys.GroupCandidatesColumns;


/**
 * Hook to use the local storage object inside the browser window
 */
export const useLocalStorage = () => {
  const setLocal = (name: TLocalStorageKeys, value: any) => {
    const valueToAdd = value.toString();
    localStorage.setItem(name, valueToAdd);
  };
  const getLocal = (name: TLocalStorageKeys) => localStorage.getItem(name);
  const removeLocal = (name: TLocalStorageKeys) =>
    localStorage.removeItem(name);
  const clearLocal = () => localStorage.clear();
  return { getLocal, setLocal, removeLocal, clearLocal };
};
