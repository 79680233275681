import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import ApiStateTab from './ApiStateTab';

export default function TabActive(props: { accountCode: string }) {
  return (
    <ApiStateTab
      apiStatus={ApiKeyState.Active}
      accountCode={props.accountCode}
    />
  );
}
