import { Typography, Popover, Button, Paper } from '@mui/material';
import { RemoveCircle as IconRemoveCircle } from '@mui/icons-material';
import * as React from 'react';
import { useMutation } from '@apollo/client';
import TestIds from 'Tests/TestIds';
import { RemovePersonGroup } from 'Apollo';
import ButtonLoading from 'Theme/components/ButtonLoading';
import { useState } from 'react';
import useNotification from 'Hooks/useNotification';
import { useStyles } from 'Components/Popover/Popover.styles';
import useTranslate from 'Hooks/useTranslate';
import { generatePath, useHistory, useParams } from 'react-router';
import { PrivateRoutes } from 'Router/Routes';

interface Props {
  personGroup: IPersonGroup;
}

function RemoveGroupButton(props: Readonly<Props>) {
  const classes = useStyles();
  const ts = useTranslate();
  const history = useHistory();
  const accountCode = useParams<{ accountCode: string }>().accountCode;

  const { personGroup } = props;

  const buttonRef = React.createRef<HTMLButtonElement>();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { onSuccess, onError } = useNotification();

  const variables: RemovePersonGroupVars = {
    code: personGroup.code
  };

  const [removePersonGroup, { loading }] = useMutation(RemovePersonGroup, {
    onError: (e)=> {
      setIsOpen(false);
      onError(e)
    },
    onCompleted: () => {
      onSuccess('page.groupProfile.removeGroup.removeSuccess', {groupName: personGroup.name});
      setIsOpen(false);
      history.push(
        generatePath(PrivateRoutes.personGroups.path, {
          accountCode
        })
      );
    }
  });

  const handleRemove = async () => {
    await removePersonGroup({
      variables: { ...variables },
      fetchPolicy: 'network-only'
    });
  };

  return (
    <>
      <Button
        ref={buttonRef}
        data-testid={TestIds.pages.groupProfile.removeGroup.removeGroupButton}
        variant="outlined"
        color="inherit"
        type="button"
        aria-roledescription={ts('common.action.delete')}
        onClick={() => {
          setIsOpen(true);
          setAnchorEl(buttonRef.current);
        }}
        endIcon={<IconRemoveCircle />}
      >
        {ts('common.action.delete')}
      </Button>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setIsOpen(false);
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Paper className={classes.paper}>
          <Typography variant="body2" className={classes.text}>
            {ts('page.groupProfile.removeGroup.removeGroupConfirmation', {
              groupName: personGroup.name
            })}
          </Typography>
          <Button
            size="medium"
            type="button"
            color='inherit'
            className={classes.button}
            onClick={() => setIsOpen(false)}
          >
            {ts('common.action.cancel')}
          </Button>
          <ButtonLoading
            data-testid={
              TestIds.pages.groupProfile.removeGroup.confirmDeletionButton
            }
            loading={loading}
            type="submit"
            size="medium"
            color="primary"
            variant="contained"
            onClick={handleRemove}
          >
            {ts('common.action.confirm')}
          </ButtonLoading>
        </Paper>
      </Popover>
    </>
  );
}

export default RemoveGroupButton;
