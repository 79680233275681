import { Button, Grid } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import ButtonLoading from 'Theme/components/ButtonLoading';

interface Props {
  expiryDate?: Date | null;
  loading: boolean;
  handleSubmit(): void;
  onCancel(): void;
}

const ExpirationDateActionButtons = ({
  expiryDate,
  loading,
  handleSubmit,
  onCancel
}: Props) => {
  const ts = useTranslate();

  return (
    <Grid item>
      <Grid container justifyContent="center">
        <Button type="button" size="medium" onClick={onCancel} color="inherit">
          {ts('common.action.cancel')}
        </Button>
        {expiryDate !== undefined && (
          <ButtonLoading
            name={TestIds.component.setExpirationDate.confirmButton}
            type="submit"
            size="medium"
            color="primary"
            variant="contained"
            loading={loading}
            disabled={expiryDate === undefined}
            onClick={handleSubmit}
            style={{ marginLeft: 16 }}
          >
            {ts('common.action.confirm')}
          </ButtonLoading>
        )}
      </Grid>
    </Grid>
  );
};

export default ExpirationDateActionButtons;
