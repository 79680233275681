import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { colors, palette } from 'Theme/constants';

export const useStyles = makeStyles((theme: Theme) => ({
    requestBanner: {
        backgroundColor: palette.background.dark
    },
    pendingBanner: {
        backgroundColor: theme.palette.primary.main
    },
    banner: {
        padding: `0px ${theme.spacing(1)} 0px ${theme.spacing(2)}`,
        height: theme.spacing(6),
        display: 'flex',
        flexWrap: 'nowrap'
    },
    bannerText: {
        color: colors.white,
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: theme.spacing(1)
    }
}));