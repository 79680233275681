import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  boxMargin: {
    margin: '30px auto'
  },
  date: {
    textTransform: 'uppercase',
    fontSize: '12px',
    letterSpacing: '2px'
  },
  circularClearer: {
    opacity: '0.3',
    marginTop: '44px'
  },
  smallerIcon: {
    fontSize: '35px !important'
  },
  textAlignCenter: {
    textAlign: 'center'
  }
}));
