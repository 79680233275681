import { gql } from '@apollo/client';
import { AvatarFragment } from './Avatar';

export const LightPersonProfileFragment = gql`
  fragment LightPersonProfile on LightPersonProfile {
    avatar {
      ...Avatar
    }
    firstName
    lastName
    email
    immutableId
  }
  ${AvatarFragment}
`;
