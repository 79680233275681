import { gql } from '@apollo/client';
import { AccountWithNotificationSettingsFragment } from '../../fragments/account/AccountWithNotificationSettings';

export const GetAccountWithNotificationSettings = gql`
  query GetAccountWithNotificationSettings($accountCode: String!) {
    account(accountCode: $accountCode) {
      ...AccountWithNotificationSettings
    }
  }
  ${AccountWithNotificationSettingsFragment}
`;
