import { Card, CardContent, Grid, Typography } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent';
import { useStyles } from './ServiceAccountDistribution.style';

type P = { colors: string[] };
export type TProps = TooltipProps<ValueType, NameType> & P;
export const ToolTipContent = (props: TProps) => {
  const classes = useStyles();
  const { active, payload = [], colors } = props;
  if (!active || payload.length < 1) {
    return null;
  }

  const tooltipPayload = payload[0].payload;
  if (!tooltipPayload) {
    return null;
  }

  const data = tooltipPayload.payload;

  return (
    <Card classes={{ root: classes.tooltip }} style={{ flexDirection: 'row' }}>
      <CardContent style={{ padding: 8 }}>
        <Typography>
          <Grid
            container={true}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item={true}>
              <FiberManualRecord style={{ color: colors[data.index % 5] }} />
            </Grid>
            <Grid item={true}>
              <FormattedMessage
                id="page.services.index.accountDistribution"
                values={{ ...data }}
              />
            </Grid>
          </Grid>
        </Typography>
      </CardContent>
    </Card>
  );
};
