import { Paper, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Container from 'Theme/components/Container';
import Skeleton from 'Theme/components/Skeleton';

const styles = (theme: Theme) =>
  createStyles({
    toolbarMixin: theme.mixins.toolbar,
    header: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(3)
    },
    paper: {
      padding: theme.spacing(4)
    }
  });

interface Props extends WithStyles<typeof styles> {
  shape: 'text' | 'rectangular' | 'circle';
}

function ProfilePageSkeleton(props: Props) {
  return (
    <Container>
      <div className={props.classes.header}>
        <Skeleton variant={props.shape} width={72} height={72} />
        <Skeleton width={200} />
      </div>

      <Paper classes={{ root: props.classes.paper }}>
        <Skeleton width={300} />
        <Skeleton width={150} />
      </Paper>
    </Container>
  );
}

export default withStyles(styles)(ProfilePageSkeleton);
