import { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  GetAvailableServicesForPersonGroup,
  ServiceSubscriptionOrder
} from 'Apollo';
import { ROW_PER_PAGE_DEFAULT, ROW_PER_PAGE_OPTIONS } from 'Theme/config';
import TableGroupAvailableServices from './TableGroupAvailableServices';
import { useParams } from 'react-router-dom';
import ErrorHandlerContext from 'Context/ErrorHandler.context';
import { TableCollectionColumn } from 'Components/Shared';
import { calculateNewPage } from 'Tools/calculateNewPage';

interface Props {
  selectedIds: string[] | null;
  onUpdate: (ids: string[] | null) => void;
  updateTotalCount: (v: number) => void;
  columns: TableCollectionColumn<IServiceSubscriptionWithCount>[];
}

function TableGroupAvailableServicesContainer(props: Readonly<Props>) {
  const { selectedIds, onUpdate, updateTotalCount, columns } = props;
  const ErrorHandler = useContext(ErrorHandlerContext);

  const { groupCode } = useParams<IGroupIdentifier>();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);
  const [orderBy, setOrderBy] = useState<ServiceSubscriptionOrder>(
    ServiceSubscriptionOrder.NameAsc
  );

  const onChangeOrderBy = (orderBy: ServiceSubscriptionOrder) => {
    setOrderBy(orderBy);
  };

  const onChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
  };

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  let { loading, data, error: queryError, refetch } = useQuery<
    GetAvailableServicesForPersonGroupData
  >(GetAvailableServicesForPersonGroup, {
    onCompleted: data => {
      updateTotalCount(data.availableServicesForPersonGroup.totalCount);
    },
    variables: {
      personGroupCode: groupCode,
      page:{ first: rowsPerPage, offset: currentPage * rowsPerPage },
      searchTerm: null,
      orderBy: orderBy
    },
    onError: ErrorHandler.onError,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  });

  const onUserSearch = async (value: string) => {
    const filterValue = value.trim();

    onUpdate([]);

    await refetch({ searchTerm: filterValue, offset: 0 });
  };

  const rows =
    data?.availableServicesForPersonGroup?.edges?.map(o => o.node) ?? [];

  const totalCount = data?.availableServicesForPersonGroup?.totalCount ?? 0;

  return (
    <TableGroupAvailableServices
      loading={loading}
      rows={rows}
      onUpdate={onUpdate}
      onUpdateSearch={(search: string) => onUserSearch(search)}
      totalCount={totalCount}
      selectedIds={selectedIds}
      onChangeOrderBy={onChangeOrderBy}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      orderBy={orderBy}
      currentPage={currentPage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      error={queryError}
      columns={columns}
    />
  );
}

export default TableGroupAvailableServicesContainer;
