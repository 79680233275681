import { useMutation } from '@apollo/client';
import useNotification from 'Hooks/useNotification';
import { UpdatePersonAccount } from 'Apollo/mutations/person/UpdatePersonAccount';
import { GetPersonWithAccount } from 'Apollo/queries/person/GetPersonWithAccount';
import { GetPersonAccessRights } from 'Apollo';
import UnLinkAccount from './UnLinkAccount';
import { PersonContext } from "Context/CurrentPerson";
import { useContext } from 'react';
import { generatePath } from 'react-router';
import { PrivateRoutes } from 'Router/Routes';
import { LocalContext } from 'Context/Local.context';

interface Props {
  person: IPersonWithAccount;
  accessRights: IPersonAccessRight[];
  isOpen: boolean;
  onClose: () => void;
}

function UnLinkAccountContainer(props: Readonly<Props>) {
  const { person, accessRights, isOpen, onClose } = props;
  const { currentAccount } = useContext(LocalContext);
  const { currentPersonRoles } = useContext(PersonContext);
  const { onError, onSuccess } = useNotification();

  const basePath = generatePath(PrivateRoutes.users.path, {
    accountCode: currentAccount?.code ?? 'all'
  });

  const [onUpdate, { loading }] = useMutation<
    IUpdatePersonAccountData,
    IUpdatePersonAccountVars
  >(UpdatePersonAccount, {
    onError,
    onCompleted: () => {
      onClose();
      onSuccess('component.userAccountUpdate.mutation.unLinkSuccess', {
        value: person.account?.code
      });
      // if current person can't see later this updated profile after action is done, he should be redirected on the users list
      if (!currentPersonRoles.hasAccessToAccounts) {
        window.location.href = basePath;
      }
    },
    refetchQueries: [GetPersonWithAccount, GetPersonAccessRights]
  });

  const handleUpdate = () => {
    onUpdate({
      variables: {
        newAccountCode: null,
        personIdentifier: person.immutableId
      }
    });
  };

  return (
    <UnLinkAccount
      person={person}
      accessRights={accessRights}
      isOpen={isOpen}
      loading={loading}
      onClose={onClose}
      updateAccount={handleUpdate}
    />
  );
}

export default UnLinkAccountContainer;
