import { gql } from '@apollo/client';
import { OrganizationFragment } from 'Apollo';

export const GetOrganization = gql`
  query GetOrganization($code: String!) {
    organization(code: $code) {
      ...Organization
    }
  }
  ${OrganizationFragment}
`;
