import OrganizationProfile from './OrganizationProfile';
import ProfilePageSkeleton from 'Theme/components/ProfilePageSkeleton';
import { useQuery } from '@apollo/client';
import useNotification from 'Hooks/useNotification';
import { useParams } from 'react-router-dom';
import ErrorMsg from 'Components/Shared/ErrorMsg/ErrorMsg';
import { GetOrganization } from 'Apollo/queries/organization/GetOrganization';


const OrganizationProfileContainer = () => {
  const { organizationCode } = useParams<{
    organizationCode: string;
  }>();
  const { onError } = useNotification();

  const { data, loading, error } = useQuery<
    GetOrganizationData, GetOrganizationVars
  >(GetOrganization, {
    variables: {
      code: organizationCode
    },
    onError
  });

  if (loading ?? !data) return <ProfilePageSkeleton shape="rectangular" />;

  if (!loading && error) return <ErrorMsg />;

  return <OrganizationProfile organization={data.organization} />;
};

export default OrganizationProfileContainer;
