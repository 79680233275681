import en from './en';
import fr from './fr';
import es from './es';
import pt from './pt';
import ca from './ca';
import { TTranslationMessagesList } from 'Languages';

const messages: TTranslationMessagesList = {
    en,
    fr,
    es,
    pt,
    ca
  };

  export { messages };
