import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { GetServiceSubscription } from '../../Apollo';
import { Container, ProfilePageSkeleton } from 'Theme/components';
import ServiceProfile from './ServiceProfile';
import ErrorMsg from 'Components/Shared/ErrorMsg';
import useServiceSubscriptionRouteMatch from 'Hooks/useServiceSubscriptionRouteMatch';
import ErrorHandlerContext from 'Context/ErrorHandler.context';
import { LocalContext } from 'Context/Local.context';
import EmptyState from 'Components/Shared/EmptyState';
import ErrorIcon from '@mui/icons-material/Error';
import { Paper } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import { useCurrentUserInfo } from 'Hooks/useCurrentUserInfo';

const ServiceProfileContainer = () => {
  const serviceSubscriptionIdentifier = useServiceSubscriptionRouteMatch();
  const { allAccountsSelected } = useContext(LocalContext);
  const { isAdminOfOnlyOneAccount } = useCurrentUserInfo();

  const ts = useTranslate();

  const ErrorHandler = useContext(ErrorHandlerContext);
  const variables = {
    serviceSubscriptionIdentifier: {
      accountCode: allAccountsSelected
        ? null
        : serviceSubscriptionIdentifier.accountCode,
      serviceCode: serviceSubscriptionIdentifier.serviceCode
    }
  };

  const { data, loading, error } = useQuery<{
    serviceSubscription: IServiceSubscription;
  }>(GetServiceSubscription, {
    variables,
    onError: ErrorHandler.onError,
    skip: allAccountsSelected
  });

  if (allAccountsSelected && !isAdminOfOnlyOneAccount)
    return (
      <Container>
        <Paper sx={{ width: '100%', marginTop: '24px' }}>
          <EmptyState
            primary={ts('error.noAccountSelected')}
            secondary={ts('error.selectAnAccount')}
            icon={<ErrorIcon />}
          />
        </Paper>
      </Container>
    );

  if (loading) return <ProfilePageSkeleton shape="rectangular" />;

  if (error || !data) return <ErrorMsg />;

  return <ServiceProfile service={data.serviceSubscription} />;
};

export default ServiceProfileContainer;
