import { Component, ErrorInfo } from 'react';
import { TTranslationMessagesList } from '../../../Languages';
import { PageError } from 'Page/PageError';
import { messages } from 'Languages/Translation';

type Props = { locale: keyof TTranslationMessagesList };

type State = { hasError: boolean };

export default class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Errors could be sent somewhere to be analyzed.
    // console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <PageError
          title={messages[this.props.locale]['error.oops']}
          textPrimary={messages[this.props.locale]['error.type.E500']}
        />
      );
    }

    return this.props.children;
  }
}
