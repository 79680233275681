import { TTabView } from '../Components/Shared/Tabs/Tabs';
import { PrivateRoutes } from '../Router/Routes';
import { useParams, generatePath } from 'react-router-dom';
import {Info as IconInfo, BubbleChart as IconBubbleChart, Event as IconEvent} from '@mui/icons-material';
import TestIds from 'Tests/TestIds';
import TabGeneral from 'Page/UsersProfile/Tabs/TabGeneral/TabGeneral';
import TabActivities from 'Page/UsersProfile/Tabs/TabActivities/TabActivities';
import TabServices from 'Page/UsersProfile/Tabs/TabServices/TabServices';

interface Props {
  person: IPersonWithAccountAndDirectory;
}

const useTabsUser = ({ person }: Props): TTabView[] => {
  const { accountCode, immutableId } = useParams<{
    accountCode: string;
    immutableId: string;
  }>();

  const getPath = (path: string) =>
    generatePath(path, {
      accountCode,
      immutableId
    });

  const tabs: TTabView[] = [
    {
      icon: <IconInfo />,
      path: PrivateRoutes.usersProfile.path,
      link: getPath(PrivateRoutes.usersProfile.path),
      title: 'profile.menu.general',
      component: <TabGeneral person={person} />,
      testid: TestIds.pages.profile.linkGeneral
    },
    {
      icon: <IconBubbleChart />,
      path: PrivateRoutes.usersProfileServices.path,
      link: getPath(PrivateRoutes.usersProfileServices.path),
      title: 'profile.menu.services',
      component: <TabServices person={person}/>,
      testid: TestIds.pages.profile.linkServices
    },
    {
      icon: <IconEvent />,
      path: PrivateRoutes.usersProfileActivities.path,
      link: getPath(PrivateRoutes.usersProfileActivities.path),
      title: 'profile.menu.activities',
      component: <TabActivities person={person} />,
      testid: TestIds.pages.profile.linkActivities
    }
  ];

  return tabs;
};

export default useTabsUser;
