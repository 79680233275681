import { Typography, Tooltip, Box } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PersonAvatar from 'Theme/components/Avatar/PersonAvatar';
import {
  FormattedDate,
  FormattedRelativeTime,
  FormattedTime
} from 'react-intl';
import { selectUnit } from '@formatjs/intl-utils';
import ApiKeyCardAction from './ApiKeyCardAction';
import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import { IApiKey } from 'Apollo/fragments/apikey/apiKeyTypes';
import { useStyles } from './ApiKey.styles';
import useTranslate from 'Hooks/useTranslate';
import { compareDates } from 'Tools/compareDates';
import testIds from 'Tests/TestIds';
import CardWithAvatar from 'Components/Shared/CardWithAvatar/CardWithAvatar';

interface Props {
  api: IApiKey;
  refetchQueries: any;
}

function ApiKeyCard(props: Readonly<Props>) {
  const classes = useStyles();
  const ts = useTranslate();
  const { api, refetchQueries } = props;

  return (
    <CardWithAvatar 
      avatar={ <VpnKeyIcon classes={{ root: classes.keyIcon }} />}
      cardHeader={
        api.creationDate !== null && api.state === ApiKeyState.Active ? (
          <Tooltip
            title={
              <>
                <FormattedDate value={api.creationDate} /> -{' '}
                <FormattedTime value={api.creationDate} />
              </>
            }
          >
            <Typography variant="caption" classes={{ root: classes.subtitle1 }} data-testid={testIds.component.apikeycard.creationDate + api.uuid}>
              {compareDates(new Date(api.creationDate), new Date()) ? (
                ts('common.dates.thisInstant')
              ) : (
                <FormattedRelativeTime
                  {...selectUnit(new Date(api.creationDate))}
                />
              )}
            </Typography>
          </Tooltip>
        ) : (
          <div title={ts('page.apiKeys.undefinedCreationDate')} />
        )
      }
      cardContent={
        <>
        <Typography variant="subtitle1" classes={{ root: classes.subtitle1 }}>
            {api.name}
          </Typography>
          <Box height="8px" />
          <Typography variant="body2" className={classes.body2}>
            {/* For now one key is attached to one service. In future it will be possible to assign an apikey to many services */}
            {api.accessRights?.[0]?.label ?? ''}
          </Typography>
        </>
      }
      cardFooter={
        <>
          <PersonAvatar
            person={api.creator}
            size="small"
            tooltipTitle={
              api.creator !== undefined && api.creator !== null
                ? undefined
                : ts('page.apiKeys.undefinedCreator')
            }
          />
          {api !== null && (
            <ApiKeyCardAction
              key={api.uuid}
              api={api}
              refetchQueries={refetchQueries}
            />
          )}
        </>
      }
    />
  );
}

export default ApiKeyCard;
