import { Box, Avatar, Typography, Paper } from '@mui/material';
import { useStyles } from './TopProfile.styles';
import { CdsColorBackgroundPrimaryMain } from '@cegid/design-tokens';
import ContextualHelp from '../ContextualHelp/ContextualHelp';

interface Props {
  name: string;
  colorBlockBgColor?: string;
  youConsultSentence?: string;
  colorBlockTitle?: string;
  colorBlockSubTitle?: string;
  contextualHelpContent?: string;
  titleId?: string;
  subtitleId?: string;
}

function TopProfile({ name, colorBlockBgColor, youConsultSentence, colorBlockTitle, colorBlockSubTitle, contextualHelpContent, titleId, subtitleId }: Readonly<Props>) {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.header}>
        <Avatar variant="square" classes={{ root: classes.avatar }} role="avatar" style={{backgroundColor: colorBlockBgColor}}>
          {name?.charAt(0)}
        </Avatar>
        <Typography variant="h5" color="textSecondary" paragraph>
          {youConsultSentence}
        </Typography>
        <Typography variant="h5">{name}</Typography>
      </Box>
      <Paper
        style={{
          padding: '2em',
          marginBottom: '1em',
          background: colorBlockBgColor ?? CdsColorBackgroundPrimaryMain,
          color: 'white'
        }}
      >
        <Typography paragraph variant="h5" data-testid={titleId}>
          {colorBlockTitle}
        </Typography>
        <Typography
          variant="body1"
          component="div"
          style={{ opacity: 0.6, display: 'flex', alignItems: 'center' }}
          data-testid={subtitleId}
        >
          {colorBlockSubTitle}
          {contextualHelpContent &&(
            <ContextualHelp
              color="inherit"
              content={contextualHelpContent}
              role="help"
            />
          )}
          
        </Typography>
      </Paper>
    </>
  );
}

export default TopProfile;
