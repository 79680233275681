import { Paper, Button } from '@mui/material';
import { Add as IconAdd } from '@mui/icons-material';
import TestIds from 'Tests/TestIds';
import PaperTitle from 'Theme/components/PaperTitle';
import { useContext, useState } from 'react';
import AddServicesToGroupDrawer from './AddServicesToGroupDrawer/AddServicesToGroupDrawer';
import PersonGroupServicesContainer from './PersonGroupServices/PersonGroupServices.container';
import { useStyles } from './PersonGroupServices/PersonGroupServices.styles';
import { PersonContext } from 'Context/CurrentPerson';
import useTranslate from 'Hooks/useTranslate';

interface Props {
  group: IPersonGroup;
}

const TabServices = ({ group }: Readonly<Props>) => {
  const [isAddingServices, setIsAddingService] = useState<boolean>(false);
  const classes = useStyles();
  const { currentPersonRoles } = useContext(PersonContext);
  const ts = useTranslate();

  return (
    <>
      <Paper classes={{ root: classes.paper }}>
        <PaperTitle
          title={ts('page.services.title')}
          secondaryAction={
            currentPersonRoles.isAccountAdmin && (
              <Button
                color="primary"
                onClick={() => setIsAddingService(true)}
                data-testid={
                  TestIds.pages.groupProfile.services.addServiceButton
                }
              >
                <IconAdd style={{ marginRight: 8 }} />
                {ts('page.personGroupProfile.tabServices.addServiceButton')}
              </Button>
            )
          }
        />
        <PersonGroupServicesContainer group={group} />
      </Paper>
      <AddServicesToGroupDrawer
        onClose={() => setIsAddingService(false)}
        isOpen={isAddingServices}
      />
    </>
  );
};

export default TabServices;
