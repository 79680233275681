import { Grid, Tooltip, Typography } from '@mui/material';
import ServiceProfileLink from 'Components/Shared/ServiceProfileLink';
import Adm2ServiceProfileLink from 'Components/Shared/Adm2ServiceProfileLink';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import useTranslate from 'Hooks/useTranslate';

interface Props {
  serviceSubscription: IServiceSubscription;
}

function CellService(props: Readonly<Props>) {
  const ts = useTranslate();
  const { serviceSubscription } = props;

  if(serviceSubscription.service.groupCode === 'ADM-3' || serviceSubscription.service.groupCode === 'ADM-4') {
    return <Typography>{serviceSubscription.service.name}</Typography>
  }

  if(serviceSubscription.service.groupCode === 'ADM-2') {
    return <Adm2ServiceProfileLink serviceSubscription={serviceSubscription} />
  }

  if(serviceSubscription.isAvailable === false){
    return(
      <Grid container alignItems="center">
          <Grid item>
            <ServiceProfileLink serviceSubscription={serviceSubscription} />
          </Grid>
          <Grid item>
            <Tooltip title={ts('common.service.unavailable')}>
              <InfoOutlinedIcon fontSize="inherit" style={{ marginLeft: 8 }} />
            </Tooltip>
          </Grid>
        </Grid>
    )
  } 
  else {
    return <ServiceProfileLink serviceSubscription={serviceSubscription} />
  }

}

export default CellService;
