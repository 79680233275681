import { useQuery } from '@apollo/client';
import Skeleton from 'Theme/components/Skeleton';
import InfiniteScroll from 'Theme/components/InfiniteScroll';
import Container from 'Theme/components/Container';
import TestIds from 'Tests/TestIds';
import { GetApiKeys } from 'Apollo/queries/apikey/GetApiKeys';
import NoApiKey from '../NoApiKey';
import ApiKeyCard from '../ApiKeyCard';
import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import { IApiKey } from 'Apollo/fragments/apikey/apiKeyTypes';
import { useStyles } from '../ApiKey.styles';
import useNotification from 'Hooks/useNotification';

interface Props {
  accountCode: string;
  apiStatus: ApiKeyState;
}

function ApiStateTab(props: Props) {
  const { accountCode, apiStatus } = props;
  const { onError } = useNotification();
  const variables = {
    accountFilter: accountCode !== 'all' ? accountCode : '',
    page: { first: 14, offset: 0 },
    serviceFilter: null,
    state: apiStatus
  };

  const { data, loading, fetchMore } = useQuery(GetApiKeys, {
    variables,
    onError
  });

  const classes = useStyles();
  let apiKeys: Array<{ node: IApiKey }> = data?.apiKeys
    ? data.apiKeys.edges
    : [];

  return (
    <div style={{ top: '24px', position: 'relative' }}>
      <Container>
        {loading && (
          <div className={classes.cards}>
            {Array.from({ length: 8 }).map((_, index) => (
              <Skeleton
                variant="rectangular"
                width="200px"
                height="200px"
                classes={{ root: classes.card }}
                key={index as any}
              />
            ))}
          </div>
        )}
        {!loading && data?.apiKeys.totalCount === 0 ? (
          <NoApiKey />
        ) : (
          <InfiniteScroll
            onFetchMore={() => {
              if (data == null || data.apiKeys == null) {
                return;
              }

              if (data.apiKeys.edges.length >= data.apiKeys.totalCount) {
                return;
              }

              fetchMore({
                variables: {
                  offset: data.apiKeys.edges.length
                },
                updateQuery: (prev: GetApiKeysData, { fetchMoreResult }) => {
                  if (fetchMoreResult == null) {
                    return prev;
                  }
                  return Object.assign({}, prev, {
                    apiKeys: Object.assign({}, prev.apiKeys, {
                      edges: prev.apiKeys.edges.concat(
                        fetchMoreResult.apiKeys.edges
                      )
                    })
                  });
                }
              });
            }}
          >
            <div
              className={classes.cards}
              data-testid={TestIds.pages.apiKeys.apiSection}
            >
              {apiKeys.map(
                (r, index) =>
                  r.node !== null && (
                    <ApiKeyCard
                      key={index as any}
                      api={r.node}
                      refetchQueries={[
                        {
                          // refetch api keys card list
                          query: GetApiKeys,
                          variables: variables
                        }
                      ]}
                    />
                  )
              )}
            </div>
          </InfiniteScroll>
        )}
      </Container>
    </div>
  );
}

export default ApiStateTab;
