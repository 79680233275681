import { Box, Button, Grid, Typography, Alert } from '@mui/material';
import { Imgs } from 'Assets';
import useTranslate from 'Hooks/useTranslate';
import { useStyles } from './FormUsersCreation.styles';
import TestIds from 'Tests/TestIds';

type Props = {
  invitationsQuantity: number;
  failuredInvitations: IUserAccountInvitation[];
  onClose(): void;
};

const FormUsersCreationSuccess = ({
  invitationsQuantity,
  failuredInvitations,
  onClose
}: Props) => {
  const ts = useTranslate();
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      spacing={1}
      className={classes.containerSuccess}
    >
      <Grid item container justifyContent="center">
        <img
          src={Imgs.mailIcon.src}
          alt={Imgs.mailIcon.alt}
          width="150"
          height="150"
        />
      </Grid>
      <Grid container justifyContent="center" item>
        <Typography
          variant="h5"
          paragraph
          data-testid={TestIds.component.searchByUser.searchIcon}
        >
          {ts('component.modals.createUser.invitationSent', {
            invitationsQuantity
          })}
        </Typography>
      </Grid>
      {failuredInvitations.length > 0 && (
        <Grid item>
          <Typography variant="h5" paragraph>
            {ts('component.modals.createUser.invitationFailed')}
          </Typography>
          <Box className="failureBox">
            {failuredInvitations.map((inv: any) => (
              <Alert severity="error" key={inv.error.message}>
                {inv.error.message}
              </Alert>
            ))}
          </Box>
        </Grid>
      )}
      <Grid item container justifyContent="center" spacing={2}>
        <Grid item>
          <Button color="primary" variant="contained" onClick={onClose}>
            {ts('common.action.close')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormUsersCreationSuccess;
