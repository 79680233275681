import { Paper } from '@mui/material';
import PaperTitle from 'Theme/components/PaperTitle';

interface Props {
  title: string;
  info?: string;
  children: any;
}

function SectionWithTitle(props: Readonly<Props>) {
  const { title, info, children } = props;

  return (
    <Paper style={{ marginBottom: '1em' }} key={title}>
      <PaperTitle title={title} info={info ?? undefined} />
      {children}
    </Paper>
  );
}

export default SectionWithTitle;
