import { gql } from '@apollo/client';

export const GetDirectoriesExtendedInfo = gql`
  query GetDirectoriesExtendedInfo(
    $personImmutableId: String!
  ) {
    directoriesAuthenticatingPerson(personImmutableId: $personImmutableId) {
      customAttribute {
        objectId
        tenantId
        upn
      }
      identifier
      identityIdentifier
      isFederated
      label
      profileManagement {
        canCreateProfile
        canDeleteProfile
        readonlyProperties
      }
    }
  }
`;