import { Grid, Hidden, useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet';
import UsersCounter from '../Components/UsersCounter';
import UsersTableContainer from './UsersTable/UsersTable.container';
import UserStatusDistribution from './UserStatusDistribution';
import useTranslate from 'Hooks/useTranslate';
import mediaQueries from 'Theme/constants/mediaQueries';
import AdministratorsCard from './AdministratorsCard/AdministratorsCard';

interface Props {}

function PageUsers(props: Props) {

  const ts = useTranslate();

  const isMobileP = useMediaQuery(mediaQueries.mobilePortrait);
  
  return <>
    <Helmet title={ts('page.users.title' )} />
    <Grid container={true} style={{ flex: '0 auto' }}>
      <Hidden mdDown={true}>
        <Grid
          container={true}
          item={true}
          spacing={3}
          style={{ marginBottom: '12px' }}
        >
          <Grid item={true} xs={12} sm={4}>
            <UsersCounter showButton={false} />
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <UserStatusDistribution />
          </Grid>
          <Grid item={true} xs={12} sm={4}>
            <AdministratorsCard />
          </Grid>
        </Grid>
      </Hidden>

      <Grid item={true} xs={12}>
        <UsersTableContainer isMobileP={isMobileP} />
      </Grid>
    </Grid>
  </>;
}

export default PageUsers;
