import { Group as GroupIcon } from '@mui/icons-material';
import EmptyState from 'Components/Shared/EmptyState';
import useTranslate from 'Hooks/useTranslate';
import { TServiceGroupList } from '../../Models';
import { getLightPersonGroupColumns } from './LightGroupColumns';
import TableServicesGroupsSkeleton from './TableServicesGroups.skeleton';
import { ROW_PER_PAGE_OPTIONS } from 'Theme/config';
import { TableCollection } from 'Theme/components/TableCollection';

const loadingTotalCount = 1000;

interface Props {
  data?: TServiceGroupList;
  loading: boolean;
  page: number;
  rowPerPage: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
}

const TableServicesGroups = ({ data, loading, onChangePage, onChangeRowsPerPage, page, rowPerPage }: Props) => {
  const ts = useTranslate();
  const rows = (data?.edges ?? []).map(o => o.node);

  if (loading) {
    return <TableServicesGroupsSkeleton />;
  }

  if (!loading && !rows.length)
    return (
      <EmptyState
        icon={<GroupIcon />}
        primary={ts('page.serviceProfile.serviceMembers.groups.emptyTitle')}
      />
    );

  const columns = getLightPersonGroupColumns();


  return (
    <TableCollection
      onChangeRowsPerPage={onChangeRowsPerPage}
      isSelectionHidden={true}
      selectedIds={[]}
      rowsPerPage={rowPerPage}
      onSelect={()=>{}}
      onUnselect={()=>{}}
      rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      loading={false}
      columns={columns}
      rows={rows}
      getRowId={r => r.code}
      totalCount={data?.totalCount ?? loadingTotalCount}
      page={page}
      onChangePage={onChangePage}
    />
  );
};

export default TableServicesGroups;
