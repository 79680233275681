import { gql } from '@apollo/client';

export const AccountFragment = gql`
  fragment Account on Account {
    code
    name
    active
    type
    phase
    zipCode
    city
  }
`;
