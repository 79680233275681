import { Grid, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { PersonAvatar } from 'Theme/components/Avatar';
import { palette } from 'Theme/constants';

interface Props extends WithStyles<typeof styles> {
  personToPreview: IPerson;
  title: any;
}

const styles = (theme: Theme) =>
  createStyles({
    accountsPaddBott: {
      paddingBottom: theme.spacing(2),
      marginLeft: theme.spacing(2)
    },
    emailBlackLight: {
      color: palette.text.secondary,
      lineHeight: '20px'
    }
  });

function PreviewCard(props: Props) {
  return (
    <Grid container direction="row" item sm={6}>
      <Grid item xs={2}>
        <PersonAvatar person={props.personToPreview} size="large" />
      </Grid>
      <Grid item xs={10}>
        <Grid
          container
          direction="column"
          className={props.classes.accountsPaddBott}
        >
          <Typography variant="h6">{props.title}</Typography>
          <Typography variant="body2">
            {props.personToPreview.firstName} {props.personToPreview.lastName}
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            className={props.classes.emailBlackLight}
          >
            {props.personToPreview.email}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(PreviewCard);
