import makeStyles from '@mui/styles/makeStyles';
import { CdsSizeRadius } from '@cegid/design-tokens';

export const useStyles = makeStyles(() => ({
    status: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 8px',
        width: 'fit-content',
        borderRadius: CdsSizeRadius
    }
}));