import { Button, Typography, useTheme } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { Business as IconBusiness, Sync } from '@mui/icons-material';
import useTranslate from 'Hooks/useTranslate';
import * as React from 'react';
import { Link } from 'react-router-dom';
import TestIds from 'Tests/TestIds';

interface Props {}

function SwitchAccountButton(props: Props) {
  const ts = useTranslate();
  const theme = useTheme();

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      component={React.forwardRef<Link, ButtonProps>(
        ({ className, children }, ref) => (
          <Link
            ref={ref}
            data-testid={TestIds.common.header.switchAccountButton}
            className={className}
            to="/all/account_selection"
          >
            {children}
          </Link>
        )
      )}
    >
      <IconBusiness sx={{ color: theme.palette.primary.main }} />
      <Sync
        sx={{
          display: {
            xs: 'block',
            md: 'none',
            color: theme.palette.primary.main
          }
        }}
      />
      <Typography
        ml={1}
        variant="body2"
        color={theme.palette.primary.main}
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        {ts('common.switchAccount')}
      </Typography>
    </Button>
  );
}

export default SwitchAccountButton;
