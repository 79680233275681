import {
  IconButton,
  Typography,
  Popover,
  Button,
  Paper,
  Tooltip
} from '@mui/material';
import { RemoveCircle as IconRemoveCircle } from '@mui/icons-material';
import * as React from 'react';
import ButtonLoading from 'Theme/components/ButtonLoading';
import { useState } from 'react';
import useTranslate from 'Hooks/useTranslate';
import { useStyles } from 'Components/Popover/Popover.styles';

interface Props {
  loading: boolean;
  confirmationMessage: string;
  actionId: string;
  confirmationId: string;
  onSubmit: () => void;
  isOpen: boolean;
}

function RemoveButton(props: Readonly<Props>) {
  const classes = useStyles();
  const buttonRef = React.createRef<HTMLButtonElement>();

  const ts = useTranslate();
  const {
    loading,
    confirmationMessage,
    actionId,
    confirmationId,
    isOpen,
    onSubmit
  } = props;

  const [popupOpen, setPopupOpen] = useState<boolean>(isOpen);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleSubmit = () => {
    setPopupOpen(false);
    onSubmit();
  };

  return <>
    <Tooltip title={ts('common.action.delete')}>
      <IconButton
        ref={buttonRef}
        onClick={() => {
          setPopupOpen(true);
          setAnchorEl(buttonRef.current);
        }}
        data-testid={actionId}
        size="large">
        <IconRemoveCircle />
      </IconButton>
    </Tooltip>
    <Popover
      open={popupOpen}
      anchorEl={anchorEl}
      onClose={() => {
        setPopupOpen(false);
        setAnchorEl(null);
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Paper className={classes.paper}>
        <Typography variant="body2" className={classes.text}>
          {confirmationMessage}
        </Typography>
        <Button
          size="medium"
          color='inherit'
          className={classes.button}
          onClick={() => setPopupOpen(false)}
        >
          {ts('common.action.cancel')}
        </Button>
        <ButtonLoading
          data-testid={confirmationId}
          loading={loading}
          type="submit"
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {ts('common.action.delete')}
        </ButtonLoading>
      </Paper>
    </Popover>
  </>;
}

export default RemoveButton;
