import { Typography, useMediaQuery } from '@mui/material';
import { PersonAccessRightStatus } from 'Apollo';
import useTranslate from 'Hooks/useTranslate';
import AccessRightAvailabilitySmallWidth from 'Page/UsersProfile/Tabs/TabServices/AccessRightsTable/AccessRightAvailability/AccessRightAvailabilitySmallWidth';
import { FormattedDate } from 'react-intl';
import TestIds from 'Tests/TestIds';
import IconButtonWithPopOver from 'Theme/components/IconButtonWithPopOver';
import mediaQueries from 'Theme/constants/mediaQueries';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

interface Props {
  access: IPersonAccessRight;
}

function CellServiceInfo({ access }: Readonly<Props>) {
  const isMobile = useMediaQuery(mediaQueries.mobile);
  const ts = useTranslate();

  const dateToDisplay = access?.creationDate ?? '';

  return (
    <>
      {isMobile && <AccessRightAvailabilitySmallWidth accessRight={access} />}
      {access.service.description && (
        <IconButtonWithPopOver
          tooltip={ts('common.action.see.seeDescription')}
          customIcon={<InfoOutlinedIcon />}
          description={
            <div
              data-testid={
                TestIds.pages.userProfile.tabServices.serviceRow.description +
                access.service.groupCode
              }
            >
              <Typography variant="body2">
                {access.service.description}
              </Typography>
              {dateToDisplay !== '' &&
                access.status === PersonAccessRightStatus.Accepted && (
                  <Typography variant="caption" color="secondary">
                    {ts('page.userProfile.services.accessToServiceSince')}
                    <FormattedDate
                      value={dateToDisplay}
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                    />
                  </Typography>
                )}
            </div>
          }
          id={
            TestIds.pages.profile.services.serviceDescriptionIcon +
            access.service.groupCode
          }
        />
      )}
      {!!access.personGroupSource && (
        <IconButtonWithPopOver
          tooltip={ts('common.action.see.seeGroupDetails')}
          id={
            TestIds.pages.userProfile.tabServices.serviceRow
              .inheritedFromGroupIcon + access.service.groupCode
          }
          customIcon={<GroupIcon />}
          description={
            <>
              <Typography variant="body2">
                {ts('page.userProfile.services.inheritedFromGroup')}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {access.personGroupSource.name}
              </Typography>
            </>
          }
        />
      )}
      {!!access.expirationDate && (
        <IconButtonWithPopOver
          tooltip={ts('common.action.see.seeExpirationDate')}
          customIcon={<AccessTimeFilledIcon />}
          description={
            <Typography variant="body2">
              {ts('common.date.expireDetails', {
                date: (
                  <FormattedDate
                    value={new Date(access.expirationDate)}
                    year="numeric"
                    month="2-digit"
                    day="2-digit"
                  />
                ),
                hour: (
                  <FormattedDate
                    value={new Date(access.expirationDate)}
                    hour="2-digit"
                    minute="2-digit"
                  />
                )
              })}
            </Typography>
          }
        />
      )}
    </>
  );
}

export default CellServiceInfo;
