import { Paper, Typography, IconButton, Tooltip } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { useQuery } from '@apollo/client';
import {
  ActivationStatus,
  GetDirectoriesExtendedInfo,
  ReadonlyProperty
} from 'Apollo';
import CurrentPerson from 'Context/CurrentPerson';
import { DataRow } from 'Theme/components/DataRow';
import Skeleton from 'Theme/components/Skeleton';
import PaperTitle from 'Theme/components/PaperTitle';
import EditNameToggle from './EditName';
import EditPhoneToggle from './EditPhone';
import EditLanguageToggle from '../Account/EditLanguage';
import EmailRow from './EmailRow/EmailRow';
import TestIds from 'Tests/TestIds';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import { userFullName } from 'Tools';
import { FormattedDate } from 'react-intl';

interface Props {
  person: IPersonWithAccountAndDirectory;
}

function Account(props: Readonly<Props>) {
  const { person } = props;
  const isDisabled = props.person?.status === ActivationStatus.Disabled;

  const ts = useTranslate();
  const { onError } = useNotification();

  let { data, loading } = useQuery<
    GetDirectoriesExtendedInfoData,
    GetDirectoriesExtendedInfoVars
  >(GetDirectoriesExtendedInfo, {
    variables: {
      personImmutableId: person.immutableId
    },
    onError,
    fetchPolicy: 'cache-first'
  });

  return (
    <CurrentPerson.Consumer>
      {({ currentPerson, currentPersonRoles }) => {
        const isProfileOwner =
          currentPerson?.immutableId === props.person.immutableId;
        const canEdit =
          currentPersonRoles?.canWriteAll ||
          currentPersonRoles.isAccountAdmin ||
          isProfileOwner;

        const redirectUrl = encodeURIComponent('/');

        let authDirs =
          data !== undefined ? data.directoriesAuthenticatingPerson : [];
        let hasAFederatedDirectory =
          authDirs.find(d => d.isFederated) !== undefined;
        let readonlyPropertiesTabs: ReadonlyProperty[] = [];

        authDirs.forEach(d => {
          if (d.profileManagement?.readonlyProperties.length > 0) {
            for (
              let i = 0;
              i < d.profileManagement?.readonlyProperties.length;
              i++
            ) {
              readonlyPropertiesTabs.push(
                d.profileManagement?.readonlyProperties[i]
              );
            }
          }
        });

        const canEditPhone =
          !readonlyPropertiesTabs.includes(ReadonlyProperty.PhoneNumber) ||
          !readonlyPropertiesTabs.includes(ReadonlyProperty.MobileNumber);

        const canEditName = !readonlyPropertiesTabs.includes(
          ReadonlyProperty.LastName
        );
        const canEditFirstName = !readonlyPropertiesTabs.includes(
          ReadonlyProperty.FirstName
        );

        const canEditLanguage = !readonlyPropertiesTabs.includes(
          ReadonlyProperty.LanguageCode
        );

        const isEditable = canEdit && !isDisabled;
        const isNameEditable = (canEditName || canEditFirstName) && isEditable;
        const isPhoneEditable = canEditPhone && isEditable;
        const isLanguageEditable = canEditLanguage && isEditable;

        return (
          <Paper style={{ marginBottom: '16px' }}>
            <PaperTitle title={ts('page.userProfile.section.account.title')} />
            {loading && (
              <>
                {Array.from({ length: 4 }).map((_, index) => (
                  <DataRow
                    key={index as any}
                    label={<Skeleton variant="rectangular" width="100px" />}
                    divider={index < 3}
                  >
                    <Skeleton variant="rectangular" width="200px" />
                  </DataRow>
                ))}
              </>
            )}
            {!loading && (
              <>
                <DataRow
                  label={ts('page.userProfile.section.personal.fullname')}
                  action={
                    isNameEditable && (
                      <EditNameToggle
                        person={props.person}
                        readonlyProperties={readonlyPropertiesTabs}
                      />
                    )
                  }
                  divider={true}
                >
                  <Typography
                    variant="body2"
                    data-testid={
                      TestIds.pages.userProfile.account.fullnameValue
                    }
                  >
                    {userFullName(
                      props.person.firstName,
                      props.person.lastName
                    )}
                  </Typography>
                </DataRow>
                {!hasAFederatedDirectory && (
                  <DataRow
                    label={ts('page.userProfile.section.personal.password')}
                    divider={true}
                    action={
                      isProfileOwner &&
                      !isDisabled && (
                        <Tooltip
                          title={ts('page.userProfile.editPassword')}
                          placement="bottom"
                        >
                          <IconButton
                            href={
                              `${window.AppSettings.API_ENDPOINT_URI}` +
                              `pmsession/login?logintype=changepassword&token=${encodeURIComponent(
                                props.person.email!
                              )}&returnUrl=${encodeURIComponent(
                                `/end_login?returnUrl=${redirectUrl}`
                              )}`
                            }
                            size="large">
                            <CreateIcon />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                  >
                    <Typography variant="body2">•••••••••</Typography>
                  </DataRow>
                )}

                <EmailRow
                  person={props.person}
                  isProfileOwner={isProfileOwner}
                  hasAFederatedDirectory={hasAFederatedDirectory}
                />

                <DataRow
                  label={ts('page.userProfile.section.personal.phone')}
                  action={
                    isPhoneEditable && (
                      <EditPhoneToggle
                        person={props.person}
                        readonlyProperties={readonlyPropertiesTabs}
                      />
                    )
                  }
                  divider={true}
                >
                  <div>
                    {props.person.phoneNumber && (
                      <Typography
                        variant="body2"
                        data-testid={TestIds.pages.userProfile.account.phoneValue}
                      >
                        {props.person.phoneNumber}
                      </Typography>
                    )}
                    {props.person.mobileNumber && (
                      <Typography
                        variant="body2"
                        data-testid={
                          TestIds.pages.userProfile.account.mobilePhoneValue
                        }
                      >
                        {props.person.mobileNumber}
                      </Typography>
                    )}
                  </div>
                </DataRow>
                <DataRow
                  label={ts('profile.about.language')}
                  action={
                    isLanguageEditable && (
                      <EditLanguageToggle person={props.person} />
                    )
                  }
                  divider={true}
                >
                  <Typography
                    variant="body2"
                    data-testid={
                      TestIds.pages.userProfile.account.languageValue
                    }
                  >
                    {props.person.language ? props.person.language.label : '/'}
                  </Typography>
                </DataRow>
                <DataRow
                  label={ts('profile.about.creationDate')}
                  divider={true}
                >
                  <Typography
                    variant="body2"
                    data-testid={
                      TestIds.pages.userProfile.account.creationValue
                    }
                  >
                    <FormattedDate value={props.person.creationDate} />
                  </Typography>
                </DataRow>
              </>
            )}
          </Paper>
        );
      }}
    </CurrentPerson.Consumer>
  );
}

export default Account;
