import { gql } from '@apollo/client';

export const GetAccount = gql`
  query GetAccount($accountCode: String!) {
    account(accountCode: $accountCode) {
      code
      name
      active
      type
      phase
      zipCode
      city
    }
  }
`;
