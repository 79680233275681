import { TTabView } from '../Components/Shared/Tabs/Tabs';
import { PrivateRoutes } from '../Router/Routes';
import { generatePath, useParams } from 'react-router-dom';
import TestIds from 'Tests/TestIds';
import TabGeneral from 'Page/AccountProfile/Tabs/TabGeneral';
import { Info as IconInfo } from '@mui/icons-material';

interface Props {
  account: IAccountWithNotificationSettings;
}

const useTabsAccountProfile = ({ account }: Props): TTabView[] => {
  const { accountCode } = useParams<{
    accountCode: string;
  }>();

  const getPath = (path: string) =>
    generatePath(path, {
      accountCode: accountCode
    });

  const tabs: TTabView[] = [
    {
      icon: <IconInfo />,
      title: 'profile.menu.general',
      path: PrivateRoutes.accountProfile.path,
      link: getPath(PrivateRoutes.accountProfile.path),
      component: <TabGeneral account={account} />,
      testid: TestIds.pages.accountProfile.linkGeneral
    }
  ];

  return tabs;
};

export default useTabsAccountProfile;
