import { TTabView } from '../Components/Shared/Tabs/Tabs';
import { PrivateRoutes } from '../Router/Routes';
import { useParams, generatePath } from 'react-router-dom';
import {
  Info as IconInfo,
  BubbleChart as IconBubbleChart,
  People as PeopleIcon
} from '@mui/icons-material';
import TestIds from 'Tests/TestIds';
import TabGeneral from 'Page/PersonGroupProfile/Tabs/TabGeneral/TabGeneral';
import TabServices from 'Page/PersonGroupProfile/Tabs/TabServices/TabServices';
import TabMembers from 'Page/PersonGroupProfile/Tabs/TabMembers';

interface Props {
  group: IPersonGroup;
}

const useTabsGroup = ({ group }: Props): TTabView[] => {
  const { accountCode, groupCode } = useParams<{
    accountCode: string;
    groupCode: string;
  }>();

  const getPath = (path: string) =>
    generatePath(path, {
      accountCode,
      groupCode
    });

  const tabs: TTabView[] = [
    {
      icon: <IconInfo />,
      path: PrivateRoutes.personGroupProfile.path,
      link: getPath(PrivateRoutes.personGroupProfile.path),
      title: 'profile.menu.general',
      component: <TabGeneral group={group} />,
      testid: TestIds.pages.profile.linkGeneral
    },
    {
      icon: <IconBubbleChart />,
      path: PrivateRoutes.personGroupProfileServices.path,
      link: getPath(PrivateRoutes.personGroupProfileServices.path),
      title: 'profile.menu.services',
      component: <TabServices group={group} />,
      testid: TestIds.pages.profile.linkServices
    },
    {
      icon: <PeopleIcon />,
      path: PrivateRoutes.personGroupProfileMembers.path,
      link: getPath(PrivateRoutes.personGroupProfileMembers.path),
      title: 'profile.menu.members',
      component: <TabMembers group={group} />,
      testid: TestIds.pages.profile.linkActivities
    }
  ];

  return tabs;
};

export default useTabsGroup;
