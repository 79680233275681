import * as React from 'react';
import { styled } from '@mui/styles';
import { MaterialDesignContent, SnackbarProvider as Snackbar } from 'notistack';
import { colors } from 'Theme/constants';
import SnackbarCloseButton from 'Components/Shared/Snackbar/SnackbarCloseButton';
import TestIds from 'Tests/TestIds';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    color: colors.white,
    backgroundColor: 'rgba(0,0,0,0.87)'
  },
  '&.notistack-MuiContent-error': {
    color: colors.white,
    backgroundColor: colors.warn
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: colors.blue[400]
  }
}));

interface Props {
  children: React.ReactElement;
}

function SnackbarProvider(props: Props) {
  const renderCloseButton = (key: string) => (
    <SnackbarCloseButton
      snackbarId={key}
      data-testid={TestIds.common.snackbar.action.close}
    />
  );
  return (
    <Snackbar
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent
      }}
      maxSnack={3}
      preventDuplicate={true}
      hideIconVariant={true}
      transitionDuration={{ enter: 400, exit: 800 }}
      autoHideDuration={10000}
      action={renderCloseButton}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      data-testid={TestIds.common.snackbar.element}
    >
      {props.children}
    </Snackbar>
  );
}

export default SnackbarProvider;
