import { PersonAccessRightStatus } from "../Apollo/fragments/person/PersonAccessRight";

function getPendingSuccessResult(data: CreatePersonAccessRightsForSingleUserData) {
    const {
        pending,
        success
      } = data.createPersonAccessRightsForSingleUser.reduce(
        (
          acc: {
            pending: IAccessRightCreation[];
            success: IAccessRightCreation[];
          },
          result: IAccessRightCreation
        ) => {
          if (
            result.status === PersonAccessRightStatus.ToValidate
          ) {
            return {
              ...acc,
              pending: acc.pending.concat([result])
            };
          }
    
          return {
            ...acc,
            success: acc.success.concat([result])
          };
        },
        { pending: [], success: [] }
      );

      return { pending, success }
}


  export default getPendingSuccessResult;