import Typography from '@mui/material/Typography';
import {
  Alert as MuiAlert,
  AlertTitle,
  IconButton,
  useTheme,
  Tooltip
} from '@mui/material';
import { useStyles } from './Alert.styles';
import { ReactNode } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import useTranslate from 'Hooks/useTranslate';

enum Severity {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}
interface Props {
  severity: 'success' | 'info' | 'warning' | 'error';
  content: string | ReactNode;
  title?: string;
  icon?: ReactNode;
  sx?: any;
  onClose?: () => void;
}

function Alert(props: Readonly<Props>) {
  const { severity, title, content, icon = undefined , sx, onClose } = props;
  const classes = useStyles();
  const theme = useTheme();
  const ts = useTranslate();

  const customClasse = () => {
    switch (severity) {
      case Severity.Success:
        return classes.success;
      case Severity.Error:
        return classes.error;
      case Severity.Warning:
        return classes.warning;
      case Severity.Info:
        return classes.info;
    }
  };

  return (
    <MuiAlert
      elevation={6}
      icon={icon}
      variant="filled"
      className={customClasse()}
      sx={sx}
    >
      {title && <AlertTitle>{title}</AlertTitle>}

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2" color="textPrimary">
          {content}
        </Typography>
        {!!onClose && (
          <Tooltip title={ts('common.action.cancel')}>
            <IconButton
              size="small"
              style={{
                color: theme.palette.grey[400],
                marginLeft: 16
              }}
              onClick={onClose}
            >
              <CloseIcon color="action" sx={{ fontSize: '12px' }} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </MuiAlert>
  );
}

export default Alert;
