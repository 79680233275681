import { useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import TableServicesIpRestrictions from './TableServicesIpRestrictions';
import { GetServiceSubscriptionIpRestrictions } from '../../Apollo';
import { TServiceIpFilteringList } from '../../Models';
import ErrorMsg from 'Components/Shared/ErrorMsg';
import EmptyState from 'Components/Shared/EmptyState';
import useLocationPage from '../../Hooks/useLocationPage';
import { Lock as LockIcon } from '@mui/icons-material';
import useTranslate from '../../Hooks/useTranslate';
import useServiceSubscriptionRouteMatch from 'Hooks/useServiceSubscriptionRouteMatch';
import ErrorHandlerContext from 'Context/ErrorHandler.context';

const TableServicesIpRestrictionsContainer = () => {
  const ts = useTranslate();

  const ErrorHandler = useContext(ErrorHandlerContext);

  const serviceSubscriptionIdentifier = useServiceSubscriptionRouteMatch();

  const { currentPage, changePage } = useLocationPage();

  const variables = {
    page: currentPage,
    serviceSubscriptionIdentifier
  };

  const [getIpRestrictions, { loading, data, error }] = useLazyQuery<{
    serviceSubscriptionIPRestrictions: TServiceIpFilteringList;
  }>(GetServiceSubscriptionIpRestrictions, {
    onError: ErrorHandler.onError
  });

  useEffect(() => {
    changePage(currentPage);
    getIpRestrictions({ variables });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIpRestrictions]);

  const onReload = (page: IPage) => {
    changePage(page);
    getIpRestrictions({
      variables: { serviceSubscriptionIdentifier, page }
    });
  };

  if (error) return <ErrorMsg onRetry={getIpRestrictions} />;

  const rows =
    data && data.serviceSubscriptionIPRestrictions?.edges
      ? data.serviceSubscriptionIPRestrictions.edges.map(o => o.node)
      : [];

  if (!rows.length && !loading)
    return (
      <EmptyState
        icon={<LockIcon />}
        primary={ts('page.serviceProfile.security.emptyTitle')}
        secondary={ts('page.serviceProfile.security.emptySubtitle')}
      />
    );

  return (
    <TableServicesIpRestrictions
      totalCount={data?.serviceSubscriptionIPRestrictions.totalCount || 1000}
      rows={rows}
      loading={loading}
      onReload={onReload}
    />
  );
};

export default TableServicesIpRestrictionsContainer;
