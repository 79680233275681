import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '16px 16px 32px 16px'
    },
    headerAvatar: {
        marginBottom: theme.spacing(2)
    }
}));