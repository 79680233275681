import { useQuery } from '@apollo/client';
import { ListItem, ListItemText } from '@mui/material';
import { GetAccount } from 'Apollo/queries/account/GetAccount';
import shortAccountlabel from 'Tools/LabelFormatter/shortAccountLabel';

import { AccountsListSkeleton } from './AccountsList.skeleton';
import { Link, generatePath } from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';

interface Props {
  accountCode: string;
}

function CurrentAccount(props: Readonly<Props>) {
  const { accountCode } = props;

  let { data, loading } = useQuery<GetAccountData, GetAccountVars>(GetAccount, {
    variables: {
      accountCode: accountCode
    },
    fetchPolicy: 'cache-first'
  });

  const tiers = data?.account;
  let tiersName = tiers ? shortAccountlabel(tiers) : '';

  return (
    <>
      {loading && <AccountsListSkeleton />}
      {!loading && data !== undefined && (
        <ListItem>
          <Link
            to={generatePath(PrivateRoutes.accountProfile.path, {
              accountCode: accountCode
            })}
          >
            <ListItemText primary={tiersName} />
          </Link>
        </ListItem>
      )}
    </>
  );
}

export default CurrentAccount;
