import { gql } from '@apollo/client';
import { LightPersonProfileFragment } from '../../fragments/person/LightPersonProfile';

export const GetPersonsAddableToServiceSubscriptionOwner = gql`
  query GetPersonsAddableToServiceSubscriptionOwner(
    $serviceSubscriptionIdentifier: serviceSubscriptionIdentifier!
    $page: PageInput
    $orderBy: PersonOrder
    $searchTerm: String
  ) {
    personsAddableToServiceSubscriptionOwner(
      serviceSubscriptionIdentifier: $serviceSubscriptionIdentifier
      page: $page
      orderBy: $orderBy
      searchTerm: $searchTerm
    ) {
      edges {
        node {
          ...LightPersonProfile
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${LightPersonProfileFragment}
`;
