import { useIntl } from 'react-intl';
import { TProfileManagerMessagesKeys } from '../Languages/TProfileManagerMessages';

export type TTranslate = (
  id: TProfileManagerMessagesKeys,
  values?: Record<string, any> | undefined
) => string;

const useTranslate = () => {
  const intl = useIntl();
  const translate: TTranslate = (
    id: TProfileManagerMessagesKeys,
    values?: Record<string, any>
  ) => {
    return intl.formatMessage({ id }, { ...values });
  };
  return translate;
};

export default useTranslate;
