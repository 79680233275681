import { gql } from '@apollo/client';
import { ServiceSubscriptionFragment } from '../../fragments/service/ServiceSubscription';

export const GetAvailableServicesForPersonGroup = gql`
  query GetAvailableServicesForPersonGroup(
    $personGroupCode: String!
    $page: PageInput
    $searchTerm: String
    $orderBy: ServiceSubscriptionOrder
  ) {
    availableServicesForPersonGroup(
      personGroupCode: $personGroupCode
      page: $page
      searchTerm: $searchTerm
      orderBy: $orderBy
    ) {
      edges {
        node {
          ...ServiceSubscription
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${ServiceSubscriptionFragment}
`;
