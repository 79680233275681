import { TTabView } from '../Components/Shared/Tabs/Tabs';
import { PrivateRoutes } from '../Router/Routes';
import { useParams, generatePath } from 'react-router-dom';
import OrganizationProfileGeneral from 'Page/OrganizationProfile/Tabs/TabGeneral/TabGeneral';
import OrganizationProfileDomains from 'Page/OrganizationProfile/Tabs/TabDomains/TabDomains';

interface Props {
  organization: IOrganization;
}

const useTabsOrganizationProfile = ({ organization }: Props): TTabView[] => {
  const { accountCode, organizationCode } = useParams<{
    accountCode: string;
    organizationCode: string;
  }>();

  const getPath = (path: string) =>
    generatePath(path, {
      accountCode,
      organizationCode
    });

  const tabs: TTabView[] = [
    {
      title: 'page.organizationProfile.menu.general',
      path: PrivateRoutes.organizationProfile.path,
      link: getPath(PrivateRoutes.organizationProfile.path),
      component: <OrganizationProfileGeneral organization={organization} />
    },
    {
      title: 'page.organizationProfile.menu.domains',
      path: PrivateRoutes.organizationProfileDomains.path,
      link: getPath(PrivateRoutes.organizationProfileDomains.path),
      component: <OrganizationProfileDomains organization={organization} />
    },
  ];

  return tabs;
};

export default useTabsOrganizationProfile;
