import { gql } from '@apollo/client';
import { PersonWithAccountFragment } from '../../fragments/person/PersonWithAccount';

export const GetPersonGroupPersons = gql`
  query GetPersonGroupPersons(
    $code: String!
    $page: PageInput
    $orderBy: PersonOrder
    $searchTerm: String
  ) {
    personGroupPersons(
      code: $code
      page: $page
      orderBy: $orderBy
      searchTerm: $searchTerm
    ) {
      edges {
        node {
          ...PersonWithAccount
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${PersonWithAccountFragment}
`;
