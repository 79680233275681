import { ActivationStatus } from 'Apollo/fragments/person/Person';
import useTranslate from 'Hooks/useTranslate';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IconButtonWithPopOver from 'Theme/components/IconButtonWithPopOver';
import {
  CdsColorTextCriticalHighEmphasis,
  CdsColorTextSuccessHighEmphasis
} from '@cegid/design-tokens';

interface Props {
  person: IPerson;
}

function UserStatusPopover({ person }: Readonly<Props>) {
    const ts = useTranslate();

    switch (person.status) {
        case ActivationStatus.Activated:
            return (
                <IconButtonWithPopOver
                    customIcon={
                        <CheckCircleOutlineIcon
                        fontSize="small"
                        style={{ color: CdsColorTextSuccessHighEmphasis }}
                        />
                    }
                    description={ts('page.userProfile.section.personal.status.activated')}
                    role='activated-user-status-popover'
                />
            );
        case ActivationStatus.ToActivate:
            return (
                <IconButtonWithPopOver
                    customIcon={
                        <AccessTimeIcon
                        fontSize="small"
                        style={{ color: CdsColorTextCriticalHighEmphasis }}
                        />
                    }
                    description={ts(
                        'page.userProfile.section.personal.status.toActivate'
                    )}
                    role='toActivate-user-status-popover'
                />
            );
        case ActivationStatus.Created:
            return (
                <IconButtonWithPopOver
                    customIcon={
                        <AccessTimeIcon
                        fontSize="small"
                        style={{ color: CdsColorTextCriticalHighEmphasis }}
                        />
                    }
                    description={ts('page.userProfile.section.personal.status.created')}
                    role='created-user-status-popover'
                />
            );
        case ActivationStatus.Disabled:
            return (
                <IconButtonWithPopOver
                    customIcon={
                        <ErrorOutlineIcon
                        fontSize="small"
                        style={{ color: CdsColorTextCriticalHighEmphasis }}
                        />
                    }
                    description={ts('page.userProfile.section.personal.status.disabled')}
                    role='disabled-user-status-popover'
                />
            );
        default:
            return null;
    }
}

export default UserStatusPopover;
