import { Grid, Paper, MenuList, MenuItem } from '@mui/material';
import TestIds from 'Tests/TestIds';
import PersonAvatar from 'Theme/components/Avatar/PersonAvatar';
import { useStyles } from '../../SearchBars.styles';
import { userFullName } from 'Tools/LabelFormatter/userFullName';
import useTranslate from 'Hooks/useTranslate';

interface Props {
  currentSearch: string;
  currentUserSelected?: string;
  data: IPaginatedList<ILightPersonAccessRight> | undefined;
  onChooseUser(value: ILightPersonProfile): void;
}

const highlightExpression = (text: string, search: string) =>
  text.replace(new RegExp(search, 'gi'), match => `<b>${match}</b>`);

const AvailabledResult = (props: Props) => {
  const { data, onChooseUser, currentUserSelected, currentSearch } = props;
  const ts = useTranslate();
  const classes = useStyles();

  return (
    <Grid
      item
      data-testid={TestIds.component.searchByUser.resultList}
      xs={12}
      className={classes.resultList}
    >
      <Paper elevation={2}>
        <MenuList>
          {data?.edges.map((elt, i: number) => {
            let userFirstName = elt.node.personProfile?.firstName;
            let userLastName = elt.node.personProfile?.lastName;
            let fullN = userFullName(userFirstName, userLastName);
            let userEmail = elt.node.personProfile?.email;

            let sequenceToDisplay = `${highlightExpression(
              fullN ?? '',
              currentSearch
            )} · ${ts('component.accessrights.pendingRequest.count', {
              value: elt.node.countAccessRequests
            })} · ${highlightExpression(userEmail ?? '', currentSearch)}`;

            return (
              <MenuItem
                key={elt.node.personProfile.email}
                onClick={() => onChooseUser(elt.node.personProfile)}
                selected={
                  elt.node.personProfile?.immutableId === currentUserSelected
                }
                data-testid={`${TestIds.component.searchByUser.totalRequestOnUser}-${i}`}
              >
                <PersonAvatar
                  person={{ ...elt.node.personProfile }}
                  size={'small'}
                  className={classes.userAvatar}
                />
                {/*
                  When dangerouslySetInnerHTML is used, React also knows that the content of that specific element is dynamic.
                  And for the children of that node, it simply skips the comparison against the virtual DOM to gain some extra performance.
                  
                  As the name of the property suggests, it can be dangerous to use because it makes your code vulnerable to cross-site scripting (XSS) attacks. 
                  This becomes an issue especially if you are fetching data from a third-party source or rendering content submitted by users.

                  Here it's not our case so e can use it
                  https://blog.logrocket.com/using-dangerouslysetinnerhtml-in-a-react-application/  
                  */}
                <div
                  className={classes.overflow}
                  dangerouslySetInnerHTML={{ __html: sequenceToDisplay }}
                />
              </MenuItem>
            );
          })}
        </MenuList>
      </Paper>
    </Grid>
  );
};

export default AvailabledResult;
