import { useMutation } from '@apollo/client';
import { CreatePersonAccessRightsByEmail } from 'Apollo';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import { useState } from 'react';
import isValidEmail from 'Tools/isValidEmail';
import EmailForm from './EmailForm';

interface Props {
  onCancel(): void;
  onSubmit(): void;
  serviceSubscriptions: IServiceSubscriptionIdentifier[];
}

const EmailFormContainer = ({
  onCancel,
  onSubmit,
  serviceSubscriptions
}: Props) => {
  const ts = useTranslate();
  const { onSuccess, onError } = useNotification();
  const [validationError, setValidationError] = useState<string | undefined>();
  const [createPersonAccessRightByEmail] = useMutation(
    CreatePersonAccessRightsByEmail,
    {
      onCompleted: () =>
        onSuccess(
          'page.serviceProfile.serviceMembers.invitationResult.external'
        ),
      onError
    }
  );
  const submit = async (email: string) => {
    if (!isValidEmail(email)) {
      setValidationError(ts('error.form.invalidEmail'));
      return;
    }
    await createPersonAccessRightByEmail({
      variables: {
        serviceSubscriptionIdentifiers: serviceSubscriptions,
        email
      }
    });
    onSubmit();
  };
  return (
    <EmailForm onCancel={onCancel} onSubmit={submit} error={validationError} />
  );
};

export default EmailFormContainer;
