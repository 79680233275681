import React from 'react';
import { TTabView } from '../Components/Shared/Tabs/Tabs';
import { PrivateRoutes } from '../Router/Routes';
import joinURL from 'url-join';
import { useParams, generatePath } from 'react-router-dom';
import ExternalServiceProfileGeneral from '../Page/ExternalServiceProfile/ExternalServiceProfileGeneral';

interface Props {
  service: IExternalServiceSubscription;
}

const useTabsExternalService = ({ service }: Props): TTabView[] => {
  const { accountCode, serviceCode, targetAccountCode } = useParams<{
    accountCode: string;
    serviceCode: string;
    targetAccountCode: string;
  }>();

  const basePath = generatePath(PrivateRoutes.externalServiceProfile.path, {
    accountCode,
    serviceCode,
    targetAccountCode
  });

  return [
    {
      title: 'profile.menu.general',
      path: PrivateRoutes.serviceProfile.path,
      link: joinURL(basePath, ''),
      component: <ExternalServiceProfileGeneral serviceSubscription={service} />
    }
  ];
};

export default useTabsExternalService;
