import * as React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TestIds from 'Tests/TestIds';
import TabActive from './TabActive';
import TabRevoked from './TabRevoked';

interface Tab {
  icon: React.ReactElement;
  path: string;
  name: string;
  Tab: React.ComponentType<{ accountCode: string | null }>;
  testId: string;
}

export default [
  {
    icon: <CheckCircleOutlineIcon />,
    path: '/',
    name: 'page.apiKeys.activeTitle',
    Tab: TabActive,
    testId: TestIds.pages.apiKeys.linkActive
  },
  {
    icon: <HighlightOffIcon />,
    path: '/revoked',
    name: 'page.apiKeys.revokedTitle',
    Tab: TabRevoked,
    testId: TestIds.pages.apiKeys.linkRevoked
  }
] as Tab[];
