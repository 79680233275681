export interface IQueryData {
  accounts: IPaginatedList<IAccount>;
}

export interface ISettings {
  colors: string[];
}
export const settings: ISettings = {
  colors: ['#FE8D70', '#ff5c35', '#0046fe', '#7183ff', '#002c52']
};
