export const userFullName = (
  userFirstname: string | null | undefined,
  userLastname: string | null | undefined
) => {
  let userFNotNull = !!userFirstname;
  let userLNotNull = !!userLastname;
  let fullNameEmpty = userFirstname === null && userLastname === null;

  if (fullNameEmpty) {
    return '';
  }
  if (userFNotNull && userLNotNull) {
    return userFirstname + ' ' + userLastname;
  }
  if (userFNotNull && !userLNotNull) {
    return userFirstname!.trim();
  }
  if (!userFNotNull && userLNotNull) {
    return userLastname!.trim();
  }
  return '';
};
