import { gql } from '@apollo/client';
import { ServiceSubscriptionWithCountFragment } from '../../fragments/service/ServiceSubscriptionWithCount';

/** Return current user's administrated services */
export const GetServiceSubscriptions = gql`
  query GetServiceSubscriptions(
    $servicesFilter: String
    $accountCode: String
    $page: PageInput
    $orderBy: ServiceSubscriptionOrder
    $apiKeyEligibleOnly: Boolean!
  ) {
    serviceSubscriptions(
      servicesFilter: $servicesFilter
      accountCode: $accountCode
      page: $page
      orderBy: $orderBy
      apiKeyEligibleOnly: $apiKeyEligibleOnly
    ) {
      edges {
        node {
          ...ServiceSubscriptionWithCount
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${ServiceSubscriptionWithCountFragment}
`;
