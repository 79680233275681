import { gql } from '@apollo/client';
import { LanguageFragment } from '../../fragments/language/Language';

export const GetLanguages = gql`
  query GetLanguages {
    languages {
      edges {
        node {
          ...Language
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${LanguageFragment}
`;
