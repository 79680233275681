import { gql } from '@apollo/client';
import { AccountFragment } from '../..';

export enum PersonGroupsOrder {
  NameAsc = 'NAMEASC',
  NameDesc = 'NAMEDESC',
  AccountNameAsc = 'ACCOUNTASC',
  AccountNameDesc = 'ACCOUNTDESC',
  DescriptionAsc = 'DESCRIPTIONASC',
  DescriptionDesc = 'DESCRIPTIONDESC'
}

export const GetPersonGroups = gql`
  query GetPersonGroups(
    $accountCode: String
    $orderBy: PersonGroupOrder
    $first: Int
    $offset: Int
  ) {
    personGroups(
      accountCode: $accountCode
      orderBy: $orderBy
      first: $first
      offset: $offset
    ) {
      edges {
        node {
          account {
            ...Account
          }
          avatar {
            initials
            color
          }
          code
          name
          description
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${AccountFragment}
`;

export const GetPersonGroupsTotalCount = gql`
  query GetPersonGroupsTotalCount($accountCode: String) {
    personGroups(accountCode: $accountCode) {
      totalCount
    }
  }
`;
