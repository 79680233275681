import { Block } from '@mui/icons-material';
import { Box, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorMsg } from '../Shared';

interface ErrorProps {
  loadingError: boolean;
  isEmpty: boolean;
  emptyMessage: string;
}

const useErrorStyles = makeStyles((theme: Theme) =>
({
  emptyBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3)
  },
  emptyIcon: {
    fontSize: theme.spacing(6),
    color: theme.palette.primary.main
  }
}));

const Error = ({ loadingError, isEmpty: empty, emptyMessage }: ErrorProps) => {
  const classes = useErrorStyles();
  if (loadingError) return <ErrorMsg />
  if (empty) return <Box className={classes.emptyBox}>
    <Block className={classes.emptyIcon} />
    <Typography variant='body1'>
      {emptyMessage}
    </Typography>
  </Box>
  return null;
}

export default Error;
