import { Typography } from '@mui/material';
import { TableCollectionColumn } from 'Components/Shared/Table';
import UserNameCell from 'Components/Shared/UserNameCell/UserNameCell';
import UserCellSkeleton from 'Theme/components/UserCellSkeleton';
import Skeleton from 'Theme/components/Skeleton';
import useTranslate from './useTranslate';
import UserStatusChip from 'Components/Shared/Chips/UserStatusChip/UserStatusChip';

export interface IAdminConfig {
  isUserHidden?: boolean;
  isEmailHidden?: boolean;
  isStatusHidden?: boolean;
  isAccountHidden?: boolean;
}

export const defaultConfig: IAdminConfig = {
  isUserHidden: false,
  isEmailHidden: false,
  isStatusHidden: false,
  isAccountHidden: false
};

export const useColumnsAdministrators = (
  adminConfig: IAdminConfig
): TableCollectionColumn<{ node: IPersonWithAccount }>[] => {
  const ts = useTranslate();

  return [
    {
      key: 'admin',
      hidden: adminConfig.isUserHidden ?? defaultConfig.isUserHidden,
      renderHead: () => ts('page.administrators.index.administrators'),
      renderSkeleton: () => <UserCellSkeleton />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => <UserNameCell person={person} />
    },
    {
      key: 'email',
      hidden: adminConfig.isEmailHidden ?? defaultConfig.isEmailHidden,
      renderHead: () => ts('page.administrators.index.email'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => person.email
    },
    {
      key: 'status',
      hidden: adminConfig.isStatusHidden ?? defaultConfig.isStatusHidden,
      renderHead: () => ts('page.administrators.index.allStatus'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="10%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => <UserStatusChip person={person} />
    },
    {
      key: 'account',
      hidden: adminConfig.isAccountHidden ?? defaultConfig.isAccountHidden,
      renderHead: () => ts('common.terminology.client'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) =>
        person.account && (
          <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
            {person.account.name}
          </Typography>
        )
    }
  ];
};
