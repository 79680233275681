import { useQuery } from '@apollo/client';
import Organizations from './Organizations';
import useNotification from 'Hooks/useNotification';
import { GetOrganizations } from 'Apollo/queries/organization/GetOrganizations';


function OrganizationsContainer() {
  const { onError } = useNotification();

    let { data, loading } = useQuery<GetOrganizationsData>(
      GetOrganizations,
      {
        onError,
        fetchPolicy: 'cache-and-network'
      }
    );

  const rows = data?.organizations.edges.map(org => ({ ...org.node })) ?? [];

  return <Organizations loading={loading} dataResult={rows} />
  
}
export default OrganizationsContainer;
