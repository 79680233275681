import { IconButton, Theme, Popover, Tooltip, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import * as React from 'react';
import { CdsSizeRadius } from '@cegid/design-tokens';

const styles = (theme: Theme) =>
  createStyles({
    popoverText: {
      padding: theme.spacing(3),
      maxWidth: 400,
      borderRadius: CdsSizeRadius
    }
  });

interface Props {
  customIcon: React.ReactElement;
  description: string | React.ReactElement;
  id?: string;
  tooltip?: string;
  role?: string;
}

interface State {
  isOpen: boolean;
  anchorEl: HTMLButtonElement | null;
}

class IconButtonWithPopOver extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  public readonly state: State = {
    isOpen: false,
    anchorEl: null
  };

  private buttonRef = React.createRef<HTMLButtonElement>();

  public render() {
    const { id, customIcon, description, classes, tooltip, role } = this.props;
    const { isOpen, anchorEl } = this.state;

    return (
      <>
        {tooltip ?  (
          <Tooltip title={tooltip}>
            <IconButton
              ref={this.buttonRef}
              onClick={() => {
                this.setState({
                  isOpen: true,
                  anchorEl: this.buttonRef.current
                });
              }}
              color={'default'}
              disableRipple={true}
              data-testid={id}
              size="large"
              role={role}
            >
              {customIcon}
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            ref={this.buttonRef}
            onClick={() => {
              this.setState({
                isOpen: true,
                anchorEl: this.buttonRef.current
              });
            }}
            color={'default'}
            disableRipple={true}
            data-testid={id}
            size="large"
            role={role}
          >
            {customIcon}
          </IconButton>
        )}
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={() => {
            this.setState({
              isOpen: false,
              anchorEl: null
            });
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <div
            data-testid={id}
            className={classes.popoverText}
          >
            <Typography variant="body2">{description}</Typography>
          </div>
        </Popover>
      </>
    );
  }
}

export default withStyles(styles)(IconButtonWithPopOver);
