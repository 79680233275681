import { APIError } from './types';

/*
 * Return the error messages from API errors by giving precedence
 * to the deepest ones, which are more qualified.
 */
function getErrorMessages(errors: APIError[]): string[] {
  return errors.reduce((acc: string[], error: APIError) => {
    if (error.extensions) {
      if (
        error.extensions.innerErrors &&
        error.extensions.innerErrors.length > 0
      ) {
        return acc.concat(
          error.extensions.innerErrors.map(innerError => innerError.message)
        );
      }

      if (error.extensions.message) {
        return acc.concat([error.extensions.message]);
      }
    }

    return acc.concat([error.message]);
  }, []);
}

export default getErrorMessages;
