import { useMutation } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { ValidateEmailChangeRequest } from 'Apollo';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import { useEffect } from 'react';
import { PrivateRoutes } from 'Router/Routes';
import ChangeEmailValidation from './ChangeEmailValidation';
import { PageError } from 'Page/PageError';

type Props = {
  token: string;
};

const ChangeEmailValidationContainer = ({ token }: Props) => {
  const ts = useTranslate();
  const { onError } = useNotification();

  const [updateEmail, { data, loading, error }] = useMutation<{
    validateEmailChangeRequest: { newEmail: string };
  }>(ValidateEmailChangeRequest);

  useEffect(
    () => {
      const { origin } = window.location;
      const validationPath = origin + PrivateRoutes.changeEmailValidation.path;
      updateEmail({ variables: { token }, onError });
      window.history.pushState({}, '', validationPath);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (loading) {
    return <LinearProgress color="secondary" />;
  }

  if (error || !data) {
    return (
      <PageError
        title={ts('error.somethingHappened')}
        textPrimary={ts('error.sentence.emailChange')}
      />
    );
  }

  return (
    <ChangeEmailValidation email={data.validateEmailChangeRequest.newEmail} />
  );
};
export default ChangeEmailValidationContainer;
