import { useMutation } from '@apollo/client';
import {
  AddServiceToGroupStatus,
  AddServiceSubscriptionsToPersonGroup,
  GetPersonGroupServiceSubscriptions
} from 'Apollo';
import useNotification from 'Hooks/useNotification';
import { FormEvent } from 'react';
import FormAddServicesToGroup from './FormAddServicesToGroup';
import { useParams } from 'react-router-dom';

interface Props {
  selectedIds: string[] | null;
  totalCount: number;
  testid?: string;
  onCancel: () => void;
}

let operationSuccess: IPersonGroupServiceAssignment[];

const FormAddServicesToGroupContainer = (props: Props) => {
  const { selectedIds, totalCount, testid, onCancel } = props;

  const { onSuccess, onError } = useNotification();

  const { groupCode } = useParams<IGroupIdentifier>();

  const serviceSubscriptions =
    selectedIds == null ? null : selectedIds.map(id => JSON.parse(id));

  const [addServicesToPersonGroup, { loading: isAddingService }] = useMutation<
    AddServiceSubscriptionsToPersonGroupData,
    AddServiceSubscriptionsToPersonGroupVars
  >(AddServiceSubscriptionsToPersonGroup, {
    onError,
    onCompleted: (data: AddServiceSubscriptionsToPersonGroupData) => {
      const addedServicesArray = data?.addServiceSubscriptionsToPersonGroup;
      operationSuccess = addedServicesArray.filter(
        inv => inv.status === AddServiceToGroupStatus.Accepted
      );

      if (operationSuccess.length > 0) {
        onSuccess(
          'page.groupProfile.addServices.completedSuccess',
          { count: operationSuccess.length },
          'persist'
        );
        onCancel();
      }
    },
    variables: {
      groupCode: groupCode,
      serviceSubscriptions: serviceSubscriptions
    },
    refetchQueries: [GetPersonGroupServiceSubscriptions]
  });

  const onFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await addServicesToPersonGroup();
  };

  return (
    <FormAddServicesToGroup
      onFormSubmit={onFormSubmit}
      loading={isAddingService}
      disabled={selectedIds !== null && selectedIds.length < 1}
      totalCount={totalCount}
      onCancel={onCancel}
      testid={testid}
    />
  );
};

export default FormAddServicesToGroupContainer;
