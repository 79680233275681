import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    dialogContentRoot: {
        textAlign: 'center'
    },
    menuItemEl: {
        display: 'inline',
        marginRight: theme.spacing(1)
    },
    dialogActions: {
        flexDirection: 'column',
        margin: 0
    },
    buttonClose: {
        alignSelf: 'flex-end',
        marginTop: theme.spacing(3),
        color: theme.palette.secondary.main
    },
    contentText: {
        padding: `0 ${theme.spacing(6)}`
    },
    accessRequestImage: {
        display: 'block',
        width: '100%',
        maxWidth: 200,
        margin: `${theme.spacing(5)} auto ${theme.spacing(6)}`
    }
}))