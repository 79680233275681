import { Grid, Typography, useMediaQuery } from '@mui/material';
import { InfoOutlined as IconInfoOutlined } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import RequestAdministrativeAccessDialog from '../Dialog/RequestAdministrativeAccessDialog';
import { PersonContext } from '../../../Context/CurrentPerson';
import ExpirationDateTimer from '../ExpirationDateTimer';
import useTranslate from 'Hooks/useTranslate';
import { useStyles } from './RequestAdministrativeAccessBanner.styles';
import { mdiShieldCrown } from '@mdi/js';
import SvgIcon from 'Theme/components/SvgIcon/SvgIcon';
import colors from 'Theme/constants/colors';
import { useQuery } from '@apollo/client';
import { GetPersonAccessRights, PersonAccessRightStatus } from 'Apollo';
import { useContext } from 'react';
import { LocalContext } from 'Context/Local.context';
import mediaQueries from 'Theme/constants/mediaQueries';

interface Props {}

function RequestAdministrativeAccessBanner(props: Props) {
  const classes = useStyles();
  const { currentPerson } = useContext(PersonContext);
  const { accountCode } = useParams<{ accountCode: string }>();
  const ts = useTranslate();
  const { currentAccount, allAccountsSelected } = useContext(LocalContext);
  const isMobile = useMediaQuery(mediaQueries.mobile);

  const noCurrentAccount = !accountCode || allAccountsSelected;
  const displayWhenCurrentAccountButNotOnMobile =
    !isMobile && accountCode && accountCode !== 'all';
  const displaySuperReaderSentence =
    displayWhenCurrentAccountButNotOnMobile || noCurrentAccount;

  const { data, loading, refetch } = useQuery<GetPersonAccessRightsData>(
    GetPersonAccessRights,
    {
      skip: noCurrentAccount,

      variables: {
        personIdentifier: currentPerson.immutableId,
        status: [PersonAccessRightStatus.Accepted]
      }
    }
  );

  let access = data?.personAccessRights.edges.map(node => node.node);

  const canManageAccount = access?.some(a => a?.service.groupCode === 'ADM-1' && a?.account?.code === currentAccount?.code);
  const currentAccountAccess = access?.find(acc => acc?.service?.groupCode === 'ADM-1' && acc?.account?.code === currentAccount?.code);
  console.log('canManageAccount', canManageAccount);

  if (!loading && canManageAccount) {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={clsx(classes.banner, classes.pendingBanner)}
      >
        <Grid item className={classes.bannerText}>
          <IconInfoOutlined color="inherit" className={classes.icon} />
          <Typography variant="body2" color="inherit">
            <ExpirationDateTimer
              expirationDate={new Date(currentAccountAccess?.expirationDate!)}
              onExpired={() => refetch()}
            />
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={clsx(classes.banner, classes.requestBanner)}
    >
      <Grid item className={classes.bannerText}>
        <div className={classes.icon}>
          <SvgIcon path={mdiShieldCrown} fill={colors.white} />
        </div>
        <Typography variant="body2" color="inherit">
          {displaySuperReaderSentence && ts('common.person.role.superReaderSentence')}
        </Typography>
      </Grid>
      {accountCode && accountCode !== 'all' && (
        <Grid item>
          <RequestAdministrativeAccessDialog />
        </Grid>
      )}
    </Grid>
  );
}

export default RequestAdministrativeAccessBanner;
