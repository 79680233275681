import * as React from 'react';
import { Divider, Typography } from '@mui/material';
import { useStyles } from './PaperTitle.styles';
import ContextualHelp from 'Components/Shared/ContextualHelp';

interface Props {
  title: React.ReactNode;
  secondaryAction?: React.ReactNode;
  info?: string;
  // "subtitle1" is an old variant that is meant to disappear in the future.
  // Ideally, PaperTitle should not have any variant so it's always consistent.
  variant?: 'h5' | 'subtitle1';
}

const PaperTitle = (props: Readonly<Props>) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant={props.variant ? props.variant : 'h5'}>
            {props.title}
          </Typography>
          {props.info && <ContextualHelp content={props.info} />}
        </div>
        <div>{props.secondaryAction}</div>
      </div>
      {props.variant === 'subtitle1' && <Divider />}
    </>
  );
};

export default PaperTitle;
