import { get, remove, set } from 'local-storage';
import { TableCollectionColumn } from 'Theme/components/TableCollection/TableCollection';
import { ICsrfToken } from './auth';

export enum LocalStorageKeys {
  CsrfToken = 'CSRFToken',
  ServicesColumns = 'servicesColumns',
  LightServicesColumns = 'lightServicesColumns',
  UsersColumns = 'usersColumns',
  LightUsersColumns = 'lightUsersColumns',
  GroupAvailabledServices = 'groupAvailabledServicesColumns',
  LightGroupAvailabledServices = 'lightGroupAvailabledServicesColumns',
  GroupCandidatesColumns = 'groupCandidatesColumns'
}

export interface SerializedColumn {
  key: string;
  hidden: boolean;
}

class LocalStorage {
  public static get CsrfToken() {
    return get<ICsrfToken>(LocalStorageKeys.CsrfToken);
  }

  public static set CsrfToken(value: ICsrfToken | null) {
    if (value == null) {
      remove(LocalStorageKeys.CsrfToken);
    } else {
      set<ICsrfToken>(LocalStorageKeys.CsrfToken, value);
    }
  }

  public static serializeColumns(
    columns: TableCollectionColumn[]
  ): SerializedColumn[] {
    return columns.map(column => ({
      key: column.key,
      hidden: Boolean(column.hidden)
    }));
  }
  public static deserializeColumns(
    columns: TableCollectionColumn[],
    serializedColumns: SerializedColumn[]
  ) {
    const deserializedColumns = serializedColumns.reduce(
      (acc: TableCollectionColumn[], serializedColumn) => {
        const column = columns.find(
          column => column.key === serializedColumn.key
        );
        return column
          ? acc.concat([
              {
                ...column,
                ...serializedColumn
              }
            ])
          : acc;
      },
      []
    );
    const newColumns = columns
      .filter(
        column =>
          deserializedColumns.find(
            deserializedColumn => deserializedColumn.key === column.key
          ) == null
      )
      .map(column => ({
        ...column,
        hidden:
          // If the user had a configuration in their localStorage and it didn't include this column, hide it by default.
          // This way we don't mess with their configuration when adding new columns to a table.
          deserializedColumns.length > 0 ? true : Boolean(column.hidden)
      }));

    return deserializedColumns.concat(newColumns);
  }

  public static get GroupCandidatesColumns() {
    return get<SerializedColumn[]>(LocalStorageKeys.GroupCandidatesColumns);
  }
  public static set GroupCandidatesColumns(value: SerializedColumn[] | null) {
    if (value == null) {
      remove(LocalStorageKeys.GroupCandidatesColumns);
    } else {
      set<SerializedColumn[]>(LocalStorageKeys.GroupCandidatesColumns, value);
    }
  }

  public static get ServicesColumns() {
    return get<SerializedColumn[]>(LocalStorageKeys.ServicesColumns);
  }
  public static set ServicesColumns(value: SerializedColumn[] | null) {
    if (value == null) {
      remove(LocalStorageKeys.ServicesColumns);
    } else {
      set<SerializedColumn[]>(LocalStorageKeys.ServicesColumns, value);
    }
  }

  public static get LightServicesColumns() {
    return get<SerializedColumn[]>(LocalStorageKeys.LightServicesColumns);
  }
  public static set LightServicesColumns(value: SerializedColumn[] | null) {
    if (value == null) {
      remove(LocalStorageKeys.LightServicesColumns);
    } else {
      set<SerializedColumn[]>(LocalStorageKeys.LightServicesColumns, value);
    }
  }

  public static get UsersColumns() {
    return get<SerializedColumn[]>(LocalStorageKeys.UsersColumns);
  }
  public static set UsersColumns(value: SerializedColumn[] | null) {
    if (value == null) {
      remove(LocalStorageKeys.UsersColumns);
    } else {
      set<SerializedColumn[]>(LocalStorageKeys.UsersColumns, value);
    }
  }

  public static get LightUsersColumns() {
    return get<SerializedColumn[]>(LocalStorageKeys.LightUsersColumns);
  }
  public static set LightUsersColumns(value: SerializedColumn[] | null) {
    if (value == null) {
      remove(LocalStorageKeys.LightUsersColumns);
    } else {
      set<SerializedColumn[]>(LocalStorageKeys.LightUsersColumns, value);
    }
  }

  public static get GroupAvailabledServices() {
    return get<SerializedColumn[]>(LocalStorageKeys.GroupAvailabledServices);
  }
  public static set GroupAvailabledServices(value: SerializedColumn[] | null) {
    if (value == null) {
      remove(LocalStorageKeys.GroupAvailabledServices);
    } else {
      set<SerializedColumn[]>(LocalStorageKeys.GroupAvailabledServices, value);
    }
  }

  public static get LightGroupAvailabledServices() {
    return get<SerializedColumn[]>(LocalStorageKeys.LightGroupAvailabledServices);
  }
  public static set LightGroupAvailabledServices(value: SerializedColumn[] | null) {
    if (value == null) {
      remove(LocalStorageKeys.LightGroupAvailabledServices);
    } else {
      set<SerializedColumn[]>(LocalStorageKeys.LightGroupAvailabledServices, value);
    }
  }
}

export default LocalStorage;
