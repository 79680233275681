import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import React from 'react';
import ButtonLoading from 'Theme/components/ButtonLoading';
import TestIds from 'Tests/TestIds';
import useTranslate from 'Hooks/useTranslate';
import { GetPersonGroup, UpdatePersonGroup } from 'Apollo';
import useNotification from 'Hooks/useNotification';
import { useMutation } from '@apollo/client';

interface Props {
  group: IPersonGroup;
  onClose: () => void;
  field: 'label' | 'description';
}

const EditGroupDialog = (props: Props) => {
  const { group, field, onClose } = props;
  const ts = useTranslate();
  const [label, setLabel] = React.useState(group.name);
  const [description, setDescription] = React.useState(group.description);
  const { onSuccess, onError } = useNotification();

  let isValid =
    (field === 'label' && label.length > 0) ||
    (field === 'description' && description.length > 0);

  const [updatePersonGroup, { loading }] = useMutation<
    UpdatePersonGroupData,
    UpdatePersonGroupVars
  >(UpdatePersonGroup, {
    onCompleted: () => {
      onClose();
      onSuccess('component.editToggle.editGroup.update.success');
    },
    onError,
    refetchQueries: [
      {
        query: GetPersonGroup,
        variables: {
          code: group.code
        }
      },
      'GetPersonGroup'
    ]
  });

  const onUpdate = () => {
    updatePersonGroup({
      variables: {
        groupIdentifier: group.code,
        label: label,
        description: description
      }
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loading || !isValid) {
      return;
    }
    onUpdate();
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth={true}>
      <form
        onSubmit={handleSubmit}
        data-testid={TestIds.pages.profile.editNameForm}
      >
        <DialogTitle>{ts('page.groupProfile.editProfile.title')}</DialogTitle>
        <DialogContent>
          {field === 'label' && (
            <TextField
              autoFocus={true}
              variant="outlined"
              type="text"
              name="name"
              label={ts('page.groupProfile.editProfile.name')}
              value={label}
              fullWidth={true}
              onChange={e => setLabel(e.target.value)}
              error={label.length === 0}
              helperText={
                label.length === 0 ? ts('helperText.groupNameRequired') : ''
              }
            />
          )}
          {field === 'description' && (
            <TextField
              variant="outlined"
              multiline={true}
              minRows={4}
              maxRows={10}
              type="text"
              name="description"
              label={ts('page.groupProfile.editProfile.description')}
              value={description}
              fullWidth={true}
              onChange={e => setDescription(e.target.value)}
              error={description.length === 0}
              helperText={description.length === 0 ? ts('helperText.groupDescriptionRequired') : ''}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color='inherit'
            onClick={onClose}
            data-testid={TestIds.common.snackbar.action.cancelNameChange}>
            {ts('common.action.cancel')}
          </Button>
          <ButtonLoading
            data-testid={TestIds.pages.profile.saveNameButton}
            type="submit"
            color="primary"
            disabled={!isValid}
            loading={loading}
          >
            {ts('common.action.validate')}
          </ButtonLoading>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditGroupDialog;
