import AccountSelection from './AccountSelection';
import { useLazyQuery } from '@apollo/client';
import { AccountOrder, GetAccounts } from 'Apollo';

interface Props {}

const AccountSelectionContainer = (props: Props) => {
  const [getData, { data: selectorOptions }] = useLazyQuery<GetAccountsData>(
    GetAccounts,
    {
      variables: { 
        page: { first: 10, offset: 0 },
        orderBy: AccountOrder.NameAsc
      }
    }
  );

  const handleSearch = (value: string) => {
    let filterValue = value.trim();
    if (filterValue !== '') {
      getData({ variables: { filter: filterValue } });
    }
  };

  let accounts = selectorOptions?.accounts.edges.map(acc => acc.node) || [];

  return <AccountSelection accounts={accounts} onSearch={handleSearch} />;
};

export default AccountSelectionContainer;
