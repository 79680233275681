import { Divider, Grid } from '@mui/material';
import { Fragment } from 'react';
import Skeleton from 'Theme/components/Skeleton';

const TableServicesGroupsSkeleton = () => {

  const mockRows = Array.from(Array(4).keys());
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item><Skeleton width={"10%"} height="20px" variant="rectangular" /></Grid>
      <Grid item><Divider /></Grid>
      {mockRows.map((i) => {
        return (
          <Fragment key={i}>
            <Grid item><Skeleton width={"100%"} height="20px" variant="rectangular" /></Grid>
            <Grid item><Divider /></Grid>
          </Fragment>
        );
      })}
      <Grid item container>
        <div style={{width: "80%"}}></div>
        <Skeleton width={"20%"} height="20px" variant="rectangular" />
      </Grid>
    </Grid>
  );
};

export default TableServicesGroupsSkeleton;
