import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import EmptyState from 'Components/Shared/EmptyState';
import useTranslate from 'Hooks/useTranslate';
import { calculateNewPage } from 'Tools/calculateNewPage';
import TableCollection from 'Theme/components/TableCollection/TableCollection';
import { PersonOrder } from 'Apollo';
import { useColumnsUserServices } from 'Hooks/useColumnsUserServices';
import useTableHandlers from 'Hooks/useTableHandlers';
import { useState } from 'react';
import UserServicesActionsDrawer from 'Components/ServiceMembersPanel/Drawers/UserServicesActionsDrawer';
import { stringifyPersonAccessRight } from 'Tools/stringifyPersonAccessRight';
import { useActionsOnUserServicesColumns } from 'Hooks/useActionsOnUserServicesColumns';
import { useMediaQuery } from '@mui/material';
import mediaQueries from 'Theme/constants/mediaQueries';

interface Props {
  testId?: string;
  person: IPersonWithAccount;
  currentPage: number;
  rowsPerPage: number;
  loading: boolean;
  accessRights: IPersonAccessRight[];
  totalCount: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (v: number) => void;
}

function AccessRightsTable(props: Readonly<Props>) {
  const { person, loading, rowsPerPage, accessRights, currentPage, totalCount, onChangeRowsPerPage, onChangePage } = props;

  const ts = useTranslate();

  const isMobile = useMediaQuery(mediaQueries.mobile);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [drawerMode, setDrawerMode] = useState<string | null>(null);

  const handleDrawer = (mode: string | null) => {
    if(mode === null){
      setDrawerMode(null);
      setSelectedIds([]);
    } else {
      setDrawerMode(mode);
    }
  }

  const onUpdate = (ids: string[]) => {
    setSelectedIds(ids);
    if(ids.length === 0) {     
      setDrawerMode(null);
    } else {
      setDrawerMode('setDrawerMode');
    }
  };

  const { handleSelect, handleUnselect } = useTableHandlers({
    onUpdate,
    selectedIds
  });

  const onRemoveAccess = (row: IPersonAccessRight) => {
    setSelectedIds([stringifyPersonAccessRight(row)]);
    setDrawerMode('setRemoval');
  }

  const onUpdateUserAccess = (row: IPersonAccessRight) => {
    setSelectedIds([stringifyPersonAccessRight(row)]);
    setDrawerMode('setExpiration');
  }

  const columns = useColumnsUserServices({
    isStatusHidden: isMobile
  }).concat(
    useActionsOnUserServicesColumns(onRemoveAccess, onUpdateUserAccess)
  );

  const handleChangeRowperPage = (newRowsPerPage: number) => {
    onChangeRowsPerPage(newRowsPerPage);
    onChangePage(
      calculateNewPage(currentPage, rowsPerPage, newRowsPerPage)
    );
  };

  return (
    <>
      <TableCollection
        isSelectAllDisabled={true}
        loading={loading}
        columns={columns}
        rows={loading ? [] : accessRights}
        getRowId={(acc: IPersonAccessRight) =>
          stringifyPersonAccessRight(acc)
        }
        disabledRows={accessRights.filter((acc) => (acc.personGroupSource !== null || acc.status !== 'ACCEPTED')).map(row => stringifyPersonAccessRight(row))}
        orderBy={PersonOrder.NameAsc}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowperPage}
        page={currentPage}
        onChangePage={(page: number) => onChangePage(page)}
        selectedIds={selectedIds}
        onSelect={handleSelect}
        onUnselect={handleUnselect}
        totalCount={totalCount}
        emptyStateData={
          <EmptyState
            icon={<BubbleChartIcon color="inherit" />}
            primary={ts('page.userProfile.services.emptyStateTitle')}
            secondary={ts('page.userProfile.services.emptyStateText')}
          />
        }
      />
      {drawerMode && (
        <UserServicesActionsDrawer person={person} currentMode={drawerMode} selectedIds={selectedIds} setMode={handleDrawer} />
      )}
    </>
  );
}

export default AccessRightsTable;
