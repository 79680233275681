export function isValidIP(ip: string) {
  /**
   *  The regex pattern validates an IP address with the following rules:
   *  - It ensures that the IP address is not 255.255.255.255 or 0.0.0.0.
   *  - It disallows any IP address in the range of 10.0.0.0 to 10.255.255.255.
   *  - It disallows any IP address in the range of 172.16.0.0 to 172.31.255.255.
   *  - It disallows any IP address in the range of 192.168.0.0 to 192.168.255.255.
   *  - It disallows any IP address in the range of 169.254.0.0 to 169.254.255.255.
   *  - It validates IP addresses within the range of 0.0.0.0 to 255.255.255.255 with each segment being a number between 0 and 255 inclusive.
   */

  const pattern = /^(?!.*(?:^|\.)255\.255\.255\.255$)(?!.*(?:^|\.)0\.0\.0\.0$)(?!^10\.)(?!^172\.(1[6-9]|2\d|3[01])\.)(?!^192\.168\.)(?!^169\.254\.)(25\d|2[0-4]\d|[01]?\d\d?)\.((25\d|2[0-4]\d|[01]?\d\d?)\.){2}(25\d|2[0-4]\d|[01]?\d\d?)$/;

  return pattern.test(ip);
}
