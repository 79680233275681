import { gql } from '@apollo/client';

export const GetOrganizations = gql`
  query GetOrganizations {
    organizations {
      edges {
        node {
          code
          name
          dateCreation
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
`;