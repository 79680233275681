import { Box, Divider, List } from '@mui/material';
import * as React from 'react';
import Skeleton from 'Theme/components/Skeleton';

function DashBoardSubscriptionRequestsSkeleton() {
  return (
    <List>
      {Array.from(Array(3).keys()).map(k => {
        return (
          <React.Fragment key={`SkeletonRequest-${k}`}>
            <Box p={3}>
              <Skeleton variant="rectangular" width="100%" height="70px" />
            </Box>
            <Divider variant="inset" sx={{ opacity: 0.6 }} />
          </React.Fragment>
        );
      })}
    </List>
  );
}

export default DashBoardSubscriptionRequestsSkeleton;
