export default function shortAccountlabel(acc: Partial<IAccount>) {

  let labelToDisplay: string = '';

  if (acc.code && acc.name) {
    labelToDisplay = acc.code + ' - ' + acc.name;
  }

  if (!acc.code && acc.name) {
    labelToDisplay = acc.name;
  }

  if (acc.code && !acc.name) {
    labelToDisplay = acc.code;
  }
  return labelToDisplay;
}
