import { gql } from '@apollo/client';
import { AvatarFragment } from '../person/Avatar';

export const LightPersonGroupFragment = gql`
  fragment LightPersonGroup on LightPersonGroup {
    avatar {
      ...Avatar
    }
    code
    name
  }
  ${AvatarFragment}
`;
