import { useQuery } from '@apollo/client';
import { GetPersonAccessRightRequests } from 'Apollo';
import { useParams } from 'react-router-dom';
import DashBoardSubscriptionRequests from './DashBoardSubscriptionRequests';

type Props = {};

const DashBoardSubscriptionRequestsContainer = (props: Props) => {
  const { accountCode } = useParams<IUriParams>();
  const { data, loading, refetch } = useQuery<
    IAdministratedPersonAccessRightsData,
    IAdministratedPersonAccessRightsVars
  >(GetPersonAccessRightRequests, {
    fetchPolicy: 'no-cache',
    variables: {
      accountCode: accountCode.toLocaleLowerCase() !== 'all' ? accountCode : '',
      status: 'TOVALIDATE',
      immutableId: '',
      infoPerson: '',
      page: { first: 3, offset: 0 }
    }
  });

  const onRefetch = () => refetch();

  const accessRightsRequests = data
    ? data.personAccessRightRequests.edges.map(i => i.node)
    : [];

  return (
    <DashBoardSubscriptionRequests
      loading={loading}
      accessRightsRequests={accessRightsRequests}
      onRefetch={onRefetch}
    />
  );
};

export default DashBoardSubscriptionRequestsContainer;
