import {
  Button,
  IconButton,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material'
import LinkOff from '@mui/icons-material/LinkOff';
import Link from '@mui/icons-material/Link';
import MoreHorizOutlined from '@mui/icons-material/MoreHorizOutlined';
import { createRef, useState } from 'react';
import useTranslate from 'Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import { useStyles } from './ActionsPopover.styles';
import { useCurrentUserInfo } from 'Hooks/useCurrentUserInfo';

interface Props {
  person: IPersonWithAccount;
  action1: () => void;
  action2: () => void;
}

function ActionsPopover(props: Readonly<Props>) {
  const { person, action1, action2 } = props;
  const classes = useStyles();

  const ts = useTranslate();

  const { isAdminOfOnlyOneAccount } = useCurrentUserInfo();

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const buttonRef = createRef<HTMLButtonElement>();

  const handleOpen = () => {
    setPopupOpen(true);
    setAnchorEl(buttonRef.current);
  };

  const handleClose = () => {
    setPopupOpen(false);
    setAnchorEl(null);
  };

  const handleAction1 = () => {
    action1();
    handleClose();
  };

  const handleAction2 = () => {
    action2();
    handleClose();
  };

  return (
    <>
      <IconButton
        ref={buttonRef}
        onClick={handleOpen}
        data-testid={`${TestIds.pages.profile.personalInformation.updateAccountButton}`}
      >
        <MoreHorizOutlined />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={popupOpen}
        onClose={handleClose}
      >
        <MenuItem
          component={Button as any}
          onClick={handleAction1}
          fullWidth
          className={classes.menuItem}
        >
          <ListItemText>
            {ts('component.userAccountUpdate.unlink', {
              value: person.account?.name ?? person.account?.code
            })}
          </ListItemText>
          <LinkOff className={classes.listItemIcon} />
        </MenuItem>
        {!isAdminOfOnlyOneAccount && (
          <MenuItem
            component={Button as any}
            onClick={handleAction2}
            fullWidth
            className={classes.menuItem}
          >
            <ListItemText>
              {ts('component.userAccountUpdate.link')}
            </ListItemText>
            <Link className={classes.listItemIcon} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default ActionsPopover;
