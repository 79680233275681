import { TTabView } from '../Components/Shared/Tabs/Tabs';
import { PrivateRoutes } from '../Router/Routes';
import ServiceProfileGeneral from '../Page/ServiceProfile/ServiceProfileGeneral';
import ServiceProfileRestrictions from '../Page/ServiceProfile/ServiceProfileRestrictions';
import { useParams, generatePath } from 'react-router-dom';
import ServiceProfileMembers from '../Page/ServiceProfile/ServiceProfileMembers';
import {Info as IconInfo, Lock as LockIcon, People as PeopleIcon} from '@mui/icons-material';

interface Props {
  service: IServiceSubscription;
}

const useTabsService = ({ service }: Props): TTabView[] => {
  const { accountCode, serviceCode } = useParams<{
    accountCode: string;
    serviceCode: string;
  }>();

  const getPath = (path: string) =>
    generatePath(path, {
      accountCode,
      serviceCode
    });

  const tabs: TTabView[] = [
    {
      icon: <IconInfo />,
      title: 'profile.menu.general',
      path: PrivateRoutes.serviceProfile.path,
      link: getPath(PrivateRoutes.serviceProfile.path),
      component: <ServiceProfileGeneral serviceSubscription={service} />
    },
    {
      icon: <PeopleIcon />,
      title: 'profile.menu.members',
      path: PrivateRoutes.serviceProfileMembers.path,
      link: getPath(PrivateRoutes.serviceProfileMembers.path),
      component: <ServiceProfileMembers serviceSubscription={service} />
    }
  ];

  if (!serviceCode.startsWith('ADM')) {
    tabs.push({
      icon: <LockIcon />,
      title: 'profile.menu.restrictions',
      path: PrivateRoutes.serviceProfileRestrictions.path,
      link: getPath(PrivateRoutes.serviceProfileRestrictions.path),
      component: <ServiceProfileRestrictions serviceSubscription={service} />
    });
  }

  return tabs;
};

export default useTabsService;
