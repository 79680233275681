// tslint:disable: jsx-no-lambda
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import TestIds from 'Tests/TestIds';

interface Props {
  snackbarId: string | number | undefined;
}

function SnackbarCloseButton(props: Props) {
  const { closeSnackbar } = useSnackbar();
  return (
    <Button
      data-testid={TestIds.common.snackbar.action.close}
      onClick={() => closeSnackbar(props.snackbarId)}
      color="inherit"
      sx={{ color : "white"}}
    >
      <FormattedMessage id="common.action.close" />
    </Button>
  );
}

export default SnackbarCloseButton;
