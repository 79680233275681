import { Person as UserIcon } from '@mui/icons-material';
import { useColumnsCandidates } from 'Hooks/useColumnsCandidates';
import useTranslate from '../../Hooks/useTranslate';
import EmptyState from 'Components/Shared/EmptyState';
import TableServicesCandidatesSkeleton from './TableServicesCandidates.skeleton';
import { TableCollection } from 'Theme/components/TableCollection';
import { useState } from 'react';
import useTableHandlers from 'Hooks/useTableHandlers';

interface Props {
  rows: ILightPersonProfile[];
  loading: boolean;
  totalCount: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (page: number) => void;
  onChangeRowPerPage: (rowsPerPage: number) => void;
  onUpdateSelection: (ids: string[]) => void;
}

const TableServicesCandidates = ({
  rows,
  loading,
  onChangePage,
  onChangeRowPerPage,
  totalCount,
  page,
  rowsPerPage,
  onUpdateSelection
}: Props) => {
  const columns = useColumnsCandidates();
  const ts = useTranslate();

  const onUpdate = (ids: string[]) => {
    setSelectedIds(ids);
    onUpdateSelection(ids)
  };

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { handleSelect, handleUnselect } = useTableHandlers({
    onUpdate,
    selectedIds
  });

  if (loading) {
    return <TableServicesCandidatesSkeleton />;
  }

  if (!loading && !rows.length)
    return (
      <EmptyState
        primary={ts('page.servicesMembers.noAvailableUserTitle')}
        icon={<UserIcon />}
      />
    );

  return (
    <TableCollection
      loading={false}
      columns={columns}
      rows={rows}
      getRowId={r => r.immutableId}
      totalCount={totalCount}
      page={page}
      rowsPerPage={rowsPerPage}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowPerPage}
      selectedIds={selectedIds}
      onSelect={handleSelect}
      onUnselect={handleUnselect}
      maxSelected={1000}
      isSelectAllDisabled={true}
    />
  );
};

export default TableServicesCandidates;
