export default [
  'mobileStepper',
  'drawer',
  'modal',
  'appBar',
  'snackbar',
  'tooltip'
].reduce(
  (zIndex, name, index) =>
    Object.assign(zIndex, { [name]: 1000 + 100 * index }),
  {}
) as {
  mobileStepper: number;
  drawer: number;
  modal: number;
  appBar: number;
  snackbar: number;
  tooltip: number;
};
