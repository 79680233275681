import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2)
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
  },
  toolbarLeft: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    flexGrow: 'inherit'
  },
  toolbarRight: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  }
}));
