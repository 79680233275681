import { gql } from '@apollo/client';
import { LightPersonProfileFragment } from '../person/LightPersonProfile';

export enum ApiKeyState {
  Active = 'ACTIVE',
  Revoqued = 'REVOKED'
}

export const ApiKeyFragment = gql`
  fragment ApiKey on ApiKey {
    creationDate
    revocationDate
    creator {
      ...LightPersonProfile
    }
    name
    accessRights {
      label
      groupCode
      accountName
      accountCode
    }
    state
    uuid
  }
  ${LightPersonProfileFragment}
`;
