import { gql } from '@apollo/client';
import { ApiKeyEventFragment } from '../../fragments/apikey/ApiKeyEvent';

export const GetApiKeyEvents = gql`
  query GetApiKeyEvents($apiKeyUuid: String!, $first: Int, $offset: Int) {
    apiKeyEvents(apiKeyUuid: $apiKeyUuid, first: $first, offset: $offset) {
      edges {
        node {
          ...ApiKeyEvent
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${ApiKeyEventFragment}
`;
