import FormUsersCreation from 'Components/FormUsersCreation';
import Modal from 'Components/Shared/Modal';

type Props = {
  isOpen: boolean;
  onClose(): void;
};

const ModalUsersCreation = (props: Props) => {
  const { onClose } = props;

  return (
    <Modal {...props} title="component.modals.createUser.heading" maxWidth="md">
      <FormUsersCreation onCancel={onClose} />
    </Modal>
  );
};

export default ModalUsersCreation;
