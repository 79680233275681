import { Avatar } from '@mui/material';
import { AvatarProps } from '@mui/material/Avatar';
import { Group as IconGroup, } from '@mui/icons-material';
import { useStyles } from './Square.styles';

interface Props extends AvatarProps {
  group: ILightPersonGroup;
  size: 'smaller' | 'small' | 'medium' | 'large' | 'larger';
}

function GroupAvatar(props: Readonly<Props>) {
  const { group, size } = props;
  const classes = useStyles();

  return (
    <Avatar
      {...props}
      variant='square'
      role="avatar"
      classes={{
        root: classes[size] ?? classes['larger']
      }}
      style={{
        backgroundColor: group.avatar.color ? group.avatar.color : ''
      }}
    >
      {group?.avatar?.initials?.length ? group.avatar.initials : <IconGroup className={classes.icon} />}
    </Avatar>
  );
}

export default GroupAvatar;
