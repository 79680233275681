import { useMutation } from '@apollo/client';
import { useRouteMatch } from 'react-router-dom';
import { AddServiceSubscriptionExternalOwner } from '../../Apollo/mutations/service/AddServiceSubscriptionExternalOwner';
import useNotification from '../../Hooks/useNotification';
import FormExternalOwner from './FormExternalOwner';

type Props = {
  onCancel(): void;
};

export type TExternalOwnerValuesForm = Pick<
  IAddServiceSubscriptionExternalOwnerInput,
  'email'
>;

const FormExternalOwnerContainer = ({ onCancel }: Props) => {
  const { onError, onSuccess } = useNotification();
  const { params: serviceSubscriptionIdentifier } = useRouteMatch<
    IServiceSubscriptionIdentifier
  >();
  const [addExternalOwner, { loading }] = useMutation<
    ILightPersonProfile,
    IAddServiceSubscriptionExternalOwnerInput
  >(AddServiceSubscriptionExternalOwner);

  const onAddExternalOwner = ({ email }: TExternalOwnerValuesForm) => {
    addExternalOwner({
      variables: {
        email,
        serviceSubscriptionIdentifier
      },
      onError,
      onCompleted: () => {
        onSuccess('page.serviceProfile.owners.sucess', {
          ownersCount: 1
        });
        onCancel();
      },
      refetchQueries: ['GetServiceOwners']
    });
  };

  return (
    <FormExternalOwner
      onSubmit={onAddExternalOwner}
      loading={loading}
      onCancel={onCancel}
    />
  );
};

export default FormExternalOwnerContainer;
