import { gql } from '@apollo/client';
import { AccountFragment } from '../account/Account';
import { ServiceFragment } from '../service/Service';
import { PersonFragment } from './Person';
import { PersonGroupFragment } from '../personGroup/PersonGroup';

export enum PersonAccessRightStatus {
  Accepted = 'ACCEPTED',
  ToValidate = 'TOVALIDATE',
  Refused = 'REFUSED',
  Suspended = 'SUSPENDED'
}

export const PersonAccessRightFragment = gql`
  fragment PersonAccessRight on AccessRight {
    person {
      ...Person
    }
    account {
      ...Account
    }
    service {
      ...Service
    }
    personGroupSource {
      ...PersonGroup
    }
    isAvailable
    status
    expirationDate
    creationDate
  }
  ${PersonFragment}
  ${ServiceFragment}
  ${PersonGroupFragment}
  ${AccountFragment}
`;
