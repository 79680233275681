const FONT_FAMILY = '"Roboto", "Helvetica", "Arial", sans-serif';
const FONT_WEIGHT_LIGHT = 300;
const FONT_WEIGHT_REGULAR = 400;
const FONT_WEIGHT_MEDIUM = 500;
const FONT_WEIGHT_BOLD = 700;

const typography = {
  htmlFontSize: 16,
  fontSize: 14,

  fontWeightLight: FONT_WEIGHT_LIGHT,
  fontWeightRegular: FONT_WEIGHT_REGULAR,
  fontWeightMedium: FONT_WEIGHT_MEDIUM,
  fontWeightBold: FONT_WEIGHT_BOLD,

  h1: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_LIGHT,
    fontSize: 96,
    lineHeight: 1,
    letterSpacing: '-1.5px'
  },
  h2: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_LIGHT,
    fontSize: 60,
    lineHeight: 1,
    letterSpacing: '-0.5px'
  },
  h3: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: 48,
    lineHeight: 1.04,
    letterSpacing: 0
  },
  h4: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: 24,
    lineHeight: 1.17,
    letterSpacing: '0.25px'
  },
  h5: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: 20,
    lineHeight: 1.33,
    letterSpacing: '0em'
  },
  h6: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_MEDIUM,
    fontSize: 20,
    lineHeight: 1.6,
    letterSpacing: '0.25px'
  },
  subtitle1: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_LIGHT,
    fontSize: 16,
    lineHeight: 1.75,
    letterSpacing: '0.15px'
  },
  subtitle2: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_MEDIUM,
    fontSize: 14,
    lineHeight: 1.57,
    letterSpacing: '0.1px'
  },
  body1: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: '0.5px'
  },
  body2: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.25px'
  },
  button: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_MEDIUM,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '1.25px',
    // TypeScript has a brainfart here and takes "uppercase" for a string, instead of "uppercase".
    textTransform: 'uppercase' as 'uppercase'
  },
  caption: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_REGULAR,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em'
  },
  overline: {
    fontFamily: FONT_FAMILY,
    fontWeight: FONT_WEIGHT_MEDIUM,
    fontSize: '0.75rem',
    lineHeight: 2.66,
    letterSpacing: '0.08333em',
    // TypeScript has a brainfart here and takes "uppercase" for a string, instead of "uppercase".
    textTransform: 'uppercase' as 'uppercase'
  }
};

export default typography;
