import { useFormikContext, getIn } from 'formik';
import { TextField, TextFieldProps, Grid, Chip, Box, Autocomplete } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AutocompleteChangeReason } from '@mui/material/useAutocomplete';
import useTranslate from 'Hooks/useTranslate';
import TestIds from 'Tests/TestIds';

interface ITextInput extends Omit<TextFieldProps, 'name'> {
  name: string;
}

const useStyles = makeStyles(() => ({
  input: {
    maxHeight: 280,
    alignItems: 'start',
    overflowX: 'hidden',
    overflowY: 'auto'
  }
}));

const TextInputEmails = ({ name, helperText, ...otherProps }: ITextInput) => {
  const classes = useStyles();
  const ts = useTranslate();
  const {
    setFieldTouched,
    setFieldValue,
    values,
    errors,
    touched
  } = useFormikContext();
  const emailsValue = getIn(values, name);
  const error = getIn(errors, name);
  const visible = getIn(touched, name);

  const verifyEmail = (email: string) => {
    const regex = window.AppSettings.EMAIL_VALIDATION;
    return new RegExp(regex).test(email);
  };
  const onChange = (
    e: React.ChangeEvent<{}>,
    values: any[],
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'removeOption' || reason === 'createOption')
      setFieldValue(name, values);
    if (reason === 'clear') return setFieldValue(name, []);
  };

  const onInputChange = (e: React.ChangeEvent<{}>, values: string) => {
    const emails = values.split(/[,;\s]/).map((e: string) => e.trim());
    const emailsVerified = emails.filter((e: string) => !!e);

    if (emails.length > 1 && emailsVerified.length > 0) {
      const newEmails = [...emailsValue, ...emailsVerified];
      setFieldValue(name, newEmails);
    }
  };

  const onBlur = (e: React.FocusEvent<{}>, values: string) => {
    const emails = values.split(/[,;\s]/).map((e: string) => e.trim());
    const emailsVerified = emails.filter((e: string) => !!e);
    emailsVerified.length > 0 &&
      setFieldValue(name, [...emailsValue, ...emailsVerified]);
  };

  return (
    <Grid
      container
      direction="column"
      style={{ position: 'relative', paddingBottom: 20 }}
    >
      <Autocomplete
        clearOnBlur
        clearOnEscape
        multiple
        value={emailsValue}
        options={[]}
        freeSolo
        limitTags={3}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option, index) => {
            return (
              <Chip
                variant="outlined"
                color={verifyEmail(option) ? 'default' : 'secondary'}
                label={option}
                {...getTagProps({ index })}
              />
            );
          })
        }
        onChange={onChange}
        autoSelect
        onInputChange={onInputChange}
        renderInput={params => (
          <TextField
            {...params}
            aria-label={`input-${name}`}
            size="small"
            variant="outlined"
            onBlur={e => {
              setFieldTouched(name);
              onBlur(e, e.target.value);
            }}
            placeholder={
              !emailsValue.length
                ? ts('component.form.placeholder.emailsInput')
                : undefined
            }
            helperText={(visible && error) || helperText}
            inputProps={{
              ...params.inputProps,
              style: {
                overflow: 'auto',
                flex: '0 0 90%'
              }
            }}
            InputProps={{
              ...params.InputProps,
              className: `${params.InputProps.className} ${classes.input}`,
              startAdornment: (
                <div
                  style={{
                    maxHeight: 150,
                    overflowY: 'auto'
                  }}
                >
                  {params.InputProps.startAdornment}
                </div>
              )
            }}
            error={!!error && visible}
            {...otherProps}
            data-testid={TestIds.component.inviteUserDialog.emailInput}
          />
        )}
      />
      {emailsValue.length > 0 && (
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Chip
            color="default"
            label={ts('component.form.label.emailInputCount', {
              emailsCount: emailsValue.length
            })}
            size={'small'}
          />
        </Box>
      )}
    </Grid>
  );
};

export default TextInputEmails;
