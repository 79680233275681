import { Grid, useMediaQuery } from '@mui/material';
import { PersonGroupsOrder } from 'Apollo';
import PersonGroupNameCell from 'Components/Shared/PersonGroupNameCell/PersonGroupNameCell';
import Skeleton from 'Theme/components/Skeleton';
import useTranslate from './useTranslate';
import { MoreHorizOutlined as MoreIcon } from '@mui/icons-material';
import IconButtonWithPopOver from 'Theme/components/IconButtonWithPopOver';
import { TableCollectionColumn } from 'Components/Shared/Table';
import mediaQueries from 'Theme/constants/mediaQueries';

export interface IGroupColumnsConfig {
  isGroupHidden?: boolean;
  isDescriptionHidden?: boolean;
  isAccountHidden?: boolean;
}

export const groupCandidatesDefaultConfig: IGroupColumnsConfig = {
  isGroupHidden: false,
  isDescriptionHidden: false,
  isAccountHidden: false
};

export const useColumnsGroups = (
  columnsConfig: IGroupColumnsConfig
): TableCollectionColumn<{ node: IPersonGroup }>[] => {
  const ts = useTranslate();

  const isMobileP = useMediaQuery(mediaQueries.mobilePortrait);

  return [
    {
      key: 'group',
      orderBy: {
        asc: PersonGroupsOrder.NameAsc,
        desc: PersonGroupsOrder.NameDesc
      },
      renderHead: () => ts('page.groups.index.group'),
      renderSkeleton: () => (
        <Grid container={true} alignItems="center">
          <Skeleton variant="rectangular" width={40} height={40} />
          <Skeleton
            variant="rectangular"
            width="20%"
            style={{ marginLeft: '16px' }}
          />
        </Grid>
      ),
      renderCell: ({
        row: { node: personGroup }
      }: {
        row: { node: IPersonGroup };
      }) => <PersonGroupNameCell group={personGroup} />
    },
    {
      key: 'description',
      orderBy: {
        asc: PersonGroupsOrder.DescriptionAsc,
        desc: PersonGroupsOrder.DescriptionDesc
      },
      renderHead: () => ts('common.terminology.description'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: personGroup }
      }: {
        row: { node: IPersonGroup };
      }) =>
        isMobileP ? (
          <IconButtonWithPopOver
            description={personGroup.description}
            customIcon={<MoreIcon />}
          />
        ) : (
          personGroup.description
        )
    },
    {
      key: 'account',
      orderBy: {
        asc: PersonGroupsOrder.AccountNameAsc,
        desc: PersonGroupsOrder.AccountNameDesc
      },
      renderHead: () => ts('page.groups.index.account'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: personGroup }
      }: {
        row: { node: IPersonGroup };
      }) =>
        personGroup.account && (
          <span id="common.accountLabel">{personGroup.account.name}</span>
        )
    }
  ];
};
