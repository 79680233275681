import {
  Paper,
  Theme,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import * as React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client';
import { GetPersonActivities, ActivityType, ActivationStatus } from 'Apollo';
import { DataRow, InfiniteScroll } from 'Theme/components';
import { ErrorHandler } from 'Theme/components/ErrorHandler';
import EmptyState from 'Components/Shared/EmptyState';
import HistoryIcon from '@mui/icons-material/History';
import AlertDisabledAccount from 'Components/AlertDisabledAccount';

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2)
    },
    circularClearer: {
      opacity: '0.3'
    },
    blockTitle: {
      padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
      height: theme.spacing(8)
    },
    formControlLabel: {
      marginRight: theme.spacing(4)
    },
    radioGroup: {
      justifyContent: 'center'
    }
  });

interface Props {
  person: IPersonWithAccount;
}

interface State {
  activityType: ActivityType;
}

class TabActivities extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  public readonly state: State = {
    activityType: ActivityType.All
  };

  public render() {
    const { person, classes } = this.props;
    const { activityType } = this.state;
    const isDisabled = person?.status === ActivationStatus.Disabled;

    return (
      <>
        {isDisabled && <AlertDisabledAccount />}
        <ErrorHandler.Consumer>
          {errorHandler => (
            <Query
              query={GetPersonActivities}
              variables={{
                personIdentifier: person.immutableId,
                activityType,
                first: 50
              }}
              onError={errorHandler.onError}
            >
              {({ data, fetchMore }: QueryResult<GetPersonActivitiesData>) => (
                <InfiniteScroll
                  onFetchMore={() => {
                    if (data == null || data.personActivities == null) {
                      return;
                    }

                    if (
                      data.personActivities.edges.length >=
                      data.personActivities.totalCount
                    ) {
                      return;
                    }

                    fetchMore({
                      variables: {
                        offset: data.personActivities.edges.length
                      },
                      updateQuery: (
                        prev: GetPersonActivitiesData,
                        { fetchMoreResult }
                      ) => {
                        if (fetchMoreResult == null) {
                          return prev;
                        }
                        return Object.assign({}, prev, {
                          personActivities: Object.assign(
                            {},
                            prev.personActivities,
                            {
                              edges: prev.personActivities.edges.concat(
                                fetchMoreResult.personActivities.edges
                              )
                            }
                          )
                        });
                      }
                    });
                  }}
                >
                  <Paper classes={{ root: classes.paper }}>
                    <DataRow
                      label={
                        <FormattedMessage id="page.userProfile.activities.filterLabel" />
                      }
                    >
                      <RadioGroup
                        name="activityType"
                        value={activityType}
                        onChange={(event, newActivityType) => {
                          this.setState({
                            activityType: newActivityType as ActivityType
                          });
                        }}
                        row
                        classes={{ root: classes.radioGroup }}
                      >
                        {Object.keys(ActivityType).map(key => (
                          <FormControlLabel
                            key={key}
                            classes={{ root: classes.formControlLabel }}
                            value={
                              ActivityType[key as keyof typeof ActivityType]
                            }
                            control={<Radio color="primary" />}
                            label={
                              <FormattedMessage
                                id="page.userProfile.activities.activityType"
                                values={{
                                  value:
                                    ActivityType[
                                      key as keyof typeof ActivityType
                                    ]
                                }}
                              />
                            }
                          />
                        ))}
                      </RadioGroup>
                    </DataRow>
                  </Paper>
                  <Paper>
                    <Typography
                      variant="h5"
                      classes={{ root: classes.blockTitle }}
                    >
                      <FormattedMessage id="page.userProfile.activities.title" />
                    </Typography>
                    {data === undefined ? (
                      <EmptyState
                        icon={<CircularProgress size={44} color="inherit" />}
                        primary={' '}
                        secondary={' '}
                        className={classes.circularClearer}
                      />
                    ) : data.personActivities !== undefined &&
                      data.personActivities.totalCount === 0 ? (
                      <EmptyState
                        icon={<HistoryIcon color="inherit" />}
                        primary={
                          <FormattedMessage id="page.userProfile.activities.emptyStateTitle" />
                        }
                        secondary={
                          <FormattedMessage id="page.userProfile.activities.emptyStateText" />
                        }
                      />
                    ) : (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <FormattedMessage id="page.userProfile.lastActivities.activity" />
                            </TableCell>
                            <TableCell>
                              <FormattedMessage id="page.userProfile.lastActivities.result" />
                            </TableCell>
                            <TableCell>
                              <FormattedMessage id="page.userProfile.lastActivities.date" />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.personActivities.edges.map(
                            ({ node: activity }, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <FormattedMessage
                                    id="page.userProfile.lastActivities.activityType"
                                    values={{
                                      value: activity.action
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <FormattedMessage
                                    id="page.userProfile.lastActivities.activityResult"
                                    values={{
                                      value: activity.actionResult
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <FormattedDate
                                    value={activity.date}
                                    year="numeric"
                                    month="2-digit"
                                    day="2-digit"
                                    hour="2-digit"
                                    minute="2-digit"
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    )}
                  </Paper>
                </InfiniteScroll>
              )}
            </Query>
          )}
        </ErrorHandler.Consumer>
      </>
    );
  }
}

export default withStyles(styles)(TabActivities);
