import React from 'react';
import { Box, Grid, Hidden, Typography } from '@mui/material';
import { FormikHelpers, FormikValues } from 'formik';
import { TextInput, Form, SubmitButton, ResetButton } from '../Shared';
import { Imgs } from '../../Assets';
import {
  IServiceFormIpFiltering,
  serviceFormDefaultIpFiltering,
  serviceFormIpFilteringValidation
} from '../../Models';
import useTranslate from '../../Hooks/useTranslate';

interface Props {
  onCreateRestriction(x: IServiceFormIpFiltering): void;
  loading: boolean;
}

const FormIpFiltering = ({ onCreateRestriction, loading }: Props) => {
  const ts = useTranslate();

  const handleSubmit = (
    restriction: FormikValues,
    { resetForm }: FormikHelpers<FormikValues>
  ) => {
    onCreateRestriction(restriction as IServiceFormIpFiltering);
    resetForm();
  };

  return (
    <Form
      initialValues={serviceFormDefaultIpFiltering}
      validationSchema={() => serviceFormIpFilteringValidation(ts)}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item container md={6} xs={12}>
          <Grid item container xs={12} justifyContent="space-between">
            <Grid item container md={5} xs={12}>
              <TextInput
                name="iPstart"
                label={ts('page.servicesIpRestrictions.index.startIp')}
                placeholder="___ . ___ . ___ . ___"
              />
            </Grid>
            <Grid item container md={2} justifyContent="center">
              <Hidden only={['sm', 'xs']}>
                <Typography children="-" />
              </Hidden>
            </Grid>
            <Grid item container md={5} xs={12}>
              <TextInput
                name="iPend"
                label={ts('page.servicesIpRestrictions.index.endIp')}
                placeholder="___ . ___ . ___ . ___"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextInput
              name="description"
              label={ts('page.servicesIpRestrictions.index.description')}
              placeholder={ts(
                'page.serviceProfile.security.descriptionPlaceholder'
              )}
            />
          </Grid>
        </Grid>
        <Grid item container md={2} justifyContent="center" />
        <Grid item container md={2} justifyContent="center">
          <img src={Imgs.ipFiltering.src} alt={Imgs.ipFiltering.alt} />
        </Grid>
        <Grid item container xs={12} justifyContent="center">
          <ResetButton title={ts('common.action.cancel')} />
          <Box p={2} />
          <SubmitButton
            title={ts('page.serviceProfile.security.addRange')}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default FormIpFiltering;
