import { Box, Grid } from '@mui/material';
import Skeleton from 'Theme/components/Skeleton';
import React from 'react';

type Props = {};

const AccessRightsSkeleton = (props: Props) => {
  const mockCards = Array.from(Array(4).keys());
  return (
    <Grid container direction="column" alignItems="center">
      <Grid container direction="column">
        <Box m={1} />
        {mockCards.map(i => {
          return (
            <Box py={2} key={i}>
              <Skeleton variant="rectangular" height={128} width="100%" />
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default AccessRightsSkeleton;
