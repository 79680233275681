import { gql } from '@apollo/client';

export const ServiceSubscriptionIpRestrictionFragment = gql`
  fragment ServiceIPRange on ServiceIPRange {
    activityKind
    iPstart
    iPend
    isActive
    description
    immutableId
  }
`;
