import { Container, Paper, Typography, Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import useTranslate from '../../../../Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import CheckboxWithIndetermined from './CheckboxWithIndetermined';
import { IServiceSubscriptionProperties } from './UpdateProperties.types';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
    width: '500px'
  },
  h6: {
    marginBottom: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  buttonsSectionEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(4)
  },
  proppertyRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

interface Props {
  onCancel: () => void;
  onSubmit: (data: IServiceSubscriptionProperties) => void;
  initialValues: IServiceSubscriptionProperties;
}

/**
 * @param initialValue a initial nullable boolean
 * @param value the current switch state
 * @returns the next state for the switch. If the initial value
 * is null, then we cycle between true, false and null.
 * Otherwise we only cycle between true and false.
 */
function toggleProperty(
  initialValue: boolean | null,
  value: boolean | null
): boolean | null {
  if (value === null) {
    return true;
  } else if (value) {
    return false;
  } else {
    return initialValue === null ? null : true;
  }
}

const UpdateProperties = ({ onCancel, onSubmit, initialValues }: Props) => {
  const ts = useTranslate();
  const classes = useStyles();
  const [state, setState] = React.useState<IServiceSubscriptionProperties>(
    initialValues
  );

  const { accessByDefault, administratedValidation } = state;

  const onClick = (propName: 'accessByDefault' | 'administratedValidation') => {
    return () => {
      setState(oldState => ({
        ...oldState,
        [propName]: toggleProperty(initialValues[propName], oldState[propName])
      }));
    };
  };

  return (
    <>
      <Helmet title={ts('page.services.updateProperties.title')} />
      <Container>
        <Paper classes={{ root: classes.paper }}>
          <form
            onSubmit={e => {
              e.preventDefault();
              onSubmit(state);
            }}
            noValidate
            onReset={onCancel}
          >
            <Typography variant="h6" classes={{ root: classes.h6 }}>
              {ts('page.services.action.updateServicesProperties')}
            </Typography>
            <div className={classes.proppertyRow}>
              <Typography variant="body2">
                {ts('page.serviceProfile.info.adminValidation')}
              </Typography>
              <CheckboxWithIndetermined
                value={administratedValidation}
                onClick={onClick('administratedValidation')}
                testid={
                  TestIds.pages.services.updateProperties.adminValidCheckbox
                }
              />
            </div>
            <div className={classes.proppertyRow}>
              <Typography variant="body2">
                {ts('page.serviceProfile.info.defaultService')}
              </Typography>
              <CheckboxWithIndetermined
                value={accessByDefault}
                onClick={onClick('accessByDefault')}
                testid={TestIds.pages.services.updateProperties.defaultCheckbox}
              />
            </div>
            <div className={classes.buttonsSectionEnd}>
              <Button
                type="reset"
                size="medium"
                color='inherit'
                classes={{ root: classes.cancelButton }}
                data-testid={
                  TestIds.pages.services.updateProperties.cancelButton
                }
              >
                <FormattedMessage id="common.action.cancel" />
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                data-testid={
                  TestIds.pages.services.updateProperties.submitButton
                }
              >
                <FormattedMessage id="common.action.validate" />
              </Button>
            </div>
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default UpdateProperties;
