import { gql } from '@apollo/client';
import { PersonFragment } from '../../fragments/person/Person';

export const MigrateIdentityToProvider = gql`
  mutation MigrateIdentityToProvider(
    $data: String!
    $identityProvider: String!
  ){
    migrateIdentityToProvider(data: $data, identityProvider: $identityProvider){
      ...Person
    }
  }
  ${PersonFragment}`;

