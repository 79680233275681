import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useStyles } from './Drawer.styles';
import { Person as UserIcon } from '@mui/icons-material';
import useTranslate from 'Hooks/useTranslate';
import {
  DeletePersonAccessRight,
  GetPersonAccessRights,
  UpdatePersonAccessRights
} from 'Apollo';
import { useMutation } from '@apollo/client';
import useNotification from 'Hooks/useNotification';
import { mdiCalendarClock } from '@mdi/js';
import {
  CdsColorActionNeutralActive,
  CdsSizeSpacing,
  CdsSizeSpecificXxs
} from '@cegid/design-tokens';
import SvgIcon from 'Theme/components/SvgIcon/SvgIcon';
import DrawerBottom from 'Components/Shared/BottomDrawer/DrawerBottom';
import IconRemoveCircle from '@mui/icons-material/RemoveCircle';
import TestIds from 'Tests/TestIds';
import { PersonContext } from 'Context/CurrentPerson';
import { useContext } from 'react';
import { ActivationStatus } from 'Apollo/fragments/person/Person';
import ButtonLoading from 'Theme/components/ButtonLoading';
import ExpirationDrawer from 'Components/Shared/ExpirationDrawer/ExpirationDrawer';

interface Props {
  person: IPersonWithAccount;
  selectedIds: string[];
  currentMode: string | null;
  setMode: (mode: string | null) => void;
}

function UserServicesActionsDrawer(props: Readonly<Props>) {
  const { person, selectedIds, currentMode, setMode } = props;
  const ts = useTranslate();
  const classes = useStyles();
  const { onSuccess, onError, onFailure } = useNotification();
  const { currentPerson, currentPersonRoles } = useContext(PersonContext);
  const isAdminMode = currentPersonRoles.isAccountAdmin;

  const isActivated = person.status === ActivationStatus.Activated;
  const isRemovalAllowed =
    isAdminMode ||
    (currentPerson.immutableId === person.immutableId && isActivated);
  let canWrite = isRemovalAllowed || currentPersonRoles.isServiceAdmin;

  let newSelected = selectedIds.map(id => JSON.parse(id));

  const [
    updateAccessRightsExpirationDate,
    { loading: loadingUpdateMutation }
  ] = useMutation<
    { updatePersonAccessRights: boolean },
    IUpdatePersonAccessRightVariables
  >(UpdatePersonAccessRights, {
    onCompleted: () => {
      onSuccess('page.serviceProfile.serviceMembers.updateEndDateResult.one');
      setMode(null);
    },
    refetchQueries: [GetPersonAccessRights],
    onError
  });

  const [removeAccessRights, { loading: loadingRemoveMutation }] = useMutation<
    { deletePersonAccessRight: boolean },
    IDeletePersonAccessRightVariables
  >(DeletePersonAccessRight, {
    onCompleted: () => {
      onSuccess(
        selectedIds.length > 1
          ? 'page.serviceProfile.serviceMembers.removeResult.manyServiceOneUser'
          : 'page.serviceProfile.serviceMembers.removeResult.one'
      );
      setMode(null);
    },
    refetchQueries: [GetPersonAccessRights],
    onError: error => {
      onFailure(error.message);
    }
  });

  let existingExpirationDateOnSelected = [];

  for (const item of newSelected) {
    if (item.expirationDate !== null) {
      existingExpirationDateOnSelected.push(item.expirationDate);
    }
  }

  const setAccessRightsEndDate = async (newExpiryDate: Date | null) => {
    let newDataU: IPersonAccessRightUpdate[] = newSelected.map(e => ({
      accountCode: e.accountCode,
      endValidityDate: newExpiryDate,
      personIdentifier: person.immutableId,
      serviceGroupCode: e.serviceCode
    }));

    await updateAccessRightsExpirationDate({
      variables: {
        data: newDataU
      }
    });
  };

  const setAccessRightsRemove = async () => {
    let newDataR: IPersonAccessRightDeletion[] = newSelected.map(e => ({
      accountCode: e.accountCode,
      personIdentifier: person.immutableId,
      serviceGroupCode: e.serviceCode
    }));

    await removeAccessRights({
      variables: {
        data: newDataR
      }
    });
  };

  return (
    <DrawerBottom
      drawerHead={
        <>
          <Grid item sm={4}>
            <div style={{ padding: '8px' }}>
              <Typography variant="body2" color="textSecondary">
                <UserIcon
                  color="primary"
                  classes={{ root: classes.headerLeftIcon }}
                />
                {ts('page.services.index.selectedServiceCount', {
                  count: selectedIds.length
                })}
              </Typography>
            </div>
          </Grid>
          <Grid item sm={4} p={1}>
            <div className={classes.drawerIcons}>
              <Tooltip
                title={ts(
                  'page.userProfile.acceptedServices.updateServiceEndDateForUser'
                )}
              >
                <IconButton
                  onClick={() => setMode('setExpiration')}
                  size="large"
                >
                  <SvgIcon
                    path={mdiCalendarClock}
                    fill={CdsColorActionNeutralActive}
                    width="24"
                  />
                </IconButton>
              </Tooltip>
              {canWrite && (
                <div className={classes.drawerIcons} style={{marginLeft: '8px'}}>
                  <Tooltip title={ts('common.action.delete')}>
                    <IconButton
                      onClick={() => setMode('setRemoval')}
                      data-testid={
                        TestIds.pages.profile.services.unsubscribeButton
                      }
                      size="large"
                    >
                      <IconRemoveCircle />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          </Grid>
          <Grid item sm={4}></Grid>
        </>
      }
      drawerBody={
        <>
          {currentMode === 'setExpiration' && (
            <ExpirationDrawer
              loading={loadingUpdateMutation}
              onCancel={() => setMode(null)}
              onSubmit={(newExpiryDate: Date) => {
                setAccessRightsEndDate(newExpiryDate);
              }}
              title={ts(
                'page.serviceProfile.serviceMembers.updateServicesEndDateForOneUser',
                {
                  count: selectedIds.length
                }
              )}
              expiryDateOfSelectedId={existingExpirationDateOnSelected.length >= 1 ? existingExpirationDateOnSelected[0] : undefined}
              usersCount={1}
              servicesCount={newSelected.length}
            />
          )}
          {currentMode === 'setRemoval' && (
            <div
              style={{
                margin: 40,
                textAlign: 'center'
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ marginBottom: CdsSizeSpecificXxs }}
              >
                {ts('page.userProfile.services.removeConfirmation', {
                  count: selectedIds.length
                })}
              </Typography>
              <Button
                size="medium"
                color="inherit"
                sx={{ marginRight: CdsSizeSpacing }}
                onClick={() => setMode(null)}
              >
                {ts('common.action.cancel')}
              </Button>
              <ButtonLoading
                data-testid={
                  TestIds.pages.profile.services.confirmDeletionButton
                }
                loading={loadingRemoveMutation}
                type="submit"
                size="medium"
                color="primary"
                variant="contained"
                onClick={() => setAccessRightsRemove()}
              >
                {ts('common.action.delete')}
              </ButtonLoading>
            </div>
          )}
        </>
      }
    />
  );
}

export default UserServicesActionsDrawer;
