import { gql, MutationFunction } from '@apollo/client';
import { AccessRightCreationFragment } from '../../fragments/person/AccessRightCreation';

export const CreatePersonAccessRights = gql`
  mutation CreatePersonAccessRights(
    $accessRightData: [ServiceSubscriptionInput]
    $personIdentifiers: [String]
  ) {
    createPersonAccessRights(
      accessRightData: $accessRightData
      personIdentifiers: $personIdentifiers
    ) {
      ...AccessRightCreation
    }
  }
  ${AccessRightCreationFragment}
`;

export type CreatePersonAccessRightsMutation = MutationFunction<
  CreatePersonAccessRightsData,
  CreatePersonAccessRightsVariables
>;
