import Table, { ITableMessages, TableCollectionColumn } from "./Table";

interface Props<T> {
    /**Hint: General state of loading (skeleton rendered)*/
    loading: boolean;
    /**Hint: */
    columns: TableCollectionColumn<T>[];
    /**Hint: Data loaded from api. Modeled via renderCell*/
    rows: T[];
    /**Hint: Custom function to calculate unique React Keys ids*/
    getRowId: (row: T) => string;
    /**Hint: String with the current order of the table*/
    orderBy?: string;
    /**Hint: Callback that is called when order by has changed*/
    onChangeOrderBy?: (orderBy: string) => void;
    /**Hint: Current Page number*/
    page: ITablePage;
    /**Hint: Callback that is called on Page Change*/
    onChangePage: (page: ITablePage) => void;
    /**Hint: Total count of results from the API without pagination*/
    totalCount: number;
    /**Hint: Data stored into localStorage with user preferences*/
    emptyStateData?: React.ReactNode;
    /**Hint: Mesages IDs to translate*/
    messages?: ITableMessages;
  }

const TableNoSelection = <T extends unknown>(props: Props<T>) => {
    return <Table<T>
        columns={props.columns}
        loading={props.loading}
        messages={props.messages}
        rows={props.rows}
        getRowId={props.getRowId}
        page={props.page}
        onChangePage={props.onChangePage}
        totalCount={props.totalCount}
        emptyStateData={props.emptyStateData}
        onSelectionUpdate={() => {}}
        selectedIds={[]}
        isSelectionHidden={true}
        maxSelected={0}
    />
};

export default TableNoSelection;