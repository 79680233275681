import {
  BubbleChart as IconBubbleChart,
  Person as IconPerson,
  Group as GroupIcon
} from '@mui/icons-material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import * as React from 'react';
import { useStyles } from './ErrorSnackbarContent.styles';

interface Props {
  domain: 'user' | 'service' | 'apiKey' | 'group' | null;
  children: React.ReactNode;
}

function ErrorSnackbarContent(props: Props) {
  const { domain, children } = props;

  const classes = useStyles();

  if (domain) {
    return (
      <span className={classes.container}>
        <span className={classes.icon}>
          {domain === 'user' && <IconPerson />}
          {domain === 'service' && <IconBubbleChart />}
          {domain === 'apiKey' && <VpnKeyIcon />}
          {domain === 'group' && <GroupIcon />}
        </span>
        <span className={classes.content}>{children}</span>
      </span>
    );
  }

  return <span className={classes.content}>{children}</span>;
}

export default ErrorSnackbarContent;
