import {
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { ExpandMore as IconExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import { useStyles } from './AddPersonsDrawer.styles';
import useTranslate from 'Hooks/useTranslate';
import TestIds from 'Tests/TestIds';
import LocalStorage from 'Tools/localStorage';
import { TableCollectionColumn } from 'Theme/components/TableCollection/TableCollection';
import { TableCollectionColumnSelection } from 'Theme/components/TableCollection';
import TableGroupCandidatesContainer from 'Components/TableGroupCandidates/TableGroupCandidates.container';
import FormAddGroupMemberContainer from 'Components/FormAddGroupMember/FormAddGroupMember.container';
import TableDrawer from 'Components/Shared/TableDrawer';
import { useColumnsUsers } from 'Hooks/useColumnsUsers';

interface Props {
  group: IPersonGroup;
  onCancel: () => void;
}

const AddPersonsDrawer = (props: Props) => {
  const { group, onCancel } = props;
  const classes = useStyles();
  const ts = useTranslate();

  const initialColumns = useColumnsUsers({});

  const [columns, setColumns] = useState<TableCollectionColumn[]>(
    LocalStorage.deserializeColumns(
      useColumnsUsers({}),
      LocalStorage.GroupCandidatesColumns ?? []
    )
  );

  const [selectedIds, setSelectedIds] = useState<string[] | null>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  return (
    <TableDrawer isOpen={true}>
      <AccordionSummary classes={{ content: classes.expansionPanelSummary }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">
              {ts('page.groupProfile.members.add')}
            </Typography>
          </Grid>
          <Grid item>
            <TableCollectionColumnSelection
              columns={columns}
              onChange={newColumns => {
                setColumns(newColumns);
                LocalStorage.GroupCandidatesColumns = LocalStorage.serializeColumns(
                  newColumns
                );
              }}
              onReset={() => {
                setColumns(initialColumns);
                LocalStorage.GroupCandidatesColumns = null;
              }}
            />
            <IconButton
              disableRipple
              onClick={() => onCancel()}
              data-testid={
                TestIds.pages.groupProfile.closeCandidateDrawerExpandMore
              }
              size="large">
              <IconExpandMore />
            </IconButton>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <TableGroupCandidatesContainer
          selectedIds={selectedIds}
          onUpdate={(ids: string[] | null) => setSelectedIds(ids)}
          updateTotalCount={(v: number) => setTotalCount(v)}
          groupCode={group.code}
        />
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <FormAddGroupMemberContainer
          onCancel={onCancel}
          selectedIds={selectedIds}
          totalCount={selectedIds === null ? totalCount : selectedIds.length}
          groupCode={group.code}
          data-testid={TestIds.pages.groupProfile.members.addMembersForm}
        />
      </AccordionActions>
    </TableDrawer>
  );
};

export default AddPersonsDrawer;
