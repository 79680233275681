import { gql } from '@apollo/client';
import { PersonFragment } from '../person/Person';

export enum AuditAction {
  Signin = 'SIGNIN',
  PersonGroupCreation = 'PERSONGROUPCREATION',
  PersonStatusChanged = 'PERSONSTATUSCHANGED',
  ProfileEdited = 'PROFILEEDITED',
  MigrationToMicrosoft = 'MIGRATIONTOMICROSOFT'
}

export enum AuditActionResult {
  Activated = 'ACTIVATED',
  Disabled = 'DISABLED',
  SigninSucceeded = 'SIGNINSUCCEEDED',
  SigninFailed = 'SIGNINFAILED',
  PersonCreated = 'PERSONCREATED',
  PersonDisabled = 'PERSONDISABLED',
  PersonActivated = 'PERSONACTIVATED',
  PersonToActivate = 'PERSONTOACTIVATE',
  AuthenticationSucceeded = 'AUTHENTICATIONSUCCEEDED'
}

export const AuditFragment = gql`
  fragment Audit on IPersonAudit {
    date
    action
    actionResult
    person {
      ...Person
    }
    author {
      ...Person
    }
  }
  ${PersonFragment}
`;
