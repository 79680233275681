import { Grid } from '@mui/material';
import { PersonOrder } from 'Apollo';
import Skeleton from 'Theme/components/Skeleton';
import { FormattedDate } from 'react-intl';
import useTranslate from './useTranslate';
import { UserNameCell } from 'Components/Shared/UserNameCell';
import { TableCollectionColumn } from 'Components/Shared/Table';
import UserStatusChip from 'Components/Shared/Chips/UserStatusChip/UserStatusChip';

export interface IUserColumnsConfig {
  isUserHidden?: boolean;
  isEmailHidden?: boolean;
  isStatusHidden?: boolean;
  isAccountHidden?: boolean;
  isCreationDateHidden?: boolean;
  isActivationDateHidden?: boolean;
  isLanguageHidden?: boolean;
  isPhoneNumberHidden?: boolean;
  isMobileNumberHidden?: boolean;
  isTitleCodeHidden?: boolean;
}

export const defaultConfig: IUserColumnsConfig = {
  isUserHidden: false,
  isEmailHidden: false,
  isStatusHidden: false,

  isAccountHidden: true,
  isCreationDateHidden: true,
  isActivationDateHidden: true,
  isLanguageHidden: true,
  isPhoneNumberHidden: true,
  isMobileNumberHidden: true,
  isTitleCodeHidden: true
};

export const useColumnsUsers = (
  columnsConfig: IUserColumnsConfig
): TableCollectionColumn<{ node: IPersonWithAccount }>[] => {
  const ts = useTranslate();

  return [
    {
      key: 'user',
      orderBy: {
        asc: PersonOrder.NameAsc,
        desc: PersonOrder.NameDesc
      },
      hidden: columnsConfig.isUserHidden ?? defaultConfig.isUserHidden,
      renderHead: () => ts('page.users.index.users'),
      renderSkeleton: () => (
        <Grid container={true} alignItems="center">
          <Skeleton variant="circle" width={24} height={24} />
          <Skeleton
            variant="rectangular"
            width="20%"
            style={{ marginLeft: '16px' }}
          />
        </Grid>
      ),
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => <UserNameCell person={person} />
    },
    {
      key: 'email',
      hidden: columnsConfig.isEmailHidden ?? defaultConfig.isEmailHidden,
      renderHead: () => ts('page.users.index.email'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => person.email
    },
    {
      key: 'status',
      orderBy: {
        asc: PersonOrder.StatusAsc,
        desc: PersonOrder.StatusDesc
      },
      hidden: columnsConfig.isStatusHidden ?? defaultConfig.isStatusHidden,
      renderHead: () => ts('page.users.index.allStatus'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="10%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => <UserStatusChip person={person} />
    },
    {
      key: 'account',
      hidden: columnsConfig.isAccountHidden ?? defaultConfig.isAccountHidden,
      renderHead: () => ts('common.terminology.account'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => person.account?.name
    },
    {
      key: 'creationDate',
      hidden:
        columnsConfig.isCreationDateHidden ??
        defaultConfig.isCreationDateHidden,
      renderHead: () => ts('page.users.index.creationDate'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => (
        <FormattedDate
          value={person.creationDate}
          year="numeric"
          month="2-digit"
          day="2-digit"
          hour="2-digit"
          minute="2-digit"
        />
      )
    },
    {
      key: 'activationDate',
      hidden:
        columnsConfig.isActivationDateHidden ??
        defaultConfig.isActivationDateHidden,
      renderHead: () => ts('page.users.index.activationDate'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) =>
        person.activationDate && (
          <FormattedDate
            value={person.activationDate}
            year="numeric"
            month="2-digit"
            day="2-digit"
            hour="2-digit"
            minute="2-digit"
          />
        )
    },
    {
      key: 'language',
      hidden: columnsConfig.isLanguageHidden ?? defaultConfig.isLanguageHidden,
      renderHead: () => ts('page.users.index.language'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => person.language?.label
    },
    {
      key: 'phoneNumber',
      hidden:
        columnsConfig.isPhoneNumberHidden ?? defaultConfig.isPhoneNumberHidden,
      renderHead: () => ts('page.users.index.phoneNumber'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => person.phoneNumber
    },
    {
      key: 'mobileNumber',
      hidden:
        columnsConfig.isMobileNumberHidden ??
        defaultConfig.isMobileNumberHidden,
      renderHead: () => ts('page.users.index.mobileNumber'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => person.mobileNumber
    },
    {
      key: 'titleCode',
      hidden:
        columnsConfig.isTitleCodeHidden ?? defaultConfig.isTitleCodeHidden,
      renderHead: () => ts('page.users.index.titleCode'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => person.titleCode
    }
  ];
};
