import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { ROW_PER_PAGE_OPTIONS } from 'Theme/config';
import Skeleton from 'Theme/components/Skeleton';
import PersonGroupServicesRow from './PersonGroupServicesRow';
import { TableCollectionPagination } from 'Theme/components/TableCollection/TableCollectionPagination';
import { calculateNewPage } from 'Tools/calculateNewPage';

interface Props {
  testId?: string;
  currentPage: number;
  rowsPerPage: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rpp: number) => void;
  onRefetch: () => void;
  loading: boolean;
  serviceSubscriptions: IServiceSubscription[];
  totalCount: number;
  group: IPersonGroup;
}

function PersonGroupServicesTable(props: Readonly<Props>) {
  const {
    loading,
    serviceSubscriptions,
    totalCount,
    currentPage,
    rowsPerPage,
    testId,
    onChangePage,
    onChangeRowsPerPage,
    onRefetch
  } = props;

  const handleChangePage = (newPage: number) => {
    onChangePage(newPage);
    onRefetch();
  };

  const handleChangeRowsPerPage = (rpp: number) => {
    onChangeRowsPerPage(rpp);
    onChangePage(calculateNewPage(currentPage, rowsPerPage, rpp));
    onRefetch();
  };

  return <>
    <Table data-testid={testId}>
      <TableBody>
        {loading
          ? Array.from({ length: rowsPerPage }).map((_, index) => (
              <TableRow key={index as any}>
                <TableCell>
                  <Skeleton variant="rectangular" width="100%" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rectangular" width="100%" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rectangular" width="100%" />
                </TableCell>
              </TableRow>
            ))
          : serviceSubscriptions.map(serviceSubscription => (
              <PersonGroupServicesRow
                group={props.group}
                key={[
                  serviceSubscription.service.groupCode,
                  serviceSubscription.account.code
                ]
                  .filter(Boolean)
                  .join('-')}
                serviceSubscription={serviceSubscription}
              />
            ))}
      </TableBody>
    </Table>
    <TableCollectionPagination
      variant='outlined'
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      page={currentPage}
      onChangePage={handleChangePage}
      totalCount={totalCount}
    />
  </>;
}

export default PersonGroupServicesTable;
