import { gql, MutationFunction } from '@apollo/client';
import { AccessRightCreationFragment } from '../../fragments/person/AccessRightCreation';

export const CreatePersonAccessRightsForSingleUser = gql`
  mutation CreatePersonAccessRightsForSingleUser(
    $personIdentifier: PersonIdentifierInput
    $accessRightData: [ServiceSubscriptionInput]
  ) {
    createPersonAccessRightsForSingleUser(
      personIdentifier: $personIdentifier
      accessRightData: $accessRightData
    ) {
      ...AccessRightCreation
    }
  }
  ${AccessRightCreationFragment}
`;

export type CreatePersonAccessRightsForSingleUserMutation = MutationFunction<
  CreatePersonAccessRightsForSingleUserData,
  CreatePersonAccessRightsForSingleUserVariables
>;
