import useTabsOrganizationProfile from 'Hooks/useTabsOrganizationProfile';
import useTranslate from 'Hooks/useTranslate';
import ProfilePageTemplate from 'Components/Shared/ProfileTemplate/ProfilePageTemplate';
import { generatePath, useParams } from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';

interface Props {
  organization: IOrganization;
}

function OrganizationProfile({ organization }: Readonly<Props>) {
  const ts = useTranslate();
  const tabs = useTabsOrganizationProfile({ organization });

  const { accountCode, organizationCode } = useParams<{
    accountCode: string;
    organizationCode: string;
  }>();

  const basePath = generatePath(PrivateRoutes.organizationProfile.path, {
    accountCode: accountCode,
    organizationCode: organizationCode
  });

  return (
    <ProfilePageTemplate
      title={ts('page.organizationProfile.pageTitle', {
        value: organization.name
      })}
      basePath={basePath}
      tabs={tabs}
    />
  );
}

export default OrganizationProfile;
