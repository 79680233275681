import useTranslate from 'Hooks/useTranslate';
import { CheckCircle as IconCheckCircle, RadioButtonUnchecked as IconRadioButtonUnchecked, } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

interface Props {
  serviceSubscription: IServiceSubscription;
}

function CellAdministratedValidation(props: Readonly<Props>) {
  const ts = useTranslate();
  const { serviceSubscription } = props;

  if(serviceSubscription.administratedValidation){
    return(<Tooltip
        title={ts('page.userProfile.services.administratedValidation')}
      >
        <IconCheckCircle color="disabled" />
      </Tooltip>)
  } 
  return(
    <Tooltip
      title={ts('page.userProfile.services.notAdministratedValidation')}
    >
      <IconRadioButtonUnchecked color="disabled" />
    </Tooltip>
  )
}

export default CellAdministratedValidation;
