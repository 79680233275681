import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  expirationDate: Date;
  onExpired: () => void;
}

interface State {
  remainingTime: number;
}

const TIMER_DELAY_UPDATE = 60 * 1000;

class ExpirationDateTimer extends React.Component<Props, State> {
  public getRemainingTime(expirationDate: Date): number {
    const now = new Date();

    return expirationDate.getTime() - now.getTime();
  }

  private timeoutID: number | null = null;

  state = {
    remainingTime: this.getRemainingTime(this.props.expirationDate)
  };

  public componentDidMount() {
    this.updateRemainingTime();
  }

  public componentWillUnmount() {
    if (this.timeoutID == null) {
      return;
    }

    window.clearTimeout(this.timeoutID);
    this.timeoutID = null;
  }

  private updateRemainingTime = () => {
    const remainingTime = this.getRemainingTime(this.props.expirationDate);
    const remainingMinutes = new Date(remainingTime).getUTCMinutes();

    if (remainingMinutes <= 0) {
      this.timeoutID = null;
      this.props.onExpired();
      return;
    }

    this.timeoutID = window.setTimeout(() => {
      const { expirationDate } = this.props;
      const remainingTime = this.getRemainingTime(expirationDate);

      this.setState({ remainingTime }, this.updateRemainingTime);
    }, TIMER_DELAY_UPDATE);
  };

  public render() {
    const { remainingTime } = this.state;
    let hours: any = new Date(remainingTime).getUTCHours();
    let minutes: any = new Date(remainingTime).getUTCMinutes();

    if (hours < 10) {
      hours = `0${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    return (
      <FormattedMessage
        id="banner.pendingRequest"
        values={{
          hours,
          minutes
        }}
      />
    );
  }
}

export default ExpirationDateTimer;
