import { TableCell, TableRow, Typography, Box } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import IconButtonWithPopOver from 'Theme/components/IconButtonWithPopOver';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TestIds from 'Tests/TestIds';
import accountLabel from 'Tools/LabelFormatter/accountLabel';
import RemoveServiceButton from './RemoveServiceButton';

const styles = createStyles({
  label: {
    width: '50%'
  },
  family: {
    width: '40%'
  },
  description: {
    width: '10%'
  }
});

interface Props extends WithStyles<typeof styles> {
  serviceSubscription: IServiceSubscription;
  group: IPersonGroup;
}

function PersonGroupServicesRow(props: Readonly<Props>) {
  const { serviceSubscription, classes, group } = props;

  return (
    <TableRow>
      <TableCell classes={{ root: props.classes.label }}>
        <Typography variant="subtitle1">
          {serviceSubscription.service?.name ?? ''}
        </Typography>

        {serviceSubscription.account && (
          <Typography variant="body2">
            {accountLabel(serviceSubscription.account)}
          </Typography>
        )}
      </TableCell>
      <TableCell classes={{ root: classes.family }}>
        <Typography variant="body2">
          {serviceSubscription.service?.family ?? ''}
        </Typography>
      </TableCell>
      <TableCell classes={{ root: classes.description }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {serviceSubscription.service?.description && (
            <IconButtonWithPopOver
              description={serviceSubscription.service.description}
              customIcon={<InfoOutlinedIcon />}
              id={
                TestIds.pages.groupProfile.services.serviceDescriptionIcon +
                serviceSubscription.service.groupCode
              }
            />
          )}
          <RemoveServiceButton
            serviceSubscription={serviceSubscription}
            group={group}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default withStyles(styles)(PersonGroupServicesRow);
