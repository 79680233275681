import { Typography } from "@mui/material";
import { useStyles } from "../Directories.styles";

interface Props {
    title: string;
    content: string;
    id?: string;
}

function DirectoryExtraInfo(props: Props) {

    const { title, content, id } = props;
    const classes = useStyles();

    return (
        <div data-testid={id}>
            <Typography variant="caption" className={classes.overline}>
                {title}
            </Typography>
            <Typography variant="caption" color="textSecondary" >
                {content}
            </Typography>
        </div>
    );
}

export default DirectoryExtraInfo;