import { IconButton, Tooltip } from '@mui/material';
import { Create as IconCreate } from '@mui/icons-material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReadonlyProperty } from '../../../../../../Apollo';
import TestIds from 'Tests/TestIds';
import EditNameDialog from './EditNameDialog';

interface Props {
  person: IPersonWithAccount;

  // List of fields to disable.
  // e.g [ReadonlyProperty.LastName] will disable lastname field in dialog
  readonlyProperties: ReadonlyProperty[];
}

interface State {
  isDialogOpen: boolean;
}

class EditNameToggle extends React.Component<Props, State> {
  public readonly state: State = {
    isDialogOpen: false
  };

  public render() {
    const { person, readonlyProperties } = this.props;
    const { isDialogOpen } = this.state;

    return <>
      <Tooltip
        title={<FormattedMessage id="common.action.edit" />}
        placement="bottom"
      >
        <IconButton
          data-testid={TestIds.pages.profile.editNameButton}
          onClick={this.onOpenDialog}
          size="large">
          <IconCreate />
        </IconButton>
      </Tooltip>
      {isDialogOpen && (
        <EditNameDialog
          person={person}
          readonlyProperties={readonlyProperties}
          onClose={this.onCloseDialog}
        />
      )}
    </>;
  }

  private onOpenDialog = () => {
    this.setState({
      isDialogOpen: true
    });
  };

  private onCloseDialog = () => {
    this.setState({
      isDialogOpen: false
    });
  };
}

export default EditNameToggle;
