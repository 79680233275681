import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import React from 'react';
import { MutationResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps as InjectedIntlProps
} from 'react-intl';
import {
  CreateEmailChangeRequest,
  CreateEmailChangeRequestMutation
} from '../../../../../../Apollo';
import isValidEmail from 'Tools/isValidEmail';
import ButtonLoading from 'Theme/components/ButtonLoading';
import { ErrorHandler } from 'Theme/components/ErrorHandler';

interface Props {
  person: IPersonWithAccount;
  onClose: () => void;
  onCompleted: () => void;
}

interface State {
  email: string;
  error: Error | null;
}

class EditEmailDialog extends React.Component<
  Props & InjectedIntlProps,
  State
> {
  public readonly state: State = {
    email: this.props.person.email || '',
    error: null
  };

  public render() {
    const { person, onCompleted, onClose } = this.props;
    const { email, error } = this.state;

    return (
      <ErrorHandler.Consumer>
        {errorHandler => (
          <Mutation<Object, CreateEmailChangeRequestVariables>
            mutation={CreateEmailChangeRequest}
            variables={{
              personIdentifier: person.immutableId,
              newEmail: email
            }}
            onCompleted={() => onCompleted()}
            onError={e => errorHandler.onError(e)}
          >
            {(
              createEmailChangeRequest: CreateEmailChangeRequestMutation,
              { loading, data }: MutationResult
            ) => (
              <Dialog
                open={true}
                onClose={onClose}
                maxWidth="xs"
                fullWidth={true}
              >
                {data == null ? (
                  <form
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                      event.preventDefault();

                      const { intl } = this.props;

                      if (loading) {
                        return;
                      }

                      if (!isValidEmail(email)) {
                        this.setState({
                          error: new Error(
                            intl.formatMessage({
                              id: 'error.form.invalidEmail'
                            })
                          )
                        });
                        return;
                      }

                      createEmailChangeRequest();
                    }}
                  >
                    <DialogTitle>
                      <FormattedMessage id="page.userProfile.editEmail.editEmail" />
                    </DialogTitle>
                    <DialogContent>
                      <TextField
                        name="email"
                        variant="outlined"
                        label={
                          <FormattedMessage
                            id={'page.userProfile.editEmail.email'}
                          />
                        }
                        onChange={event =>
                          this.setState({
                            email: event.target.value,
                            error: null
                          })
                        }
                        value={email}
                        fullWidth
                        autoFocus={true}
                        required
                        error={Boolean(error)}
                        helperText={error && error.message}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={onClose} color='inherit'>
                        <FormattedMessage id="common.action.cancel" />
                      </Button>
                      <ButtonLoading
                        type="submit"
                        color="primary"
                        loading={loading}
                      >
                        <FormattedMessage id="common.action.validate" />
                      </ButtonLoading>
                    </DialogActions>
                  </form>
                ) : (
                  <>
                    <DialogTitle>
                      <FormattedMessage id="page.userProfile.editEmail.confirmation.title" />
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <FormattedMessage
                          id="page.userProfile.editEmail.confirmation.message"
                          values={{ email }}
                        />
                      </DialogContentText>
                      <DialogContentText>
                        <FormattedMessage id="page.userProfile.editEmail.confirmation.hint" />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={onClose}>
                        <FormattedMessage id="common.action.close" />
                      </Button>
                    </DialogActions>
                  </>
                )}
              </Dialog>
            )}
          </Mutation>
        )}
      </ErrorHandler.Consumer>
    );
  }
}

export default injectIntl(EditEmailDialog);
