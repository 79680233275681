import { Typography, Grid, Divider } from '@mui/material';
import { useQuery } from '@apollo/client';
import { DirectoryAvatar } from 'Theme/components/Avatar';
import Skeleton from 'Theme/components/Skeleton';
import { DataRow } from 'Theme/components/DataRow';
import TestIds from 'Tests/TestIds';
import { useStyles } from '../Directories.styles';
import DirectoryExtraInfo from '../DirectoryExtraInfo';
import { GetDirectoriesExtendedInfo } from 'Apollo';
import { Imgs } from 'Assets';
import useTranslate from 'Hooks/useTranslate';
import MergeableDirectory from './MergeableDirectory/MergeableDirectory';
import useNotification from 'Hooks/useNotification';

interface Props {
  person: IPersonWithAccount;
  isCurrentPerson: boolean;
  hasSuperRights: boolean;
}

function PersonDirectories(props: Readonly<Props>) {
  const ts = useTranslate();
  const classes = useStyles();
  const { person, hasSuperRights, isCurrentPerson } = props;
  const { onError } = useNotification();

  let { data, loading } = useQuery<
    GetDirectoriesExtendedInfoData,
    GetDirectoriesExtendedInfoVars
  >(GetDirectoriesExtendedInfo, {
    variables: {
      personImmutableId: person.immutableId
    },
    onError
  });

  if (loading || data == null || data.directoriesAuthenticatingPerson == null)
    return (
      <DataRow
        label={
          <Skeleton
            component="span"
            variant="rectangular"
            width="64px"
            height="64px"
          />
        }
        divider={true}
      >
        <Skeleton variant="rectangular" width="200px" />
      </DataRow>
    );

  const directoriesAuth = data.directoriesAuthenticatingPerson;

  /* shouldn't already have a federated identity */
  const hasAFederatedDirectory = directoriesAuth.some(
    (d: IDirectoryAuthenticatingPersonType) => d.isFederated
  );

  const isFederatedWithGoogle = directoriesAuth.some(
    (d: IDirectoryAuthenticatingPersonType) => d.label === 'Google'
  );

  const isFederatedWithMicrosoft = directoriesAuth.some(
    (d: IDirectoryAuthenticatingPersonType) => d.label === 'Microsoft'
  );

  const isFederatedWithApple = directoriesAuth.some(
    (d: IDirectoryAuthenticatingPersonType) => d.label === 'Apple'
  );

  /* -- To be able to merge a local account with a MS account the current user -- */
  /* shouldn't have an email address that could be managed by a federabled directory */
  /* example even if toto@federatedDirectory.com have no identity, his directory different from MS is federated */
  const userEmailManagedByFederatedDirectoryNotMS =
    hasAFederatedDirectory && !isFederatedWithMicrosoft;
  const userEmailManagedByFederatedDirectoryNotGoogle =
    hasAFederatedDirectory && !isFederatedWithGoogle;
  const userEmailManagedByFederatedDirectoryNotApple =
    hasAFederatedDirectory && !isFederatedWithApple;

  const canMergeLocalAccountToMS =
    isCurrentPerson &&
    !hasAFederatedDirectory &&
    !userEmailManagedByFederatedDirectoryNotMS;
  const canMergeLocalAccountToGoogle =
    isCurrentPerson &&
    !hasAFederatedDirectory &&
    !userEmailManagedByFederatedDirectoryNotGoogle;
  const canMergeLocalAccountToApple =
    isCurrentPerson &&
    !hasAFederatedDirectory &&
    !userEmailManagedByFederatedDirectoryNotApple;

  return (
    <Grid
      container
      direction="column"
      data-testid={TestIds.pages.profile.directories.directoriesList}
    >
      {directoriesAuth.map((directory, index) => {
        
        const cegidPasseportLabel = 'Cegid Passeport';

        const isCegidId = directory.label === 'CegidID';
        const isCegidPasseport = directory.label === cegidPasseportLabel;
        const isApple = directory.label === 'Apple';
        const isGoogle = directory.label === 'Google';
        const isMicrosoft = directory.label === 'Microsoft';

        return (
          <Grid
            key={directory.identityIdentifier}
            item
            container
            direction="row"
            alignItems="center"
            data-testid={
              TestIds.pages.profile.directories.directoriesListItem + index
            }
          >
            <Grid item>
              {isMicrosoft && (
                <img
                  src={Imgs.microsoft.src}
                  alt={Imgs.microsoft.alt}
                  className={classes.logo}
                  data-testid={TestIds.pages.profile.mergeAccount.MSlogo}
                />
              )}
              {(isCegidPasseport || isCegidId) && (
                <img
                  src={Imgs.cegidCorners.src}
                  alt={Imgs.cegidCorners.alt}
                  className={classes.logo}
                  data-testid={TestIds.pages.profile.mergeAccount.CegidLogo}
                />
              )}
              {isGoogle && (
                <img
                  src={Imgs.google.src}
                  alt={Imgs.google.alt}
                  className={classes.logo}
                  data-testid={TestIds.pages.profile.mergeAccount.GoogleLogo}
                />
              )}
              {isApple && (
                <img
                  src={Imgs.apple.src}
                  alt={Imgs.apple.alt}
                  className={classes.logo}
                  data-testid={TestIds.pages.profile.mergeAccount.AppleLogo}
                />
              )}
              {!isMicrosoft &&
                !isCegidPasseport &&
                !isCegidId &&
                !isGoogle &&
                !isApple && (
                  <DirectoryAvatar directory={directory} size="larger" />
                )}
            </Grid>

            <Grid item>
              <Typography variant="body2">
                {directory.label !== null && (isCegidId ? cegidPasseportLabel : directory.label)}
              </Typography>
            </Grid>

            {hasSuperRights && (
              <Grid
                item
                xs={12}
                sm={9}
                className={classes.directoryExtraInfoBloc}
                data-testid={
                  TestIds.pages.userProfile.directories.directoryExtraInfoBloc +
                  '-' +
                  directory.identityIdentifier
                }
              >
                {!!directory.identifier && (
                  <DirectoryExtraInfo
                    key={directory.label + 'directory.identifier'}
                    title={ts('page.userProfile.section.technical.identifier')}
                    content={directory.identifier}
                    id={
                      TestIds.pages.userProfile.directories.identifier +
                      '-' +
                      index
                    }
                  />
                )}
                {!!directory.identityIdentifier && (
                  <DirectoryExtraInfo
                    key={directory.label + 'directory.identityIdentifier'}
                    title={ts(
                      'page.userProfile.section.technical.identityIdentifier'
                    )}
                    content={directory.identityIdentifier}
                    id={
                      TestIds.pages.userProfile.directories.identityIdentifier +
                      '-' +
                      index
                    }
                  />
                )}
                {!!directory.customAttribute &&
                  !!directory.customAttribute.tenantId && (
                    <DirectoryExtraInfo
                      key={
                        directory.label + 'directory.customAttribute.tenantId'
                      }
                      id={
                        TestIds.pages.userProfile.directories.tenantid +
                        '-' +
                        index
                      }
                      title={ts('page.userProfile.section.technical.tenantId')}
                      content={directory.customAttribute.tenantId}
                    />
                  )}
                {!!directory.customAttribute &&
                  !!directory.customAttribute.objectId && (
                    <DirectoryExtraInfo
                      key={
                        directory.label + 'directory.customAttribute.objectId'
                      }
                      id={
                        TestIds.pages.userProfile.directories.objectId +
                        '-' +
                        index
                      }
                      title={ts('page.userProfile.section.technical.objectId')}
                      content={directory.customAttribute.objectId}
                    />
                  )}
                {!!directory.customAttribute &&
                  !!directory.customAttribute.upn && (
                    <DirectoryExtraInfo
                      key={directory.label + 'directory.customAttribute.upn'}
                      id={
                        TestIds.pages.userProfile.directories.upn + '-' + index
                      }
                      title={ts('page.userProfile.section.technical.upn')}
                      content={directory.customAttribute.upn}
                    />
                  )}
              </Grid>
            )}
            <Divider variant="fullWidth" />
          </Grid>
        );
      })}
      {canMergeLocalAccountToMS && (
        <MergeableDirectory dirKey="microsoft" person={person} />
      )}
      {canMergeLocalAccountToGoogle && (
        <MergeableDirectory dirKey="google" person={person} />
      )}
      {canMergeLocalAccountToApple && (
        <MergeableDirectory dirKey="apple" person={person} />
      )}
    </Grid>
  );
}

export default PersonDirectories;
