import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client';
import { Helmet } from 'react-helmet';

import {
  GetAccountsWithNotificationSettings,
  GetAccountWithNotificationSettings
} from '../../Apollo';
import { ErrorHandler } from 'Theme/components/ErrorHandler';
import NotificationsForm from './NotificationsForm';
import NotificationsFormSkeleton from './NotificationsFormSkeleton';
import useTranslate from 'Hooks/useTranslate';
import { useParams } from 'react-router-dom';

interface Props {}

function Notifications(props: Props) {
  const { accountCode } = useParams<{ accountCode: string }>();
  const ts = useTranslate();

  return (
    <>
      <Helmet title={ts('page.notifications.title')} />
      <ErrorHandler.Consumer>
        {errorHandler => {
          if (accountCode === 'all') {
            return (
              <Query
                query={GetAccountsWithNotificationSettings}
                onError={errorHandler.onError}
              >
                {({
                  data,
                  loading
                }: QueryResult<GetAccountsWithNotificationSettingsData>) => {
                  if (loading || data == null || data.accounts == null) {
                    return <NotificationsFormSkeleton />;
                  }
                  return (
                    <NotificationsForm
                      accounts={data.accounts}
                      refetchQueries={[
                        { query: GetAccountsWithNotificationSettings }
                      ]}
                    />
                  );
                }}
              </Query>
            );
          }

          return (
            <Query
              query={GetAccountWithNotificationSettings}
              variables={{
                accountCode: accountCode
              }}
              onError={errorHandler.onError}
            >
              {({
                data,
                loading
              }: QueryResult<GetAccountWithNotificationSettingsData>) => {
                if (loading || data == null || data.account == null) {
                  return <NotificationsFormSkeleton />;
                }

                const accountsData = {
                  edges: [{ node: data.account }],
                  pageInfo: {
                    hasNextPage: false
                  },
                  totalCount: 1
                };

                return (
                  <NotificationsForm
                    accounts={accountsData}
                    refetchQueries={[
                      {
                        query: GetAccountWithNotificationSettings,
                        variables: {
                          accountCode: accountCode
                        }
                      }
                    ]}
                  />
                );
              }}
            </Query>
          );
        }}
      </ErrorHandler.Consumer>
    </>
  );
}

export default Notifications;
