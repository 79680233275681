import { gql } from '@apollo/client';
import { AccountFragment } from '../account/Account';
import { ServiceFragment } from './Service';

export enum ServiceSubscriptionOrder {
  AccountLabelAscThenNameAsc = 'ACCOUNTLABELASCTHENNAMEASC',
  AccountLabelDescThenNameAsc = 'ACCOUNTLABELDESCTHENNAMEASC',
  FamilyAscThenNameAsc = 'FAMILYASCTHENNAMEASC',
  FamilyDescThenNameAsc = 'FAMILYDESCTHENNAMEASC',
  NameDesc = 'NAMEDESC',
  NameAsc = 'NAMEASC'
}

export const ServiceSubscriptionFragment = gql`
  fragment ServiceSubscription on ServiceSubscription {
    service {
      ...Service
    }
    account {
      ...Account
    }
    targetAccount {
      ...Account
    }
    administratedValidation
    accessByDefault
    isAvailable
    maxSubscriptions
  }
  ${ServiceFragment}
  ${AccountFragment}
`;
