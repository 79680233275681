import { Button, Grid } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import { Form, SubmitButton, TextInput } from 'Components/Shared';
import useFormValidation from '../../Hooks/useFormValidation';
import { TExternalOwnerValuesForm } from './FormExternalOwner.container';

type Props = {
  onCancel(): void;
  onSubmit(values: TExternalOwnerValuesForm): void;
  loading: boolean;
};

const FormExternalOwner = ({ onCancel, onSubmit, loading }: Props) => {
  const ts = useTranslate();
  const { externalOwnerValidation } = useFormValidation();
  const initValues: TExternalOwnerValuesForm = { email: '' };

  return (
    <Form<TExternalOwnerValuesForm>
      initialValues={initValues}
      onSubmit={onSubmit}
      validationSchema={externalOwnerValidation}
    >
      <TextInput
        name="email"
        size="medium"
        label={ts('page.services.createSubscription.stepEmail.email')}
        autoFocus
      />
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button onClick={onCancel} color='inherit'>{ts('common.action.cancel')}</Button>
        </Grid>
        <Grid item>
          <SubmitButton
            title={ts('page.servicesMembers.invite')}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default FormExternalOwner;
