import { useParams, generatePath } from 'react-router-dom';
import useTabsService from '../../Hooks/useTabsService';
import useTranslate from 'Hooks/useTranslate';
import ProfilePageTemplate from 'Components/Shared/ProfileTemplate/ProfilePageTemplate';
import { PrivateRoutes } from 'Router/Routes';

interface Props {
  service: IServiceSubscription;
}

const ServiceProfile = ({ service }: Props) => {
  const tabs = useTabsService({ service });
  const ts = useTranslate();

  const { accountCode, serviceCode } = useParams<{
    accountCode: string;
    serviceCode: string;
  }>();

  const basePath = generatePath(PrivateRoutes.serviceProfile.path, {
    accountCode: accountCode,
    serviceCode: serviceCode
  });

  return (
    <ProfilePageTemplate title={ts('page.serviceProfile.title', {
      service: service.service.name
    })} basePath={basePath} tabs={tabs} />
  );
};

export default ServiceProfile;
