import { useContext, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Theme,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import TestIds from 'Tests/TestIds';
import { useStyles } from './GenerateApiKey.styles';
import useTranslate from 'Hooks/useTranslate';
import GenerateApiKeyDialogContainer from './GenerateApiKeyDialog.container';
import { LocalContext } from 'Context/Local.context';
import { useCurrentUserInfo } from 'Hooks/useCurrentUserInfo';

interface Props {
  accountCode: string | null;
}

function GenerateApiKeyToggle(props: Readonly<Props>) {
  const { accountCode } = props;
  const {isAdminOfOnlyOneAccount} = useCurrentUserInfo();
  const { availableAccounts } = useContext(LocalContext);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  const classes = useStyles();
  const ts = useTranslate();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const onCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const requireToSelectAccount = !accountCode && !isAdminOfOnlyOneAccount;
  const canCreateApi =
    isDialogOpen && (accountCode !== null || isAdminOfOnlyOneAccount);

  return (
    <>
      {!isMobile && (
        <Tooltip
          title={
            requireToSelectAccount
              ? ts('page.apiKeys.requireAccountSelectionTooltip')
              : ts('page.apiKeys.addApikeyTooltip')
          }
        >
          <Box display="block">
            <Button
              variant="contained"
              disabled={requireToSelectAccount}
              color="primary"
              size="small"
              onClick={onOpenDialog}
              data-testid={TestIds.pages.apiKeys.createNewApiButton}
            >
              <VpnKeyIcon className={classes.icons} />
              {ts('page.apiKeys.generate.apiKey')}
            </Button>
          </Box>
        </Tooltip>
      )}
      {isMobile && (
        <IconButton
          onClick={onOpenDialog}
          disabled={requireToSelectAccount}
          data-testid={TestIds.pages.apiKeys.createNewApiButton}
          size="large"
        >
          <VpnKeyIcon />
        </IconButton>
      )}

      {canCreateApi && (
        <GenerateApiKeyDialogContainer
          accountCode={accountCode ?? availableAccounts[0]?.code ?? ''}
          onClose={onCloseDialog}
          isOpen={isDialogOpen}
        />
      )}
    </>
  );
}

export default GenerateApiKeyToggle;
