import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import { useStyles } from './FormContainer.styles';

interface Props {
  inputs: React.ReactNode;
  actions: React.ReactNode;
  heading: React.ReactNode;
  subheading: React.ReactNode;
  illustration?: string;
}

function FormContainer(props: Props) {

  const classes = useStyles();
  const { inputs,  illustration, heading, subheading, actions } = props;

  return (
    <>
      <Grid container spacing={5} classes={{ root: classes.container }}>
        <Grid
          container
          item
          direction="column"
          sm={6}
          classes={{ root: classes.inputsContainer }}
        >
          {inputs}
        </Grid>
        <Grid
          container
          item
          sm={6}
          classes={{ root: classes.illustrationContainer }}
        >
          <div className={classes.wizard}>
            <img
              src={illustration}
              alt="illustration"
              className={classes.wizardImage}
            />
            <Typography
              component="div"
              variant="h5"
              classes={{ root: classes.wizardHeading }}
            >
              {heading}
            </Typography>
            <Typography component="div" variant="body2" color="textSecondary">
              {subheading}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid spacing={5} container>
        <Grid sm={6} justifyContent="flex-end" container item>
          {actions}
        </Grid>
        <Grid sm={6} container item />
      </Grid>
    </>
  );
}

export default FormContainer;
