import OrganizationDomains from './OrganizationDomains';
import { CircularProgress } from '@mui/material';
import { GetOrganizationDomains } from 'Apollo/queries/organization/GetOrganizationDomains';
import { useQuery } from '@apollo/client';
import useNotification from 'Hooks/useNotification';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { ROW_PER_PAGE_DEFAULT } from 'Theme/config';
import { calculateNewPage } from 'Tools/calculateNewPage';
import ApartmentIcon from '@mui/icons-material/Apartment';
import EmptyState from 'Components/Shared/EmptyState';
import useTranslate from 'Hooks/useTranslate';
import ErrorMsg from 'Components/Shared/ErrorMsg/ErrorMsg';

interface Props {
  organization: IOrganization;
}

function OrganizationDomainsContainer({ organization }: Readonly<Props>) {
  const { onError } = useNotification();
  const ts = useTranslate();
  const { organizationCode } = useParams<{ organizationCode: string }>();
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);
  const [currentPage, setCurrentPage] = useState<number>(0);

  let { data, loading, error } = useQuery<
    GetOrganizationDomainsData,
    GetOrganizationDomainsVars
  >(GetOrganizationDomains, {
    fetchPolicy: 'cache-and-network',
    variables: {
      code: organizationCode,
      page: {
        first: rowsPerPage,
        offset: currentPage * rowsPerPage
      }
    },
    onError
  });

  let rows: Array<IOrganizationDomain> = [];
  let totalCount = 0;

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const onChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
  };

  if (data?.organizationDomains) {
    rows = data.organizationDomains.edges.map(d => d.node);
    totalCount = data.organizationDomains.totalCount;
  }

  if (loading) {
    return (
      <EmptyState icon={<CircularProgress />} primary={' '} secondary={' '} />
    );
  }

  if (!loading && error) return <ErrorMsg />;

  if (!loading && data && data?.organizationDomains.totalCount === 0) {
    return (
      <EmptyState
        icon={<ApartmentIcon />}
        primary={ts('page.organizationDomains.emptyState')}
        secondary={ts('page.organizationDomains.startAddingDomain')}
      />
    );
  }

  return (
    <OrganizationDomains
      loading={loading}
      organization={organization}
      domains={rows}
      totalCount={totalCount}
      currentPage={currentPage}
      onChangePage={onChangePage}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );
}
export default OrganizationDomainsContainer;
