import { useState } from 'react';
import { useQuery } from '@apollo/client';
import useNotification from 'Hooks/useNotification';
import { GetAvailablePersonsForPersonGroup, PersonOrder } from 'Apollo';
import { ErrorMsg } from 'Components/Shared';
import {
  DEBOUNCE_DELAY,
  ROW_PER_PAGE_DEFAULT,
  ROW_PER_PAGE_OPTIONS
} from 'Theme/config';
import debounce from 'lodash.debounce';
import TableGroupCandidates from './TableGroupCandidates';
import { calculateNewPage } from 'Tools/calculateNewPage';

interface Props {
  selectedIds: string[] | null;
  onUpdate: (ids: string[] | null) => void;
  updateTotalCount: (v: number) => void;
  groupCode: string;
}

function TableGroupCandidatesContainer(props: Readonly<Props>) {
  const { onUpdate, selectedIds, groupCode, updateTotalCount } = props;
  const { onError } = useNotification();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(ROW_PER_PAGE_DEFAULT);
  const [orderBy, setOrderBy] = useState<PersonOrder>(PersonOrder.NameAsc);

  const onChangeOrderBy = (orderBy: PersonOrder) => {
    setOrderBy(orderBy);
  };

  const onChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(calculateNewPage(currentPage, rowsPerPage, newRowsPerPage));
  };

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const { data, loading, error, refetch } = useQuery<
    GetAvailablePersonsForPersonGroupData,
    GetAvailablePersonsForPersonGroupVars
  >(GetAvailablePersonsForPersonGroup, {
    onCompleted: data => {
      updateTotalCount(data.availablePersonsForPersonGroup.totalCount);
    },
    onError,
    variables: {
      personGroupCode: groupCode,
      page: {
        first: rowsPerPage,
        offset: currentPage * rowsPerPage
      },
      orderBy: orderBy
    }
  });

  const onUserSearch = async (value: string) => {
    const filterValue = value.trim();

    onUpdate([]);

    await refetch({ searchTerm: filterValue, page: { first: rowsPerPage, offset: 0 } });
  };

  const debouncedUserSearch = debounce(onUserSearch, DEBOUNCE_DELAY);

  const rows = data?.availablePersonsForPersonGroup?.edges?.map(o => o.node) ?? [];

  const totalCount = data?.availablePersonsForPersonGroup?.totalCount ?? 0;

  if (error) return <ErrorMsg />;

  return (
    <TableGroupCandidates
      loading={loading}
      rows={rows}
      onUpdate={onUpdate}
      onUpdateSearch={(search: string) => debouncedUserSearch(search)}
      totalCount={totalCount}
      selectedIds={selectedIds}
      onChangeOrderBy={onChangeOrderBy}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      orderBy={orderBy}
      currentPage={currentPage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
    />
  );
}

export default TableGroupCandidatesContainer;
