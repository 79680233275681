import { gql } from '@apollo/client';
import { PersonWithAccountFragment } from '../../fragments/person/PersonWithAccount';

export const GetPersonWithAccount = gql`
  query GetPersonWithAccount($identifier: PersonIdentifierInput!) {
    person(identifier: $identifier) {
      ...PersonWithAccount
    }
  }
  ${PersonWithAccountFragment}
`;
