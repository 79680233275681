import { useQuery } from '@apollo/client';
import { Card, CardContent, CircularProgress } from '@mui/material';
import { GetAccountsWithServicesSubscriptionsCount } from '../../../Apollo';
import AccountsChart from './AccountsChart';
import { useStyles } from './ServiceAccountDistribution.style';

interface Props {}

function ServicesAccountDistribution(props: Props) {
  const classes = useStyles();
  const { data } = useQuery<{
    accountsWithServicesSubscriptionsCount: IPaginatedList<
      IAccountWithSubscriptionsCount
    >;
  }>(GetAccountsWithServicesSubscriptionsCount);

  if (data === undefined) {
    return (
      <Card classes={{ root: classes.card }}>
        <CardContent classes={{ root: classes.cardContent }}>
          <CircularProgress />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card classes={{ root: classes.card }}>
      <CardContent classes={{ root: classes.cardContent }}>
        <AccountsChart data={data} />
      </CardContent>
    </Card>
  );
}

export default ServicesAccountDistribution;
