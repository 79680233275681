import { Location } from 'history';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

interface PreviousLocationContext {
  previousLocation: Location | null;
}

export const locationHistoryContext = React.createContext<
  PreviousLocationContext
>({
  previousLocation: null
});

const { Provider, Consumer } = locationHistoryContext;

type Props = {
  children: React.ReactNode;
};

const PreviousLocationProvider = ({ children }: Props) => {
  const location = useLocation();
  const [
    previousLocation,
    setPreviousLocation
  ] = React.useState<Location | null>(null);
  const [currentLocation, setCurrentLocation] = React.useState<Location | null>(
    null
  );

  React.useEffect(() => {
    if (currentLocation && currentLocation.key === location.key) {
      return;
    }

    setPreviousLocation(currentLocation);
    setCurrentLocation(location);
  }, [currentLocation, location]);

  return <Provider value={{ previousLocation }}>{children}</Provider>;
};

const previousLocation = {
  Provider: PreviousLocationProvider,
  Consumer
};

export default previousLocation;
