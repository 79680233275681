import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import FormContainer from 'Theme/components/FormContainer/FormContainer';
import { CreatePersonGroup } from 'Apollo';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import { NewGroup } from 'Page/PersonGroupCreate/types';
import GroupSrc from 'Theme/assets/images/group.svg';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';
import testIds from 'Tests/TestIds';
import ButtonLoading from 'Theme/components/ButtonLoading';
import Skeleton from 'Theme/components/Skeleton';
import Form from 'Page/PersonGroupCreate/Form';
import { useContext, useState } from 'react';
import { PersonContext } from 'Context/CurrentPerson';

type Props = {
  data: GetAccountsData | undefined;
};

const FormCreateGroup = ({ data }: Props) => {
  const { accountCode } = useParams<{ accountCode: string }>();
  const { currentPerson } = useContext(PersonContext);
  const calculatedAccountCode =
    accountCode === 'all'
      ? currentPerson?.account
        ? currentPerson.account.code
        : ''
      : accountCode;

  const { onError, onSuccess } = useNotification();
  const [newGroup, setNewGroup] = useState<NewGroup>({
    name: '',
    description: '',
    accountCode: calculatedAccountCode
  });
  const ts = useTranslate();
  const history = useHistory();
  const onCompleted = () => {
    history.push(
      generatePath(PrivateRoutes.personGroups.path, {
        accountCode
      })
    );
    onSuccess('page.createPersonGroup.success', { groupName: newGroup.name });
  };

  const variables = {
    accountCode: newGroup.accountCode,
    name: newGroup.name,
    description: newGroup.description
  };

  const [
    onCreatePersonGroup,
    { loading: isLoadingCreatePersonGroup }
  ] = useMutation(CreatePersonGroup, {
    onCompleted,
    onError,
    variables,
    refetchQueries: ['GetPersonGroups']
  });

  const onChangeNewGroup = (newGroupChanges: Partial<NewGroup>) => {
    setNewGroup({ ...newGroup, ...newGroupChanges });
  };

  return (
    <form
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (isLoadingCreatePersonGroup) {
          return;
        }

        onCreatePersonGroup();
      }}
    >
      <FormContainer
        illustration={GroupSrc}
        heading={ts('page.createPersonGroup.heading')}
        subheading={ts('page.createPersonGroup.subheading')}
        inputs={
          data == null || data.accounts == null ? (
            <Skeleton width="100%" />
          ) : (
            <Form
              accounts={data.accounts}
              accountCode={accountCode}
              newGroup={newGroup}
              onChangeNewGroup={onChangeNewGroup}
            />
          )
        }
        actions={
          <>
            <Button
              onClick={() => history.goBack()}
              data-testid={testIds.pages.addGroup.cancel}
              style={{ marginRight: '8px' }}
              color='inherit'
            >
              {ts('common.action.cancel')}
            </Button>
            <ButtonLoading
              type="submit"
              color="primary"
              variant="contained"
              disabled={newGroup.name === '' || newGroup.accountCode === ''}
              loading={data == null || isLoadingCreatePersonGroup}
              data-testid={testIds.pages.addGroup.addButton}
            >
              {ts('common.action.add')}
            </ButtonLoading>
          </>
        }
      />
    </form>
  );
};

export default FormCreateGroup;
