import { FormattedMessage } from 'react-intl';
import {
  Drawer,
  Accordion,
  AccordionSummary,
  IconButton,
  Popper,
  Tooltip,
  Typography,
  Paper,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import { PersonContext } from 'Context/CurrentPerson';
import { SvgIcon } from 'Theme/components/SvgIcon/SvgIcon';
import { mdiAccountArrowRight, mdiPencil } from '@mdi/js';
import TestIds from 'Tests/TestIds';
import UpdatePropertiesContainer from './UpdateProperties/UpdateProperties.container';
import Container from 'Theme/components/Container';
import { Helmet } from 'react-helmet';
import EmailFormContainer from 'Components/EmailForm/EmailForm.container';
import { createRef, useContext, useState } from 'react';
import useTranslate from 'Hooks/useTranslate';

const useStyles = makeStyles(theme => ({
  drawer: {
    borderRadius: '20px 20px 0 0',
    maxHeight: '90%'
  },
  expansionPanel: {
    backgroundColor: 'transparent'
  },
  expansionPanelSummary: {
    cursor: 'auto',
    // Required for the text ellipsis
    overflow: 'hidden'
  },
  header: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    // Required for the text ellipsis
    overflow: 'hidden'
  },
  headerLeft: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  headerLeftIcon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle'
  },
  headerRight: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(3)}`
  },

  // Taken from https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/popper/ScrollPlayground.js#L43-L101
  popper: {
    // This popper is used in a drawer so it needs to appear on top of it
    zIndex: theme.zIndex.modal + 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    },
    willChange:
      'auto !important' /* the default value 'transform' blurs the text */
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid'
    }
  },
  paper: {
    padding: theme.spacing(3),
    width: '500px'
  },
  subscribeTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 500
  }
}));

interface Props {
  selectedIds: string[];
  rowIndex: Record<string, IServiceSubscription>;
  selectedCount: number;
  disabled: boolean;
}
type DisplayAction = 'addSubscriptions' | 'updateProperties';

const ActionOnSelectedSubscriptions = (props: Props) => {
  const { selectedIds, rowIndex, selectedCount: count = 0, disabled } = props;
  const classes = useStyles();
  const ts = useTranslate();
  const [displayAction, setDisplayAction] = useState<DisplayAction | null>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [popperOpen, setPopperOpen] = useState<boolean>(false);
  const [popperOpen2, setPopperOpen2] = useState<boolean>(false);
  const [arrowRef, setArrowRef] = useState<null | HTMLElement>(null);
  const [arrowRef2, setArrowRef2] = useState<null | HTMLElement>(null);
  const buttonRef = createRef<HTMLButtonElement>();
  const buttonRef2 = createRef<HTMLButtonElement>();
  const {
    currentPersonRoles: { isAccountAdmin }
  } = useContext(PersonContext);

  const isExpanded = false;
  const onCollapse = () => {
    setDisplayAction(null);
    setPopperOpen(false);
    setPopperOpen2(false);
    setAnchorEl2(null);
    setAnchorEl(null);
  };

  return <>
    {count > 0 && <div style={{ height: '72px' }} />}
    <Drawer
      anchor="bottom"
      variant={isExpanded ? 'temporary' : 'persistent'}
      open={count > 0}
      classes={{ paper: classes.drawer }}
    >
      <Accordion
        expanded={isExpanded}
        classes={{ root: classes.expansionPanel }}
      >
        <AccordionSummary
          classes={{ content: classes.expansionPanelSummary }}
          expandIcon={
            !isExpanded ? null : (
              <ExpandMoreIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (displayAction) {
                    setDisplayAction(null);
                  }
                }}
              />
            )
          }
          component="div"
        >
          <div className={classes.header}>
            <Typography
              variant="body2"
              color="textSecondary"
              classes={{ root: classes.headerLeft }}
            >
              <BubbleChartIcon
                color="primary"
                classes={{ root: classes.headerLeftIcon }}
              />
              <FormattedMessage
                id="page.services.index.selectedServiceCount"
                values={{ count }}
              />
            </Typography>
            <div className={classes.headerRight}>
              {isAccountAdmin && (
                <>
                  <Tooltip
                    title={
                      <FormattedMessage id="page.services.action.subscribeExternalUser" />
                    }
                  >
                    <IconButton
                      ref={buttonRef}
                      color={
                        displayAction === 'addSubscriptions'
                          ? 'primary'
                          : 'default'
                      }
                      onClick={() => {
                        setDisplayAction('addSubscriptions');
                        setPopperOpen(true);
                        setPopperOpen2(false);
                        setAnchorEl(buttonRef.current);
                      }}
                      disabled={disabled}
                      data-testid={
                        TestIds.pages.services.addExternalUserButton
                      }
                      size="large">
                      <SvgIcon
                        path={mdiAccountArrowRight}
                        fill="#666666"
                        width="24"
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={
                      <FormattedMessage id="page.services.action.updateServicesProperties" />
                    }
                  >
                    <IconButton
                      ref={buttonRef2}
                      color={
                        displayAction === 'updateProperties'
                          ? 'primary'
                          : 'default'
                      }
                      onClick={() => {
                        setDisplayAction('updateProperties');
                        setPopperOpen(false);
                        setPopperOpen2(!popperOpen2);
                        setAnchorEl2(buttonRef2.current);
                      }}
                      disabled={disabled}
                      data-testid={
                        TestIds.pages.services.updateProperties.featureButton
                      }
                      size="large">
                      <SvgIcon path={mdiPencil} fill="#666666" width="24" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </AccordionSummary>

        {anchorEl && displayAction === 'addSubscriptions' && (
          <Popper
            open={popperOpen}
            placement="top"
            anchorEl={anchorEl}
            modifiers={[
              {
                name: 'arrow',
                enabled: true,
                options: {
                  element: arrowRef
                }
              }
            ]}
            className={classes.popper}
          >
            <span
              className={classes.arrow}
              ref={element => {
                setArrowRef(element);
              }}
            />
            <Helmet title={ts('page.services.createSubscription.title')} />
            <Container>
              <Paper classes={{ root: classes.paper }}>
                <Typography variant="h5" className={classes.subscribeTitle}>
                  {ts('page.services.action.subscribeExternalUser')}
                </Typography>
                <EmailFormContainer
                  serviceSubscriptions={selectedIds.map(s => JSON.parse(s))}
                  onCancel={onCollapse}
                  onSubmit={onCollapse}
                />
              </Paper>
            </Container>
          </Popper>
        )}

        {anchorEl2 && displayAction === 'updateProperties' && (
          <Popper
            open={popperOpen2}
            placement="top"
            anchorEl={anchorEl2}
            modifiers={[
              {
                name: 'arrow',
                enabled: true,
                options: {
                  element: arrowRef2
                }
              }
            ]}
            className={classes.popper}
          >
            <span
              className={classes.arrow}
              ref={element => {
                setArrowRef2(element);
              }}
            />
            <UpdatePropertiesContainer
              selectedServiceIds={selectedIds}
              onCollapse={onCollapse}
              rowIndex={rowIndex}
            />
          </Popper>
        )}
      </Accordion>
    </Drawer>
  </>;
};

export default ActionOnSelectedSubscriptions;
