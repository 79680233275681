import { useQuery } from '@apollo/client';
import { Typography, Button } from '@mui/material';
import Skeleton from '@mui/lab/Skeleton/Skeleton';
import { GetPersonGroupsTotalCount } from 'Apollo/queries/personGroup/GetPersonGroups';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import { PrivateRoutes } from 'Router/Routes';
import DataRow from 'Theme/components/DataRow/DataRow';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  account: IAccount;
}

function SectionGroups(props: Readonly<Props>) {
  const { account } = props;
  const { onError } = useNotification();
  const ts = useTranslate();

  const { data, loading } = useQuery(GetPersonGroupsTotalCount, {
    variables: {
      accountCode: account.code
    },
    onError
  });

  return (
    <DataRow label={ts('page.accountProfile.sections.groups.title')} divider={true}>
      {loading && <Skeleton width={120} height={30} variant="rectangular" color='primary'/>}
      {!loading && (<Typography>{data.personGroups.totalCount}</Typography>)}
      <div>
        <Button
          component={RouterLink}
          to={generatePath(PrivateRoutes.personGroups.path, {
            accountCode: account.code
          })}
          variant="text"
          color="primary"
        >
          {ts('page.accountProfile.sections.groups.seeAllGroups')}
        </Button>
      </div>
    </DataRow>
  );
}

export default SectionGroups;
