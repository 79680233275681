import { gql } from '@apollo/client';
import { PersonAccessRightFragment } from '../../fragments/person/PersonAccessRight';

export const GetPersonAccessRights = gql`
  query GetPersonAccessRights(
    $personIdentifier: String
    $page: PageInput
    $status: [PersonAccessRightStatus]!
    $searchTerm: String
  ) {
    personAccessRights(
      personIdentifier: $personIdentifier
      page: $page
      status: $status
      searchTerm: $searchTerm
    ) {
      edges {
        node {
          ...PersonAccessRight
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${PersonAccessRightFragment}
`;
