import { Error } from '@mui/icons-material';
import { Alert, AlertTitle, Box } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import {
  CdsColorActionWarnDarkActive,
  CdsColorBackgroundWarnLight
} from '@cegid/design-tokens';

type Props = {};

const AlertDisabledAccount = (props: Props) => {
  const ts = useTranslate();
  return (
    <Box mb={3}>
      <Alert
        severity="warning"
        icon={<Error style={{ color: CdsColorActionWarnDarkActive }} />}
        style={{
          borderLeft: `6px solid ${CdsColorActionWarnDarkActive}`,
          backgroundColor: CdsColorBackgroundWarnLight
        }}
      >
        <AlertTitle>
          {ts('page.userProfile.accountDisabledAlert.title')}
        </AlertTitle>
        {ts('page.userProfile.accountDisabledAlert.message')}
      </Alert>
    </Box>
  );
};

export default AlertDisabledAccount;
