import Skeleton from 'Theme/components/Skeleton';
import { TableCollectionColumn } from 'Components/Shared/Table';
import useTranslate from './useTranslate';
import { DomainValidationMode } from 'Apollo/fragments/organization/DomainValidationMode';
import { IconButton, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyToClipboard } from 'Tools/copyToClipboard';
import { FormattedDate } from 'react-intl';
import DomainStatusChip from 'Components/Shared/Chips/DomainStatusChip/DomainStatusChip';

export const useColumnsDomains = (): TableCollectionColumn<
  IOrganizationDomain
>[] => {
  const ts = useTranslate();

  return [
    {
      key: 'name',
      width: 0.25,
      renderHead: () => ts('common.tableHead.name'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="30%" />,
      renderCell: ({ row }: { row: IOrganizationDomain }) => row.name
    },
    {
      key: 'active',
      width: 0.1,
      renderHead: () => ts('common.tableHead.active'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="30%" />,
      renderCell: ({ row }: { row: IOrganizationDomain }) => {
        if (row.validationMode === DomainValidationMode.Strong) {
          return (
            <Typography variant='body2'>{ts('common.terminology.yes')}</Typography>
          );
        }
        if (row.validationMode === DomainValidationMode.None) {
          return (
            <Typography variant='body2'>{ts('common.terminology.no')}</Typography>
          );
        }
        if (row.validationMode === DomainValidationMode.Weak) {
          return (
            <Typography variant='body2'>{ts('common.terminology.yes')}</Typography>
          );
        }
      }
    },
    {
      key: 'status',
      width: 0.25,
      renderHead: () => ts('common.tableHead.status'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="30%" />,
      renderCell: ({ row }: { row: IOrganizationDomain }) => 
        <DomainStatusChip
          domain={row}
        />
    },
    {
      key: 'code',
      width: 0.3,
      renderHead: () => ts('common.tableHead.validationCode'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="30%" />,
      renderCell: ({ row }: { row: IOrganizationDomain }) => {
        if (row.validationCode !== null) { 
          return (
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Typography variant='caption' component='div'>
                {row.validationCode}
              </Typography>
              <Tooltip title={row.validationCode}>
                <IconButton
                  onClick={() => copyToClipboard(row.validationCode as string)}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </div>
          )
        } else return '';
      }
    },
    {
      key: "expiration",
      width: 0.1,
      renderHead: () => ts('common.tableHead.expiration'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="30%" />,
      renderCell: ({ row }: { row: IOrganizationDomain }) => {
        if (row.validationCodeExpirationDate === null) {
          return '';
        }
        const limitDate = new Date(row.validationCodeExpirationDate);
        return (
          <Typography variant="caption" role="expiration-date">
            {<FormattedDate
              value={limitDate.toISOString()}
              year="numeric"
              month="2-digit"
              day="2-digit"
            />}
          </Typography>
        )
      }
    }
  ];
};
