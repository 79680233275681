import {
  AppBar,
  Box,
  Divider,
  Toolbar,
  Typography
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import NavigationButton from '../SidebarBurgerBar';
import HeaderProfileMenu from '../HeaderProfileMenu/HeaderProfileMenu';
import SwitchAccountButton from '../HeaderSwitchAccount/SwitchAccountButton';
import { Imgs } from '../../Assets';
import { BusinessCenter } from '@mui/icons-material';
import { useState } from 'react';
import { useStyles } from './Header.styles';
import useTranslate from 'Hooks/useTranslate';
import AccountsSelector from 'Components/Shared/AccountsSelector';
import RequestAdministrativeAccessBanner from 'Components/RequestAdministrativeAccessBanner/Banner/RequestAdministrativeAccessBanner';
import SuperUserBanner from './SuperUserBanner/SuperUserBanner';
import ConditionalHeader from './ConditionalHeader';
import { useCurrentUserInfo } from 'Hooks/useCurrentUserInfo';

enum AppBarElevation {
  Normal = 0,
  Raised = 3
}

function Header() {
  const classes = useStyles();
  const ts = useTranslate();
  const { 
    isSimpleUser, 
    hasTooManyAccounts, 
    hasAccessToAccounts,
    canAskForAccountAdministrationDelegation,
    isOrganizationOwner,
    canWriteAll,
    hasLimitedAccounts
  } = useCurrentUserInfo();


  const canSeeSideBar = !isSimpleUser || isOrganizationOwner;

  const [pageTitle, setPageTitle] = useState<string | null>(null);

  const onChangeClientState = ({ title }: { title: string | undefined }) => {
    const parts = title ? title.split('|').map(part => part.trim()) : [];
    const newPageTitle = parts[1] || null;
    if (pageTitle !== newPageTitle) {
      setPageTitle(newPageTitle);
    }
  };

  return (
    <div style={{position: 'fixed', width: '100%', zIndex: 1103 }}>
      <Helmet onChangeClientState={onChangeClientState} />
      <AppBar
        position="fixed"
        color="default"
        elevation={AppBarElevation.Normal}
        classes={{ root: classes.appBar }}
      >
        <Toolbar>
          {canSeeSideBar && <NavigationButton />}
          <Typography variant="h4" color="textSecondary" noWrap={true}>
            <Link className={classes.linkToHome} to={'/'}>
              <img
                src={Imgs.cegid.src}
                alt={Imgs.cegid.alt}
                className={classes.logo}
              />
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                {ts('applicationName')}
              </Box>
            </Link>
          </Typography>
          <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            {pageTitle && (
              <Typography
                variant="h6"
                color="textSecondary"
                noWrap={true}
                classes={{ root: classes.pageTitle }}
              >
                {pageTitle}
              </Typography>
            )}
          </Box>
          <div className={classes.grow} />
          <ConditionalHeader />
          <div className={classes.actionItem}>
            {/* ----- admin of less than 20 accounts -----*/}
            {hasLimitedAccounts && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    display: { xs: 'none', sm: 'block' },
                    paddingRight: '8px'
                  }}
                >
                  <BusinessCenter className={classes.colorSecondary} />
                </Box>
                <AccountsSelector />
              </div>
            )}
            {/* ----- admin of more than 20 accounts need context-----*/}
            {hasTooManyAccounts && <SwitchAccountButton />
            }
          </div>
          <div className={classes.actionItem}>
            <HeaderProfileMenu />
          </div>
        </Toolbar>
        {canAskForAccountAdministrationDelegation && !canWriteAll && (
          <RequestAdministrativeAccessBanner />
        )}
        {canWriteAll && <SuperUserBanner />}
        <Divider />
      </AppBar>
      <div className={classes.keepHeight} />
      {hasAccessToAccounts && <div className={classes.keepMoreHeight} />}
    </div>
  );
}

export default Header;
