import { gql, MutationFunction } from '@apollo/client';
import { ApiKeyFragment } from '../../fragments/apikey/ApiKey';

export const RevokeApiKey = gql`
  mutation RevokeApiKey($apiKeyUuid: String!) {
    revokeApiKey(apiKeyUuid: $apiKeyUuid) {
      ...ApiKey
    }
  }
  ${ApiKeyFragment}
`;

export type RevokeApiKeyMutation = MutationFunction<
  RevokeApiKeyData,
  RevokeApiKeyVariables
>;
