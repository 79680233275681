import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useParams
} from 'react-router-dom';
import { IconButton, Tooltip, Box } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import Container from 'Theme/components/Container';
import { Helmet } from 'react-helmet';
import { useStyles } from './ApiKeys.styles';
import TABS from './Tabs';
import { PrivateRoutes } from 'Router/Routes';
import joinURL from 'url-join';
import GenerateApiKeyToggle from './GenerateApiKey/GenerateApiKeyToggle';
import { useContext } from 'react';
import useTranslate from 'Hooks/useTranslate';
import { LocalContext } from 'Context/Local.context';
import SubHeader from 'Components/Shared/SubHeader/SubHeader';
import useTabsApiKeys from 'Hooks/useTabsApikeys';

const ApiKeys = () => {
  const { apiKeyStatus } = useParams<{
    apiKeyStatus: string;
  }>();
  const { currentAccount } = useContext(LocalContext);
  const tabs = useTabsApiKeys();
  const ts = useTranslate();
  const classes = useStyles();

  const selectedAccountCode = currentAccount?.code ?? null;

  const basePath = generatePath(PrivateRoutes.apiKeyServices.path, {
    accountCode: selectedAccountCode ?? 'all',
    apiKeyState: apiKeyStatus
  });

  return (
    <>
      <Helmet title={ts('page.apiKeys.title')} />
      <SubHeader
        tabs={tabs}
        actionsButton={
          <div className={classes.subheaderActionButtons}>
            <Tooltip title={ts('page.apiKeys.refreshTooltip')}>
              <Box display="block">
                <IconButton
                  className={classes.refreshButton}
                  onClick={(
                    event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>
                  ) => window.location.reload()}
                  size="large"
                >
                  <ReplayIcon className={classes.icons} color="action" />
                </IconButton>
              </Box>
            </Tooltip>
            <GenerateApiKeyToggle accountCode={selectedAccountCode} />
          </div>
        }
      />
      <Container>
        <Switch>
          {TABS.map(({ path, Tab, name }) => (
            <Route
              key={name}
              path={joinURL(PrivateRoutes.apiKeyServices.path, path)}
              exact={true}
            >
              <Tab accountCode={selectedAccountCode} />
            </Route>
          ))}
          <Route>
            <Redirect to={basePath} />
          </Route>
        </Switch>
      </Container>
    </>
  );
};

export default ApiKeys;
