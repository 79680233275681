import { gql } from '@apollo/client';
import { ApiKeyFragment } from '../../fragments/apikey/ApiKey';

export const GetApiKey = gql`
  query GetApiKey(
    $apiKeyId: String
  ) {
    apiKey(
        apiKeyId: $apiKeyId
    ) {
        ...ApiKey
    }
  }
  ${ApiKeyFragment}
`;
