import { TypePolicies } from '@apollo/client';

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      personAccessRightRequests: {
        keyArgs: ['accountCode', 'status', 'immutableId', 'infoPerson'],
        read(a) {
          return a;
        },
        merge(existing = { edges: [] }, incoming, w) {
          if (w.variables?.page.offset === 0) {
            return incoming;
          }

          return {
            ...incoming,
            edges: [...existing.edges, ...incoming.edges]
          };
        }
      },
      directoriesAuthenticatingPerson: {
        merge(_, incoming) {
          return incoming;
        }
      }
    }
  }
};
