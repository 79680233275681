import React, { useState } from 'react';
import {
  Button, Typography,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, TextField
} from '@mui/material';
import ButtonLoading from 'Theme/components/ButtonLoading';
import SuccessStep from './SuccessStep';
import TestIds from 'Tests/TestIds';
import { useStyles } from './GenerateApiKey.styles';
import useTranslate from 'Hooks/useTranslate';
import ServicesSelector from './ServicesSelector/ServicesSelector';

interface Props {
  accountCode: string;
  onClose: () => void;
  isOpen: boolean;
  activeStep: number;
  selectedServiceCode: string;
  onSelectService: (code: string) => void;
  onUpdateApiKeyName: (name: string) => void;
  newApiKeyName: string;
  newApiKeyUuid: string;
  newApiKeySecret: string;
  isCreating: boolean;
  errorDuringCreation: string | null;
  onSubmit: any;
}

function GenerateApiKeyDialog(props: Props) {
  
  const { activeStep, selectedServiceCode, newApiKeyName, errorDuringCreation, accountCode, isCreating, isOpen, newApiKeySecret, newApiKeyUuid, onClose, onSelectService, onUpdateApiKeyName, onSubmit } = props;
  const classes = useStyles();
  const ts = useTranslate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedServiceCodeError, setSelectedServiceCodeError] = useState<boolean>(false);
  const [newApiKeyNameError, setNewApiKeyNameError] = useState<boolean>(false);


  const newApiKeySteps = [
    { title: ts("page.apiKeys.generate.theApiKey") },
    { title: ts("page.apiKeys.success.title") }
  ];

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    if (errorDuringCreation) {
      setErrorMessage(errorDuringCreation);
    }

    if (selectedServiceCode === '') {
      setErrorMessage(ts('page.apiKeys.generate.mandatoryService'));
      setSelectedServiceCodeError(true)
      return;
    }

    if (newApiKeyName === '') {
      setErrorMessage(ts('page.apiKeys.generate.mandatoryName'));
      setNewApiKeyNameError(true)
      return;
    }

    onSubmit();
  
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <form
        onSubmit={handleSubmit}
      >
        <DialogTitle>
          {newApiKeySteps[activeStep].title}
        </DialogTitle>

        {activeStep === 0 ? (
          <>
            <DialogContent>
              <>
                <ServicesSelector
                  selectedServiceCode={selectedServiceCode}
                  accountCode={accountCode}
                  handleChangeSelectedService={(code: string) => {
                    setSelectedServiceCodeError(false);
                    setErrorMessage(null);
                    onSelectService(code);
                  }}
                  selectedServiceCodeError={selectedServiceCodeError}
                />
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                >
                  <TextField
                    id="outlined-basic"
                    label={ts('page.apiKeys.generate.enterNewApiKeyName')}
                    variant="outlined"
                    className={classes.apiKeyFieldName}
                    onChange={event => {
                      setErrorMessage(null);
                      setNewApiKeyNameError(false);
                      onUpdateApiKeyName(
                        event.target.value as string
                      )

                    }}
                    error={newApiKeyNameError}
                    defaultValue=""
                    data-testid={
                      TestIds.pages.apiKeys.creation
                        .apiKeyNameField
                    }
                  />
                </FormControl>
                <Typography variant="caption" color="error">
                  {errorMessage || errorDuringCreation}
                </Typography>
              </>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onClose}
                color='inherit'
                data-testid={
                  TestIds.pages.apiKeys.creation.cancelButton
                }>
                {ts('common.action.cancel')}
              </Button>
              <ButtonLoading
                data-testid={
                  TestIds.pages.apiKeys.creation.generateButton
                }
                type="submit"
                color="primary"
                loading={isCreating}
              >
                {ts('page.apiKeys.generate.theApiKey')}
              </ButtonLoading>
            </DialogActions>
          </>
        ) : (
          <SuccessStep
            newApiKeyUuid={newApiKeyUuid}
            newApiKeySecret={newApiKeySecret}
            onClose={onClose}
          />
        )}
      </form>
    </Dialog>
  );

}

export default GenerateApiKeyDialog;
