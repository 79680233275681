import { Button, Container, Typography } from '@mui/material';
import TopProfile from 'Components/Shared/ProfileTemplate/TopProfile';
import useTranslate from 'Hooks/useTranslate';
import { PrivateRoutes } from 'Router/Routes';
import { DataRow } from 'Theme/components';
import SectionWithTitle from 'Theme/components/SectionWithTitle/SectionWithTitle';
import { FormattedDate } from 'react-intl';
import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';

interface Props {
  organization: IOrganization;
}

function TabGeneral({organization}: Readonly<Props>) {
  const { accountCode, organizationCode } = useParams<{ accountCode: string; organizationCode: string; }>();
  const ts = useTranslate();

  return (
    <Container>
      <TopProfile
        name={organization.name}
        youConsultSentence={ts('page.organizationProfile.youConsultSentence')}
        colorBlockTitle={organization.name}
        colorBlockSubTitle={`${ts("common.label.id")} ${organization.code}`}
      />
      <SectionWithTitle title={ts('page.organizationProfile.aboutTitle')}>
        <DataRow label={ts('common.label.name')} divider={true}>
          <Typography>{organization.name}</Typography>
        </DataRow>
        <DataRow label={ts('page.organizationProfile.uniqueIdentifier')} divider={true}>
          <Typography>{organization.code}</Typography>
        </DataRow>
        <DataRow label={ts('page.organizationProfile.creationDate')} divider={true}>
          <Typography>
            <FormattedDate
              value={organization.dateCreation}
            />
          </Typography>
        </DataRow>
        <DataRow label={ts('page.organizationProfile.countDomains')} divider={false} action={
          <Button
            component={Link}
            to={generatePath(PrivateRoutes.organizationProfileDomains.path, {
              accountCode: accountCode,
              organizationCode: organizationCode
            })}
            variant="text"
            color="primary"
          >
            {ts(
              'page.organizationProfile.seeAllDomains'
            )}
          </Button>
        }>
          <></>
        </DataRow>
      </SectionWithTitle>
    </Container>
  );
}

export default TabGeneral;
