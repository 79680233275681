import { IconButton, Tooltip } from '@mui/material';
import { Create as IconCreate } from '@mui/icons-material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import TestIds from 'Tests/TestIds';
import EditLanguageDialog from './EditLanguageDialog';

interface Props {
  person: IPersonWithAccount;
}

interface State {
  isDialogOpen: boolean;
}

class EditLanguageToggle extends React.Component<Props, State> {
  public readonly state: State = {
    isDialogOpen: false
  };

  public render() {
    const { person } = this.props;
    const { isDialogOpen } = this.state;

    return <>
      <Tooltip
        title={<FormattedMessage id="common.action.edit" />}
        placement="bottom"
      >
        <IconButton
          data-testid={TestIds.pages.profile.editLanguageButton}
          onClick={this.onOpenDialog}
          size="large">
          <IconCreate />
        </IconButton>
      </Tooltip>
      {isDialogOpen && (
        <EditLanguageDialog person={person} onClose={this.onCloseDialog} />
      )}
    </>;
  }

  private onOpenDialog = () => {
    this.setState({
      isDialogOpen: true
    });
  };

  private onCloseDialog = () => {
    this.setState({
      isDialogOpen: false
    });
  };
}

export default EditLanguageToggle;
