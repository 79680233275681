import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import{FiberManualRecord as IconFiberManualRecord} from '@mui/icons-material';
import * as React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

const withWidth = () => (WrappedComponent:any) => (props:any) => <WrappedComponent {...props} width="xs" />;

const styles = createStyles({
  legend: {
    alignSelf: 'center'
  },
  legendItem: {
    // targets : MuiListItemIcon
    '& > svg': {
      marginRight: 5
    },
    // targets : MuiListItemText
    '& > div': {
      padding: 0
    },
    paddingBottom: 0,
    paddingTop: 0
  },
  root: {
    flex: '1 1 auto'
  }
});

interface IDoughnutProps {
  datas: Array<{ label: string; value: number; color: string; testId?: string }>;
  displayTitle?: 'aside' | 'binded';
}

interface IDoughnutState {}

export default class Doughnut extends React.PureComponent<
  IDoughnutProps & WithStyles<typeof styles>,
  IDoughnutState
> {
  public static Display = withStyles(styles)(
    withWidth()(Doughnut)
  ) as React.ComponentType<IDoughnutProps>;

  public render() {
    return this.props.displayTitle === 'binded'
      ? this.displayLegendWithBind()
      : this.displayLegendOnRight();
  }

  private displayLegendOnRight() {
    const { datas, classes } = this.props;
    return (
      <Grid container={true} className={classes.root}>
        <Grid item={true} xs={4}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                stroke="none"
                innerRadius={27}
                outerRadius={45}
                dataKey="value"
                data={datas}
              >
                {datas.map((d, i) => {
                  return <Cell key={`Doughnut.Pie.cell.${i}`} fill={d.color} />;
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item={true} className={classes.legend} xs={8}>
          <List>
            {datas.map((d, i) => {
              if (d.value !== 0 && d.label !== 'created') {
                return (
                  <ListItem
                    key={`Doughnut.label.${i}`}
                    className={classes.legendItem}
                  >
                    <ListItemIcon>
                      <IconFiberManualRecord
                        style={{ width: 15, color: d.color }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      secondary={
                        <Typography variant="caption" data-testid={d.testId}>
                          {d.label}
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              }
              return null;
            })}
          </List>
        </Grid>
      </Grid>
    );
  }
  private displayLegendWithBind() {
    const { datas, classes } = this.props;
    return (
      <Grid container={true} className={classes.root}>
        <Grid xs={12}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                stroke="none"
                label={true}
                innerRadius={27}
                outerRadius={45}
                dataKey={undefined as any}
                data={datas}
              >
                {datas.map((d, i) => {
                  return <Cell key={`Doughnut.Pie.cell.${i}`} fill={d.color} />;
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    );
  }
}
