import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    drawer: {
      borderRadius: '20px 20px 0 0',
      maxHeight: '80%',
    },
    headerLeftIcon: {
      marginRight: theme.spacing(1),
      verticalAlign: 'middle'
    },
    drawerIcons: {
      display: 'flex',
      justifyContent: 'center',
    },
  }));