interface Props {
  children: React.ReactNode;
}

function Container(props: Readonly<Props>) {
  return (
    <div
      style={{
        maxWidth: 920,
        margin: '0 auto'
      }}
    >
      {props.children}
    </div>
  );
}

export default Container;
