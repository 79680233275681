import { Box, Typography } from '@mui/material';
import TestIds from 'Tests/TestIds';
import useTranslate from 'Hooks/useTranslate';
import Skeleton from 'Theme/components/Skeleton';

interface Props {
  loading: boolean;
  nbResult: number;
}

const ResultSentence = (props: Props) => {
  const { loading, nbResult } = props;
  const ts = useTranslate();

  let noResult = nbResult === 0;

  return (
    <Box pb={2}>
      {loading && <Skeleton variant="rectangular" height={128} width="100%" />}
      {!loading && !noResult && (
        <Typography
          variant="h6"
          align="left"
          data-testid={TestIds.component.searchByUser.userSelectedSentence}
          color="textSecondary"
          paragraph
        >
          {ts('component.accessrights.pendingRequest.sentence', {
            value: nbResult
          })}
        </Typography>
      )}
    </Box>
  );
};

export default ResultSentence;
