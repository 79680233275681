import * as React from 'react';
import { Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

const styles = (theme: Theme) =>
  createStyles({
    emptyState: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: theme.spacing(9),
      paddingTop: theme.spacing(4),
      height: '100%'
    },
    emptyStateIcon: {
      fontSize: 60,
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(1)
    },
    emptyStateTitle: {
      marginBottom: theme.spacing(1)
    },
    emptyStateActions: {
      textAlign: 'center',
      marginTop: theme.spacing(3)
    }
  });

interface Props extends WithStyles<typeof styles> {
  icon?: React.ReactElement;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  actions?: React.ReactNode | null;
  className?: string;
}

function EmptyState(props: Readonly<Props>) {
  return (
    <div
      className={clsx(props.classes.emptyState, {
        [`${props.className}`]: !!props.className
      })}
    >
      {props.icon && (
        <div className={props.classes.emptyStateIcon}>
          {React.cloneElement(props.icon, { fontSize: 'large' })}
        </div>
      )}
      <Typography
        variant="h4"
        color="textSecondary"
        align="center"
        classes={{ root: props.classes.emptyStateTitle }}
      >
        {props.primary}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        {props.secondary}
      </Typography>
      {props.actions && (
        <div className={props.classes.emptyStateActions}>{props.actions}</div>
      )}
    </div>
  );
}

export default withStyles(styles)(EmptyState);
