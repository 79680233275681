import { MenuItem, TextField, useMediaQuery } from '@mui/material';
import shortAccountlabel from 'Tools/LabelFormatter/shortAccountLabel';
import { LocalContext } from 'Context/Local.context';
import { useContext, useState } from 'react';
import useTranslate from 'Hooks/useTranslate';
import { useHistory } from 'react-router-dom';
import { useStyles } from './AccountsSelector.styles';
import { updateAccountInPath } from 'Tools/updateAccountInPath';
import mediaQueries from 'Theme/constants/mediaQueries';

interface Props {}

function AccountsSelector(props: Readonly<Props>) {
  const ts = useTranslate();
  const history = useHistory();
  const { currentAccount, availableAccounts, allAccountsSelected } = useContext(LocalContext);
  const classes = useStyles();

  const isMobileP = useMediaQuery(mediaQueries.mobilePortrait);

  const [selectedAccountCode, setSelectedAccountCode] = useState<string>(
    currentAccount?.code ?? 'all'
  );

  let currentRoute = history.location.pathname;

  const handleChangeAccount = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedAccountCode(event.target.value as string);
    let redirectUrl = updateAccountInPath(currentRoute, { accountCode: event.target.value as string });
    history.push(redirectUrl)
  };

  if(!availableAccounts) return null;

  return (
      <TextField
        select
        size='small'
        InputProps={{
          className: classes.inputRoot
        }}
        value={currentAccount?.code ?? 'all'}
        onChange={handleChangeAccount}
      >
      <MenuItem
        key="x"
        value="all"
        role="<option>"
        aria-label={ts('component.administratedAccountsSelector.allAccounts')}
        selected={allAccountsSelected}
      >
        {ts('component.administratedAccountsSelector.allAccounts')}
      </MenuItem>
      {availableAccounts.map((acc: IAccount) => {
        const tiersName = shortAccountlabel(acc);
        return (
          <MenuItem
            key={acc.code}
            value={acc.code}
            role="<option>"
            selected={selectedAccountCode === acc.code}
          >
            {isMobileP ? acc.code : `${tiersName}`}
          </MenuItem>
        );
      })}
      </TextField>
  );
}

export default AccountsSelector;
