import useTranslate from './useTranslate';

const useModifiers = () => {
  const ts = useTranslate();
  const boolToText = (o: boolean) =>
    ts(!!o ? 'common.action.yes' : 'common.action.no');

  return { boolToText };
};

export default useModifiers;
