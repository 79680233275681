import { gql } from '@apollo/client';

export enum ApiKeyEventType {
  Creation = 'CREATION',
  AccessGranted = 'ACCESSGRANTED',
  Revocation = 'REVOCATION',
  AccessDenied = 'ACCESSDENIED'
}

export enum ApiKeyEventResult {
  Activated = 'ACTIVATED',
  Disabled = 'DISABLED',
  SigninSucceeded = 'SIGNINSUCCEEDED',
  SigninFailed = 'SIGNINFAILED'
}

export const ApiKeyEventFragment = gql`
  fragment ApiKeyEvent on ApiKeyEvent {
    apiKeyUuid
    author {
      firstName
      lastName
    }
    date
    type
  }
`;
