import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    borderRadius: '20px 20px 0 0',
    maxHeight: '90%'
  },
  expansionPanel: {
    backgroundColor: 'transparent'
  },
  expansionPanelSummary: {
    cursor: 'auto'
  },
  summaryPanel: {
    display: 'flex',
    width: '100%',
    'justify-content': 'space-between'
  }
}));
