import * as React from 'react';
import { ApiKeyState } from 'Apollo/fragments/apikey/ApiKey';
import ApiStateTab from './ApiStateTab';

export default function TabRevoked(props: { accountCode: string }) {
  return (
    <ApiStateTab
      apiStatus={ApiKeyState.Revoqued}
      accountCode={props.accountCode}
    />
  );
}
