import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    borderRadius: '20px 20px 0 0',
    '& table>tbody>tr>td:nth-last-child(-n+1), & table>thead>tr>th:nth-last-child(-n+1)': {
      textAlign: 'center'
    },
    maxHeight: '90%'
  },
  expansionPanel: {
    backgroundColor: 'transparent'
  },
  expansionPanelSummary: {
    cursor: 'auto'
  }
}));
