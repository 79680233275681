import { gql } from '@apollo/client';
import { AccountWithNotificationSettingsFragment } from '../../fragments/account/AccountWithNotificationSettings';

export const GetAccountsWithNotificationSettings = gql`
  query GetAccountsWithNotificationSettings {
    accounts {
      edges {
        node {
          ...AccountWithNotificationSettings
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${AccountWithNotificationSettingsFragment}
`;
