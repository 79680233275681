import { Typography, useMediaQuery } from '@mui/material';
import mediaQueries from 'Theme/constants/mediaQueries';
import accountLabel from 'Tools/LabelFormatter/accountLabel';

interface Props {
  access: IPersonAccessRight;
}

function CellServiceWithAccount({ access }: Readonly<Props>) {
  const isMobile = useMediaQuery(mediaQueries.mobile);

  return (
    <>
      <Typography variant="subtitle1">{access.service.name ?? ''}</Typography>
      {access.account && (
        <Typography variant="body2">
          {isMobile ? access.account.name : accountLabel(access.account)}
        </Typography>
      )}
    </>
  );
}

export default CellServiceWithAccount;
