import IconButtonWithPopOver from 'Theme/components/IconButtonWithPopOver';
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';

interface Props {
  content: string;
  color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
  role?: string;
}

function ContextualHelp({ content, color, role }: Readonly<Props>) {
  return (
    <div style={{ marginLeft: '8px' }} role={role}>
      <IconButtonWithPopOver
        customIcon={<InfoOutlinedIcon fontSize="small" color={color ?? "action"} />}
        description={content}
      />
    </div>
  );
}

export default ContextualHelp;
