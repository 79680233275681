import { gql } from '@apollo/client';
import { DomainFragment } from 'Apollo/fragments/domain/Domain';

export const GetOrganizationDomains = gql`
  query GetOrganizationDomains($code: String!) {
    organizationDomains(code: $code) {
      edges {
        node {
          ...Domain
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${DomainFragment}
`;
