/**
 * readonly clipboard: Clipboard;
 * Available only in secure contexts.
 *
 * [MDN Reference](https://developer.mozilla.org/docs/Web/API/Navigator/clipboard)
 */


export function copyToClipboard(v: string) {
    if (!navigator.clipboard) {
        console.error('Clipboard not available');
        return;
    }
    navigator.clipboard?.writeText(v);
}