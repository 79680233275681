import { Grid, Paper, Typography, Button } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, generatePath, useParams } from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';
import CurrentPerson from 'Context/CurrentPerson';
import Skeleton from 'Theme/components/Skeleton';
import { ErrorHandler } from 'Theme/components/ErrorHandler';
import { QueryResult, MutationFunction, MutationResult } from '@apollo/client';
import { Query, Mutation } from '@apollo/client/react/components';
import { GetPersonMigrationPreview } from 'Apollo/queries/person/GetPersonMigrationPreview';
import Authentication from 'Tools/auth';
import PreviewCard from '../Components/PreviewCard';
import TestIds from 'Tests/TestIds';
import { useStyles } from './MergeDirectory.styles';
import useTranslate from '../../Hooks/useTranslate';
import { MigrateIdentityToProvider } from '../../Apollo';
import DirectoryMigrationHeader from '../../Components/Shared/DirectoryMigrationHeader';
import { capitalizeFirstLetter } from 'Tools';
import useNotification from 'Hooks/useNotification';

let previewCardTitle: string;

function MergeDirectoryPreview() {
  const classes = useStyles();
  let { identityProvider: dirName } = useParams<any>();
  const { onSuccess, onError } = useNotification();
  const capitalizedDirName = capitalizeFirstLetter(dirName);
  const ts = useTranslate();

  switch (capitalizedDirName) {
    case 'Microsoft':
      previewCardTitle = ts('common.terminology.MSaccount');
      break;
    case 'Google':
      previewCardTitle = ts('common.terminology.GoogleAccount');
      break;
    default:
    // will NOT execute because for now only 2 idp merge possible.
  }

  const onCompleted = () => {
    onSuccess('page.userProfile.identityMerged', { dir: capitalizedDirName });
    Authentication.logout();
  };

  return (
    <CurrentPerson.Consumer>
      {({ currentPerson }: CurrentPersonContext) => (
        <ErrorHandler.Consumer>
          {errorHandler => (
            <Query
              query={GetPersonMigrationPreview}
              variables={{
                personMigrationData: window.location.hash.slice(1)
              }}
              onError={errorHandler.onError}
            >
              {({ data }: QueryResult<GetPersonMigrationPreviewData>) => {
                if (
                  data == null ||
                  data.personMigrationPreview == null ||
                  data.personMigrationPreview.migratedPerson == null
                ) {
                  return <Skeleton width={200} />;
                }
                return (
                  <Mutation
                    mutation={MigrateIdentityToProvider}
                    variables={{
                      data: data.personMigrationPreview.migrationData,
                      identityProvider: capitalizedDirName
                    }}
                    onError={onError}
                    onCompleted={onCompleted}
                  >
                    {(
                      migrateIdentity: MutationFunction,
                      { loading: isLoadingMigrateIdentity }: MutationResult
                    ) => (
                      <form
                        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                          event.preventDefault();

                          if (isLoadingMigrateIdentity) {
                            return;
                          }

                          migrateIdentity();
                        }}
                      >
                        <Grid
                          container
                          justifyContent="center"
                          className={classes.step2MSmerge}
                        >
                          <Helmet
                            title={ts('page.userProfile.mergeAction', {
                              value: capitalizedDirName
                            })}
                          />
                          <Grid item>
                            <Paper>
                              <DirectoryMigrationHeader
                                directory={capitalizedDirName}
                              />
                              <Grid
                                container
                                justifyContent="center"
                                direction="column"
                                className={classes.stepContent}
                              >
                                <Typography
                                  variant="h4"
                                  className={classes.yourInformation}
                                >
                                  {ts('page.userProfile.yourInformation')}
                                </Typography>
                                <Grid
                                  container
                                  justifyContent="space-around"
                                  direction="row"
                                  className={classes.identitiesToMerge}
                                >
                                  <PreviewCard
                                    personToPreview={currentPerson}
                                    title={ts(
                                      'common.terminology.localAccount'
                                    )}
                                  />
                                  <PreviewCard
                                    title={previewCardTitle}
                                    personToPreview={
                                      data.personMigrationPreview.migratedPerson
                                    }
                                  />
                                </Grid>

                                <Typography
                                  variant="subtitle1"
                                  className={classes.customSubtitle1}
                                >
                                  {ts('page.userProfile.mergeValidationText', {
                                    dir: capitalizedDirName
                                  })}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  className={classes.subText}
                                >
                                  {ts(
                                    'page.userProfile.mergeValidationSubText',
                                    { dir: capitalizedDirName }
                                  )}
                                </Typography>
                              </Grid>

                              <Grid
                                container
                                justifyContent="flex-end"
                                direction="row"
                                className={classes.action}
                              >
                                <Grid item className={classes.spacing}>
                                  <Link
                                    to={generatePath(
                                      PrivateRoutes.usersProfile.path,
                                      {
                                        accountCode: currentPerson.account
                                          ? currentPerson.account.code
                                          : 'all',
                                        immutableId: currentPerson.immutableId
                                      }
                                    )}
                                  >
                                    <Button
                                      color='inherit'
                                      data-testid={
                                        TestIds.common.snackbar.action.cancel
                                      }>
                                      {ts('common.action.cancel')}
                                    </Button>
                                  </Link>
                                </Grid>
                                <Grid item>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={isLoadingMigrateIdentity}
                                  >
                                    {ts('common.action.migrate')}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Mutation>
                );
              }}
            </Query>
          )}
        </ErrorHandler.Consumer>
      )}
    </CurrentPerson.Consumer>
  );
}

export default MergeDirectoryPreview;
