import findDuplicates from 'Tools/findDuplicates';
import * as Yup from 'yup';
import useTranslate from './useTranslate';

// Extend the yup interface to add custom methods
declare module 'yup' {
  interface ArraySchema<T> {
    emailsValidation(errorMessage: string): ArraySchema<T>;
    emailsLength(errorMessage: string): ArraySchema<T>;
    emailsDuplication(errorMessage: string): ArraySchema<T>;
  }
}

Yup.addMethod(Yup.array, 'emailsValidation', function(errorMessage) {
  return this.test('emailsValidation', (value, { createError, path }) => {
    if (value) {
      for (let i in value) {
        if (!new RegExp(window.AppSettings.EMAIL_VALIDATION).test(value[i])) {
          return createError({
            message: `${errorMessage}: ${value[i]}`,
            path
          });
        }
      }
    }
    return true;
  });
});

Yup.addMethod(Yup.array, 'emailsLength', function(errorMessage) {
  return this.test('emailsLength', errorMessage, value => {
    if (value) {
      return value.length < 101;
    }
    return false;
  });
});

Yup.addMethod(Yup.array, 'emailsDuplication', function(errorMessage) {
  return this.test('emailsDuplication', (value, { createError, path }) => {
    if (value) {
      const duplicates = findDuplicates(value);
      if (duplicates.length > 0) {
        return createError({
          message: `${errorMessage}: ${duplicates.join(', ')}`,
          path
        });
      }
      return true;
    }
    return false;
  });
});

const useFormValidation = () => {
  const ts = useTranslate();
  const externalOwnerValidation = Yup.object().shape({
    email: Yup.string()
      .required(ts('error.form.requiredField'))
      .matches(
        new RegExp(window.AppSettings.EMAIL_VALIDATION),
        ts('error.form.invalidEmail')
      )
  });
  const usersCreation = Yup.object().shape({
    account: Yup.string()
      .required(ts('error.form.requiredField'))
      .min(1, ts('error.form.requiredField')),
    emails: Yup.array()
      .of(Yup.string())
      .required(ts('error.form.requiredField'))
      .min(1, ts('error.form.requiredField'))
      .emailsValidation(ts('error.form.invalidEmail'))
      .emailsLength(ts('error.form.invalidEmailsLength'))
      .emailsDuplication(ts('error.form.emailsDuplication'))
  });

  return { externalOwnerValidation, usersCreation };
};

export default useFormValidation;
