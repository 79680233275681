import { Box, Button, Card, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Imgs } from 'Assets';
import useTranslate from 'Hooks/useTranslate';

type Props = {
  email: string;
};

const useStyles = makeStyles(() => ({
  image: {
    width: 80
  }
}));

const ChangeEmailValidation = ({ email }: Props) => {
  const ts = useTranslate();
  const { image } = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ height: '100vh' }}
    >
      <Grid item>
        <Card>
          <Box p={4} maxWidth={450}>
            <Box pb={3}>
              <img
                src={Imgs.cegid.src}
                alt={Imgs.cegid.alt}
                className={image}
              />
            </Box>
            <Typography variant="h4" paragraph>
              {ts('page.changeEmailValidation.title')}
            </Typography>
            <Typography variant="body1" paragraph>
              {ts('page.changeEmailValidation.subtitle', { email })}
            </Typography>
            <Box pt={3}>
              <Button variant="contained" color="primary" href="/">
                {ts('page.changeEmailValidation.goToCegid')}
              </Button>
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ChangeEmailValidation;
