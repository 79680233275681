import { gql } from '@apollo/client';

export const GetPersonMigrationPreview = gql`
  query GetPersonMigrationPreview($personMigrationData: String!) {
    personMigrationPreview(personMigrationData: $personMigrationData) {
      migratedPerson {
        avatar {
          color
          initials
        }
        email
        firstName
        lastName
      }
      migrationData
    }
  }
`;
