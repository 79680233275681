import { LinearProgress } from '@mui/material';
import usePMCookie from '../Hooks/usePMCookie';
import Authentication from '../Tools/auth';

type Props = {
  children?: React.ReactNode;
};

const CookieCheck = ({ children }: Props) => {
  const { cookie } = usePMCookie();

  if (!cookie.PMSession) {
    Authentication.login();
    return <LinearProgress color="secondary" />;
  }

  return <>{children}</>;
};

export default CookieCheck;
