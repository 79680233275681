import { Person as UserIcon } from '@mui/icons-material';
import useTranslate from '../../Hooks/useTranslate';
import EmptyState from 'Components/Shared/EmptyState';
import { getActionsOnLightProfileColumns } from './ActionsColumns';
import { useColumnsCandidates } from 'Hooks/useColumnsCandidates';
import { PersonOrder } from 'Apollo';
import { MAX_SELECTED } from 'Theme/config';
import { TableCollection } from 'Theme/components/TableCollection';

interface Props {
  selectedIds: string[] | null;
  onselect: (ids: string[] | null) => void;
  onUnselect: (ids: string[] | null) => void;
  rows: ILightPersonProfile[];
  loading: boolean;
  totalCount: number;
  page: number;
  rowsPerPage: number;
  onChangeRowsPerPage: (newRowsPerPage: number) => void;
  setPage: (page: number) => void;
  onRemoveUser: (row: ILightPersonProfile) => Promise<void>;
  onUpdateUserSubscription: (row: ILightPersonProfile) => Promise<void>;
}

const TableServicesMembers = ({
  selectedIds,
  onselect,
  onUnselect,
  onChangeRowsPerPage,
  rows,
  loading,
  setPage,
  onRemoveUser,
  onUpdateUserSubscription,
  totalCount,
  page,
  rowsPerPage
}: Props) => {
  const columns = useColumnsCandidates().concat(
    getActionsOnLightProfileColumns(onRemoveUser, onUpdateUserSubscription)
  );

  const ts = useTranslate();

  return (
    <TableCollection
      isSelectAllDisabled={true}
      loading={loading}
      columns={columns}
      rows={rows}
      getRowId={(r: IPerson) => r.immutableId}
      orderBy={PersonOrder.NameAsc}
      rowsPerPage={rowsPerPage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      page={page}
      onChangePage={setPage}
      onUnselect={onUnselect}
      selectedIds={selectedIds}
      onSelect={onselect}
      maxSelected={MAX_SELECTED}
      totalCount={totalCount}
      emptyStateData={
        <EmptyState
          icon={<UserIcon />}
          primary={ts('page.serviceProfile.serviceMembers.emptyTitle')}
        />
      }
    />
  );
};

export default TableServicesMembers;
