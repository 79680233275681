import { Typography, Grid } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GetEmailChangeRequest } from 'Apollo';
import { DataRow } from 'Theme/components/DataRow';
import TestIds from 'Tests/TestIds';
import EditEmailToggle from '../EditEmail';
import DeleteEmailRequest from './DeleteEmailRequest';
import ResendEmailRequest from './ResendEmailRequest';
import useTranslate from 'Hooks/useTranslate';
import useNotification from 'Hooks/useNotification';

interface Props {
  person: IPersonWithAccount;
  isProfileOwner: boolean;
  hasAFederatedDirectory: boolean;
}

function EmailRow(props: Props) {
  const { isProfileOwner, hasAFederatedDirectory, person } = props;
  const ts = useTranslate();
  const { onError } = useNotification();
  const { data, refetch } = useQuery<GetEmailChangeRequestData>(
    GetEmailChangeRequest,
    {
      variables: { personIdentifier: person.immutableId },
      skip: !isProfileOwner,
      onError: onError
    }
  );

  const { emailChangeRequest } = data ?? {};

  return (
    <DataRow
      label={ts('page.userProfile.section.personal.email')}
      divider={true}
      action={
        !emailChangeRequest &&
        !hasAFederatedDirectory &&
        isProfileOwner && (
          <EditEmailToggle person={person} onCompleted={() => refetch()} />
        )
      }
    >
      <Typography
        variant="body2"
        data-testid={TestIds.pages.userProfile.account.emailValue}
      >
        {person.email}
      </Typography>
      {emailChangeRequest && isProfileOwner && (
        <Grid container alignItems="center">
          <Grid item data-testid={TestIds.pages.profile.pendingEmailChange}>
            <Typography variant="caption">
              {ts('page.userProfile.section.personal.pendingEmailRequest', {
                value: emailChangeRequest.newEmail
              })}
            </Typography>
          </Grid>
          <Grid item>
            <ResendEmailRequest
              userId={person.immutableId}
              newEmail={emailChangeRequest.newEmail}
            />
          </Grid>
          <Grid item>
            <DeleteEmailRequest
              userId={person.immutableId}
              onCompleted={() => refetch()}
            />
          </Grid>
        </Grid>
      )}
    </DataRow>
  );
}

export default EmailRow;
