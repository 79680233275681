import { gql } from '@apollo/client';

export const UpdatePersonAccessRights = gql`
  mutation UpdatePersonAccessRights(
    $data: [AccessRightUpdate]!
  ) {
    updatePersonAccessRights(
      data: $data
    ) 
  }
`;
