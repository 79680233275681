import { Accordion, Drawer } from '@mui/material';
import { useStyles } from './TableDrawer.styles';

type Props = {
  isOpen: boolean;
  children: NonNullable<React.ReactNode>;
};

const TableDrawer = (props: Props) => {
  const { isOpen, children } = props;
  const classes = useStyles();

  return (
    <Drawer
      anchor="bottom"
      variant="temporary"
      open={isOpen}
      classes={{ paper: classes.drawer }}
    >
      <Accordion expanded={true} classes={{ root: classes.expansionPanel }}>
        {children}
      </Accordion>
    </Drawer>
  );
};

export default TableDrawer;
