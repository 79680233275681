import { useQuery } from '@apollo/client';
import { GetAccountWithNotificationSettings } from 'Apollo';
import { ErrorMsg } from 'Components/Shared';
import ProfilePageSkeleton from 'Theme/components/ProfilePageSkeleton';
import AccountProfile from './AccountProfile';
import { useContext } from 'react';
import { LocalContext } from 'Context/Local.context';

interface Props {}

const AccountProfileContainer = (props: Readonly<Props>) => {
  const { currentAccount } = useContext(LocalContext);

  const { data, loading, error } = useQuery<
    GetAccountWithNotificationSettingsData
  >(GetAccountWithNotificationSettings, {
    skip: !currentAccount?.code,
    variables: {
      accountCode: currentAccount?.code
    },
    fetchPolicy: 'cache-and-network'
  });

  if (!loading && error) return <ErrorMsg />;

  if (loading ?? !data) return <ProfilePageSkeleton shape="rectangular" />;

  return <AccountProfile account={data.account} />;
};

export default AccountProfileContainer;
