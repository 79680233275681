import { ApolloError } from '@apollo/client';
import { ErrorDomain } from 'Theme/components/ErrorHandler/types';
import { createContext } from 'react';

export interface IErrorHandlerContext {
  handle: (errors: string[], domain?: ErrorDomain) => void;
  onError: (error: ApolloError, domain?: ErrorDomain) => void;
}

const ErrorHandlerContext = createContext<IErrorHandlerContext>({
  handle: () => {},
  onError: () => {}
});

export default ErrorHandlerContext;
