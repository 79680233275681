import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CdsColorBackgroundPrimaryLight, CdsColorBackgroundWhiteMain, CdsColorBrandNeutral99 } from '@cegid/design-tokens';

export const useStyles = makeStyles((theme: Theme) => ({
  accessRightsPageLayout: {
    margin: `-${theme.spacing(4)} -${theme.spacing(3)} 0px -${theme.spacing(3)}`
  },
  accessRightsTopSection: {
    position: 'fixed',
    width: '100%',
    zIndex: theme.zIndex.drawer - 1,
    background: CdsColorBackgroundPrimaryLight
  },
  accessRightsRequestsPageTitle: {
    display: 'flex',
    justifyContent: 'center',
    padding: '2em',
    background: CdsColorBackgroundWhiteMain,
    position: 'relative',
    borderTop: `1px solid ${CdsColorBrandNeutral99}`,
    borderBottom: `1px solid ${CdsColorBrandNeutral99}`,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  accessRightsCardsContainer: {
    margin: '1em',
    position: 'relative',
    top: '180px',
    [theme.breakpoints.down('md')]: {
     /** The top position is depending of if the title of accessright page is displayed or not */
      top: '3em'
    }
  }
}));
