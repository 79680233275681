import { Button, Grid, Typography } from '@mui/material';
import  ButtonLoading  from 'Theme/components/ButtonLoading';
import Alert from 'Components/Shared/Alert';
import useTranslate from 'Hooks/useTranslate';
import Modal from 'Components/Shared/Modal';
import { PersonContext } from 'Context/CurrentPerson';
import { useContext } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';
import { useStyles } from '../UserAccountUpdate.styles';
import { userFullName } from 'Tools/LabelFormatter/userFullName';
import TestIds from 'Tests/TestIds';

interface Props {
  person: IPersonWithAccount;
  accessRights: IPersonAccessRight[];
  loading: boolean;
  isOpen: boolean;
  onClose: () => void;
  updateAccount: () => void;
}

function UnLinkAccount(props: Readonly<Props>) {
  const { person, accessRights, loading, onClose, updateAccount } = props;
  const { currentPersonRoles } = useContext(PersonContext);
  const classes = useStyles();
  const ts = useTranslate();

  const userNotExternalisableAccessRights = accessRights.filter(
    acc =>
      acc.account?.code === person.account?.code &&
      !acc.service.isExternalisable
  );
  const userExternalisableAccessRights = accessRights.filter(
    acc =>
      acc.account?.code === person.account?.code && acc.service.isExternalisable
  );


  const accountVeryShortLabel = person.account?.name ?? person.account?.code;

  return (
    <Modal
      {...props}
      title={'component.userAccountUpdate.unlinkDialogTitle'}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            {ts('component.userAccountUpdate.alertInfo.previousMembersList', {
              value: accountVeryShortLabel ?? ''
            })}
          </Typography>
          <Typography variant="body1">
            {ts('component.userAccountUpdate.alertWarning.unlinkGroups')}
          </Typography>
          {userNotExternalisableAccessRights.length > 5 && (
            <Typography variant="body1">
              {ts('component.userAccountUpdate.lostAccessCount', {
                value: userNotExternalisableAccessRights.length
              })}
              <Link
                to={generatePath(
                  PrivateRoutes.usersProfile.path + '/services',
                  {
                    accountCode: person.account?.code,
                    immutableId: person.immutableId
                  }
                )}
                className={classes.quickLinks}
              >
                {ts(
                  'component.userAccountUpdate.switchAccount.servicesListLink'
                )}
              </Link>
            </Typography>
          )}

          {userNotExternalisableAccessRights.length > 0 &&
            userNotExternalisableAccessRights.length <= 5 && (
              <>
                <Typography variant="body1">
                  {ts(
                    'component.userAccountUpdate.alertInfo.previousAccountAccessRights'
                  )}
                </Typography>
                {userNotExternalisableAccessRights.map(
                  (acc: IPersonAccessRight) => {
                    return (
                      <Typography
                        variant="caption"
                        color="inherit"
                        key={acc.service.groupCode + '-' + acc.account?.name}
                        component="li"
                      >
                        {acc.service.name}
                        {acc.personGroupSource &&
                          ts(
                            'component.userAccountUpdate.inheritedServicesFromGroup',
                            { value: acc.personGroupSource.name }
                          )}
                      </Typography>
                    );
                  }
                )}
              </>
            )}
        </Grid>
        <Grid item xs={12}>
          {userExternalisableAccessRights.length > 0 && (
            <Alert
              severity="warning"
              title={ts(
                'component.userAccountUpdate.alertWarning.alertWarningTitle'
              )}
              content={
                <>
                  {ts(
                    'component.userAccountUpdate.alertWarning.retainedAccessRights'
                  )}
                  {userExternalisableAccessRights.map(
                    (acc: IPersonAccessRight) => (
                      <Typography
                        variant="caption"
                        color="inherit"
                        key={acc.service.groupCode + '-' + acc.account?.name}
                        component="li"
                      >
                        {acc.service.name}
                      </Typography>
                    )
                  )}

                  <br />
                  {ts(
                    'component.userAccountUpdate.alertWarning.removeAllAccessWarning',
                    {
                      value: userFullName(person.firstName, person.lastName)
                    }
                  )}
                  <Link
                    to={generatePath(
                      PrivateRoutes.usersProfile.path + '/services',
                      {
                        accountCode: person.account?.code,
                        immutableId: person.immutableId
                      }
                    )}
                    className={classes.quickLinks}
                  >
                    {ts('profile.menu.services')}
                  </Link>
                </>
              }
            />
          )}
          {!currentPersonRoles.hasAccessToAccounts && (
            <Alert
              severity="warning"
              title={ts('component.userAccountUpdate.irreversibleAction')}
              content={
                <>
                  {ts(
                    'component.userAccountUpdate.alertWarning.noMoreAccessToProfile',
                    {
                      value: accountVeryShortLabel
                    }
                  )}
                </>
              }
            />
          )}
          {currentPersonRoles.hasAccessToAccounts && (
            <Alert
              severity="warning"
              title={ts('component.userAccountUpdate.alertWarning.awareness')}
              data-testid={TestIds.component.userAccountUpdate.unlinkAccountAwareness}
              content={
                <>
                  <span style={{ marginRight: '4px' }}>
                    {ts(
                      'component.userAccountUpdate.forSuperUserOnly.newAccessToProfile'
                    )}
                  </span>
                  <Link
                    to={generatePath(PrivateRoutes.users.path, {
                      accountCode: 'all'
                    })}
                    className={classes.quickLinks}
                  >
                    {ts(
                      'component.userAccountUpdate.forSuperUserOnly.administratedUsers'
                    )}
                  </Link>
                </>
              }
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button size="medium" onClick={onClose} style={{ marginRight: 8 }} color='inherit'>
              {ts('common.action.cancel')}
            </Button>
            <ButtonLoading
              loading={loading}
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              onClick={() => updateAccount()}
            >
              {ts('common.action.confirm')}
            </ButtonLoading>
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default UnLinkAccount;
