import { gql } from '@apollo/client';

export const AddServiceSubscriptionExternalOwner = gql`
  mutation AddServiceSubscriptionExternalOwner(
    $serviceSubscriptionIdentifier: serviceSubscriptionIdentifier!
    $email: String!
  ) {
    addServiceSubscriptionExternalOwners(
      serviceSubscriptionIdentifier: $serviceSubscriptionIdentifier
      email: $email
    ) {
      immutableId
    }
  }
`;
