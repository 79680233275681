import { createContext } from 'react';

export interface ILocalContext {
  currentAccount: IAccount | null;
  allAccountsSelected: boolean;
  availableAccounts: IAccount[];
  accountsCount: number;
}

export const LocalContext = createContext<ILocalContext>({
  currentAccount: null,
  allAccountsSelected: false,
  availableAccounts: [],
  accountsCount: 0
});
