import * as Yup from 'yup';
import { TTranslate } from '../../Hooks/useTranslate';
import { isValidIP } from 'Tools/isValidIp';

export enum EIpActivityKind {
  PERSONS = 'PERSONS'
  // API_KEY = 'API_KEY'
}
export interface IServiceFormIpFiltering {
  activityKind: EIpActivityKind;
  iPstart: string;
  iPend: string;
  description: string;
  isActive?: boolean;
  immutableId?: string;
  __typename?: string;
}

export type TServiceIpFilteringList = IPaginatedList<IIpRestriction>;

export type TServiceMemberList = IPaginatedList<ILightPersonProfile>;

export type TServiceGroupList = IPaginatedList<ILightPersonGroup>;

export const serviceFormDefaultIpFiltering: IServiceFormIpFiltering = {
  activityKind: EIpActivityKind.PERSONS,
  iPstart: '',
  iPend: '',
  description: ''
};

export const serviceFormIpFilteringValidation = (t: TTranslate) => {
  const pad = (num: string) => String('00' + num).slice(-3);
  const ipRange = (rng: string) => {
    let [ip1, ip2] = rng.split('-');
    ip1 = ip1
      .split('.')
      .map(num => pad(num))
      .join('.');
    ip2 = ip2
      .split('.')
      .map(num => pad(num))
      .join('.');
    return ip1 <= ip2;
  };

  const ipValidation = Yup.string()
    .required(t('error.form.requiredField'))
    .test(t('error.form.invalidIp'), t('error.form.invalidIp'), (value = '') =>
      isValidIP(value)
    )
    .test(
      t('error.form.invalidIpRange'),
      t('error.form.invalidIpRange'),
      (_, ctx) => {
        const { parent }: { parent: IServiceFormIpFiltering } = ctx;
        const range = `${parent.iPstart}-${parent.iPend}`;
        return ipRange(range);
      }
    )
    .required(t('error.form.requiredField'));

  return Yup.object().shape({
    activityKind: Yup.string().required(t('error.form.requiredField')),
    iPstart: ipValidation,
    iPend: ipValidation,
    description: Yup.string().required(t('error.form.requiredField')),
    isActive: Yup.boolean()
  });
};
