import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { CdsColorTextSuccessHighEmphasis, CdsColorTextCriticalHighEmphasis, CdsColorTextWarnHighEmphasis } from '@cegid/design-tokens';

export const useStyles = makeStyles((theme: Theme) => ({
  availabilityIcon: {
    verticalAlign: 'middle',
    marginRight: 4
  },
  availabilityIconAccepted: {
    color: CdsColorTextSuccessHighEmphasis
  },
  availabilityIconToValidate: {
    color: CdsColorTextWarnHighEmphasis
  },
  availabilityIconRefused: {
    color: CdsColorTextCriticalHighEmphasis
  },
  availabilityIconUnavailable: {
    color: CdsColorTextCriticalHighEmphasis
  }
}));