import * as React from 'react';
import { IconButton, InputAdornment, TextField, Box } from '@mui/material';
import { Search as IconSearch } from '@mui/icons-material';
import { useState } from 'react';
import { useStyles } from './ExpandedSearch.styles';
import TestIds from '../../../Tests/TestIds';
import { debounce } from 'Tools/debounce';
import { DEBOUNCE_DELAY } from 'Theme/config';

interface Props {
  onChange: (value: string) => void;
  testId: string;
  placeholder: string;
}

const ExpandedSearch = (props: Props) => {
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);
  const { onChange, testId } = props;
  const [searchValue, setSearchValue] = useState('');
  const debouncedSendRequest = React.useMemo(() => {
    return debounce(onChange, DEBOUNCE_DELAY);
  }, [onChange]);
  const inputRef = React.createRef<HTMLInputElement>();

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setSearchValue(value);
    debouncedSendRequest(value);
  }

  return (
    <Box className={classes.container}>
      <TextField
        placeholder={props.placeholder}
        value={searchValue}
        inputRef={inputRef}
        className={classes.input}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-testid={testId}
        size="small"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                data-testid={TestIds.common.navigation.searchButton}
                color={isFocused ? 'primary' : 'default'}
                onClick={() => setIsFocused(!isFocused)}
                size="large">
                <IconSearch
                  style={{
                    color: isFocused
                      ? 'CdsColorBackgroundPrimaryLight'
                      : 'inherit'
                  }}
                  data-testid={TestIds.component.searchByUser.searchIcon}
                />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export default ExpandedSearch;
