import { IconButton, SwipeableDrawer, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close as IconClose, Menu as IconMenu } from '@mui/icons-material';
import TestIds from 'Tests/TestIds';
import Sidebar from '../Sidebar';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useStyles = makeStyles(theme => ({
  keepHeight: theme.mixins.toolbar,
  menuButton: {
    marginLeft: -theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const NavigationButton = () => {
  const location = useLocation();
  const [isNavigationOpen, setIsNavigationOpen] = useState<boolean>(false);
  const onCloseNavigation = () => {
    setIsNavigationOpen(false);
  };

  useEffect(() => {
    setIsNavigationOpen(false);
  }, [location]);

  const classes = useStyles();
  const onToggleOpenNavigation = () => {
    setIsNavigationOpen(!isNavigationOpen);
  };

  const onOpenNavigation = () => {
    setIsNavigationOpen(true);
  };

  return <>
    <Tooltip
      title={<FormattedMessage id="common.terminology.menu" />}
      disableFocusListener={true}
    >
      <IconButton
        onClick={onToggleOpenNavigation}
        classes={{ root: classes.menuButton }}
        data-testid={TestIds.common.header.navigationButton}
        size="large">
        {isNavigationOpen ? <IconClose /> : <IconMenu />}
      </IconButton>
    </Tooltip>

    <SwipeableDrawer
      open={isNavigationOpen}
      onClose={onCloseNavigation}
      onOpen={onOpenNavigation}
      ModalProps={{
        keepMounted: isNavigationOpen
      }}
    >
      <div className={classes.keepHeight} />
      <Sidebar />
    </SwipeableDrawer>
  </>;
};

export default NavigationButton;
