import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReplayIcon from '@mui/icons-material/Replay';
import ErrorIcon from '@mui/icons-material/Error';
import useTranslate from '../../../Hooks/useTranslate';

interface Props {
  onRetry?(): void;
}

const useStyles = makeStyles(() => ({
  errorBloc: {
    border: '1px solid #f44336'
  }
}));

const ErrorMsg = ({ onRetry }: Props) => {
  const ts = useTranslate();
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.errorBloc}
      justifyContent="center"
      direction="column"
      alignItems="center"
    >
      <Box p={2} />
      <ErrorIcon fontSize="large" color="secondary" />
      <Box p={1} />
      <Typography
        variant="body1"
        children={ts('error.somethingHappened')}
        paragraph
      />
      {!onRetry && (
        <Typography
          variant="caption"
          color="error"
          children={ts('error.comeBackLater')}
          paragraph
        />
      )}
      {!!onRetry && (
        <>
          <Typography
            variant="body1"
            children={ts('common.action.retry')}
            paragraph
            color="textSecondary"
          />

          <ReplayIcon
            fontSize="small"
            onClick={onRetry}
            color="primary"
            data-testid="reload-icon"
            style={{ cursor: 'pointer' }}
          />
        </>
      )}
      <Box p={2} />
    </Grid>
  );
};

export default ErrorMsg;
