import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HistoryIcon from '@mui/icons-material/History';
import { PaperTitle } from 'Theme/components';
import EmptyState from 'Components/Shared/EmptyState';
import { ButtonProps } from '@mui/material/Button';
import * as React from 'react';
import { Query } from '@apollo/client/react/components';
import { QueryResult } from '@apollo/client';
import { Link, generatePath, useParams } from 'react-router-dom';
import { FormattedMessage, FormattedDate } from 'react-intl';
import joinURL from 'url-join';
import { ActivityType, GetPersonActivities } from '../../../../Apollo';
import { PrivateRoutes } from 'Router/Routes';
import PaperFooter from 'Components/Shared/PaperFooter/PaperFooter';
import useNotification from 'Hooks/useNotification';

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  circularClearer: {
    opacity: '0.3'
  }
}));

interface Props {
  person: IPerson;
}

function LastActivities(props: Props) {
  const classes = useStyles();
  const { accountCode } = useParams<{ accountCode: string }>();
  const { onError } = useNotification();

  return (
    <Query
      query={GetPersonActivities}
      variables={{
        personIdentifier: props.person.immutableId,
        activityType: ActivityType.All,
        first: 5
      }}
      onError={onError}
    >
      {({ loading, data }: QueryResult<GetPersonActivitiesData>) => (
        <Paper classes={{ root: classes.root }}>
          <PaperTitle
            title={
              <FormattedMessage id="page.userProfile.lastActivities.title" />
            }
          />
          {loading || data === undefined ? (
            <EmptyState
              icon={<CircularProgress size={44} color="inherit" />}
              primary={' '}
              secondary={' '}
              className={classes.circularClearer}
            />
          ) : data?.personActivities !== undefined &&
            data?.personActivities.totalCount === 0 ? (
            <EmptyState
              icon={<HistoryIcon color="inherit" />}
              primary={
                <FormattedMessage id="page.userProfile.activities.emptyStateTitle" />
              }
              secondary={
                <FormattedMessage id="page.userProfile.activities.emptyStateText" />
              }
            />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="page.userProfile.lastActivities.activity" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="page.userProfile.lastActivities.result" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="page.userProfile.lastActivities.date" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.personActivities.edges.map((activity, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <FormattedMessage
                        id="page.userProfile.lastActivities.activityType"
                        values={{
                          value: activity.node.action
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <FormattedMessage
                        id="page.userProfile.lastActivities.activityResult"
                        values={{
                          value: activity.node.actionResult
                        }}
                      />
                    </TableCell>
                    <TableCell>
                    <FormattedDate
                      value={activity.node.date}
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                      hour="2-digit"
                      minute="2-digit"
                    />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <PaperFooter>
            <Button
              color="primary"
              component={React.forwardRef<Link, ButtonProps>(
                ({ className, children }, ref) => (
                  <Link
                    ref={ref}
                    className={className}
                    to={joinURL(
                      generatePath(PrivateRoutes.usersProfile.path, {
                        accountCode: accountCode,
                        immutableId: props.person.immutableId
                      }),
                      '/activities'
                    )}
                  >
                    {children}
                  </Link>
                )
              )}
            >
              <FormattedMessage id="page.userProfile.lastActivities.allActivities" />
            </Button>
          </PaperFooter>
        </Paper>
      )}
    </Query>
  );
}

export default LastActivities;
