import { gql } from '@apollo/client';

export const UpdatePersonAccount = gql`
  mutation UpdatePersonAccount(
    $newAccountCode: String
    $personIdentifier: String!
  ) {
    updatePersonAccount(newAccountCode: $newAccountCode, personIdentifier: $personIdentifier) 
  }
`;
