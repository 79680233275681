import { Paper } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import PaperTitle from 'Theme/components/PaperTitle';
import CurrentPerson from '../../../../../Context/CurrentPerson';
import { useStyles } from './Directories.styles';
import PersonDirectories from './PersonDirectories/PersonDirectories';

interface Props {
  person: IPersonWithAccount;
}

function Directories(props: Props) {
  const classes = useStyles();

  return (
    <CurrentPerson.Consumer>
      {({ currentPerson, currentPersonRoles }: CurrentPersonContext) => {
        const hasSuperRights = currentPersonRoles.hasAccessToAccounts;
        const isCurrentPerson =
          currentPerson.immutableId === props.person.immutableId;
        return (
          <Paper classes={{ root: classes.root }}>
            <PaperTitle
              title={
                <FormattedMessage id="page.userProfile.section.directories.title" />
              }
            />
            <PersonDirectories
              person={props.person}
              isCurrentPerson={isCurrentPerson}
              hasSuperRights={hasSuperRights}
            />
          </Paper>
        );
      }}
    </CurrentPerson.Consumer>
  );
}

export default Directories;
