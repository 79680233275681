import { gql } from '@apollo/client';

export enum NotificationKind {
  None = 'NONE',
  Email = 'EMAIL'
}

export const NotificationSettingsFragment = gql`
  fragment NotificationSettings on NotificationSettings {
    newUserAccessRights
  }
`;
