// This is a copy and simple adaptation of material-ui's future Skeleton.
// We should be able to replace it with the official one when it's released.
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui-lab/src/Skeleton/Skeleton.js
import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';

const styles = (theme: Theme) =>
  createStyles({
    /* Styles applied to the root element. */
    root: {
      display: 'block',
      backgroundColor: theme.palette.action.hover,
      height: '1.2em'
    },
    /* Styles applied to the root element if `variant="text"`. */
    text: {
      marginTop: '0.8em',
      marginBottom: '0.8em',
      borderRadius: theme.shape.borderRadius
    },
    /* Styles applied to the root element if `variant="rectangular"`. */
    rectangular: {},
    /* Styles applied to the root element if `variant="circle"`. */
    circle: {
      borderRadius: '50%'
    },
    /* Styles applied to the root element if `disabledAnimate={false}`. */
    animate: {
      animation: '$skeleton 1.5s ease-in-out infinite'
    },
    '@keyframes skeleton': {
      '0%': {
        opacity: 1
      },
      '50%': {
        opacity: 0.4
      },
      '100%': {
        opacity: 1
      }
    }
  });

interface Props {
  height?: string | number;
  width?: string | number;
  variant?: 'text' | 'rectangular' | 'circle';
  component?: React.ElementType;
  className?: string;
  style?: React.CSSProperties;
}

class Skeleton extends React.Component<Props & WithStyles<typeof styles>> {
  public render() {
    const {
      classes,
      component: Component = 'div',
      height,
      variant = 'text',
      width,
      className = '',
      style = {}
    } = this.props;

    return (
      <Component
        className={[
          classes.root,
          classes[variant],
          classes.animate,
          className
        ].join(' ')}
        style={{
          ...style,
          width,
          height
        }}
      />
    );
  }
}

export default withStyles(styles)(Skeleton);
