const FULL_WIDTH = 1;
const ZERO_WIDTH = 0;

function getColumnsWidth(widths: Array<number | undefined>): number[] {
  const knownWidths = widths.filter(minWidth => minWidth != null) as number[];

  const occupiedWidth = knownWidths.reduce(
    (acc: number, minWidth) => acc + minWidth,
    ZERO_WIDTH
  );
  const unoccupiedWidth = FULL_WIDTH - occupiedWidth;

  const unknownWidths = widths.filter(minWidth => minWidth == null);
  const unknownWidth = unoccupiedWidth / unknownWidths.length;

  const smallestKnownWidth = knownWidths.reduce(
    (acc, minWidth) => (minWidth < acc ? minWidth : acc),
    FULL_WIDTH
  );

  let finalUnknownWidth = unknownWidth;
  let finalWidthExcessPerColumn = 0;

  if (unknownWidth > smallestKnownWidth) {
    finalUnknownWidth = smallestKnownWidth;

    const widthExcess =
      unoccupiedWidth - smallestKnownWidth * unknownWidths.length;
    finalWidthExcessPerColumn = widthExcess / widths.length;
  }

  return widths.map(width => {
    const finalWidth = width == null ? finalUnknownWidth : width;
    const finalWidthWithExcess = finalWidth + finalWidthExcessPerColumn;

    return Number(finalWidthWithExcess.toFixed(4));
  });
}

export default getColumnsWidth;
