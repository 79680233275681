import { gql } from '@apollo/client';

export const GetFellowAdministratorsCount = gql`
  query GetFellowAdministrators(
    $accountCode: String
    $first: Int
    $offset: Int
  ) {
    fellowAdministrators(
      accountCode: $accountCode
      first: $first
      offset: $offset
    ) {
      totalCount
    }
  }
`;
