import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import PersonGroupsTable from './PersonGroupsTable';
import useTranslate from 'Hooks/useTranslate';


function PagePersonGroups() {
  const ts = useTranslate();
  return (
    <>
      <Helmet title={ts('page.groups.title')} />
      <Grid container={true} justifyContent="center">
        <Grid item={true} xs={12}>
          <PersonGroupsTable />
        </Grid>
      </Grid>
    </>
  );
}

export default PagePersonGroups;
