import * as React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { ListItemProps } from '@mui/material/ListItem';
import { NavigationListItem } from 'Theme/components/NavigationList';

interface Props {
  'data-testid'?: string;
  to: string;
  target?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

function NavigationListItemLink(props: Props) {
  const location = useLocation();

  return (
    <NavigationListItem
      {...props}
      component={React.forwardRef<Link, ListItemProps>(
        ({ className, children }, ref) => (
          <Link
            ref={ref}
            data-testid={props['data-testid']}
            className={className}
            to={props.to}
            target={props.target}
          >
            {children}
          </Link>
        )
      )}
      selected={Boolean(
        matchPath(location.pathname, {
          path: props.to,
          exact: true
        })
      )}
    />
  );
}

export default NavigationListItemLink;
