import { MenuItem, ListItemAvatar, ListItemText, Typography, Select } from "@mui/material";
import TestIds from "Tests/TestIds";
import { PersonAvatar } from "Theme/components/Avatar";
import { userFullName } from "Tools";
import { useStyles } from "./Dialog/RequestAdministrativeAccessDialog.styles";

interface Props {
    data: IPaginatedList<IPerson>;
    onChange: (event: any) => void;
    value: string;
}

const FellowAdministratorsSelector = (props: Props) => {
    const { data, value, onChange } = props;
    const classes = useStyles();

    let nodes = data.edges;

    return (
        <Select
            variant="outlined"
            value={value}
            onChange={onChange}
            data-testid={
                TestIds.common
                    .RequestAdministrativeAccessBanner
                    .accessRightRequestDialog.select
            }
        >
            {nodes
                .filter(adm => adm.node.email != null)
                .map((adm) => (
                    <MenuItem
                        role="option"
                        key={adm.node.email}
                        value={
                            adm.node.immutableId
                                ? adm.node.immutableId
                                : ''
                        }
                    >
                        <ListItemAvatar>
                            <PersonAvatar
                                person={adm.node}
                                size="small"
                            />
                        </ListItemAvatar>
                        <ListItemText
                            disableTypography={true}
                        >
                            <Typography
                                noWrap={true}
                                className={classes.menuItemEl}
                            >
                                <Typography
                                    variant="subtitle2"
                                    component="span"
                                    className={classes.menuItemEl}
                                >
                                    {userFullName(adm.node.firstName, adm.node.lastName)}
                                </Typography>
                                {(adm.node.firstName ||
                                    adm.node.lastName) && (
                                        <Typography
                                            component="span"
                                            className={
                                                classes.menuItemEl
                                            }
                                        >
                                            •
                                        </Typography>
                                    )}
                                <Typography
                                    component="span"
                                    className={classes.menuItemEl}
                                >
                                    {adm.node.email}
                                </Typography>
                            </Typography>
                        </ListItemText>
                    </MenuItem>
                ))}
        </Select>
    )
}

export default FellowAdministratorsSelector;