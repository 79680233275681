import { gql } from '@apollo/client';
import { PersonWithAccountFragment } from 'Apollo/fragments/person/PersonWithAccount';

export const GetFellowAdministrators = gql`
  query GetFellowAdministrators(
    $accountCode: String
    $first: Int
    $offset: Int
  ) {
    fellowAdministrators(
      accountCode: $accountCode
      first: $first
      offset: $offset
    ) {
      edges {
        node {
          ...PersonWithAccount
        }
      }
      pageInfo {
        hasNextPage
      }
      totalCount
    }
  }
  ${PersonWithAccountFragment}
`;
