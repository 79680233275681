import * as React from 'react';

export const defaultPersonRoles: PersonRoles = {
  hasAccessToAccounts: false,
  canAskForAccountAdministrationDelegation: false,
  isAccountAdmin: false,
  isApiKeyOwner: false,
  isOrganizationOwner: false,
  isDirectoryOwner: false,
  isServiceAdmin: false,
  isSimpleUser: true,
  accounts: {},
  canReadAll: false,
  canWriteAll: false
};

export const defaultResourceAccess: IAdministrationResourceAccess = {
  canAskForAccountAdministrationDelegation: false,
  hasAccessToAccounts: false,
  isAccountAdmin: false,
  isApiKeyOwner: false,
  isOrganizationOwner: false,
  isDirectoryOwner: false,
  isServiceAdmin: false,
  canReadAll: false,
  canWriteAll: false
};

export const PersonContext = React.createContext<CurrentPersonContext>({
  // The default context is mocked so the typechecking is easier on us
  // and always consider that currentPerson will be a proper IPersonWithAccount.
  // Which is the case since the Provider is always provided the logged in user.
  currentPerson: {
    status: null,
    avatar: null,
    lastName: null,
    firstName: null,
    immutableId: '',
    immutableGuid: '',
    email: null,
    phoneNumber: null,
    mobileNumber: null,
    language: null,
    professionCode: null,
    titleCode: null,
    sicId: null,
    activationDate: null,
    creationDate: '',
    account: null
  },
  currentPersonRoles: defaultResourceAccess,
  recompute: () => {}
});

const { Provider, Consumer } = PersonContext;

interface Props {
  currentPerson: IPersonWithAccount;
  resourceAccess: IAdministrationResourceAccess;
}

interface State {
  // This is only used to trigger a rerender,
  // resulting in the currentPersonRoles being recomputed.
  lastUpdated: Date;
}

class CurrentPersonProvider extends React.Component<Props> {
  public readonly state: State = {
    lastUpdated: new Date()
  };

  public render() {
    const {
      currentPerson,
      resourceAccess,
      children
    } = this.props;

    return (
      <Provider
        value={{
          currentPerson,
          currentPersonRoles: resourceAccess,
          recompute: () => {
            this.setState({
              lastUpdated: new Date()
            });
          }
        }}
      >
        {children}
      </Provider>
    );
  }
}

const currentPerson = { Provider: CurrentPersonProvider, Consumer };

export default currentPerson;
