import { Box, Button, Drawer, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTranslate from 'Hooks/useTranslate';
import ExpandedSearch from '../../../Components/Shared/ExpandedSearch';
import TestIds from 'Tests/TestIds';
import ButtonLoading from 'Theme/components/ButtonLoading';

const useStyles = makeStyles(theme => ({
  drawer: {
    borderRadius: '20px 20px 0 0',
    maxHeight: '80%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: `0px`,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`
  },
  headerLeft: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center'
  },
  drawerButtons: {
    display: 'flex',
    justifyContent: 'end',
    gap: theme.spacing(1),
    margin: `${theme.spacing(1)} 0`,
    position: 'sticky',
    bottom: 0,
    background: 'white',
    padding: '8px',
    borderTop: '1px solid rgba(224, 224, 224, 1)'
  }
}));

interface Props {
  open: boolean;
  title: string;
  onSearch(txt: string): void;
  onClose(): void;
  onAdd(): void;
  onInviteExternal(): void;
  actions: React.ReactNode[];
  allowExternalInvitation: boolean;
  children?: React.ReactNode;
  loading: boolean;
  isReady?: boolean;
}

export const ActionsDrawer = ({
  open,
  title,
  onSearch,
  onClose,
  onAdd,
  actions,
  children,
  loading,
  isReady = false
}: Props) => {
  const classes = useStyles();
  const ts = useTranslate();

  return (
    <Drawer
      anchor="bottom"
      variant="temporary"
      open={open}
      classes={{ paper: classes.drawer }}
    >
      <Box className={classes.header} mb={2}>
        <Box className={classes.headerLeft}>
          <Typography variant="h5">{title}</Typography>
        </Box>
        {actions}
      </Box>
      <Box>
        <ExpandedSearch
          onChange={onSearch}
          testId={TestIds.pages.users.searchUserInput + 'ActionsDrawer'}
          placeholder={ts('component.searchBars.placeholder.users')}
        />
      </Box>

      {children}
      <Box className={classes.drawerButtons}>
        <Button onClick={onClose} color='inherit'>{ts('common.action.cancel')}</Button>
        <ButtonLoading
          color="primary"
          variant="contained"
          onClick={onAdd}
          disabled={loading || !isReady}
          data-testid={TestIds.component.actionsDrawer.addButton}
          loading={loading}
        >
          {ts('common.action.add')}
        </ButtonLoading>
      </Box>
    </Drawer>
  );
};
