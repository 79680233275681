import { useParams } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import TestIds from 'Tests/TestIds';
import {
  RequestAdministrativeAccess,
} from '../../../Apollo';
import padlockSrc from 'Theme/assets/images/padlock.svg';
import { useState } from 'react';
import { useStyles } from './RequestAdministrativeAccessDialog.styles';
import useTranslate from 'Hooks/useTranslate';
import FellowAdministratorsSelectorContainer from '../FellowAdministratorsSelector.container';
import useNotification from 'Hooks/useNotification';
import { useMutation } from '@apollo/client/react/hooks/useMutation';

enum RequestAccessStatus {
  pending = 'PENDING',
  approved = 'APPROVED'
}

type RequestAccessStatusType =
  | null
  | RequestAccessStatus.pending
  | RequestAccessStatus.approved;

interface Props {}

function RequestAdministrativeAccessDialog(props: Props) {
  const { accountCode } = useParams<IUriParams>();
  const classes = useStyles();
  const { onError } = useNotification();
  const ts = useTranslate();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [
    requestAccessStatus,
    setRequestAccessStatus
  ] = useState<RequestAccessStatusType | null>(null);
  const [selectedAdministratorId, setSelectedAdministratorId] = useState<string>('');

  const [requestAdministrativeAccess] = useMutation(RequestAdministrativeAccess, {
    onError
  });

  const handleChange = (id: string) => {
    setSelectedAdministratorId(id);
  };

  const handleDialogOpen = () => setIsDialogOpen(true);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setRequestAccessStatus(null);
    setSelectedAdministratorId('');
  };

  const onSendEmail = () => setRequestAccessStatus(RequestAccessStatus.pending);

  const callBackUrl = 'delegate/confirmation';

  return (
    <div>
      <Button
        color="secondary"
        variant='text'
        data-testid={
          TestIds.common.RequestAdministrativeAccessBanner.button
        }
        onClick={handleDialogOpen}
      >
        {ts('banner.buttonText')}
      </Button>

      <Dialog
        id="ask-access-to-adm-dialog"
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
        data-testid={
          TestIds.common.RequestAdministrativeAccessBanner
            .accessRightRequestDialog.dialog
        }
      >
        {requestAccessStatus === null && (
          <>
            <DialogTitle id="form-dialog-title">
              {ts('banner.accessRightRequestDialog.title')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {ts('banner.accessRightRequestDialog.text')}
              </DialogContentText>
              <Typography variant="caption">
                {ts('banner.accessRightRequestDialog.subtext')}
                <br />
                <br />
              </Typography>
              <FellowAdministratorsSelectorContainer
                onUpdate={handleChange}
                value={selectedAdministratorId}
              />
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleDialogClose}
                color="inherit"
                data-testid={
                  TestIds.common.RequestAdministrativeAccessBanner
                    .accessRightRequestDialog.cancelButton
                }
              >
                {ts('common.action.cancel')}
              </Button>
              <Button
                onClick={() => {
                  if (!selectedAdministratorId) {
                    return;
                  }
                  requestAdministrativeAccess({
                    variables: {
                      accountCode,
                      administratorImmutableId: selectedAdministratorId,
                      callBackUrl
                    }
                  });
                  onSendEmail();
                }}
                color="primary"
                disabled={!selectedAdministratorId}
                data-testid={
                  TestIds.common.RequestAdministrativeAccessBanner
                    .accessRightRequestDialog.sendEmailButton
                }
              >
                {ts('banner.accessRightRequestDialog.sendEmail')}
              </Button>
            </DialogActions>
          </>
        )}
        {requestAccessStatus === RequestAccessStatus.pending && (
          <>
            <DialogContent className={classes.dialogContentRoot}>
              <img
                src={padlockSrc}
                alt=""
                className={classes.accessRequestImage}
              />
              <DialogContentText
                className={classes.contentText}
                data-testid={
                  TestIds.common.RequestAdministrativeAccessBanner
                    .accessRightRequestDialog.confirmationText
                }
              >
                {ts('banner.accessRightRequestDialog.pendingRequest')}
              </DialogContentText>
              <Typography variant="caption">
                {ts('banner.accessRightRequestDialog.pendingRequestInfo')}
              </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                onClick={handleDialogClose}
                color="primary"
                className={classes.buttonClose}
                data-testid={
                  TestIds.common.RequestAdministrativeAccessBanner
                    .accessRightRequestDialog.closeButton
                }
              >
                {ts('common.action.close')}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}

export default RequestAdministrativeAccessDialog;
