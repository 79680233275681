import Header from 'Components/Header';
import { useContext, useMemo } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { PrivateRoutes } from './Routes';
import { PersonContext } from 'Context/CurrentPerson';
import useTranslate from 'Hooks/useTranslate';
import { useQuery } from '@apollo/client';
import { GetAccount } from 'Apollo';
import { LocalContext } from 'Context/Local.context';
import DashboardSkeleton from 'Page/Dashboard/Dashboard.skeleton';
import { Box, useMediaQuery } from '@mui/material';
import { PageError } from 'Page/PageError';
import ErrorHandlerContext from 'Context/ErrorHandler.context';
import useNotification from 'Hooks/useNotification';
import mediaQueries from 'Theme/constants/mediaQueries';

type Props = { accounts: IAccount[] | undefined, accountsCount: number | undefined; };

const SUPER_USER_QUERY_ID_KEY = 'processedSuperUserQueryId';

const LocalContextRouter = ({ accounts, accountsCount }: Props) => {
  const { currentPersonRoles } = useContext(PersonContext);
  const ts = useTranslate();
  const isMobile = useMediaQuery(mediaQueries.mobile);
  const { accountCode } = useParams<IUriParams>();
  const ErrorHandler = useContext(ErrorHandlerContext);
  const { onFailure } = useNotification();

  let { data: account, loading } = useQuery<GetAccountData, GetAccountVars>(
    GetAccount,
    {
      variables: {
        accountCode: accountCode
      },
      onError: ErrorHandler.onError,
      skip:
        !accountCode ||
        accountCode === 'all' ||
        !currentPersonRoles.isAccountAdmin,
      fetchPolicy: 'cache-first'
    }
  );

  const processedSuperUserQueryId: string =
    localStorage[SUPER_USER_QUERY_ID_KEY];
  const lastSuperUserAttemptId = currentPersonRoles.lastSuperUserAttemptId;
  if (
    !!lastSuperUserAttemptId &&
    lastSuperUserAttemptId !== processedSuperUserQueryId
  ) {
    localStorage[SUPER_USER_QUERY_ID_KEY] = lastSuperUserAttemptId;
    onFailure(ts('error.type.superUserAccessRefused'));
  }

  const value = useMemo(
    () => ({
      currentAccount: account?.account ?? null,
      allAccountsSelected: accountCode === 'all',
      availableAccounts: accounts ?? [],
      accountsCount: accountsCount ?? 0
    }),
    [account, accountCode, accounts, accountsCount]
  );

  const calculateMaxHeight = () => {
    let maxHeight: string;
    if(currentPersonRoles.canReadAll) {
      maxHeight = isMobile ? 'calc(100vh - 104px)' : 'calc(100vh - 108px)';
    } 
    else {
      maxHeight = isMobile ? 'calc(100vh - 55px)' : 'calc(100vh - 64px)';
    }
    return maxHeight;
  }

  const calculatePosition = () => {
    let position: string;
    if(currentPersonRoles.canReadAll) {
      position = isMobile ? '104px' : '108px';
    } 
    else {
      position = isMobile ? '55px' : '64px';
    }
    return position;
  };

  if (loading) return <DashboardSkeleton />;

  return (
    <LocalContext.Provider value={value}>
      <Header />
      <div style={{position: 'relative'}}>
        <Switch>
          {[
            PrivateRoutes.accountSelection,
            PrivateRoutes.administrators,
            PrivateRoutes.accountProfile,
            PrivateRoutes.accessRights,
            PrivateRoutes.apiKeyServices,
            PrivateRoutes.apikeyProfile,
            PrivateRoutes.dashboard,
            PrivateRoutes.mergeIdentity,
            PrivateRoutes.notifications,
            PrivateRoutes.personGroupCreate,
            PrivateRoutes.personGroupProfile,
            PrivateRoutes.personGroups,
            PrivateRoutes.services,
            PrivateRoutes.serviceProfile,
            PrivateRoutes.externalServiceProfile,
            PrivateRoutes.serviceProfileRestrictions,
            PrivateRoutes.serviceProfileMembers,
            PrivateRoutes.users,
            PrivateRoutes.usersProfile,
            PrivateRoutes.organizations,
            PrivateRoutes.organizationProfile,
            PrivateRoutes.organizationProfileDomains,
            PrivateRoutes.accessRights
          ].map(route => {
            const hasAccessToPage =
              !route.isAdminOnly ||
              currentPersonRoles.hasAccessToAccounts ||
              currentPersonRoles.isAccountAdmin;

              const hasPaddingLayout = route.path !== '/:accountCode/accessrights';

            return (
              <Route key={route.path} path={route.path} exact={route.exact}>
                {hasAccessToPage ? (
                  <Box id="content" p={hasPaddingLayout ? 3 : 0} style={{
                    maxHeight: calculateMaxHeight(),
                    position: 'absolute',
                    top: calculatePosition(),
                    overflowY: 'auto',
                    width: '100%'
                  }}>
                  {route.children()}
                </Box>
                ) : (
                  <PageError
                    title={ts('error.type.E403')}
                    textPrimary={ts('error.sentence.E403')}
                    accessDenied={true}
                  />
                )}
              </Route>
            );
          })}
        </Switch>
      </div>
    </LocalContext.Provider>
  );
};

export default LocalContextRouter;
