import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Add as IconAdd, Group as IconGroup } from '@mui/icons-material';
import { Paper, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PaperTitle } from 'Theme/components';
import EmptyState from 'Components/Shared/EmptyState';
import { PersonContext } from 'Context/CurrentPerson';
import TestIds from 'Tests/TestIds';
import PersonGroupPersonsTable from './PersonGroupPersonsTable';
import AddPersonsDrawer from './AddPersonsDrawer';
import useTranslate from 'Hooks/useTranslate';
import ExpandedSearch from 'Components/Shared/ExpandedSearch';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2)
  }
}));

interface Props {
  group: IPersonGroup;
  loading: boolean;
  persons: IPersonWithAccount[];
  totalCount: number;
  currentPage: number;
  rowsPerPage: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  onRefetch: () => void;
  onSearchChange: (searchTerm: any) => void; // Il recoit ici la modification de recherche
}

const PersonGroupPersons = ({
  group,
  loading,
  persons,
  totalCount,
  currentPage,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  onRefetch,
  onSearchChange,
}: Props) => {
  const { currentPersonRoles } = React.useContext(PersonContext);
  const classes = useStyles();
  const ts = useTranslate();


  const [isAddingPersons, setIsAddingPersons] = useState<boolean>(false);
  const handleSearchChange = (searchTerm: string) => { // Des qu'une modification est recu, il appelle handleSearchChange
    onSearchChange(searchTerm); // Propagez la recherche vers le conteneur
    onChangePage(0);
  };

  return (
    <>
      <Paper classes={{ root: classes.paper }}>
        <PaperTitle
          title={<FormattedMessage id="profile.menu.members" />}
          secondaryAction={
            (currentPersonRoles.canWriteAll || currentPersonRoles.isAccountAdmin) && (
              <Button
                color="primary"
                onClick={() => {
                  setIsAddingPersons(true);
                }}
                data-testid={TestIds.pages.groupProfile.members.addMembersButton}
              >
                <IconAdd style={{ marginRight: 8 }} />
                {ts('page.groupProfile.members.add')}
              </Button>
            )
          }
        />

        <ExpandedSearch
          onChange={handleSearchChange}
          testId={TestIds.pages.users.searchUserInput + '-ActionsDrawer'}
          placeholder={ts('component.searchBars.placeholder.users')}
        />

        {!loading && !persons.length ? (
          <EmptyState
            icon={<IconGroup />}
            primary={ts('page.groupProfile.members.emptyState.title')}
            secondary={ts('page.groupProfile.members.emptyState.description')}
          />
        ) : (
          <PersonGroupPersonsTable
            testId={TestIds.pages.groupProfile.table}
            loading={loading}
            persons={persons}
            personGroup={group}
            totalCount={totalCount}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            onRefetch={onRefetch}
            onChangePage={(page: number) => onChangePage(page)}
            onChangeRowsPerPage={(rpp: number) => onChangeRowsPerPage(rpp)}
          />
        )}
      </Paper>
      {isAddingPersons && (
        <AddPersonsDrawer
          group={group}
          onCancel={() => {
            setIsAddingPersons(false);
          }}
        />
      )}
    </>
  );
};

export default PersonGroupPersons;
