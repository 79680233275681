import { Button, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTranslate from 'Hooks/useTranslate';
import { useState } from 'react';
import TestIds from 'Tests/TestIds';

const useStyles = makeStyles((theme: Theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'end',
    gap: theme.spacing(1),
    margin: `${theme.spacing(1)} 0`
  }
}));

interface Props {
  onSubmit(email: string): void;
  onCancel(): void;
  error?: string;
}

const EmailForm = ({ onSubmit, onCancel, error }: Props) => {
  const ts = useTranslate();
  const classes = useStyles();
  const [email, setEmail] = useState<string>('');
  function onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onSubmit(email);
  }
  return (
    <form onSubmit={onFormSubmit}>
      <TextField
        variant="outlined"
        type="text"
        name="email"
        label={ts('page.services.createSubscription.stepEmail.email')}
        value={email}
        autoFocus={true}
        fullWidth={true}
        onChange={e => setEmail(e.target.value)}
        error={!!error}
        helperText={error}
      />
      <div className={classes.buttons}>
        <Button type="button" size="medium" onClick={() => onCancel()} color='inherit'>
          {ts('common.action.cancel')}
        </Button>
        <Button
          type="submit"
          size="medium"
          color="primary"
          variant="contained"
          data-testid={TestIds.pages.services.inviteUserButton}
        >
          {ts('page.serviceProfile.serviceMembers.invite')}
        </Button>
      </div>
    </form>
  );
};

export default EmailForm;
