import * as React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { generatePath, Link, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import {
  BubbleChart as IconBubbleChart,
  ArrowForward as IconArrowForward
} from '@mui/icons-material';
import { GetCatalogInfos } from '../../Apollo';
import { PrivateRoutes } from 'Router/Routes';
import Skeleton from 'Theme/components/Skeleton';
import CardCounter from 'Theme/components/CardCounter';
import TestIds from 'Tests/TestIds';
import useNotification from 'Hooks/useNotification';

interface Props {
  showButton?: boolean;
}

function ServicesCounter(props: Props) {
  const { accountCode } = useParams<{ accountCode: string }>();
  const { onError } = useNotification();

  const { data, loading } = useQuery<GetCatalogInfosData>(GetCatalogInfos, {
    variables: {
      accountCode: accountCode === 'all' ? null : accountCode
    },
    onError,
    fetchPolicy: 'cache-first'
  });

  return (
    <CardCounter
      testId={TestIds.pages.dashboard.card.services}
      title={<FormattedMessage id="menu.services" />}
      counter={
        (loading ?? data === undefined) ? (
          <Skeleton variant="rectangular" width="100px" />
        ) : (
          <div data-testid={TestIds.pages.services.totalCount}>
            <FormattedNumber value={data.catalogInfos?.itemCount} />
          </div>
        )
      }
      icon={<IconBubbleChart />}
      action={
        props.showButton ? (
          <Button
            color="primary"
            component={React.forwardRef<Link, ButtonProps>(
              ({ className, children }, ref) => (
                <Link
                  ref={ref}
                  className={className}
                  to={generatePath(PrivateRoutes.services.path, {
                    accountCode: accountCode
                  })}
                  data-testid={TestIds.pages.dashboard.card.serviceListButton}
                >
                  {children}
                </Link>
              )
            )}
            endIcon={<IconArrowForward />}
          >
            <FormattedMessage id="common.action.see.seeTheList" />
          </Button>
        ) : null
      }
    />
  );
}

export default ServicesCounter;
