import { gql } from '@apollo/client';

export const ServiceFragment = gql`
  fragment Service on Service {
    groupCode
    isExternalisable
    name
    family
    description
  }
`;
