import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  circularClearer: {
    opacity: '0.3'
  },
  paper: {
    marginBottom: theme.spacing(2)
  },
  emptyServices: {
    padding: `0 ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)}`
  },
  formControlLabel: {
    marginRight: theme.spacing(4)
  },
  tabResultBlock: {
    minHeight: "240px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "space-between",
  },
  radioGroup: {
    justifyContent: "center"
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1)
  },
})
)
  