import { Card, CardContent, FormControlLabel, Paper, Radio, RadioGroup, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import ServiceMembersPanelContainer from '../../../Components/ServiceMembersPanel';
import useTranslate from 'Hooks/useTranslate';
import ServiceGroupsPanelContainer from '../ServiceGroupsPanel/ServiceGroupsPanel.container';
import Alert from 'Components/Shared/Alert/Alert';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  modeSelection: {
    marginBottom: '20px',
    textAlign: 'center'
  },
  modeSelectionRadio: {
    display: 'inline-block'
  }
}));

interface Props {
  serviceSubscription: IServiceSubscription;
}

type TMemberTypes = 'users' | 'groups';

const ServiceProfileMembers = ({ serviceSubscription }: Props) => {
  const ts = useTranslate();
  const classes = useStyles();
  const [memberType, setMemberType] = useState<TMemberTypes>('users');

  return (
    <>
      <Card className={classes.modeSelection}>
        <CardContent>
          <RadioGroup
            className={classes.modeSelectionRadio}
            row
            value={memberType}
            onChange={e => {
              setMemberType(e.target.value as TMemberTypes);
            }}
          >
            <FormControlLabel
              value="users"
              control={<Radio color="primary" />}
              label={ts('menu.users')}
            />
            <FormControlLabel
              value="groups"
              control={<Radio color="primary" />}
              label={ts('menu.groups')}
            />
          </RadioGroup>
        </CardContent>
      </Card>
      {serviceSubscription.maxSubscriptions !== null && (
        <Alert
          severity="info"
          icon={<InfoOutlined fontSize="medium" />}
          title={ts('page.serviceProfile.serviceMembers.alert.accessLimit', {value: serviceSubscription.maxSubscriptions })}
          content={<Typography variant="body2">{ts('page.serviceProfile.serviceMembers.alert.groupWarning')}</Typography>}
        />
      )}
      <Paper>
        {memberType === 'users' && (
          <ServiceMembersPanelContainer
            serviceSubscriptionIdentifier={{
              accountCode: serviceSubscription.account.code,
              serviceCode: serviceSubscription.service.groupCode
            }}
            allowExternalInvitation={
              serviceSubscription.service.isExternalisable
            }
          />
        )}
        {memberType === 'groups' && <ServiceGroupsPanelContainer />}
      </Paper>
    </>
  );
};

export default ServiceProfileMembers;
