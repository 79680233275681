import { gql } from '@apollo/client';
import { DirectoryFragment } from './Directory';

// This enum is defined according to the API's spec and is already complete.
// If not, we need to double check with the API's developers.
export enum ReadonlyProperty {
  LastName = 'lastName',
  FirstName = 'firstName',
  PhoneNumber = 'phoneNumber',
  MobileNumber = 'mobileNumber',
  Account = 'account',
  LanguageCode = 'languageCode',
  TitleCode = 'titleCode',
  ProfessionCode = 'professionCode'
}

export const DirectoryWithProfileManagementFragment = gql`
  fragment DirectoryWithProfileManagement on Directory {
    ...Directory
    profileManagement {
      canCreateProfile
      canDeleteProfile
      readonlyProperties
    }
  }
  ${DirectoryFragment}
`;
