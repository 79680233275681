import {
  generatePath,
  useParams
} from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';
import useTranslate from 'Hooks/useTranslate';
import useTabsGroup from 'Hooks/useTabsGroup';
import ProfilePageTemplate from 'Components/Shared/ProfileTemplate/ProfilePageTemplate';

interface Props {
  group: IPersonGroup;
}

function PersonGroupProfile({ group }: Readonly<Props>) {
  const { accountCode, groupCode } = useParams<{
    accountCode: string;
    groupCode: string;
  }>();

  const ts = useTranslate();

  const tabs = useTabsGroup({ group });

  const basePath = generatePath(PrivateRoutes.personGroupProfile.path, {
    accountCode: accountCode,
    groupCode: groupCode
  });

  return (
    <ProfilePageTemplate tabs={tabs} basePath={basePath} title={ts('page.groupProfile.title', {
      name: group.name ?? ''
    })} />
  );
}
export default PersonGroupProfile;
