import { SvgIcon } from 'Theme/components/SvgIcon/SvgIcon';
import { mdiAccountStar } from '@mdi/js';
import ShorterUserNameCell from 'Components/Shared/UserNameCell/ShorterUserNameCell';
import Skeleton from 'Theme/components/Skeleton';
import UserCellSkeleton from 'Theme/components/UserCellSkeleton';
import { CdsColorTextNeutralMediumEmphasis } from '@cegid/design-tokens';
import { IAdminConfig } from './useColumnsAdministrators';
import useTranslate from './useTranslate';
import { Typography } from '@mui/material';
import { TableCollectionColumn } from 'Components/Shared/Table';
import UserStatusPopover from 'Components/Shared/Chips/UserStatusChip/UserStatusPopover';

export const defaultLightConfig: IAdminConfig = {
  isUserHidden: false,
  isEmailHidden: false,
  isStatusHidden: false,
  isAccountHidden: true
};

export const useColumnsAdministratorsLight = (
  smallAdminConfig: IAdminConfig
): TableCollectionColumn<{ node: IPersonWithAccount }>[] => {
  const ts = useTranslate();

  return [
    {
      key: 'admin',
      width: 0.2,
      hidden: smallAdminConfig.isUserHidden ?? defaultLightConfig.isUserHidden,
      renderHead: () => (
        <div style={{ display: 'flex' }}>
          <SvgIcon
            path={mdiAccountStar}
            fill={CdsColorTextNeutralMediumEmphasis}
          />
        </div>
      ),
      renderSkeleton: () => <UserCellSkeleton />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => <ShorterUserNameCell person={person} />
    },
    {
      key: 'email',
      width: 0.8,
      hidden:
        smallAdminConfig.isEmailHidden ?? defaultLightConfig.isEmailHidden,
      renderHead: () => ts('page.administrators.index.email'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => person.email
    },
    {
      key: 'status',
      hidden:
        smallAdminConfig.isStatusHidden ?? defaultLightConfig.isStatusHidden,
      renderHead: () => ts('page.administrators.index.allStatus'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="10%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) => <UserStatusPopover person={person} />
    },
    {
      key: 'account',
      hidden:
        smallAdminConfig.isAccountHidden ?? defaultLightConfig.isAccountHidden,
      renderHead: () => ts('common.terminology.client'),
      renderSkeleton: () => <Skeleton variant="rectangular" width="80%" />,
      renderCell: ({
        row: { node: person }
      }: {
        row: { node: IPersonWithAccount };
      }) =>
        person.account && (
          <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
            {person.account.name}
          </Typography>
        )
    }
  ];
};
