import { Box } from '@mui/material';
import Container from 'Theme/components/Container';
import Helmet from 'react-helmet';
import { Route, Switch, Redirect } from 'react-router';
import SubHeader from 'Components/Shared/SubHeader';
import { TTabView } from '../Tabs/Tabs';

interface Props {
  title: string;
  basePath: string;
  tabs: TTabView[];
}

function ProfilePageTemplate(props: Readonly<Props>) {
  const { title, basePath, tabs } = props;

  return (
    <>
      <Helmet
        title={title}
      />
      <SubHeader tabs={tabs} />
      <Box pt={3}>
        <Container>
          <Box height={24} />
          <Switch>
            {tabs.map((t, i) => (
              <Route key={t.title} path={t.path} exact={true}>
                {t.component}
              </Route>
            ))}
            <Route>
              <Redirect to={basePath} />
            </Route>
          </Switch>
        </Container>
      </Box>
    </>
  );
}

export default ProfilePageTemplate;
