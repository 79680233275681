import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import { Form, SubmitButton, TextInput } from 'Components/Shared';
import useFormValidation from '../../Hooks/useFormValidation';
import { Imgs } from 'Assets/Img';
import { TUsersCreation } from './FormUsersCreation.container';
import TextInputEmails from 'Components/Shared/Form/Fields/TextInputEmails';
import { useStyles } from './FormUsersCreation.styles';
import { LocalContext } from 'Context/Local.context';
import { useContext, useMemo, useState } from 'react';
import SearchByAccount from 'Components/Shared/SearchBars/SearchByAccount/SearchByAccount';
import { DEBOUNCE_DELAY } from 'Theme/config';
import shortAccountlabel from 'Tools/LabelFormatter/shortAccountLabel';
import { debounce } from 'Tools/debounce';
import { useCurrentUserInfo } from 'Hooks/useCurrentUserInfo';

type Props = {
  onCancel(): void;
  onSubmit(values: TUsersCreation): void;
  loadingData?: boolean;
  loading?: boolean;
  selectorList: IAccount[];
  onSearch: (value: string) => void;
  selectedAccount: IAccount | null;
  selectedAccountCode: string;
  onChooseAccount: (acc: IAccount | null) => void;
};

const FormUsersCreation = ({
  onCancel,
  onSubmit,
  loading,
  loadingData,
  selectorList,
  onSearch,
  selectedAccount,
  selectedAccountCode,
  onChooseAccount
}: Props) => {
  const ts = useTranslate();
  const { currentAccount, availableAccounts } = useContext(LocalContext);
  const {
    isAdminOfOnlyOneAccount,
    hasTooManyAccounts,
    hasLimitedAccounts,
    currentAccountExists
  } = useCurrentUserInfo();
  const [filterValue, setFilterValue] = useState<null | string>(null);

  const { usersCreation } = useFormValidation();
  const classes = useStyles();

  const initValues: TUsersCreation = {
    emails: [],
    account: availableAccounts[0]?.code ?? ''
  };

  const handleClear = () => {
    onSearch('');
    onChooseAccount(null);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    let selectedAcc = availableAccounts.find(acc => acc.code === event.target.value);
    onChooseAccount(selectedAcc ?? null);
  };

  const debouncedSendRequest = useMemo(() => {
    return debounce(onSearch, DEBOUNCE_DELAY);
  }, [onSearch]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    setFilterValue(currentValue.trim());
    debouncedSendRequest(currentValue);
  };

  return (
    <Form<TUsersCreation>
      initialValues={initValues}
      onSubmit={onSubmit}
      validationSchema={usersCreation}
    >
      <Box className={classes.container}>
        <Box className={classes.content} pb={3}>
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid
              item
              container
              justifyContent="center"
              xs={12}
              alignItems="center"
            >
              <Grid item container justifyContent="center" xs={12} md={3}>
                <img
                  width={150}
                  src={Imgs.wizardIllustration.src}
                  alt={Imgs.wizardIllustration.alt}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box pt={3}>
                  <Typography variant="h5" paragraph align="center">
                    {ts('component.modals.createUser.wizard.heading')}
                  </Typography>
                  <Typography variant="body2" align="center">
                    {ts('component.modals.createUser.wizard.subheading')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mt={3} mb={1}>
                {isAdminOfOnlyOneAccount && (
                  <TextInput
                    name="account"
                    variant="outlined"
                    size="medium"
                    label={ts('component.modals.createUser.labels.accountCode')}
                    autoFocus
                    value={shortAccountlabel(availableAccounts[0])}
                    disabled={true}
                  />
                )}
                {!isAdminOfOnlyOneAccount && currentAccount !== null && (
                  <TextInput
                    name="account"
                    variant="outlined"
                    size="medium"
                    label={ts('component.modals.createUser.labels.accountCode')}
                    autoFocus
                    value={shortAccountlabel(currentAccount)}
                    disabled={true}
                  />
                )}
                {!isAdminOfOnlyOneAccount && !currentAccountExists && hasLimitedAccounts && (
                    <Box py={1}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="account">
                          {ts('common.terminology.account')}
                        </InputLabel>
                        <Select
                          id="account"
                          // value={availableAccounts[0].code}
                          defaultValue={''}
                          label={ts(
                            'component.userAccountUpdate.chooseAccount'
                          )}
                          onChange={handleChange}
                          variant="outlined"
                          inputProps={{ id: 'account' }}
                        >
                          {availableAccounts.map((acc: IAccount) => {
                            const tiersName = shortAccountlabel(acc);
                            return (
                              <MenuItem
                                key={acc.code}
                                value={acc.code}
                                role="menuitem"
                                selected={selectedAccountCode === acc.code}
                              >
                                {tiersName}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                {!currentAccountExists && hasTooManyAccounts && (
                  <Box mb={3}>
                    <SearchByAccount
                      name="account"
                      accounts={selectorList}
                      selectedAccount={selectedAccount}
                      filterValue={filterValue ?? ''}
                      handleSearch={handleSearch}
                      handleClear={handleClear}
                      handleChooseAccount={(acc: IAccount) =>
                        onChooseAccount(acc)
                      }
                      loading={loadingData}
                      inputLabel={ts(
                        'component.modals.createUser.labels.accountCode'
                      )}
                    />
                  </Box>
                )}
              </Box>
              <TextInputEmails
                name="emails"
                size="medium"
                multiline
                helperText={ts(
                  'component.modals.createUser.labels.emailsHelperText'
                )}
                label={ts('component.modals.createUser.labels.emails')}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item>
            <Button onClick={onCancel} color="inherit">
              {ts('common.action.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <SubmitButton
              title={ts('component.modals.createUser.submit')}
              loading={loading}
              disabled={selectedAccount === null}
            />
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
};

export default FormUsersCreation;
