import { ReactElement, useContext, createContext, useEffect } from 'react';
import { AppInsightsService as ai, Insights } from './AppInsights';

type Props = {
  children: ReactElement;
};

const AppInsightsContext = createContext<Insights>(ai);

const AppInsightsContextProvider = ({ children }: Props) => {
  const { initialize } = ai;

  // Init Telemetry
  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppInsightsContext.Provider value={ai}>
      {children}
    </AppInsightsContext.Provider>
  );
};

const useAppInsightsContext = () => useContext(AppInsightsContext);

export default ai.withAITracking(AppInsightsContextProvider);
export { AppInsightsContext, useAppInsightsContext };
