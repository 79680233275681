import { FormattedMessage } from 'react-intl';
import { PersonOrder } from '../../Apollo';
import PersonGroupNameCell from '../Shared/PersonGroupNameCell/PersonGroupNameCell';
import UserCellSkeleton from 'Theme/components/UserCellSkeleton';

export const getLightPersonGroupColumns = () => {
  return [
    {
      key: 'group',
      orderBy: {
        asc: PersonOrder.NameAsc,
        desc: PersonOrder.NameDesc
      },
      hidden: false,
      renderHead: () => <FormattedMessage id="page.groups.index.group" />,
      renderSkeleton: () => <UserCellSkeleton />,
      renderCell: ({ row }: { row: ILightPersonGroup }) => (
        <PersonGroupNameCell group={row} />
      )
    }
  ];
};
