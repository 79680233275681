/* eslint-disable react-hooks/rules-of-hooks */
import { PersonContext } from "Context/CurrentPerson";
import { LocalContext } from "Context/Local.context";
import { ACCOUNTS_LIMIT_TO_HEADER_NAVIGATION } from "Theme/config";
import { useContext } from "react";

interface ICurrentUser {
    isAdminOfOnlyOneAccount: boolean;
    isSimpleUser: boolean;
    isAccountAdmin: boolean;
    isServiceAdmin: boolean;
    hasTooManyAccounts: boolean;
    hasLimitedAccounts: boolean;
    hasAccessToAccounts: boolean;
    canAskForAccountAdministrationDelegation: boolean;
    isOrganizationOwner: boolean;
    canWriteAll: boolean;
    isOnlyServiceAdmin: boolean;
    currentAccountExists: boolean;
}

export function useCurrentUserInfo(): ICurrentUser {
    const { currentAccount, availableAccounts } = useContext(LocalContext);
    const { currentPersonRoles } = useContext(PersonContext);

    const simpleUser = !currentPersonRoles.isAccountAdmin && !currentPersonRoles.isServiceAdmin;
    const onlyServiceAdmin = currentPersonRoles.isServiceAdmin && !currentPersonRoles.isAccountAdmin;
    const adminOfOneAccountOnly = availableAccounts.length === 1;
    const adminOfMaxAccounts = availableAccounts.length > ACCOUNTS_LIMIT_TO_HEADER_NAVIGATION;
    const adminOfMoreThanOneAccountAndLessThanLimit = availableAccounts.length > 1 && !adminOfMaxAccounts;

    const currentUser: ICurrentUser = {
        // properties from AdministrationResourceAccess
        isAccountAdmin: currentPersonRoles.isAccountAdmin,
        isServiceAdmin: currentPersonRoles.isServiceAdmin,
        isOrganizationOwner: currentPersonRoles.isOrganizationOwner,
        hasAccessToAccounts: currentPersonRoles.hasAccessToAccounts,
        canAskForAccountAdministrationDelegation: currentPersonRoles.canAskForAccountAdministrationDelegation,
        canWriteAll: currentPersonRoles.canWriteAll,
        // properties calculated in this hook
        isAdminOfOnlyOneAccount: adminOfOneAccountOnly,
        isSimpleUser: simpleUser,
        hasTooManyAccounts: adminOfMaxAccounts,
        hasLimitedAccounts: adminOfMoreThanOneAccountAndLessThanLimit,
        isOnlyServiceAdmin: onlyServiceAdmin,
        currentAccountExists: currentAccount !== null
    };

    return currentUser;

}