import * as React from 'react';
import { useMutation } from '@apollo/client';
import { UpdateServiceSubscriptions } from '../../../../Apollo/mutations/service/UpdateServiceSubscriptions';
import UpdateProperties from './UpdateProperties';
import {
  IServiceSubscription as IServiceSubscriptionInput,
  IServiceSubscriptionProperties
} from './UpdateProperties.types';
import useNotification from 'Hooks/useNotification';

type Props = {
  selectedServiceIds: string[];
  onCollapse: () => void;
  rowIndex: Record<string, IServiceSubscription>;
};

const reduceBool = (a: boolean | null, b: boolean): boolean | null => {
  if (a && b) {
    return true;
  }
  if (a === false && b === false) {
    return false;
  }
  return null;
};

/**
 * @param props a boolean array to analyse
 * @returns true if all values are true, false if all values are false,
 * and null if some values are false and some are true.
 */
const computeInitialValue = (props: boolean[]): boolean | null => {
  if (props.length === 0) {
    return null;
  }
  return props.reduce<boolean | null>(reduceBool, props[0]);
};

const UpdatePropertiesContainer = ({
  selectedServiceIds,
  onCollapse,
  rowIndex
}: Props) => {
  const { onError, onSuccess } = useNotification();
  const [updateService] = useMutation(UpdateServiceSubscriptions, {
    onCompleted: () => onSuccess('page.services.action.updateServicesPropertiesSuccess'),
    onError,
    refetchQueries: ['GetServiceSubscriptions']
  });

  const serviceSubscriptions: IServiceSubscriptionInput[] = React.useMemo(
    () =>
      selectedServiceIds.map(id => {
        const row = rowIndex[id];
        return {
          accountCode: row.account.code,
          targetAccountCode: row.targetAccount?.code,
          serviceCode: row.service.groupCode,
          administratedValidation: row.administratedValidation,
          accessByDefault: row.accessByDefault,
          isAvailable: row.isAvailable
        };
      }),
    [selectedServiceIds, rowIndex]
  );

  const initialProperties: IServiceSubscriptionProperties = React.useMemo(() => {
    return {
      accessByDefault: computeInitialValue(
        serviceSubscriptions.map(s => s.accessByDefault)
      ),
      administratedValidation: computeInitialValue(
        serviceSubscriptions.map(s => s.administratedValidation)
      )
    };
  }, [serviceSubscriptions]);

  const onSubmit = async (data: IServiceSubscriptionProperties) => {
    if (data.accessByDefault === initialProperties.accessByDefault) {
      data.accessByDefault = null;
    }
    if (data.administratedValidation === initialProperties.administratedValidation) {
      data.administratedValidation = null;
    }

    const serviceSubscriptionIdentifiers = serviceSubscriptions.map(({ accountCode, serviceCode, targetAccountCode }) => ({ accountCode, serviceCode, targetAccountCode }));
    await updateService({ variables: { serviceSubscriptionIdentifiers, data } });
    onCollapse();
  };

  return (
    <UpdateProperties
      onCancel={onCollapse}
      onSubmit={onSubmit}
      initialValues={initialProperties}
    />
  );
};

export default UpdatePropertiesContainer;
