import { gql } from '@apollo/client';

/** Return current user's administrated services count */
export const GetServiceSubscriptionsCount = gql`
  query GetServiceSubscriptionsCount(
    $servicesFilter: String
    $accountCode: String
    $page: PageInput
    $orderBy: ServiceSubscriptionOrder
    $apiKeyEligibleOnly: Boolean!
  ) {
    serviceSubscriptions(
      servicesFilter: $servicesFilter
      accountCode: $accountCode
      page: $page
      orderBy: $orderBy
      apiKeyEligibleOnly: $apiKeyEligibleOnly
    ) {
      totalCount
    }
  }
`;
