import { QueryResult, useQuery } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import {
  GetPersonsTotalCount,
  GetPersonsTotalCountData
} from 'Apollo/queries/person/GetPersonsTotalCount';
import useNotification from 'Hooks/useNotification';
import useTranslate from 'Hooks/useTranslate';
import Skeleton from '@mui/lab/Skeleton/Skeleton';
import { Link as RouterLink } from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';
import { DataRow } from 'Theme/components/DataRow';
import { generatePath } from 'react-router';

interface Props {
  account: IAccountWithNotificationSettings;
}

function SectionMembers(props: Readonly<Props>) {
  const { account } = props;
  const ts = useTranslate();
  const { onError } = useNotification();

  const { data, loading }: QueryResult<GetPersonsTotalCountData> = useQuery(
    GetPersonsTotalCount,
    {
      variables: {
        personFilter: {
          accountCode: account.code
        }
      },
      fetchPolicy: 'cache-first',
      onError
    }
  );

  return (
    <DataRow
      label={ts('page.accountProfile.membersSection.members')}
      divider={true}
      action={
        <Button
          component={RouterLink}
          to={generatePath(PrivateRoutes.users.path, {
            accountCode: account.code
          })}
          variant="text"
          color="primary"
        >
          {ts('page.accountProfile.membersSection.seeAllMembers')}
        </Button>
      }
    >
      {loading && (
        <Skeleton width={120} height={30} variant="rectangular" color="primary" />
      )}
      {!loading && <Typography>{data?.persons?.totalCount}</Typography>}
    </DataRow>
  );
}

export default SectionMembers;
