import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DatePickerButton from 'Components/Shared/DatePickerButton';
import useTranslate from 'Hooks/useTranslate';
import { useState } from 'react';
import { FormattedDate } from 'react-intl';
import TestIds from 'Tests/TestIds';
import Alert from 'Components/Shared/Alert';
import ExpirationDateActionButtons from './ExpirationDateActionButtons';
import { matchPath, useLocation } from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';

interface Props {
  loading: boolean;
  onCancel(): void;
  onSubmit(newExpiryDate: Date | null): void;
  title: string;
  expiryDateOfSelectedId?: Date;
  usersCount: number;
  servicesCount?: number;
}

const ExpirationDrawer = ({
  onCancel,
  onSubmit,
  loading,
  title,
  expiryDateOfSelectedId,
  usersCount,
  servicesCount
}: Props) => {
  const ts = useTranslate();
  const location = useLocation();

  const [expiryDate, setExpiryDate] = useState<Date | null | undefined>(
    expiryDateOfSelectedId ?? undefined
  );
  const [selectedExpiryDate, setSelectedExpiryDate] = useState<
    Date | null | undefined
  >(undefined);

  const handleSetExpiryDate = (d: Date) => {
    setExpiryDate(d);
    setSelectedExpiryDate(d);
  };

  function handleSubmit() {
    if (expiryDate !== undefined) {
      onSubmit(expiryDate);
    }
  }

  function handleRemoveExpiration() {
    setExpiryDate(null);
    setSelectedExpiryDate(null);
  }

  const isServiceProfileMembers = Boolean(
    matchPath(location.pathname, {
      path: PrivateRoutes.serviceProfileMembers.path,
      exact: true
    })
  );

  const isUserProfileServices = Boolean(
    matchPath(location.pathname, {
      path: PrivateRoutes.usersProfileServices.path,
      exact: true
    })
  );

  const severalServices = servicesCount !== 1;
  const existingExpirationDateOnSelected = expiryDateOfSelectedId !== undefined;
  const expiryDateNotAlreadyUpdated = selectedExpiryDate === undefined;
  const removingExpiryDate = selectedExpiryDate === null;
  const newExpiryDateUpdated = !!selectedExpiryDate;

  return (
    <Box m={4}>
      <Grid container direction="column" alignContent="center">
        <Grid item mb={2}>
          <Typography variant="subtitle1" align="center">
            {title}
          </Typography>
        </Grid>

        {/* User profile page / Services list */}
        {isUserProfileServices && (
          <Grid item>
            <>
              {/* Step 1 : an existing expiry date is already set on the selected service */}
              {!!expiryDate && !severalServices && expiryDateNotAlreadyUpdated && (
                <Alert
                  severity="warning"
                  content={
                    <>
                      {ts(
                        'page.serviceProfile.serviceMembers.expirationDateAlert.limitedAccessForOneUserAndOneService'
                      )}
                      <FormattedDate
                        value={expiryDate}
                        year="numeric"
                        month="2-digit"
                        day="2-digit"
                        hour="2-digit"
                        minute="2-digit"
                      />
                    </>
                  }
                />
              )}

              {/* Step 1 : an existing expiry date is already set on several selected services */}
              {severalServices &&
                existingExpirationDateOnSelected &&
                expiryDateNotAlreadyUpdated && (
                  <Alert
                    severity="warning"
                    content={ts(
                      'page.serviceProfile.serviceMembers.expirationDateAlert.existingExpiryDateForOneUserSeveralAccess'
                    )}
                    sx={{ maxWidth: 800 }}
                  />
                )}

              {/* Step 2 : after updating expiry date on 1 Service */}
              {expiryDate !== null && !severalServices && newExpiryDateUpdated && (
                <Alert
                  severity="info"
                  icon={null}
                  content={
                    <>
                      {ts(
                        'page.serviceProfile.serviceMembers.expirationDateAlert.limitedAccessForOneUserAndOneService'
                      )}
                      <FormattedDate
                        value={selectedExpiryDate}
                        year="numeric"
                        month="2-digit"
                        day="2-digit"
                        hour="2-digit"
                        minute="2-digit"
                      />
                    </>
                  }
                />
              )}

              {/* Step 2 : after updating expiry date on SEVERAL Services */}
              {newExpiryDateUpdated && severalServices && (
                <Alert
                  severity="info"
                  icon={null}
                  content={
                    <>
                      {ts(
                        'page.serviceProfile.serviceMembers.expirationDateAlert.expiryDateForOneUserSeveralServicesTitle'
                      )}
                      <FormattedDate
                        value={selectedExpiryDate}
                        year="numeric"
                        month="2-digit"
                        day="2-digit"
                        hour="2-digit"
                        minute="2-digit"
                      />
                    </>
                  }
                />
              )}

              {/* Step 2 : after removing expiry date on 1 Service */}
              {removingExpiryDate && !severalServices && (
                <Alert
                  severity="info"
                  content={ts(
                    'page.serviceProfile.serviceMembers.expirationDateAlert.noLimitForOneAccessTitle'
                  )}
                />
              )}

              {/* Step 2 : After removing expiry date on SEVERAL Services */}
              {severalServices && removingExpiryDate && (
                <Alert
                  severity="info"
                  content={ts(
                    'page.serviceProfile.serviceMembers.expirationDateAlert.noLimitForOneUserAndSeveralAccessTitle'
                  )}
                />
              )}
            </>
          </Grid>
        )}

        {/* Delete or define actions */}
        {!removingExpiryDate && !newExpiryDateUpdated && (
          <Grid item pb={1}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              {(!!expiryDate || isServiceProfileMembers) && (
                <>
                  <Grid item>
                    <Button
                      type="button"
                      size="medium"
                      variant="outlined"
                      color="primary"
                      onClick={handleRemoveExpiration}
                      data-testid={
                        TestIds.component.setExpirationDate
                          .removeExpiryDateButton
                      }
                    >
                      {ts(
                        'page.serviceProfile.serviceMembers.removeExpiration'
                      )}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      {ts('common.terminology.or')}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item>
                <Stack gap={2} flexDirection="row" alignItems="center">
                  <DatePickerButton
                    onAccept={d => handleSetExpiryDate(d)}
                    selectedExpiryDate={expiryDate}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Service profile page / Members list */}
        {isServiceProfileMembers && (
          <Grid item>
            {removingExpiryDate && (
              <Alert
                severity="info"
                content={ts(
                  'page.serviceProfile.serviceMembers.expirationDateAlert.updateForOnServiceSeveralUsersTitle'
                )}
                sx={{ maxWidth: 800 }}
              />
            )}
            {newExpiryDateUpdated && (
              <Alert
                severity="info"
                content={
                  <>
                    {ts(
                      'page.serviceProfile.serviceMembers.expirationDateAlert.selectedUserCanAccessTheServiceUntil',
                      { count: usersCount }
                    )}
                    <FormattedDate
                      value={selectedExpiryDate}
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                      hour="2-digit"
                      minute="2-digit"
                    />
                  </>
                }
                sx={{ maxWidth: 800 }}
              />
            )}
          </Grid>
        )}

        {/* Action buttons */}
        <ExpirationDateActionButtons
          expiryDate={selectedExpiryDate}
          loading={loading}
          handleSubmit={handleSubmit}
          onCancel={onCancel}
        />
      </Grid>
    </Box>
  );
};

export default ExpirationDrawer;
