import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    '& table>tbody>tr>td:nth-last-child(-n+2), & table>thead>tr>th:nth-last-child(-n+2)': {
      textAlign: 'center'
    }
  },
  toolbar: {
    display: 'flex',
    padding: theme.spacing(1)
  },
  toolbarLeft: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  toolbarRight: {}
}));
