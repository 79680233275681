import { useContext, useState } from 'react';
import {
  CreatePersonAccessRightsForSingleUser,
  GetPersonAccessRights
} from 'Apollo';
import getPendingSuccessResult from 'Tools/getPendingSuccessResult';
import AddServicesDrawer from './AddServicesDrawer';
import { useMutation } from '@apollo/client';
import useNotification from 'Hooks/useNotification';
import ErrorHandlerContext from 'Context/ErrorHandler.context';

interface Props {
  person: IPerson;
  currentPerson: IPersonWithAccount;
  isAdminMode: boolean;
  onclose: () => void;
  isMobileP: boolean;
}

function AddServicesDrawerContainer(props: Props) {
  const { person, isAdminMode, onclose, currentPerson, isMobileP } = props;
  const { onSuccess } = useNotification();
  const ErrorHandler = useContext(ErrorHandlerContext);
  const [selectedIds, setSelectIds] = useState<string[] | null>([]);

  const handleUpdate = (value: string[] | null) => {
    setSelectIds(value);
  };

  let qVariables = {
    personIdentifier: {
      email: isAdminMode ? person.email : null
    },
    accessRightData:
      selectedIds == null
        ? null
        : selectedIds.map(id => JSON.parse(id))
  };

  const [
    createPersonAccessRightsForSingleUser,
    { loading: isCreatingSubscription }
  ] = useMutation(CreatePersonAccessRightsForSingleUser, {
    onCompleted: (data: CreatePersonAccessRightsForSingleUserData) => {
      if (data.createPersonAccessRightsForSingleUser[0].error) {
        const errorMessages =
          data.createPersonAccessRightsForSingleUser[0].error.message;
        ErrorHandler.handle([errorMessages]);
        return;
      }

      const { pending, success } = getPendingSuccessResult(data);
      if (success.length > 0) {
        if (person.immutableId !== currentPerson.immutableId) {
          if (selectedIds?.length === 1) {
            onSuccess('page.users.subscription.success.oneToOne');
          } else {
            onSuccess('page.users.subscription.success.oneToMany', {
              servicesCount: success.length
            });
          }
        } else {
          if (selectedIds?.length === 1) {
            onSuccess('page.users.subscription.success.meToOne');
          } else {
            onSuccess('page.users.subscription.success.meToMany', { servicesCount: success.length });
          }
        }
      }
      if (pending.length > 0) {
        onSuccess('page.userProfile.services.completedPending', { count: pending.length });
      }
    },
    refetchQueries: [GetPersonAccessRights]
  });

  const onCreatePersonAccessRightsForSingleUser = () => {
    createPersonAccessRightsForSingleUser({
      variables: qVariables
    });
    onclose();
  };

  return (
    <AddServicesDrawer
      person={person}
      isAdminMode={isAdminMode}
      selectedIds={selectedIds}
      onUpdate={handleUpdate}
      onCancel={onclose}
      isCreatingSubscription={isCreatingSubscription}
      onCreate={onCreatePersonAccessRightsForSingleUser}
      isMobileP={isMobileP}
    />
  );
}

export default AddServicesDrawerContainer;
