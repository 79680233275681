import makeStyles from '@mui/styles/makeStyles';
import ListItemIcon, {
  ListItemIconProps
} from '@mui/material/ListItemIcon';
import * as React from 'react';

const useStyles = makeStyles({
  root: {
    color: 'inherit',
    marginRight: 0
  }
});

interface Props extends ListItemIconProps {}

function NavigationListItemIcon(props: Props) {
  const classes = useStyles();
  return <ListItemIcon {...props} classes={{ root: classes.root }} />;
}

export default NavigationListItemIcon;
