import { useMutation } from '@apollo/client';
import { Drawer } from '@mui/material';
import { CreatePersonAccessRights } from 'Apollo';
import useNotification from 'Hooks/useNotification';
import ErrorHandlerContext from 'Context/ErrorHandler.context';
import { useContext, useState } from 'react';
import { ServiceMembersMode } from '../ServiceMembersPanel.container';
import { useStyles } from './Drawer.styles';
import ServiceCandidates from 'Components/ServiceCandidates/ServiceCandidates';

interface Props {
  serviceSubscriptionIdentifier: IServiceSubscriptionIdentifier;
  allowExternalInvitation: boolean;
  setMode: (mode: ServiceMembersMode) => void;
  refetch: () => void;
}

function InviteMembersDrawer(props: Readonly<Props>) {
  const {
    allowExternalInvitation,
    serviceSubscriptionIdentifier,
    refetch,
    setMode
  } = props;
  const classes = useStyles();
  const ErrorHandler = useContext(ErrorHandlerContext);

  const { onSuccess } = useNotification();

  const [selectedNewUsersIds, setSelectedNewUsersIds] = useState<string[]>([]);

  const [createAccessRights, { loading: loadingAdd }] = useMutation(
    CreatePersonAccessRights
  );

  const inviteMembers = async () => {
    await createAccessRights({
      onCompleted: data => {
        const errors = data.createPersonAccessRights
          .map((r: any) => r.error?.message)
          .filter((e: string) => !!e);

        if (errors && errors.length > 0) {
          ErrorHandler.handle(errors);
        } else {
          onSuccess(
            'page.serviceProfile.serviceMembers.invitationResult.existing'
          );
        }
      },
      variables: {
        accessRightData: [serviceSubscriptionIdentifier],
        personIdentifiers: selectedNewUsersIds
      }
    });

    setMode(null);
    refetch();
  };

  return (
    <Drawer
      anchor="bottom"
      variant="temporary"
      open={true}
      classes={{ paper: classes.drawer }}
    >
      <ServiceCandidates
        setSelectedNewUsersIds={setSelectedNewUsersIds}
        onClose={() => {
          setMode(null);
        }}
        onInvite={inviteMembers}
        onInviteExternal={() => {
          setMode('invite');
        }}
        allowExternalInvitation={allowExternalInvitation}
        loading={loadingAdd}
      />
    </Drawer>
  );
}

export default InviteMembersDrawer;
