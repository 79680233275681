import { Theme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
    inputRoot: {
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.text.secondary,
        [theme.breakpoints.only('xs')]: {
            '@media (orientation: portrait)': {
                fontSize: '12px',
            }
        }
    }
}))