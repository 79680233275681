import { Button } from '@mui/material';
import useTranslate from 'Hooks/useTranslate';
import { Link as RouterLink } from 'react-router-dom';
import { PrivateRoutes } from 'Router/Routes';
import  DataRow  from 'Theme/components/DataRow/DataRow';
import { generatePath } from 'react-router';

interface Props {
  account: IAccountWithNotificationSettings;
}

function SectionNotification(props: Readonly<Props>) {
  const { account } = props;
  const ts = useTranslate();

  return (
    <DataRow
      label={ts('page.accountProfile.membersSection.notificationSettings')}
      action={
        <Button
          component={RouterLink}
          to={generatePath(PrivateRoutes.notifications.path, {
            accountCode: account.code
          })}
          variant="text"
          color="primary"
        >
          {ts('common.action.edit')}
        </Button>
      }
    >
    </DataRow>
  );
}

export default SectionNotification;
