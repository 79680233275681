import { TableCell, TableRow, Typography, Theme, Hidden } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { PersonAvatar } from 'Theme/components';
import { FormattedMessage } from 'react-intl';
import RemoveMemberButton from './RemoveMemberButton';
import { userFullName } from 'Tools';

const styles = (theme: Theme) =>
  createStyles({
    label: {
      width: theme.spacing(5),
      padding: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(
        1
      )} ${theme.spacing(2)}`
    },
    status: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`
    },
    identity: {
      padding: theme.spacing(2)
    }
  });

interface Props extends WithStyles<typeof styles> {
  person: IPersonWithAccount;
  personGroup: IPersonGroup;
  onRefetch: any;
}

function PersonGroupPersonsRow(props: Props) {
  const accountName =
    props.person?.account?.name
      ? props.person.account.name
      : '';
  const accountCode =
    props.person?.account?.code
      ? props.person.account.code
      : '';
  const zipcode =
    props.person?.account?.zipCode
      ? props.person.account.zipCode
      : '';
  const city =
    props.person?.account?.city
      ? props.person.account.city
      : '';

  const rowContent = `${accountName +
    ' - ' +
    zipcode +
    ' ' +
    city +
    ' / ' +
    accountCode}`;

  const personStatus = (
    <>
      {props.person?.status === 'TOACTIVATE' ? (
        <FormattedMessage id="page.userProfile.section.personal.status.toActivate" />
      ) : props.person?.status === 'ACTIVATED' ? (
        <FormattedMessage id="page.userProfile.section.personal.status.activated" />
      ) : props.person?.status === 'CREATED' ? (
        <FormattedMessage id="page.userProfile.section.personal.status.created" />
      ) : (
        <FormattedMessage id="page.userProfile.section.personal.status.disabled" />
      )}
    </>
  );

  return (
    <TableRow>
      <TableCell classes={{ root: props.classes.label }}>
        <PersonAvatar person={props.person} size="medium" role='avatar'/>
      </TableCell>
      <TableCell classes={{ root: props.classes.identity }}>
        <Typography variant="subtitle1">
          {userFullName(props.person.firstName, props.person.lastName)}
        </Typography>
        <Typography variant="body2">
          {props.person && props.person.email ? props.person.email : ''}
        </Typography>
        <Hidden smUp>
          <Typography variant="subtitle1">{rowContent}</Typography>
        </Hidden>
        <Hidden smUp>
          <Typography variant="body2">{personStatus}</Typography>
        </Hidden>
      </TableCell>
      <Hidden only="xs">
        <TableCell>
          <Typography variant="subtitle1">{rowContent}</Typography>
        </TableCell>
        <TableCell classes={{ root: props.classes.status }}>
          <Typography variant="body2">{personStatus}</Typography>
        </TableCell>
        <TableCell classes={{ root: props.classes.status }}>
          <RemoveMemberButton
            person={props.person}
            personGroup={props.personGroup}
            onRefetch={props.onRefetch}
          />
        </TableCell>
      </Hidden>
    </TableRow>
  );
}

export default withStyles(styles)(PersonGroupPersonsRow);
