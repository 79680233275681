import { useState } from 'react';
import ActionsPopover from './ActionsPopover/ActionsPopover';
import LinkToNewAccountContainer from './LinkToNewAccount/LinkToNewAccount.container';
import UnLinkAccountContainer from './UnLinkAccount/UnLinkAccount.container';

interface Props {
  person: IPersonWithAccount;
  accessRights: IPersonAccessRight[];
}

function UserAccountUpdate(props: Readonly<Props>) {
  const { person, accessRights } = props;

  const [popup1Open, setPopup1Open] = useState<boolean>(false);
  const [popup2Open, setPopup2Open] = useState<boolean>(false);

  const handleOpenPopover1 = () => {
    setPopup1Open(true);
  };

  const handleClosePopover1 = () => {
    setPopup1Open(false);
  };

  const handleOpenPopover2 = () => {
    setPopup2Open(true);
  };

  const handleClosePopover2 = () => {
    setPopup2Open(false);
  };

  return (
    <>
      <ActionsPopover
        person={person}
        action1={handleOpenPopover1}
        action2={handleOpenPopover2}
      />
      <UnLinkAccountContainer
        person={person}
        accessRights={accessRights}
        isOpen={popup1Open}
        onClose={handleClosePopover1}
      />
      <LinkToNewAccountContainer
        person={person}
        accessRights={accessRights}
        isOpen={popup2Open}
        onClose={handleClosePopover2}
      />
    </>
  );
}

export default UserAccountUpdate;
